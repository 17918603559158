import React from 'react'
import { PackagesOptions } from '../Components/packagesOptions'

const Packages = () => {
  return (
   <>
   <PackagesOptions />
   </>
  )
}

export default Packages
