//  This File have Header Part Present in Top of the Home Page 
// "redirectSignIn": "https://www.vimanayaatra.com",
    // "redirectSignOut": "https://www.vimanayaatra.com",

import { Button, Flex ,View,Card, Text, Badge, Authenticator, Heading, useTheme, useAuthenticator, Image} from '@aws-amplify/ui-react';
import React, { useEffect, useRef, useState } from 'react';
import { Amplify } from 'aws-amplify';
import config from '../../amplifyconfiguration.json'
import { Link, useNavigate } from 'react-router-dom';
import { usePopper } from 'react-popper';
import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';

import { signOut } from 'aws-amplify/auth';
import { FaAlignJustify } from 'react-icons/fa';
import Drawer from '../Drawer';
Amplify.configure(config);
const styles = {
  Header: {
    top: "0px",
    left: "1px",
    width: "100%",
    height: "102px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 2px 10px rgba(3,3,3,0.1)",
  },
  VimanaYaatraText: {
    color: "#007aff",
    fontSize: "24px",
    fontFamily: "Montserrat",
    fontWeight: 600,
    lineHeight: "36px",
    opacity: 0.9,
  },
  Text: {
    color: "#030303",
    fontSize: "14px",
    fontFamily: "Montserrat",
    fontWeight: 300,
    top: "30px",
    lineHeight: "22px",
  },
  WhatsaapIcon: {
    color: "#030303",
    fill: "#030303",
    fontSize: "40px",
    top: "30px",
    left: "982px",
    width: "40px",
    height: "40px",
  },
  PhoneIcon: {
    color: "#030303",
    fill: "#030303",
    fontSize: "40px",
    top: "30px",
    left: "1036px",
    width: "40px",
    height: "40px",
  },
  MailIcon: {
    color: "#030303",
    fill: "#030303",
    fontSize: "40px",
    top: "29px",
    left: "1096px",
    width: "40px",
    height: "40px",
  },
  FacebookIcon: {
    color: "#030303",
    fill: "#030303",
    fontSize: "40px",
    top: "29px",
    left: "1162px",
    width: "40px",
    height: "40px",
  },
  Button: {
    // cursor: 'pointer',
    top: "29px",
    left: "1216px",
    width: "108px",
    height: "38px",
    padding: "0px 8px",
    border: "0",
    // boxSizing: 'border-box',
    borderRadius: "24px",
    backgroundColor: "#ec130e",
    color: "#ffffff",
    fontSize: "14px",
    fontFamily: "Montserrat",
    lineHeight: "18px",
    outline: "none",
  },
  PopupCard: {
    right: "50px",
    // position: 'absolute',
    top: "80px",
    width: "350px",
    height: "200px",
    //  backgroundColor: 'rgba(255,255,255,0.48)',
    borderRadius: "12px",
    boxShadow: "0px 2px 10px rgba(3,3,3,0.1)",
  },
 
  PopperButton: {
    color: "white",
    height: "2rem",
    width: "10rem",
    marginTop: "0.8rem",
    paddingTop: "0.2rem",
    borderRadius: "36px",
    backgroundColor: "rgb(0, 122, 255)",
  },
};

const Header =  () => {
  const [token, setToken]=useState<any>(null)
  const [isDrawerOpen, setDrawerOpen] = useState(false);
useEffect(()=>{
  async function currentSession() {
    try {
      const {idToken} = (await fetchAuthSession()).tokens ?? { idToken: null };
      if (idToken !== null) {
        setToken(idToken?.payload);
    
      }
    } catch (err) {
      // console.log(err);
    }

  }
 currentSession();
},[])
  
 
  const { user} = useAuthenticator((context) => [context.user]);
  const [isOpen, setIsOpen] = useState(false);
  const navigate=useNavigate();
  const { authStatus } = useAuthenticator(context => [context.authStatus]);
  const {socialProviders} = useAuthenticator(context => [context.initializeMachine]);
// console.log(socialProviders, ":Check");
  function handleSignOut() {
    try {
      signOut();
      setIsOpen(false);
    } catch (error) {
      // console.log("error signing out: ", error);
    }
  }

  // console.log(user, "user");
  const res = user?.signInDetails?.loginId?.charAt(0).toUpperCase();
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popperElementRef.current &&
        !popperElementRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const referenceElement = useRef<HTMLDivElement>(null);
  const popperElementRef = useRef<HTMLDivElement>(null);

  const { attributes } = usePopper(
    referenceElement.current,
    popperElementRef.current,
    {
      placement: "bottom",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 10],
          },
        },
      ],
    }
  );

  return (
    // <Card style={styles.Header}>
    //   <Flex
    //     textAlign={"center"}
    //     alignItems={"flex-start"}
    //     direction={"row"}
    //     justifyContent={"space-between"}
    //     paddingInline={"5rem"}
    //     paddingBlock={"1rem"}
    //   >
    //     <Flex direction={"row"} gap={"1.3rem"} textAlign={"center"}>
    //       <Link to={"/"}>
    //         <Text
    //           data-testid="vimanyatra-header"
    //           style={styles.VimanaYaatraText}
    //         >
    //           VimanaYaatra
    //         </Text>
    //       </Link>
    //       <Flex
    //         direction={"row"}
    //         gap={"2rem"}
    //         textAlign={"center"}
    //         alignItems={"center"}
    //       >
    //         <Text
    //           style={{ ...styles.Text, cursor: "pointer" }}
    //           onClick={() => navigate("/")}
    //           textAlign={"center"}
    //         >
    //           Flights
    //         </Text>
    //         <Text
    //           style={{ ...styles.Text, cursor: "pointer" }}
    //           onClick={() => navigate("/car")}
    //           textAlign={"center"}
    //         >
    //           Car
    //         </Text>
    //         <Text
    //           style={{ ...styles.Text, cursor: "pointer" }}
    //           onClick={() => navigate("/hotel")}
    //           textAlign={"center"}
    //         >
    //           Hotels
    //         </Text>
    //         <Text style={styles.Text} textAlign={"center"}>
    //           Destinations
    //         </Text>
    //         <Text style={styles.Text} textAlign={"center"}>
    //           Top Picks
    //         </Text>
    //         <Text
    //           style={{ ...styles.Text, cursor: "pointer" }}
    //           textAlign={"center"}
    //           onClick={() => {
    //             navigate("/leadform");
    //           }}
    //         >
    //           Fill LeadForm
    //         </Text>
    //       </Flex>
    //     </Flex>
    //     <Flex>
    //       <svg style={styles.WhatsaapIcon} viewBox="0 0 24 24">
    //         <path fill="none" d="M0 0h24v24H0z"></path>
    //         <path d="M19.05 4.91A9.816 9.816 0 0 0 12.04 2c-5.46 0-9.91 4.45-9.91 9.91 0 1.75.46 3.45 1.32 4.95L2.05 22l5.25-1.38c1.45.79 3.08 1.21 4.74 1.21 5.46 0 9.91-4.45 9.91-9.91 0-2.65-1.03-5.14-2.9-7.01zm-7.01 15.24c-1.48 0-2.93-.4-4.2-1.15l-.3-.18-3.12.82.83-3.04-.2-.31a8.264 8.264 0 0 1-1.26-4.38c0-4.54 3.7-8.24 8.24-8.24 2.2 0 4.27.86 5.82 2.42a8.183 8.183 0 0 1 2.41 5.83c.02 4.54-3.68 8.23-8.22 8.23zm4.52-6.16c-.25-.12-1.47-.72-1.69-.81-.23-.08-.39-.12-.56.12-.17.25-.64.81-.78.97-.14.17-.29.19-.54.06-.25-.12-1.05-.39-1.99-1.23-.74-.66-1.23-1.47-1.38-1.72-.14-.25-.02-.38.11-.51.11-.11.25-.29.37-.43s.17-.25.25-.41c.08-.17.04-.31-.02-.43s-.56-1.34-.76-1.84c-.2-.48-.41-.42-.56-.43h-.48c-.17 0-.43.06-.66.31-.22.25-.86.85-.86 2.07 0 1.22.89 2.4 1.01 2.56.12.17 1.75 2.67 4.23 3.74.59.26 1.05.41 1.41.52.59.19 1.13.16 1.56.1.48-.07 1.47-.6 1.67-1.18.21-.58.21-1.07.14-1.18s-.22-.16-.47-.28z"></path>
    //       </svg>
    
    //       <svg style={styles.FacebookIcon} viewBox="0 0 512 512">
    //         <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"></path>
    //       </svg>
                    
    //       {authStatus === "authenticated" ? (
    //         <Badge
    //           size="large"
    //           variation="error"
    //           style={{ cursor: "pointer" }}
    //           onClick={() => setIsOpen(!isOpen)}
    //         >
    //           {res}
    //         </Badge>
    //       ) : (
    //         <Button
    //           style={styles.Button}
    //           onClick={() => navigate("/login")}
    //           data-testid="logout-button"
    //         >
    //           Log in
    //         </Button>
    //       )}
    //       {isOpen && (
    //         <Card
    //           ref={popperElementRef}
    //           data-testid="modal"
    //           style={{ ...styles.PopupCard, position: "absolute" }}
    //           {...attributes.popper}
    //         >
    //           <Flex direction={"column"} gap={"1rem"} alignItems={"center"}>
    //             <Text>{user?.username}</Text>
    //             <Text>{user?.signInDetails?.loginId}</Text>
    //             {
    //               <Authenticator>
    //                 {({ signOut }) => (
    //                   <Button
    //                     style={styles.PopperButton}
    //                     onClick={handleSignOut}
    //                   >
    //                     Sign Out
    //                   </Button>
    //                 )}
    //               </Authenticator>
    //             }

    //             {/* <Button style={styles.PopperButton}  onClick={handleSignOut}>Sign Out</Button> */}
    //           </Flex>
    //         </Card>
    //       )}
    //     </Flex>
    //   </Flex>
    // </Card>

<>
    <div className="fixed top-0 w-full h-[103px] p-4 left-[1px] bg-white" style={{  boxShadow: '0px 2px 10px rgba(3,3,3,0.1)' }}>

      
    <div className='text-center flex items-center justify-between py-4'>

    <div className="sm:hidden bg-blue-500 text-white p-2 rounded" onClick={() => setDrawerOpen(true)}>
        <FaAlignJustify />
        </div>
  
  <Drawer isOpen={isDrawerOpen} onClose={() => setDrawerOpen(false)}>
    <div className="flex flex-col gap-4 p-4">
      <div className="text-[#030303] font-sans font-medium text-sm lg:font-semibold leading-[28px] cursor-pointer" onClick={() => { navigate("/"); setDrawerOpen(false); }}>
        Flights
      </div>
      <div className="text-[#030303] font-Montserrat font-medium text-sm lg:font-semibold leading-[28px] cursor-pointer" onClick={() => { navigate("/hotel"); setDrawerOpen(false); }}>
        Hotels
      </div>
      <div className="text-[#030303] font-Montserrat font-medium text-sm lg:font-semibold leading-[28px] cursor-pointer" onClick={() => setDrawerOpen(false)}>Destinations</div>
      <div className="text-[#030303] font-Montserrat font-medium text-sm lg:font-semibold leading-[28px] cursor-pointer" onClick={() => setDrawerOpen(false)}>Top Picks</div>
      <div className="text-[#030303] font-Montserrat font-medium text-sm lg:font-semibold leading-[28px] cursor-pointer" onClick={() => { navigate("/leadform"); setDrawerOpen(false); }}>
        Fill LeadForm
      </div>
      <div className="text-[#030303] font-Montserrat font-medium text-sm lg:font-semibold leading-[28px] cursor-pointer" onClick={() => { navigate("/travelpage"); setDrawerOpen(false); }}>
        Things to do
      </div>
    </div>
  </Drawer>
    {/* <Flex direction={"row"} gap={"1.3rem"} textAlign={"center"}> */}
      <div className='flex gap-3 text-center'>

      <Link to={"/"}>
        <div className="sm:text-xl text-2xl xl:text-4xl lg:text-2xl font-montserrat text-[#007aff] font-semibold opacity-90" style={{  lineHeight: '36px' }}
          data-testid="vimanyatra-header"
          
        >
          VimanaYaatra
        </div>
      </Link>
      <div className='sm:flex hidden  gap-4 lg:gap-8 text-center items-center'
        
      >
        <div className='text-[#030303] font-sans font-medium text-sm md:font-semibold leading-[28px] text-center md:text-md lg:text-lg cursor-pointer'
          // style={{ ...styles.Text, cursor: "pointer" }}
          onClick={() => navigate("/")}
         
        >
          Flights
        </div>
        <div className='text-[#030303] font-Montserrat font-medium text-sm md:font-semibold leading-[28px] text-center md:text-md lg:text-lg cursor-pointer'
          // style={{ ...styles.Text, cursor: "pointer" }}
          onClick={() => navigate("/car")}
         
        >
          Cars
        </div>
        <div className='text-[#030303] font-Montserrat  font-medium text-sm md:font-semibold leading-[28px] text-center md:text-md lg:text-lg cursor-pointer'
          onClick={() => navigate("/hotel")}
         
        >
          Hotels
        </div>
        <div className='text-[#030303] font-Montserrat font-medium text-sm md:font-semibold leading-[28px] text-center md:text-md lg:text-lg cursor-pointer'
          // style={{ ...styles.Text, cursor: "pointer" }}
          onClick={() => navigate("/cruises")}
         
        >
          Cruises
        </div>
        <div className='text-[#030303] font-Montserrat font-medium text-sm md:font-semibold leading-[28px] text-center md:text-md lg:text-lg cursor-pointer'
          // style={{ ...styles.Text, cursor: "pointer" }}
          onClick={() => navigate("/Packages")}
         
        >
          Packages
        </div>
        <div className='text-[#030303] font-Montserrat font-medium text-sm md:font-semibold leading-[28px] text-center md:text-md lg:text-lg cursor-pointer'
          // style={{ ...styles.Text, cursor: "pointer" }}
          onClick={() => navigate("/travelpage")}
         
        >
          Things to do
        </div>
        <div className='text-[#030303] font-Montserrat  font-medium text-sm md:font-semibold leading-[28px] text-center md:text-md lg:text-lg cursor-pointer'
         > Destinations
        </div>
        <div className='text-[#030303] font-Montserrat  font-medium text-sm md:font-semibold leading-[28px] text-center md:text-md lg:text-lg cursor-pointer'
         >
          Top Picks
        </div>
        <div className='text-[#030303] font-Montserrat  font-medium text-sm md:font-semibold leading-[28px] text-center md:text-md lg:text-lg cursor-pointer'
         onClick={() => {
            navigate("/leadform");
          }}
        >
          Fill LeadForm
        </div>
        <div className='text-[#030303] font-Montserrat  font-medium text-sm md:font-semibold leading-[28px] text-center md:text-md lg:text-lg cursor-pointer'
         onClick={() => {
            navigate("/train");
          }}
        >
          Train
        </div>
      </div>
      </div>
  
    <Flex>
      <div className='md:flex hidden'>

      <svg style={styles.WhatsaapIcon} viewBox="0 0 24 24">
        <path fill="none" d="M0 0h24v24H0z"></path>
        <path d="M19.05 4.91A9.816 9.816 0 0 0 12.04 2c-5.46 0-9.91 4.45-9.91 9.91 0 1.75.46 3.45 1.32 4.95L2.05 22l5.25-1.38c1.45.79 3.08 1.21 4.74 1.21 5.46 0 9.91-4.45 9.91-9.91 0-2.65-1.03-5.14-2.9-7.01zm-7.01 15.24c-1.48 0-2.93-.4-4.2-1.15l-.3-.18-3.12.82.83-3.04-.2-.31a8.264 8.264 0 0 1-1.26-4.38c0-4.54 3.7-8.24 8.24-8.24 2.2 0 4.27.86 5.82 2.42a8.183 8.183 0 0 1 2.41 5.83c.02 4.54-3.68 8.23-8.22 8.23zm4.52-6.16c-.25-.12-1.47-.72-1.69-.81-.23-.08-.39-.12-.56.12-.17.25-.64.81-.78.97-.14.17-.29.19-.54.06-.25-.12-1.05-.39-1.99-1.23-.74-.66-1.23-1.47-1.38-1.72-.14-.25-.02-.38.11-.51.11-.11.25-.29.37-.43s.17-.25.25-.41c.08-.17.04-.31-.02-.43s-.56-1.34-.76-1.84c-.2-.48-.41-.42-.56-.43h-.48c-.17 0-.43.06-.66.31-.22.25-.86.85-.86 2.07 0 1.22.89 2.4 1.01 2.56.12.17 1.75 2.67 4.23 3.74.59.26 1.05.41 1.41.52.59.19 1.13.16 1.56.1.48-.07 1.47-.6 1.67-1.18.21-.58.21-1.07.14-1.18s-.22-.16-.47-.28z"></path>
      </svg>
      </div>
      <div className='md:flex hidden'>

      <svg style={styles.FacebookIcon} viewBox="0 0 512 512">
        <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"></path>
      </svg>
      </div>
                
      {authStatus === "authenticated" ? (
        <Badge
          size="large"
          variation="error"
          style={{ cursor: "pointer" }}
          onClick={() => setIsOpen(!isOpen)}
        >
          {res}
        </Badge>
      ) : (
        <Button
          style={styles.Button}
          onClick={() => navigate("/login")}
          data-testid="logout-button"
        >
          Log in
        </Button>
      )}
      {isOpen && (
        <Card
          ref={popperElementRef}
          data-testid="modal"
          style={{ ...styles.PopupCard, position: "absolute" }}
          {...attributes.popper}
        >
          <Flex direction={"column"} gap={"1rem"} alignItems={"center"}>
            <Text>{user?.username}</Text>
            <Text>{user?.signInDetails?.loginId}</Text>
            {
              <Authenticator>
                {({ signOut }) => (
                  <Button
                    style={styles.PopperButton}
                    onClick={handleSignOut}
                  >
                    Sign Out
                  </Button>
                )}
              </Authenticator>
            }

            {/* <Button style={styles.PopperButton}  onClick={handleSignOut}>Sign Out</Button> */}
          </Flex>
        </Card>
      )}
    </Flex>
    </div>
    </div>
<div className='py-14'></div>
</>
  );
};

export default Header;