export const boxes = [
    {
      title:"stay in new york",
      traveller:"2 traveller . 1 Room",
      date:"26th may 2024 to 27th may 2024"
    },
    {
      title:"Stay in Los Angeles",
      traveller:"4 traveller . 3 Room",
      date:"15th July 2024 to 18th July 2024"
    },
    {
      title:"Stay in Chicago",
      traveller:"1 traveller . 2 Room",
      date:"7th March 2024 to 10th March 2024"
    },
    {
      title:"Stay in Miami",
      traveller:"3 traveller . 1 Room",
      date:"22nd September 2024 to 24th September 2024"
    },
    {
      title:"Stay in San Francisco",
      traveller:"2 traveller . 3 Room",
      date:"9th August 2024 to 12th August 2024"
    },
    {
      title:"Stay in New York",
      traveller:"4 traveller . 2 Room",
      date:"5th June 2024 to 7th June 2024"
    },
    {
      title:"Stay in Los Angeles",
      traveller:"1 traveller . 1 Room",
      date:"18th April 2024 to 20th April 2024"
    },
    {
      title:"Stay in Chicago",
      traveller:"2 traveller . 2 Room",
      date:"11th May 2024 to 14th May 2024"
    },
    {
      title:"Stay in Miami",
      traveller:"3 traveller . 3 Room",
      date:"30th October 2024 to 2nd November 2024"
    },
    {
      title:"Stay in San Francisco",
      traveller:"1 traveller . 2 Room",
      date:"2nd February 2024 to 4th February 2024"
    },
    {
      title:"Stay in New York",
      traveller:"2 traveller . 1 Room",
      date:"28th December 2024 to 30th December 2024"
    },
    {
      title:"Stay in Los Angeles",
      traveller:"3 traveller . 1 Room",
      date:"13th January 2024 to 15th January 2024"
    },
    {
      title:"Stay in Chicago",
      traveller:"4 traveller . 3 Room",
      date:"20th March 2024 to 23rd March 2024"
    },
    {
      title:"Stay in Miami",
      traveller:"2 traveller . 2 Room",
      date:"8th November 2024 to 10th November 2024"
    },
    {
      title:"Stay in San Francisco",
      traveller:"1 traveller . 1 Room",
      date:"17th June 2024 to 19th June 2024"
    }
];



export const thinksToDoListData = [
  {
    name: "New York City Pass",
    day: "9d",
    rating: "4.5/5",
    review: "1,970",
    image: "https://media.istockphoto.com/id/1406960186/photo/the-skyline-of-new-york-city-united-states.jpg?s=612x612&w=0&k=20&c=yZJXNdzq3d5bKgvVzPBahBujpbVUXFyjyl9FN9L7esM=",
    price: "$146",
  },
  {
    name: "Paris Museum Pass",
    day: "5d",
    rating: "4.7/5",
    review: "2,120",
    image: "https://i.natgeofe.com/k/5b396b5e-59e7-43a6-9448-708125549aa1/new-york-statue-of-liberty.jpg",
    price: "$110",
  },
  {
    name: "London Explorer Pass",
    day: "7d",
    rating: "4.6/5",
    review: "1,870",
    image: "https://www.shutterstock.com/shutterstock/photos/248799484/display_1500/stock-photo-new-york-city-dec-times-square-is-a-busy-tourist-intersection-of-neon-art-and-commerce-and-is-248799484.jpg",
    price: "$125",
  },
  {
    name: "Rome City Pass",
    day: "6d",
    rating: "4.8/5",
    review: "1,450",
    image: "https://images.pexels.com/photos/1796715/pexels-photo-1796715.jpeg?cs=srgb&dl=pexels-chaitaastic-1796715.jpg&fm=jpg",
    price: "$135",
  },
  {
    name: "Tokyo Tower Pass",
    day: "4d",
    rating: "4.9/5",
    review: "2,300",
    image: "https://cdn.britannica.com/01/94501-050-7C939333/Big-Ben-London.jpg",
    price: "$90",
  },
  {
    name: "Sydney Opera House Tour",
    day: "3d",
    rating: "4.7/5",
    review: "1,230",
    image: "https://burst.shopifycdn.com/photos/london-uk.jpg?width=1000&format=pjpg&exif=0&iptc=0",
    price: "$75",
  },
  {
    name: "Dubai Desert Safari",
    day: "2d",
    rating: "4.8/5",
    review: "3,100",
    image: "https://static.toiimg.com/photo/53336957/.jpg",
    price: "$100",
  },
  {
    name: "Cape Town Safari",
    day: "5d",
    rating: "4.6/5",
    review: "1,580",
    image: "https://media.istockphoto.com/id/1345426734/photo/eiffel-tower-paris-river-seine-sunset-twilight-france.jpg?s=612x612&w=0&k=20&c=I5rAH5d_-Yyag8F0CKzk9vzMr_1rgkAASGTE11YMh9A=",
    price: "$210",
  },
  {
    name: "San Francisco Bay Cruise",
    day: "2d",
    rating: "4.7/5",
    review: "1,320",
    image: "https://images.unsplash.com/photo-1499856871958-5b9627545d1a?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8fA%3D%3D",
    price: "$50",
  },
  {
    name: "Niagara Falls Tour",
    day: "1d",
    rating: "4.9/5",
    review: "2,450",
    image: "https://cdn.pixabay.com/photo/2021/06/22/16/39/arch-6356637_1280.jpg",
    price: "$60",
  },
];


export const museumsListData = [
  {
    name: "The Louvre",
    day: "1d",
    rating: "4.9/5",
    review: "5320",
    image: "https://media.architecturaldigest.com/photos/5900cc370638dd3b70018b33/16:9/w_2560%2Cc_limit/Secrets%2520of%2520Louvre%25201.jpg",
    price: "$17",
  },
  {
    name: "The British Museum",
    day: "1d",
    rating: "4.8/5",
    review: "4910",
    image: "https://cdn.britannica.com/12/127112-050-F4DD3B7A/British-Museum-London.jpg",
    price: "Free",
  },
  {
    name: "The Metropolitan Museum of Art",
    day: "1d",
    rating: "4.7/5",
    review: "3870",
    image: "https://www.metmuseum.org/-/media/images/visit/met-fifth-avenue/fifthave_teaser.jpg?sc_lang=en",
    price: "$25",
  },
  {
    name: "Vatican Museums",
    day: "1d",
    rating: "4.8/5",
    review: "6200",
    image: "https://m.museivaticani.va/content/dam/museivaticani/immagini/home/01_Musei_del_papa/jcr:content/renditions/original.jpg",
    price: "$20",
  },
  {
    name: "The Uffizi Gallery",
    day: "1d",
    rating: "4.6/5",
    review: "3450",
    image: "https://media.cntraveler.com/photos/5c421f204b27de229775c27d/16:9/w_2560,c_limit/GettyImages-511081519.jpg",
    price: "$20",
  },
  {
    name: "Rijksmuseum",
    day: "1d",
    rating: "4.7/5",
    review: "2930",
    image: "https://media.tacdn.com/media/attractions-splice-spp-674x446/12/33/94/d5.jpg",
    price: "$20",
  },
  {
    name: "The State Hermitage Museum",
    day: "1d",
    rating: "4.9/5",
    review: "4750",
    image: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f2/Hermitage_Museum_in_Saint_Petersburg.jpg/880px-Hermitage_Museum_in_Saint_Petersburg.jpg",
    price: "$15",
  },
  {
    name: "Museo Nacional del Prado",
    day: "1d",
    rating: "4.8/5",
    review: "3860",
    image: "https://cdn.britannica.com/34/194834-131-8ECCF05C/Prado-Museum-Madrid.jpg?w=840&h=460&c=crop",
    price: "$17",
  },
  {
    name: "The Museum of Modern Art",
    day: "1d",
    rating: "4.7/5",
    review: "2910",
    image: "https://media.licdn.com/dms/image/C561BAQEzclYiFTGeMQ/company-background_10000/0/1593468907511/the_museum_of_modern_art_cover?e=2147483647&v=beta&t=dgNFYNE9aRSEukcumS8JIbC2fl8BjePYJKqToeWCBi4",
    price: "$25",
  },
  {
    name: "Tate Modern",
    day: "1d",
    rating: "4.6/5",
    review: "3410",
    image: "https://media.tate.org.uk/aztate-prd-ew-dg-wgtail-st1-ctr-data/images/tate-modern-extension-herzog-de-meuron-london-.width-600_V6eqx5i.jpg",
    price: "Free",
  },
  {
    name: "National Gallery of Art",
    day: "1d",
    rating: "4.8/5",
    review: "3720",
    image: "https://a.travel-assets.com/findyours-php/viewfinder/images/res70/484000/484422-downtown-dc.jpg",
    price: "Free",
  },
  {
    name: "The Art Institute of Chicago",
    day: "1d",
    rating: "4.7/5",
    review: "3190",
    image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzhpn0ayje1G8XepQkN7RZxBqRx28TZ6CGBA&s",
    price: "$25",
  }
];

