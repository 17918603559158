// // This Page Shows the List of Flight which are avaible on our Provided Date and Time.

import {
  Card,
  Divider,
  Text,
  Image,
  Flex,
  Button,
  Badge,
  Tabs,
} from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import "./../App.css";
import { MdOutlineFlightTakeoff } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { FlightInfo } from "../Components/FlightInfo";
import { FareDetails } from "../Components/FareDetails";
import { useTripType } from "../context/tripType";
import { BaggageDetails } from "../Components/BaggageDetails";
import { FormatMonth } from "../Constant/monthFormat";
import { useFlightSearchResult } from "../context/flightSearchResults";
import { useSearch, SearchDetails } from "../context/searchFlight";
import Buttons from "../Components/Buttons";
import { FlightFilter } from "../Components/FlightFilter";
import { TripType } from "../types/searchrequest";
import {
  dateDifference,
  findLowestPrice,
  formatTime,
  maxTimeForFlight,
} from "./helperFunctions/flightList.helper";
import { useFilterContext } from "../context/filterFlightContext";
import { findFilteredData } from "./helperFunctions/calender.helper";
import axios from "axios";
import { useLoading } from "../context/loadingContext";
import { useFlightTypeContext } from "../context/flightTypeContext";
import { toast } from "react-toastify";
import { PackageResult } from "../context/PackagesContext";

function formatLayoverString(layOverList: any) {
  let layoverStrings = [];
  for (const [city, time] of Object.entries(layOverList)) {
    if (time !== "0:00") {
      layoverStrings.push(`${time}h stop in ${city}`);
    }
  }
  return layoverStrings.join(" ");
}

const RecommendationCard = ({
  recommendation,
  searchDetails,
  setLoading,
}: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { setSelectedFlight } = useFlightTypeContext();
  const { packageType, setPackageType} = PackageResult();
console.log(packageType, ':package')
  const returnSegmentflyingTimeMinutes =
    recommendation?.returnSegment?.flyingTime || 0;
  const returnhours = Math.floor(returnSegmentflyingTimeMinutes / 100);
  const returnminutes = returnSegmentflyingTimeMinutes % 100;

  const onwardSegmentflyingTimeMinutes =
    recommendation?.onwardSegment?.flyingTime || 0;
  const onwardhours = Math.floor(onwardSegmentflyingTimeMinutes / 100);
  const onwardminutes = onwardSegmentflyingTimeMinutes % 100;

  const navigate = useNavigate();
  const handleItinerary = () => {
    setIsModalOpen(!isModalOpen);
  };
  const handleSelectFlight = async (recommendation: any) => {
    setLoading(true);
    const payloadObject = {
      numberOfAdults: searchDetails?.numberOfAdults || 0,
      segments: [
        {
          marketingCarrier: recommendation?.onwardSegment?.marketingCarrier,
          legs: recommendation?.onwardSegment?.legs || [],
        },
      ],
    };
    let proxyUrl;
    let endPoint;
    const url = "https://api.vimanayaatra.com/FareInformativeBestPricing";
    if (process.env.NODE_ENV === "development") {
      proxyUrl = "http://localhost:8080/";
      endPoint = proxyUrl + url;
    } else {
      endPoint = `https://${url}`;
    }

    try {
      const response = await axios.post(endPoint, payloadObject, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      if (response.data?.status === "OK") {
        setLoading(false);
        setSelectedFlight(recommendation);
        navigate("/checkout");
      } else {
        setLoading(false);
        console.log("price not match-------------------");
      }
    } catch (error: any) {
      setLoading(false);
      toast("Price Changed");
    }
  };

  const { tripType } = useTripType();

  const styles = {
    Container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "100vh",
    },
    Card: {
      top: "102px",
      left: "64px",
      width: "912px",
      // height: '230px',
      backgroundColor: "rgba(255,255,255,0.48)",
      borderRadius: "24px",
      boxShadow: "0px 2px 10px rgba(3,3,3,0.1)",
    },
    InnerCard: {
      // width: "912px",
      // height: '60px',
      backgroundColor: "#f0f0f0",
      borderRadius: "24px",
      margin: "-16px",
    },
    ImageContainer: {
      top: "230px",
      left: "104px",
      // width: '309px',
      // height: '200px',
      width: "70px",
      height: "70px",
      borderRadius: "8px",
      backgroundImage: "url(./image.png)",
      backgroundPosition: "center center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
    FlightName: {
      color: "#030303",
      fontSize: "32px",
      fontFamily: "Montserrat",
      lineHeight: "42px",
    },
    FlightInfo: {
      color: "#030303",
      fontSize: "20px",
      fontFamily: "Montserrat",
      lineHeight: "32px",
      fontWeight: 600,
    },
    FlightDate: {
      color: "#030303",
      fontSize: "24px",
      fontFamily: "Montserrat",
      fontWeight: 700,
      lineHeight: "31px",
    },
    Boarding: {
      color: "#030303",
      fontSize: "20px",
      fontFamily: "Montserrat",
      lineHeight: "26px",
    },
    Text: {
      color: "#030303",
      fontSize: "40px",
      fontFamily: "Montserrat",
      fontWeight: 600,
      lineHeight: "40px",
      // textAlign: 'center',
    },
    ParaText: {
      color: "#030303",
      fontSize: "16px",
      fontFamily: "Montserrat",
      lineHeight: "26px",
      fontWeight: 400,
      // textAlign: 'center',
    },
  };
  return (
    <>
      <Card
        // style={styles.Card}
        marginBlock={"1rem"}
        className={` ${
          isModalOpen ? "blur" : ""
        } bg-white/50 rounded-[24px] shadow-[0px_2px_10px_rgba(3,3,3,0.1)] w-screen md:w-11/12
        `}
      >
        <Flex direction={"column"} gap={"0.7rem"}>
          <Card style={styles.InnerCard} className=" ">
            <Flex margin={"0px"} height={"0.5rem"} alignItems={"center"}>
              <MdOutlineFlightTakeoff color={"#007aff"} fontSize={24} />
              <Text style={styles.ParaText}> Departure</Text>
            </Flex>
          </Card>
          <Flex
            direction={"row"}
            marginBlock={"0.8rem"}
            justifyContent={"space-between"}
          >
            <Flex width={"10%"} alignItems={"center"}>
              <Image
                src={`https://pics.avs.io/240/80/${recommendation?.onwardSegment?.legs[0]?.operatingCarrier}.png `}
                alt="flight picture"
                width={"80%"}
                height={"50%"}
                objectFit="initial"
              />
            </Flex>
            <Flex
              width={"80%"}
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Flex direction={"column"} gap={"0.8rem"}>
                <Text style={styles.FlightInfo}>
                  {formatTime(
                    recommendation.onwardSegment.legs[0].timeOfDeparture
                  )}
                </Text>
                <Text style={styles.ParaText}>
                  {recommendation.onwardSegment.origin}
                </Text>
              </Flex>
              <Flex direction={"column"} gap={"0.5rem"}>
                <Text
                  style={styles.ParaText}
                >{`${onwardhours}h ${onwardminutes}min`}</Text>
                <Divider />
                <Text style={styles.ParaText}>
                  {recommendation?.onwardSegment?.legs.length <= 1 ? (
                    "non stop"
                  ) : (
                    <>
                      <p>
                        {formatLayoverString(
                          recommendation?.onwardSegment?.layOverList
                        )}
                      </p>
                    </>
                  )}
                </Text>
              </Flex>
              <Flex direction={"column"} gap={"0.8rem"}>
                <Text style={styles.FlightInfo}>
                  {formatTime(
                    recommendation?.onwardSegment?.legs[
                      recommendation.onwardSegment.legs.length - 1
                    ]?.timeOfArrival
                  )}
                </Text>
                <Text style={styles.ParaText}>
                  {recommendation?.onwardSegment?.destination}
                </Text>
              </Flex>
            </Flex>
            <Flex
              direction={"column"}
              justifyContent={"center"}
              width={"15%"}
              marginInlineStart={"0.4rem"}
            >
              <Badge
                size="large"
                paddingInline={"2rem"}
                color={"#007aff"}
                textAlign={"center"}
                variation="info"
              >
                {recommendation?.cabinClass === "M" ? "Economy" : ""}
              </Badge>
            </Flex>
          </Flex>

          {tripType === "round-trip" ? (
            <>
              <Card style={styles.InnerCard} className="">
                <Flex margin={"0px"} height={"0.5rem"} alignItems={"center"}>
                  <MdOutlineFlightTakeoff color={"#007aff"} fontSize={24} />
                  <Text textAlign={"start"} style={styles.ParaText}>
                    {" "}
                    Return
                  </Text>
                </Flex>
              </Card>
              <Flex
                direction={"row"}
                marginBlock={"0.8rem"}
                justifyContent={"space-between"}
              >
                <Flex width={"10%"} alignItems={"center"}>
                  <Image
                    src={`https://pics.avs.io/240/80/${recommendation?.returnSegment?.legs[0]?.operatingCarrier}.png `}
                    alt="flight picture"
                    width={"80%"}
                    height={"50%"}
                    objectFit="initial"
                  />
                </Flex>
                <Flex
                  width={"80%"}
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  <Flex direction={"column"} gap={"0.8rem"}>
                    <Text style={styles.FlightInfo}>
                      {formatTime(
                        recommendation?.returnSegment?.legs[0].timeOfDeparture
                      )}
                    </Text>
                    <Text style={styles.ParaText}>
                      {recommendation.returnSegment?.origin}
                    </Text>
                  </Flex>
                  <Flex direction={"column"} gap={"0.5rem"}>
                    <Text style={styles.ParaText}>
                      {`${returnhours}h ${returnminutes}min`}{" "}
                    </Text>
                    <Divider />
                    <Text style={styles.ParaText}>
                      {recommendation?.returnSegment?.legs.length <= 1 ? (
                        "non stop"
                      ) : (
                        <>
                          <p>
                            {formatLayoverString(
                              recommendation?.returnSegment?.layOverList
                            )}
                          </p>
                        </>
                      )}
                    </Text>
                  </Flex>
                  <Flex direction={"column"} gap={"0.8rem"}>
                    <Text style={styles.FlightInfo}>
                      {formatTime(
                        recommendation.returnSegment.legs[
                          recommendation.returnSegment.legs.length - 1
                        ].timeOfArrival
                      )}
                    </Text>
                    <Text style={styles.ParaText}>
                      {" "}
                      {recommendation.returnSegment?.destination}{" "}
                    </Text>
                  </Flex>
                </Flex>
                <Flex
                  direction={"column"}
                  justifyContent={"center"}
                  width={"15%"}
                  marginInlineStart={"0.4rem"}
                >
                  <Badge
                    size="large"
                    paddingInline={"2rem"}
                    color={"#007aff"}
                    textAlign={"center"}
                    variation="info"
                  >
                    {recommendation?.cabinClass === "M" ? "Economy" : ""}
                  </Badge>
                </Flex>
              </Flex>
            </>
          ) : (
            ""
          )}
        </Flex>
        <Divider color={"#007aff"} backgroundColor={"#007aff"} />
        <Flex
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          marginBlock={"0.2rem"}
        >
          <Buttons
            Text={"Itinerary Details"}
            color="#007aff"
            backgroundColor="transparent"
            width="11rem"
            variation="link"
            onClick={handleItinerary}
          />
          <Flex
            justifyContent={"center"}
            direction={"row"}
            gap={"0.8rem"}
            alignSelf={"center"}
          >
            <Flex direction={"column"} gap={"0.1rem"} alignItems={"center"}>
              <Text style={styles.FlightInfo} color={"#007aff"}>
                USD {recommendation.totalFare}
              </Text>
              <Text style={styles.ParaText}>as per adult</Text>
            </Flex>
            <Flex alignItems={"center"}>
              <Buttons
                Text={"Select"}
                onClick={() => handleSelectFlight(recommendation)}
              />
            </Flex>
          </Flex>
        </Flex>
      </Card>

      {isModalOpen && (
        <Flex
          backgroundColor="white"
          position="fixed"
          left={300}
          top={10}
          style={{ ...styles.Card, zIndex: 1 }}
          direction={"column"}
          // width={'100%'}
          paddingBottom={"15px"}
          // maxHeight="80%"

          overflow="auto"
        >
          <Flex
            backgroundColor={"rgb(0, 122, 255)"}
            color={"white"}
            direction={"row"}
            justifyContent={"space-between"}
            paddingInline={"12px"}
            paddingBlock={"12px"}
          >
            <Flex>
              <Text
                fontSize={20}
                fontWeight="bold"
                color={"white"}
                marginBottom={2}
              >
                Itinerary Details:
              </Text>
              <Divider orientation="vertical"></Divider>
              <Text
                fontSize={20}
                color={"white"}
                fontWeight="bold"
                marginBottom={2}
              >
                Return
              </Text>
              <Divider orientation="vertical"></Divider>
              <Text
                fontSize={20}
                color={"white"}
                fontWeight="bold"
                marginBottom={2}
              ></Text>
              <Divider orientation="vertical"></Divider>
              <Text
                fontSize={20}
                color={"white"}
                fontWeight="bold"
                marginBottom={2}
              >
                {recommendation?.onwardSegment?.origin}-
                {recommendation?.onwardSegment?.destination}
              </Text>
            </Flex>
            <Text
              fontSize={20}
              color={"white"}
              onClick={handleItinerary}
              fontWeight="bold"
              marginBottom={2}
            >
              X
            </Text>
          </Flex>
          <Tabs
            justifyContent="flex-start"
            defaultValue="Flight Info"
            items={[
              {
                label: "Flight Info",
                value: "Flight Info",
                content: (
                  <>
                    <FlightInfo recommendation={recommendation} />
                  </>
                ),
              },
              {
                label: "Fare Details",
                value: "Fare Details",
                content: (
                  <>
                    <FareDetails recommendation={recommendation} />
                  </>
                ),
              },
              {
                label: "Baggage Info",
                value: "Baggage Info",
                content: (
                  <>
                    <BaggageDetails recommendation={recommendation} />
                  </>
                ),
              },
            ]}
          />
          <Flex direction={"row"} alignSelf={"center"} textAlign={"center"}>
            <Button onClick={handleItinerary} variation="primary">
              Close
            </Button>
          </Flex>
        </Flex>
      )}
    </>
  );
};
export const Flightlist = () => {
  const { flightResultData } = useFlightSearchResult();
  const { filters } = useFilterContext();
  const { searchDetails } = useSearch();
  const { setLoading } = useLoading();
  const { tripType } = useTripType();
  console.log(flightResultData, ":flightResultData");
  const [flighResultLocal, setFlighResultLocal] = useState(flightResultData);
  console.log(flighResultLocal, ":flightResultLocal");
  useEffect(() => {
    applyFilters();
  }, [flightResultData, filters]);

  useEffect(() => {
    if (
      flightResultData?.recommendations &&
      flightResultData?.recommendations.length
    ) {
      console.log("called in here0000-------->", flightResultData);
      const lowestPrice = findLowestPrice(
        flightResultData?.recommendations || []
      );
      const searchDetail: SearchDetails = {
        fromAirport: searchDetails?.fromAirport || "",
        toAirport: searchDetails?.toAirport || "",
        typeOfTrip: searchDetails?.typeOfTrip || "",
        departureDate: searchDetails?.departureDate || "",
        returnDate: searchDetails?.returnDate || "",
        sliceandDice: searchDetails?.sliceandDice || false,
        numberOfAdults: searchDetails?.numberOfAdults || 0,
        numberOfChildren: searchDetails?.numberOfChildren || 0,
        numberofInfrants: searchDetails?.numberofInfrants || 0,
        bookingClass: searchDetails?.bookingClass || "",
        lowestPrice: lowestPrice,
        numberOfFlexibleDays: "",
      };
      let searchHistory: SearchDetails[] = JSON.parse(
        localStorage.getItem("searchHistory") || "[]"
      );
      searchHistory.push(searchDetail);
      localStorage.setItem("searchHistory", JSON.stringify(searchHistory));
    }
  }, []);
  console.log(flightResultData, ":flightResultData");
  const applyFilters = () => {
    if (
      flightResultData?.recommendations &&
      flightResultData?.recommendations.length
    ) {
      setFlighResultLocal(findFilteredData(filters, flightResultData));
    }
  };
  const [isFilterVisible, setFilterVisible] = useState(false);

  const toggleFilter = () => {
    setFilterVisible(!isFilterVisible);
  };

  return (
    <>
      <Flex width={"100%"} columnGap={56}>
        <div className="filter h-7/12 overflow-y-auto my-6 sticky top-0 md:w-[25%] hidden md:flex bg-gray-100">
          <FlightFilter />
        </div>
        <div className="md:w-[75%] w-full flex flex-col gap-4 p-4">
          <button
            onClick={toggleFilter}
            className="md:hidden bg-blue-500 text-white px-4 py-2 rounded mb-4"
          >
            Show Filters
          </button>

          {/* <Flex
          width={"75%"}
          direction={"column"}
          gap={"16px"}
          style={{ overflowY: "auto" }}
        > */}
          {flighResultLocal?.recommendations?.map(
            (recommendation: any, index: any) => (
              // <Flex
              //   // direction={"column"}
              //   // gap={"16px"}
              //   justifyContent={"center"}
              //   key={index}
              //   alignItems={"center"}
              //   className={`recomendation  flex-col md:flex-row bg-white shadow-lg rounded-lg w-full `}
              // >
              <RecommendationCard
                key={index}
                recommendation={recommendation}
                searchDetails={searchDetails}
                setLoading={setLoading}
              />
              // </Flex>
            )
          )}
        </div>
      </Flex>
      {isFilterVisible && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
          <div className="bg-white p-4 rounded-lg w-full h-full md:w-3/4 md:h-3/4 overflow-auto relative">
            <button
              onClick={toggleFilter}
              className="absolute top-4 right-4 bg-red-500 text-white px-4 py-2 rounded"
            >
              Close
            </button>
            <FlightFilter />
          </div>
        </div>
      )}
    </>
  );
};
