import React from 'react'
import { Link } from 'react-router-dom'

const CruisePolicy = () => {
  return (
    <>
       <div className='w-full rounded-xl shadow-md bg-gray-200 flex flex-col items-start px-6 py-2 gap-1'>
          <p className='text-lg font-semibold'>Policies</p>
          <Link to={''}>Cruise line cancellation policy</Link>
        </div>

        <div className='w-full flex flex-col items-start p-6 gap-2'>
          <p className='text-xs font-semibold'>Terms and conditions</p>
          <p className='text-xs text-start'>Prices are cruise only, per traveler, including taxes, fees and port expenses and available within last 24 hours. Prices and availability are subject to change and rates are valid for U.S. and Canadian residents only. Cruise lines reserve the right to charge a fuel supplement and any increases in Government taxes, fees and port expenses incurred between date of booking and sailing. See Rules and Restrictions and cruise line general conditions.</p>
        </div>

        <div className='w-full flex flex-col items-center gap-2'>
          <p className='text-xs font-bold'>Tell us how we can improve our site</p>
          <button className='text-blue-600 rounded-full border b-2 border-gray-300 px-3 py-3'>Share feedback</button>
        </div>
    </>
  )
}

export default CruisePolicy
