import React from 'react';
import SearchTravelResults from './travelSearchResults';
import ShowTravel from './showTravel';
import { TravelFilter } from '../Components/filterTravels';
import TravelCarousel from '../Components/travelCarousel';

const TravelPage = () => {
  return (
    <div className="min-h-screen w-screen flex gap-4 flex-col items-center bg-gray-100">
      <ShowTravel />
      <TravelCarousel />
    
    </div>
  );
};

export default TravelPage;
