//  This File having Details of Baggage Which are allowed in Flight (Like  2 bags are allowed in flight of 7kg & 25Kg)


import { Flex, ScrollView, Table, TableBody, TableCell, TableHead, TableRow } from '@aws-amplify/ui-react'
const Baggage=({brand}:any)=>{

  return(
    <>
     <Flex direction={'column'} gap={'12px'}>
    {
     brand.map((flight: any, index: string) => (
    <Table
      caption=""
      highlightOnHover={false}
      size="small"
      variation="bordered">
      <TableHead>
        <TableRow>
          <TableCell as="th">From {flight?.fromAirport}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>{flight?.fromAirport}-{flight?.toAirport}</TableCell>
          <TableCell>Check- In</TableCell>
        </TableRow>
        <TableRow>
          <TableCell> {flight?.fareDetails?.ADT?.passengerType} </TableCell>
          <TableCell>0 Piece(s)</TableCell>
        </TableRow>
      </TableBody>
    </Table>
   
     ))}
    </Flex> 
    </>
  )
}
export const BaggageDetails = ( {recommendation}: any) => {
  return (
    <>
      <ScrollView width="100%" height="300px" maxWidth="99%">
        <Baggage brand={recommendation?.onwardSegment?.legs} />
        <Baggage brand={recommendation?.returnSegment?.legs} />
      </ScrollView>
    </>
  )
}
