// SearchContext.tsx
import React, { ReactNode, createContext, useContext, useState } from 'react';
import { BookingClass, TripType } from '../types/searchrequest';
export interface SearchDetails {
  fromAirport: string;
  toAirport: string;
  departureDate: string;
  returnDate?: string;
  sliceandDice: boolean,
  numberOfAdults: number,
  numberOfChildren?:number;
    numberofInfrants?:number;
  typeOfTrip:any,
  bookingClass:   any, 
  lowestPrice:any,
  numberOfFlexibleDays:any

}

interface SearchContextType {
  isLoading: boolean;
  searchDetails: SearchDetails | null;
  setSearchDetails: (details: SearchDetails) => void;
}

const SearchContext = createContext<SearchContextType | undefined>(undefined);

export const useSearch = () => {
   
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error('useSearch must be used within a SearchProvider');
  }
  return context;
};

export const SearchProvider:React.FC<{ children: ReactNode }> = ({ children }) =>  {
  const [isLoading, setIsLoading] = useState(false);
  const [searchDetails, setSearchDetails] = useState<SearchDetails | null>(null);

  return (
    <SearchContext.Provider value={{ isLoading, searchDetails, setSearchDetails }}>
      {children}
    </SearchContext.Provider>
  );
};
