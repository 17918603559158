import { Amplify } from 'aws-amplify';
import type { WithAuthenticatorProps } from '@aws-amplify/ui-react';
import {  Button, Heading, Image, Text, View, useAuthenticator, useTheme, withAuthenticator } from '@aws-amplify/ui-react';
import config from '../amplifyconfiguration.json';
import { useEffect } from 'react';
import '../App.css'
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { signInWithRedirect } from 'aws-amplify/auth';
const handleSocialSignIn = async () => {
  try {
   
   const data=  await signInWithRedirect({
        provider: 'Google'
     
      });
   
  } catch (error) {
    console.error('Error occurred during sign in:', error);
    
  }
};
const handleFacebookSocialSignIn = async () => {
  try {
   
   const data=  await signInWithRedirect({
        provider: 'Facebook'
     
      });
      console.log(data, 'data 22')
  } catch (error) {
    console.error('Error occurred during sign in:', error);
    
  }
};

const Login = ({ user }: WithAuthenticatorProps) =>  {

    const navigate = useNavigate();
   useEffect(()=>{
    if(user?.username){
       navigate("/"); 
    }
   },  [user])
  
  
   
  return (
    <div className='login-check'>
     
   
    </div>

  );
}
// export default Login;
export default withAuthenticator(Login, 
  {
    
    socialProviders: [ 'google', 'facebook']
  }
);
