export const hotelPricePerDay: any = {
  1: "$200",
  2: "$220",
  3: "$230",
  4: "$240",
  5: "$250",
  6: "$250",
  7: "$260",
  8: "$270",
  9: "$270",
  10: "$700",
  11: "$280",
  12: "$200",
  13: "$280",
  14: "$200",
  15: "$400",
  16: "$240",
  17: "$200",
  18: "$300",
  19: "$250",
  20: "$210",
  21: "$900",
  22: "$230",
  23: "$250",
  24: "$200",
  25: "$260",
  26: "$200",
  27: "$290",
  28: "$400",
  29: "$700",
  30: "$800",
  31: "$345",
};


export const recommantionSample = [
  {
    images: [
      "https://images.trvl-media.com/lodging/62000000/61530000/61523800/61523736/630a3b97.jpg?impolicy=fcrop&w=1200&h=800&p=1&q=medium",
      "https://images.trvl-media.com/lodging/62000000/61530000/61523800/61523736/1b2a7ec2.jpg?impolicy=fcrop&w=1200&h=800&p=1&q=medium",
      "https://images.trvl-media.com/lodging/62000000/61530000/61523800/61523736/630a3b97.jpg?impolicy=fcrop&w=1200&h=800&p=1&q=medium",
    ],
    name: "Room, 1 King Bed, Ocean View",
    size: "675 sq ft",
    wifi: "free",
    sleeps: "Sleeps 3",
    view: "ocean view",
    bedSize: "1 king bed",
    price: "$456",
    total: "$2000",
  },
  {
    images: [
      "https://images.trvl-media.com/lodging/62000000/61530000/61523800/61523736/630a3b97.jpg?impolicy=fcrop&w=1200&h=800&p=1&q=medium",
      "https://images.trvl-media.com/lodging/62000000/61530000/61523800/61523736/1b2a7ec2.jpg?impolicy=fcrop&w=1200&h=800&p=1&q=medium",
      "https://images.trvl-media.com/lodging/62000000/61530000/61523800/61523736/f56bfd4d.jpg?impolicy=fcrop&w=1200&h=800&p=1&q=medium",
    ],
    name: "Luxury Suite, Mountain View",
    size: "800 sq ft",
    wifi: "free",
    sleeps: "Sleeps 4",
    view: "mountain view",
    bedSize: "2 queen beds",
    price: "$600",
    total: "$2500",
  },
  {
    images: [
      "https://images.trvl-media.com/lodging/62000000/61530000/61523800/61523736/630a3b97.jpg?impolicy=fcrop&w=1200&h=800&p=1&q=medium",
      "https://images.trvl-media.com/lodging/62000000/61530000/61523800/61523736/f56bfd4d.jpg?impolicy=fcrop&w=1200&h=800&p=1&q=medium",
      "https://images.trvl-media.com/lodging/62000000/61530000/61523800/61523736/1b2a7ec2.jpg?impolicy=fcrop&w=1200&h=800&p=1&q=medium",
    ],
    name: "Beachfront Bungalow",
    size: "500 sq ft",
    wifi: "free",
    sleeps: "Sleeps 2",
    view: "beachfront view",
    bedSize: "1 queen bed",
    price: "$350",
    total: "$1800",
  },
  {
    images: [
      'https://images.trvl-media.com/lodging/62000000/61530000/61523800/61523736/f56bfd4d.jpg?impolicy=fcrop&w=1200&h=800&p=1&q=medium',
      "https://images.trvl-media.com/lodging/62000000/61530000/61523800/61523736/630a3b97.jpg?impolicy=fcrop&w=1200&h=800&p=1&q=medium",
      "https://images.trvl-media.com/lodging/62000000/61530000/61523800/61523736/1b2a7ec2.jpg?impolicy=fcrop&w=1200&h=800&p=1&q=medium",
    ],
    name: "Cottage, Countryside View",
    size: "600 sq ft",
    wifi: "free",
    sleeps: "Sleeps 2",
    view: "countryside view",
    bedSize: "1 king bed",
    price: "$400",
    total: "$1900",
  },
  {
    images: [
      "https://images.trvl-media.com/lodging/62000000/61530000/61523800/61523736/630a3b97.jpg?impolicy=fcrop&w=1200&h=800&p=1&q=medium",
      "https://images.trvl-media.com/lodging/62000000/61530000/61523800/61523736/1b2a7ec2.jpg?impolicy=fcrop&w=1200&h=800&p=1&q=medium",
      "https://images.trvl-media.com/lodging/62000000/61530000/61523800/61523736/f56bfd4d.jpg?impolicy=fcrop&w=1200&h=800&p=1&q=medium",
    ],
    name: "Riverside Cabin",
    size: "400 sq ft",
    wifi: "free",
    sleeps: "Sleeps 2",
    view: "riverside view",
    bedSize: "1 queen bed",
    price: "$300",
    total: "$1600",
  },
  {
    images: [
      "https://images.trvl-media.com/lodging/62000000/61530000/61523800/61523736/630a3b97.jpg?impolicy=fcrop&w=1200&h=800&p=1&q=medium",
      "https://images.trvl-media.com/lodging/62000000/61530000/61523800/61523736/f56bfd4d.jpg?impolicy=fcrop&w=1200&h=800&p=1&q=medium",
      "https://images.trvl-media.com/lodging/62000000/61530000/61523800/61523736/1b2a7ec2.jpg?impolicy=fcrop&w=1200&h=800&p=1&q=medium",
    ],
    name: "Treehouse Retreat",
    size: "300 sq ft",
    wifi: "free",
    sleeps: "Sleeps 2",
    view: "forest view",
    bedSize: "1 queen bed",
    price: "$250",
    total: "$1500",
  },
  {
    images: [
      "https://images.trvl-media.com/lodging/62000000/61530000/61523800/61523736/630a3b97.jpg?impolicy=fcrop&w=1200&h=800&p=1&q=medium",
      "https://images.trvl-media.com/lodging/62000000/61530000/61523800/61523736/1b2a7ec2.jpg?impolicy=fcrop&w=1200&h=800&p=1&q=medium",
      "https://images.trvl-media.com/lodging/62000000/61530000/61523800/61523736/f56bfd4d.jpg?impolicy=fcrop&w=1200&h=800&p=1&q=medium",
    ],
    name: "City Loft, Skyline View",
    size: "700 sq ft",
    wifi: "free",
    sleeps: "Sleeps 2",
    view: "city skyline view",
    bedSize: "1 king bed",
    price: "$500",
    total: "$2200",
  },
];

