import React from 'react'
import { useCruisesResult } from '../context/cruisesContext';

const CruiseImages = () => {
    const {selectedCruise,setSelectedCruise, setCruises} = useCruisesResult();

 
  return (
    <>
      {/* <div className="w-full grid grid-rows-4 grid-cols-4 gap-2">
  
      <div className="row-span-4 col-span-2">
        <img
          className="w-full h-full object-cover rounded-md"
          src="https://mediaim.expedia.com/cruise/pc-rp-2024-02-01/ce34b678-7429-4dda-958f-40048004a285.jpg?impolicy=resizecrop&ra=fit&rw=1000"
         
          alt="Large"
        />
      </div>
      
     
      <div className="col-span-1 row-span-2">
        <img
          className="w-full h-full object-cover rounded-md"
          src="https://mediaim.expedia.com/cruise/pc-rp-2024-02-01/ce34b678-7429-4dda-958f-40048004a285.jpg?impolicy=resizecrop&ra=fit&rw=1000"
         
          alt="Small 1"
        />
      </div>
      <div className="col-span-1 row-span-2">
        <img
          className="w-full h-full object-cover rounded-md"
          src="https://mediaim.expedia.com/cruise/pc-rp-2024-02-01/ce34b678-7429-4dda-958f-40048004a285.jpg?impolicy=resizecrop&ra=fit&rw=1000"
         
          alt="Small 2"
        />
      </div>
      <div className="col-span-1 row-span-2">
        <img
          className="w-full h-full object-cover rounded-md"
          src="https://mediaim.expedia.com/cruise/pc-rp-2024-02-01/ce34b678-7429-4dda-958f-40048004a285.jpg?impolicy=resizecrop&ra=fit&rw=1000"
         
          alt="Small 3"
        />
      </div>
      <div className="col-span-1 row-span-2">
        <img
          className="w-full h-full object-cover rounded-md"
          src="https://mediaim.expedia.com/cruise/pc-rp-2024-02-01/ce34b678-7429-4dda-958f-40048004a285.jpg?impolicy=resizecrop&ra=fit&rw=1000"
         
          alt="Small 4"
        />
      </div>
    </div> */}

<div className="w-full sm:grid sm:grid-rows-4 sm:grid-cols-4 sm:gap-2">
        {/* Large Image */}
        <div className="sm:row-span-4 sm:col-span-2">
          <img
            className="w-full h-full object-cover rounded-md"
            src={selectedCruise?.image}
            // src="https://mediaim.expedia.com/cruise/pc-rp-2024-02-01/ce34b678-7429-4dda-958f-40048004a285.jpg?impolicy=resizecrop&ra=fit&rw=1000"
            alt="Large"
          />
        </div>

        {/* Small Images */}
        <div className="hidden sm:block sm:col-span-1 sm:row-span-2">
          <img
            className="w-full h-full object-cover rounded-md"
            // src="https://mediaim.expedia.com/cruise/pc-rp-2024-02-01/ce34b678-7429-4dda-958f-40048004a285.jpg?impolicy=resizecrop&ra=fit&rw=1000"
            src={selectedCruise?.image}
            alt="Small 1"
          />
        </div>
        <div className="hidden sm:block sm:col-span-1 sm:row-span-2">
          <img
            className="w-full h-full object-cover rounded-md"
            // src="https://mediaim.expedia.com/cruise/pc-rp-2024-02-01/ce34b678-7429-4dda-958f-40048004a285.jpg?impolicy=resizecrop&ra=fit&rw=1000"
            src={selectedCruise?.image}
            alt="Small 2"
          />
        </div>
        <div className="hidden sm:block sm:col-span-1 sm:row-span-2">
          <img
            className="w-full h-full object-cover rounded-md"
            // src="https://mediaim.expedia.com/cruise/pc-rp-2024-02-01/ce34b678-7429-4dda-958f-40048004a285.jpg?impolicy=resizecrop&ra=fit&rw=1000"
            src={selectedCruise?.image}
            alt="Small 3"
          />
        </div>
        <div className="hidden sm:block sm:col-span-1 sm:row-span-2">
          <img
            className="w-full h-full object-cover rounded-md"
            // src="https://mediaim.expedia.com/cruise/pc-rp-2024-02-01/ce34b678-7429-4dda-958f-40048004a285.jpg?impolicy=resizecrop&ra=fit&rw=1000"
            src={selectedCruise?.image}
            alt="Small 4"
          />
        </div>
      </div>

    </>
  )
}

export default CruiseImages
