import React, { useState } from "react";
import { Flex } from "@aws-amplify/ui-react";
import "./hotelDetails.css";

export const HotelImagesComponent = () => {
  return (
    <div className="div-flex" style={{ marginLeft: "10%" }}>
      <div>
        <img
          className="margin-2px"
          src="https://images.trvl-media.com/lodging/62000000/61530000/61523800/61523736/31ba9b68.jpg?impolicy=resizecrop&rw=598&ra=fit"
          alt=""
        />
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <img
          className="marginTopAndLeft"
          src="https://images.trvl-media.com/lodging/62000000/61530000/61523800/61523736/cde7d362.jpg?impolicy=resizecrop&rw=297&ra=fit"
          alt=""
        />
        <img
          className="marginTopAndLeft"
          src="https://images.trvl-media.com/lodging/62000000/61530000/61523800/61523736/cde7d362.jpg?impolicy=resizecrop&rw=297&ra=fit"
          alt=""
        />
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <img
          className="marginTopAndLeft"
          src="https://images.trvl-media.com/lodging/62000000/61530000/61523800/61523736/cde7d362.jpg?impolicy=resizecrop&rw=297&ra=fit"
          alt=""
        />
        <img
          className="marginTopAndLeft"
          src="https://images.trvl-media.com/lodging/62000000/61530000/61523800/61523736/cde7d362.jpg?impolicy=resizecrop&rw=297&ra=fit"
          alt=""
        />
      </div>
    </div>
  );
};
