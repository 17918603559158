import React, { useEffect, useState } from "react";
import "./autocompleteForm.css"; // Import your CSS file

// Load Google Maps script dynamically
const loadGoogleMapsScript = (callback: () => void) => {
  const script = document.createElement("script");
  script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA96SrSSmQhUIevuhYcP0uuv5xjLjPxt2A&libraries=places&v=weekly`;
  script.defer = true;
  script.async = true;
  script.onload = callback;
  document.head.appendChild(script);
};

const AddressInput: React.FC = () => {
  const [typedText, setTypedText] = useState<string>("");
  const [selectedAddress, setSelectedAddress] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [contactNumber, setContactNumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [firstNameError, setFirstNameError] = useState<boolean>(false);
  const [lastNameError, setLastNameError] = useState<boolean>(false);
  const [contactNumberError, setContactNumberError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [addressError, setAddressError] = useState<boolean>(false);

  useEffect(() => {
    loadGoogleMapsScript(() => {
      const autocomplete = new window.google.maps.places.Autocomplete(
        document.getElementById("ship-address") as HTMLInputElement,
        {
          componentRestrictions: { country: ["us", "ca", "in"] },
          fields: ["address_components", "formatted_address"],
          types: ["address"],
        }
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        setSelectedAddress(place?.formatted_address || "");
      });
    });
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTypedText(event.target.value);
    setAddressError(false);
  };

  const handleInputBlur = () => {
    if (!selectedAddress) {
      setTypedText("");
    }
  };

  const handleSubmit = () => {
    if (!firstName) {
      setFirstNameError(true);
    } else if (!lastName) {
      setLastNameError(true);
    } else if (!validateEmail(email)) {
      setEmailError(true);
    } else if (!validatePhoneNumber(contactNumber)) {
      setContactNumberError(true);
    } else if (!selectedAddress) {
      setAddressError(true);
    } else {
      // console.log("Data for submit: ", firstName, lastName, email, contactNumber, selectedAddress);
    }
  };

  const validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validatePhoneNumber = (phone: string) => {
    const re = /^\d+$/;
    return re.test(phone);
  };

  return (
    <div className="address-form-container">
      <div>
        <p>First Name</p>
        <input
          type="text"
          value={firstName}
          onChange={(e) => {
            setFirstName(e.target.value);
            setFirstNameError(false);
          }}
        />
        {firstNameError && <span className="error">This field is required</span>}
      </div>
      <div>
        <p>Last Name</p>
        <input
          type="text"
          value={lastName}
          onChange={(e) => {
            setLastName(e.target.value);
            setLastNameError(false);
          }}
        />
        {lastNameError && <span className="error">This field is required</span>}
      </div>
      <div>
        <p>Email</p>
        <input
          type="text"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setEmailError(false);
          }}
        />
        {emailError && <span className="error">Invalid email address</span>}
      </div>
      <div>
        <p>Phone Number</p>
        <input
          type="text"
          value={contactNumber}
          onChange={(e) => {
            setContactNumber(e.target.value);
            setContactNumberError(false);
          }}
        />
        {contactNumberError && <span className="error">Please enter only numbers</span>}
      </div>
      <div>
        <p>Address</p>
        <input
          id="ship-address"
          name="ship-address"
          required
          autoComplete="off"
          value={selectedAddress ? selectedAddress : typedText}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          className="address-input"
        />
        {addressError && <span className="error">This field is required</span>}
      </div>
      <div>
        <button className="cancel">Cancel</button>
        <button className="submit" onClick={handleSubmit}>Submit</button>
      </div>
    </div>
  );
};

export default AddressInput;
