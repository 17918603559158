import React from "react";
import TrainResulttopHeading from "./trainResultTopHeading";
import TrainResultList from "./trainResultList";

const TrainSearchResult = () => {
  return (
    <>
        <TrainResulttopHeading/>
        <TrainResultList/>
    </>
  );
};

export default TrainSearchResult;
