import { FaStar } from 'react-icons/fa';

export const HotelRating = ({ rating }:any) => {
  const totalStars = 5;
  const filledStars = Math.floor(rating);
  const remainder = rating - filledStars;
  const emptyStars = totalStars - filledStars - (remainder > 0 ? 1 : 0);

  const starIcons = [];

  for (let i = 0; i < filledStars; i++) {
    starIcons.push(<FaStar key={i} color="gold" />);
  }

  if (remainder > 0) {
    starIcons.push(<FaStar key={filledStars} color="gold" style={{ opacity: remainder }} />);
  }

  for (let i = 0; i < emptyStars; i++) {
    starIcons.push(<FaStar key={filledStars + 1 + i} color="lightgray" />);
  }

  return (
    <div style={{ display: 'flex' }}>
      {starIcons.map((icon, index) => (
        <div key={index} style={{ marginRight: '5px' }}>
          {icon}
        </div>
      ))}
    </div>
  );
};

