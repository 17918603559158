import React from "react";
import { TravelDetails } from "../Components/TravelDetails";
import { Badge, Card, Divider, Flex, Image, Text } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import { withAuthenticator } from "@aws-amplify/ui-react";
import config from "../amplifyconfiguration.json";
import { useFlightTypeContext } from "../context/flightTypeContext";
import { responseDataToUiFormatData } from "./helperFunctions/calender.helper";
Amplify.configure(config);

const CheckoutPage = () => {
  const { selectedFlight } = useFlightTypeContext();
  console.log("selectedFlight--------->",selectedFlight);
  return (
    <>
      <Card>
        <Flex direction={"row"} justifyContent={"space-between"}>
          <Text fontSize={"24px"}>Itinerary Information</Text>
          <Flex direction={"column"} gap={"1px"}>
            <Text>Total Price</Text>
            <Text>Itinerary Information</Text>
            <Text>Price per person:</Text>
            <Text>incl taxes & fees</Text>
          </Flex>
        </Flex>
        <Card className="checkout-card">
          <Flex direction={"column"} gap={"1rem"}>
            <Flex
              backgroundColor={"#f5f5f5"}
              paddingInline={"12px"}
              paddingBlock={"8px"}
              justifyContent={"space-between"}
            >
              <Flex direction={"row"} gap={"32px"}>
                <Text>{responseDataToUiFormatData(selectedFlight?.onwardSegment?.legs[0].dateOfDeparture)}</Text>
                <Text>{responseDataToUiFormatData(selectedFlight?.onwardSegment?.legs[0].dateOfArrival)}</Text>
              </Flex>
              <Text>Stops</Text>
              <Text>Trip Duration</Text>
              <Text>Change</Text>
            </Flex>
            <Flex direction={"row"} gap={"24px"}>
              <Image
                src={`https://pics.avs.io/240/80/${selectedFlight?.onwardSegment?.legs[0]?.operatingCarrier}.png `}
                width={48}
                height={48}
                alt="flight picture"
              />
              <Flex
                direction={"column"}
                className="custom-amplify-flex"
                width={"10%"}
              >
                <Text textAlign={"start"}>{"Economy"}</Text>
                <Text textAlign={"start"}>{"Meal provided"}</Text>
              </Flex>

              <Flex
                direction={"column"}
                className="custom-amplify-flex"
                width={"10%"}
              >
                <Text textAlign={"start"}>{"06:10"}</Text>
                <Text textAlign={"start"}>{"11:45"}</Text>
              </Flex>

              <Flex
                direction={"column"}
                className="custom-amplify-flex"
                width={"10%"}
              >
                <Text textAlign={"start"}>{"Economy"}</Text>
                <Text textAlign={"start"}>{"Meal provided"}</Text>
              </Flex>

              <Flex
                direction={"column"}
                width={"18%"}
                className="custom-amplify-flex"
              >
                <Text textAlign={"center"}>{`Non Refundable`}</Text>
              </Flex>

              <Flex direction={"column"} className="custom-amplify-flex">
                <Badge variation="success">
                  {"recommendation.onwardSegment.bookingClass"}
                </Badge>
                <Text>Total Roundtrip Price : {selectedFlight?.totalFare}</Text>
              </Flex>
            </Flex>
            <Divider></Divider>
            <Flex marginBlock={"10px"}>
              <Text>Flight Detail</Text>
            </Flex>

            <Flex
              backgroundColor={"#f5f5f5"}
              paddingInline={"12px"}
              paddingBlock={"8px"}
              justifyContent={"space-between"}
            >
              <Flex direction={"row"} gap={"32px"}>
                <Text></Text>
                <Text></Text>
              </Flex>
              <Text>Stops</Text>
              <Text>Trip Duration</Text>
              <Text>Change</Text>
            </Flex>
            <Flex direction={"row"} gap={"24px"}>
              <Image
                src={`https://pics.avs.io/240/80/${selectedFlight?.onwardSegment?.legs[0]?.operatingCarrier}.png `}
                width={48}
                height={48}
                alt="flight picture"
              />
              <Flex
                direction={"column"}
                className="custom-amplify-flex"
                width={"10%"}
              >
                <Text textAlign={"start"}>{"Economy"}</Text>
                <Text textAlign={"start"}>{"Meal provided"}</Text>
              </Flex>

              <Flex
                direction={"column"}
                className="custom-amplify-flex"
                width={"10%"}
              >
                <Text textAlign={"start"}>{selectedFlight?.onwardSegment?.flyingTime}</Text>
                <Text textAlign={"start"}>{selectedFlight?.onwardSegment?.flyingTime}</Text>
              </Flex>

              <Flex
                direction={"column"}
                className="custom-amplify-flex"
                width={"10%"}
              >
                <Text textAlign={"start"}>{"Economy"}</Text>
                <Text textAlign={"start"}>{"Meal provided"}</Text>
              </Flex>

              <Flex
                direction={"column"}
                width={"18%"}
                className="custom-amplify-flex"
              >
                <Text textAlign={"center"}>{`Non Refundable`}</Text>
              </Flex>

              <Flex direction={"column"} className="custom-amplify-flex">
                <Badge variation="success">
                  {"recommendation.onwardSegment.bookingClass"}
                </Badge>
                <Text>Total Roundtrip Price : {selectedFlight?.totalFare}</Text>
              </Flex>
            </Flex>
            <Divider></Divider>
            <Flex marginBlock={"10px"}>
              <Text>Flight Detail</Text>
            </Flex>
            <Flex>
              <Text>Itinerary Details</Text>
              <Text>Itinerary Details</Text>
            </Flex>
          </Flex>
        </Card>
      </Card>
      <TravelDetails />
    </>
  );
};

export default withAuthenticator(CheckoutPage);
