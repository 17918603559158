import { Tabs, Flex, Theme, ThemeProvider } from '@aws-amplify/ui-react'
import { useTripType } from '../context/tripType'
import 'react-datepicker/dist/react-datepicker.css';
import { RoundedTrip } from './RoundedTrip'
import { OneWayTrip } from './OneWayTrip';
import { MultiCity } from './MultiCity';
export const Tab = () => {
  const { tripType,setTripType } = useTripType();
  const theme: Theme = {
    name: 'autocomplete-theme',
    tokens: {
      components: {
       tabs: {
        item: {
          _active: {
            color: { value: '#007aff' },
            borderColor: { value: '#007aff' }, },
        },   
        },
      },
    },
  };
  
  return (
    <>
    <ThemeProvider theme={theme} colorMode="light">  
        <Tabs 
            value={tripType}
            onValueChange={(tab) => setTripType(tab)}
            items={[
                { label: 'Round Trip', value: "round-trip", content: <RoundedTrip /> },
                { label: 'Oneway', value: "one-way", content: <OneWayTrip /> },
                { label: 'Multi-city',  value:"multi-city", content: <MultiCity/> },
            ]}
        /> 
        </ThemeProvider>    
    </>
  )
}
