import React, { useState, useEffect, useRef } from 'react';
import { FaArrowLeft, FaCheck, FaHeart, FaRegHeart, FaSave, FaShareAlt, FaTimes, FaUser } from 'react-icons/fa'
import ClientReview from './clientReview';
import { useThingsSearchResult } from '../context/thingToDo';
import TicketModal from '../Components/TicketModal'
import { Image } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import MapWithMarket from './mapsComponent';
interface Attraction {
  name: string;
  included: boolean;
}
const ShowTravelDetail = () => {
  const navigate=useNavigate()
    const handleBack = () => {
      console.log('hello')
      navigate('/travellist')
      };
   
      const sections = {
        overview: useRef<HTMLDivElement>(null),
        checkAvailability: useRef<HTMLDivElement>(null),
        about: useRef<HTMLDivElement>(null),
        location: useRef<HTMLDivElement>(null),
        reviews: useRef<HTMLDivElement>(null),
      };
    
      // Handle scrolling to section
      const handleScroll = (section: keyof typeof sections) => {
        sections[section].current?.scrollIntoView({ behavior: 'smooth' });
      };
       const [isExpanded, setIsExpanded] = useState(false);

  const toggleList = () => {
    setIsExpanded(!isExpanded);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const attractions: Attraction[] = [
    { name: "Empire State Building", included: true },
    { name: "American Museum of Natural History", included: true },
    { name: "Plus your choice of admission to 3 of the following attractions:", included: false },
    { name: "Top of the Rock® Observation Deck", included: true },
    { name: "Ferry Access to Statue of Liberty and Ellis Island", included: true },
    { name: "9/11 Memorial & Museum", included: true },
    { name: "Circle Line Sightseeing Cruises", included: true },
    { name: "Intrepid Museum", included: false },
    { name: "Guggenheim Museum", included: false },
    { name: "Transportation/Parking", included: false },
    { name: "Statue of Liberty Crown Tickets and Pedestal Access", included: false },
  ];
  const {thingsResultData,passengerThingsData} =useThingsSearchResult()
  console.log(thingsResultData, ":things")
  const imageUrl = thingsResultData?.image;
  const [isLiked, setIsLiked] = useState(false);

  const toggleLike = () => {
    setIsLiked(!isLiked);
  };

  const handleBooking=()=>{
    navigate('/thingsTicketBooking')
  }
  const price = parseFloat(thingsResultData?.price.replace('$', ''));
  const totalPrice = price * passengerThingsData.adultCount;
  console.log(totalPrice, ":totalPrice")
  return (
    <div className="min-h-screen p-4 bg-gray-100">
    <div className="flex justify-between items-center p-4">
      <button onClick={handleBack} className="text-sky-700 items-center text-center gap-1 flex hover:text-gray-900" >
        <FaArrowLeft size={18} color='skyblue' /> See all activities
      </button>
      <div className="flex space-x-4">
        <button className="text-gray-700 hover:bg-gray-200 items-center text-center p-2 rounded-full text-sky-600 flex gap-2 ">
          <FaShareAlt size={18} color='skyblue' /> Share
        </button>
        <button  onClick={toggleLike} className="text-gray-700 bg-gray-200 items-center text-center px-3 py-2 rounded-full text-sky-600 flex gap-2 ">
        {isLiked ? <FaHeart size={18} color="red" /> : <FaRegHeart size={18} color="gray" />}
       Save
        </button>
     
      </div>
    </div>

   

    <Image src={thingsResultData?.image} width={'100%'} alt='' className='rounded-xl flex md:hidden' height={'280px'} />
    <div className="md:flex ml-[10%] hidden">
      <div>
        <img
          className="m-2 w-[598px] h-[598px] object-cover rounded-lg"
          src={`${imageUrl}&rw=598&ra=fit`}
          alt="Main"
        />
      </div>
      <div className="flex flex-col">
        <img
          className="mt-2 ml-2 w-[297px] h-[297px] object-cover rounded-lg"
          src={`${imageUrl}&rw=297&ra=fit`}
          alt="Secondary"
        />
        <img
          className="mt-2 ml-2 w-[297px] h-[297px] object-cover rounded-lg"
          src={`${imageUrl}&rw=297&ra=fit`}
          alt="Secondary"
        />
      </div>
      <div className="flex flex-col">
        <img
          className="mt-2 ml-2 w-[297px] h-[297px] object-cover rounded-lg"
          src={`${imageUrl}&rw=297&ra=fit`}
          alt="Secondary"
        />
        <img
          className="mt-2 ml-2 w-[297px] h-[297px] object-cover rounded-lg"
          src={`${imageUrl}&rw=297&ra=fit`}
          alt="Secondary"
        />
      </div>
    </div>
    <div className="mt-1">
   
  <div className="mt-4 py-4 overflow-x-auto">
      <div className="flex justify-start space-x-4">
        <button onClick={() => handleScroll('overview')} className="nav-button font-semibold">Overview</button>
        <button onClick={() => handleScroll('checkAvailability')} className="nav-button font-semibold">Check Availability</button>
        <button onClick={() => handleScroll('about')} className="nav-button font-semibold">About this Activity</button>
        <button onClick={() => handleScroll('location')} className="nav-button font-semibold">Location</button>
        <button onClick={() => handleScroll('reviews')} className="nav-button font-semibold">Reviews</button>
      </div>
    </div>
      <div className="mt-8 space-y-8">
        {/* <section ref={sections.overview} className="p-4 bg-white shadow-md rounded-md">
          <h2 className="text-2xl font-bold mb-4">Overview</h2>
          <div className='flex gap-4'>

            <div className='w-[40%]'>
                <div className='flex flex-col'>
                    <p className='text-start text-4xl'>New York CityPASS®:</p>
                    <p className='text-start text-4xl'>Experience 5 must-see attractions</p>
                    <p className='text-start text-lg mt-2'>By CityPASS</p>
                </div>
                <p className='text-start text-lg'>4.5/5 1970 verified reviews</p>
                <button onClick={handleBack} className="text-sky-700 items-center text-center gap-1 flex hover:text-gray-900">
             See all review
        </button>
        <p  className='text-start text-lg mt-5'>Free cancellation available</p>
        <p  className='text-start text-2xl'>$146</p>
        <p  className='text-start text-md'>Per adult</p>
        <button onClick={handleBack} className=" items-center bg-sky-600 p-2 rounded-full text-white text-center gap-1 flex hover:text-gray-900">
            See Tickets
        </button>
            </div>

            <div>
                <div className='flex flex-col gap-2'>
                    <p className='text-4xl text-start'>Features</p>
                    <div className='flex gap-6'>
                        <p>Free cancellation available</p>
                        <p>9d</p>
                    </div>
                    <div className='flex gap-4'>
                        <p>Mobile voucher</p>
                        <p>Instant confirmation</p>
                    </div>
                </div>
                <div>
                <p className='text-4xl mt-2
                 text-start'>Overview</p>
                    
                    <ul className='text-start'>
        <li>Save time and money with one simple purchase</li>
        <li>Visit 5 top attractions including Empire State Building</li>
        <li>Easy to use mobile tickets on your choice of attractions</li>
        {isExpanded && (
          <>
            <li>Visit my.citypass.com for attraction info & reservations</li>
            <li>Save 40 percent on combined regular box office price</li>
          </>
        )}
      </ul>
      <div className='flex items-start'>
        
      <button
        className="mt-4 p-2 text-center text-sky-700 rounded"
        onClick={toggleList}
      >
        {isExpanded ? 'Show less' : 'Show more'}
      </button>
      </div>
                </div>

            </div>
          </div>
        </section> */}

<section ref={sections.overview} className="p-4 bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-bold mb-4">Overview</h2>
      <div className="flex flex-col md:flex-row gap-4">

        <div className="w-full md:w-[40%]">
          <div className="flex flex-col">
            <p className="text-start text-4xl">{thingsResultData?.name}</p>
            <p className="text-start text-4xl">Experience 5 must-see attractions</p>
            <p className="text-start text-lg mt-2">By CityPASS</p>
          </div>
          <p className="text-start text-lg">{thingsResultData?.rating} {thingsResultData?.review} verified reviews</p>
          <button onClick={handleBack} className="text-sky-700 items-center text-center gap-1 flex hover:text-gray-900">
            See all reviews
          </button>
          <p className="text-start text-lg mt-5">Free cancellation available</p>
          <p className="text-start text-2xl">{thingsResultData?.price}</p>
          <p className="text-start text-md">Per adult</p>
          <button onClick={handleBack} className="items-center bg-sky-600 p-2 rounded-full text-white text-center gap-1 flex hover:bg-sky-700">
            See Tickets
          </button>
        </div>

        <div className="w-full md:w-[60%]">
          <div className="flex flex-col gap-2">
            <p className="text-4xl text-start">Features</p>
            <div className="flex flex-wrap gap-6">
              <p>Free cancellation available</p>
              <p>{thingsResultData?.day}</p>
            </div>
            <div className="flex flex-wrap gap-4">
              <p>Mobile voucher</p>
              <p>Instant confirmation</p>
            </div>
          </div>
          <div>
            <p className="text-4xl mt-2 text-start">Overview</p>
            <ul className="text-start list-disc list-inside">
              <li>Save time and money with one simple purchase</li>
              <li>Visit 5 top attractions including Empire State Building</li>
              <li>Easy to use mobile tickets on your choice of attractions</li>
              {isExpanded && (
                <>
                  <li>Visit my.citypass.com for attraction info & reservations</li>
                  <li>Save 40 percent on combined regular box office price</li>
                </>
              )}
            </ul>
            <div className="flex items-start">
              <button
                className="mt-4 p-2 text-center text-sky-700 rounded hover:text-gray-900"
                onClick={toggleList}
              >
                {isExpanded ? 'Show less' : 'Show more'}
              </button>
            </div>
          </div>
        </div>

      </div>
    </section>
        {/* <section ref={sections.checkAvailability} className="p-4 bg-white shadow-md rounded-md">
          <h2 className="text-2xl font-bold mb-4">Check Availability</h2>
          <hr className="my-4" />
          <div className="flex justify-start space-x-4">
            <div className="p-4 bg-gray-200 rounded-md shadow-md">
              <p>Wed, Jun 28</p>
              <p>$146</p>
            </div>
            <div className="p-4 bg-gray-200 rounded-md shadow-md">
              <p>Thu, Jun 29</p>
              <p>$146</p>
            </div>
          </div>

            <div className="flex border-gray-200 border-4 mt-4 space-x-4">
                <div className=" w-[30%] flex-col flex">
                
                    <p className='text-start'>New York CityPASS: Admission to Top 5 Attractions</p>
                    <p className='text-start'>Visiting New York City? Experience 5 must-see attractions, handpicked and packaged together at a 40% savings, with New York CityPASS® tickets.</p>
                    <p className='text-start'>Get admission to 5 attractions.</p>
                    <p className='text-start'>Includes:</p>
                </div> 
                <div className=" w-[30%] flex">
                
                <p className='text-start'>New York CityPASS: Admission to Top 5 Attractions</p>
                  
                  
                
                </div>
                <div className=" border-gray-200 w-[35%] flex flex-col ">
                
                <p className='text-start'>Price details</p>
                <div className='flex justify-between'>
                    <p>$146.00 x 1 Adult</p>
                    <p>$146.00</p>

                </div>
                <hr className="my-4" />
                <div className='flex justify-between'>
                    <p>$146.00 x 1 Adult</p>
                    <div>
                    <p>$146.00</p>
                    <p>Free cancellation</p>
                    <p>Until Wed, Jun 26</p>
                    </div>

                </div> 
                <div className='flex text-start mt-12 my-4 justify-end items-center'>

                <button className=' w-32 text-white bg-sky-600 rounded-full px-3 py-2'>Book</button>
                </div>
                </div>
            </div>
             
        </section> */}
 
    <section ref={sections.checkAvailability} className="p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-3xl font-extrabold text-gray-800 mb-6">Check Availability</h2>
      <hr className="my-4 border-gray-300" />
      <div className="flex flex-wrap justify-start gap-4">
        <div className="p-4 bg-gray-200 rounded-md shadow-md">
          <p className="text-lg font-semibold text-gray-700">Wed, Jun 28</p>
          <p className="text-xl font-bold text-gray-900">{thingsResultData?.price}</p>
        </div>
        <div className="p-4 bg-gray-200 rounded-md shadow-md">
          <p className="text-lg font-semibold text-gray-700">Thu, Jun 29</p>
          <p className="text-xl font-bold text-gray-900">{thingsResultData?.price}</p>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row border-gray-200 border-4 mt-8 gap-6 p-6 rounded-lg">
        <div className="lg:w-1/3 flex items-start flex-col">
          <p className="text-xl font-semibold text-gray-800 mb-2">New York CityPASS: Admission to Top 5 Attractions</p>
          <p className="text-base text-start text-gray-700 mb-2">Visiting New York City? Experience 5 must-see attractions, handpicked and packaged together at a 40% savings, with New York CityPASS® tickets.</p>
          <p className="text-base text-gray-700 mb-2">Get admission to 5 attractions.</p>
          <p className="text-base text-gray-700 mb-2">Includes:</p>
        </div>
        <div className="lg:w-1/3 flex items-start  justify-center">
        <div className="flex items-center p-4 bg-gray-100 rounded-lg cursor-pointer shadow-md"   onClick={openModal}>
      <FaUser className="text-4xl text-gray-600 mr-4" />
      <div className="text-left">
        <p className="text-lg font-semibold text-gray-800">Adult</p>
        <p className="text-xl font-bold text-gray-900">{'numberOfAdults'}</p>
      </div>
    </div>
        </div>
        <div className="lg:w-1/3 border-gray-200 flex flex-col gap-4 p-4 bg-gray-100 rounded-lg shadow-inner">
          <p className="text-lg font-semibold text-gray-800">Price details</p>
          <div className="flex justify-between text-gray-700">
            <p>{thingsResultData?.price} x {passengerThingsData.adultCount}</p>
            <p>${totalPrice}</p>
          </div>
          <hr className="my-4 border-gray-300" />
          <div className="flex justify-between text-gray-700">
            <p>{thingsResultData?.price} x { passengerThingsData.adultCount}</p>
            <div className="text-right">
              <p>${totalPrice}</p>
              <p className="text-sm">Free cancellation</p>
              <p className="text-sm">Until Wed, Jun 26</p>
            </div>
          </div>
          <div className="flex justify-end mt-auto">
            <button onClick={handleBooking} className="w-32 text-white bg-sky-600 rounded-full px-4 py-2 hover:bg-sky-700 transition-colors duration-200">Book</button>
          </div>
        </div>
      </div>
    </section>
        {/* <section ref={sections.about} className="p-4 bg-white shadow-md flex flex-col gap-6 rounded-md">
          <h2 className="text-2xl font-bold mb-4">About this Activity</h2>
          <div className='flex'>
            <div className='w-[35%] flex flex-col'>
                <p className='text-4xl text-start'>What's included, what's not</p>
               
            </div>
            <div className='w-[65%] flex-col text-start flex'>
                <p>What's included, what's not</p>
                <p>What's included, what's not</p>
                <p>What's included, what's not</p>
                <p>What's included, what's not</p>
                <p>What's included, what's not</p>
                <p>What's included, what's not</p>
                 <p>What's included, what's not</p>
                <p>What's included, what's not</p>
            </div>
          </div>

          <div className='flex'>
            <div className='w-[35%] flex flex-col'>
                <p className='text-4xl text-start'>Know before you book</p>
            </div>
            <div className='w-[65%] flex-col text-start flex'>
                <p>What's included, what's not</p>
                <p>What's included, what's not</p>
                <p>What's included, what's not</p>
                <p>What's included, what's not</p>
                <p>What's included, what's not</p>
                <p>What's included, what's not</p>
                 <p>What's included, what's not</p>
                <p>What's included, what's not</p>
            </div>
          </div>
</section> */}

<section className="p-6 bg-white shadow-lg flex flex-col gap-8 rounded-lg" ref={sections.about} >
      <h2 className="text-3xl font-extrabold mb-6 text-gray-800">About this Activity</h2>
      
      <div className="flex flex-wrap lg:flex-nowrap gap-6">
        <div className="w-full lg:w-[35%] flex flex-col">
          <p className="text-2xl font-semibold text-start text-gray-700">What's included, what's not</p>
        </div>
        <div className="w-full lg:w-[65%] flex flex-col text-start">
          <ul className="list-none text-gray-700">
            {attractions.map((attraction:any, index) => (
              <li key={index} className="flex items-center mb-2">
                {attraction.included ? (
                  <FaCheck className="text-green-500 mr-2" />
                ) : (
                  <FaTimes className="text-red-500 mr-2" />
                )}
                {attraction.name}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
        <section ref={sections.location} className="p-4 bg-white shadow-md rounded-md">
          <h2 className="text-2xl font-bold mb-4">Location</h2>
          <div>
          <MapWithMarket className="thinks-map"/>
          </div>
        </section>

        

<section ref={sections.reviews} className="p-4 bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-bold mb-4">Reviews</h2>
      <div className="flex flex-col gap-1 items-start">
        <p className="text-green-600 text-4xl">{thingsResultData?.rating}</p>
        <p className="text-black font-semibold text-2xl">Amazing</p>
        <p>{thingsResultData?.review} verified reviews</p>
      </div>
      <ClientReview />
    </section>
      </div>
    </div>
    {isModalOpen && <TicketModal closeModal={closeModal} />}
    </div>
  
  )
}

export default ShowTravelDetail
