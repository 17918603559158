export const findtravelerValue = (popperValue:any, targetCharacter:any) => {
    // Split the string by comma to get individual parts
    const parts = popperValue.split(',');

    // Iterate over each part to find the one that ends with the target character
    for (let part of parts) {
        // Split the part by the character to separate the number and character
        const [numberPart, characterPart] = part.split("");

        // If the characterPart matches the target character, extract the number and return it
        if (characterPart && characterPart.toUpperCase() === targetCharacter.toUpperCase()) {
            // If numberPart is not null, convert it to an integer and return
            if (numberPart) {
                return parseInt(numberPart);
            }
        }
    }

    // If no match is found, return 0
    return 0;
};

export const colorCodedDates:any = {
    1: 'green',
    2: 'red',
    3: 'orange',
    4: 'green',
    5: 'red',
    6: 'green',
    7: 'orange',
    8: 'green',
    9: 'red',
    10: 'orange',
    11: 'green',
    12: 'red',
    13: 'green',
    14: 'orange',
    15: 'green',
    16: 'red',
    17: 'orange',
    18: 'green',
    19: 'red',
    20: 'orange',
    21: 'green',
    22: 'red',
    23: 'green',
    24: 'orange',
    25: 'green',
    26: 'red',
    27: 'orange',
    28: 'green',
    29: 'red',
    30: 'orange',
    31: "red"
  }