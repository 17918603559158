import React, { useState } from "react";
import "./hotelDetails.css";
import { useNavigate } from "react-router-dom";
import { recommantionSample } from "./hotelDetails.helper";
import { FaMap } from "react-icons/fa";
import { FiWifi } from "react-icons/fi";
import { FaUsers } from "react-icons/fa";
import { FiSunrise } from "react-icons/fi";
import { FaBed } from "react-icons/fa";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { PackageResult } from "../../context/PackagesContext";
import { useFlightSearchResult } from "../../context/flightSearchResults";
import { fetchData } from "../../api/api";

import { toast } from "react-toastify";
import { useRentalCarSearchResult } from "../../context/rentalCarSearchDetails";
export const HotelRecomndationCard = () => {
  const navigate = useNavigate();
  const {rentalCarDetails, setRentalCarDetails,setRentalCarData}=useRentalCarSearchResult()

  const { setFlightResultData } = useFlightSearchResult();
  const { passengerPackageData,packageType, stayFlightCarPackage,setStayFlightCarPackage } = PackageResult();
  // State to keep track of current image index for each card
  const [currentImageIndexes, setCurrentImageIndexes] = useState(
    Array(recommantionSample.length).fill(0)
  );

  // Function to handle navigation to previous image
  const handlePrevImage = (index:any) => {
    setCurrentImageIndexes((prevIndexes) => {
      const newIndexes = [...prevIndexes];
      newIndexes[index] =
        newIndexes[index] === 0
          ? recommantionSample[index].images.length - 1
          : newIndexes[index] - 1;
      return newIndexes;
    });
  };

  // Function to handle navigation to next image
  const handleNextImage = (index:any) => {
    setCurrentImageIndexes((prevIndexes) => {
      const newIndexes = [...prevIndexes];
      newIndexes[index] =
        newIndexes[index] === recommantionSample[index].images.length - 1
          ? 0
          : newIndexes[index] + 1;
      return newIndexes;
    });
  };
 console.log(packageType, ':package')
const HotelBook=async()=>{
  if (packageType.includes('Flight') && packageType.includes('Stay') && !packageType.includes('Car')) {
    console.log('hlo', packageType);
    const url = "https://api.vimanayaatra.com/SearchLite";

    try {
      const flightData = await fetchData(url, stayFlightCarPackage);
      setFlightResultData(flightData);
     
      navigate("/flightlist");
    } catch (error: any) {
      toast("404 not found");
    }
} else if (packageType.includes('Flight') && packageType.includes('Car') && packageType.includes('Stay')) {
    console.log('hloflight', packageType);
    const url = "https://api.vimanayaatra.com/SearchLite";

    try {
      const flightData = await fetchData(url, stayFlightCarPackage);
      setFlightResultData(flightData);
     
      navigate("/flightlist");
    } catch (error: any) {
      toast("404 not found");
    }
  

} else if (packageType.includes('Stay') && packageType.includes('Car') && !packageType.includes('Flight')) {
  const response = await fetch('/rentalCarData.json'); 
  const data = await response.json();
  setRentalCarData(data)
navigate('/rentalcar')
}
}
  return (
    <div
      style={{
        width: "70vw",
        margin: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "wrap",
        marginTop: "20px",
      }}
    >
      {recommantionSample.map((hotelCardData, index) => {
        return (
          <div
            key={index}
            style={{
              border: "1px solid #DFE0E4",
              borderRadius: "20px",
              marginTop: "10px",
              position: "relative", // added position relative for proper positioning of arrows
            }}
          >
            <img
              width={"331px"}
              style={{
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
              }}
              src={hotelCardData.images[currentImageIndexes[index]]}
              alt=""
            />
            <button
              className="arrow-button left-arrow"
              onClick={() => handlePrevImage(index)}
              style={{position:"absolute",left:"10px",top:"100px",backgroundColor:"white",color:"#1668E3",borderRadius:"50%",borderColor:"white",cursor:"pointer"}}
            >
              <FiChevronLeft />
            </button>
            <button
              className="arrow-button right-arrow"
              onClick={() => handleNextImage(index)}
              style={{position:"absolute",right:"10px",top:"100px",backgroundColor:"white",color:"#1668E3",borderRadius:"50%",borderColor:"white",cursor:"pointer"}}
            >
              <FiChevronRight />
            </button>
            <p
              style={{
                fontSize: "1rem",
                fontWeight: "500",
                lineHeight: "1.25rem",
              }}
            >
              {hotelCardData.name}
            </p>
            <div className="divWithIconAndPara">
              <FaMap />
              <p>{hotelCardData.size}</p>
            </div>
            <div className="divWithIconAndPara">
              <FiWifi />
              <p>{hotelCardData.wifi}</p>
            </div>
            <div className="divWithIconAndPara">
              <FaUsers />
              <p>{hotelCardData.sleeps}</p>
            </div>
            <div className="divWithIconAndPara">
              <FiSunrise />
              <p>{hotelCardData.view}</p>
            </div>
            <div className="divWithIconAndPara">
              <FaBed />
              <p>{hotelCardData.bedSize}</p>
            </div>
            <hr />
            <div
              style={{
                display: "flex",
                width: "100%",
                alignContent: "center",
                justifyContent: "space-between",
                padding: "10px",
              }}
            >
              <div>
                <div style={{ fontWeight: "500", textAlign: "left" }}>
                  {hotelCardData.price}
                </div>
                <div style={{ textAlign: "left" }}>{hotelCardData.total} total</div>
                <div>includes taxes & fees</div>
              </div>
              <button className="reserve-button" onClick={HotelBook}>Reserve</button>
            </div>
          </div>
        );
      })}
    </div>
  );
};
