import React from "react";
import { HotelImagesComponent } from "./hotelImagesComponent";
import "./hotelDetails.css";
import { HotelDiscription } from "./hotelDiscription";
import { Footer } from "../../Components/common/Footer";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { HotelRoomSelection } from "./hotelRoomSelection";
import { HotelRecomndationCard } from "./hotelRecomndationCard";

export const HotelDetailsHome = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="back-button" onClick={() => navigate("/hotel")}>
        <IoMdArrowBack size={20} color="blue" />
      </div>
      <HotelImagesComponent />
      <HotelDiscription />
      <HotelRoomSelection />
      <HotelRecomndationCard/>
      <Footer />
    </div>
  );
};
