import React from "react";
import TrainSearch from "./hotelDetails/trainSearch";
import PopularTrainJourney from "./popularTrainJourney";

const TrainHome = () => {
  return (
    <>
      <div className="mb-24"> {/* Tailwind class for margin bottom */}
        <TrainSearch />
      </div>
      <PopularTrainJourney />
    </>
  );
};

export default TrainHome;
