import { Card, Flex, Input, Text, View } from "@aws-amplify/ui-react";
import Buttons from "../Components/Buttons";
import { MdInsertComment } from "react-icons/md";
import { GrLike } from "react-icons/gr";
import { BsThreeDotsVertical } from "react-icons/bs";
import { PiCarDuotone, PiHeartLight } from "react-icons/pi";
import { RentalCardFilter } from "../Components/rentalCardFilter";
import { JSXElementConstructor, Key, ReactElement, ReactNode, ReactPortal, useState } from "react";
import { carData } from "../Constant/cirData";
import { useNavigate } from "react-router-dom";
import { useRentalCarSearchResult } from "../context/rentalCarSearchDetails";
export const RentalCarList=()=>{
    
  const {rentalCarDetails, setRentalCarDetails,setRentalCarData,rentalCarData}=useRentalCarSearchResult()
      const [isFilterVisible, setFilterVisible] = useState(false);
console.log(rentalCarData?.recommendations  , ':rentalCarData')
      const toggleFilter = () => {
          setFilterVisible(!isFilterVisible);
      };
      const navigate = useNavigate();
      const handleReserve = (index:any) => {
        navigate(`/car/${index}`);
      };
      const style = {
        Card: {
          top: "102px",
          left: "64px",
          width: "912px",
          // height: '200px',
          backgroundColor: "#ffefd5db",
          //   backgroundColor: "rgba(255,255,255,0.48)",
          borderRadius: "44px",
          boxShadow: "10px 2px 10px rgba(3,3,3,0.1)",
          cursor: "pointer",
        },
        Sidebar: {
          width: "300px",
          padding: "1rem",
          backgroundColor: "#F7F7F7",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        },
        InnerCard: {
          width: "912px",
          // height: '60px',
          backgroundColor: "#f0f0f0",
          borderRadius: "24px",
          margin: "-16px",
        },
        ImageContainer: {
          top: "230px",
          left: "104px",
          // width: '309px',
          // height: '200px',
          width: "70px",
          height: "70px",
          borderRadius: "8px",
          backgroundImage: "url(./image.png)",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        },
        FlightName: {
          color: "#030303",
          fontSize: "24px",
          fontFamily: "Montserrat",
          lineHeight: "32px",
        },
        Text: {
          color: "#030303",
          fontSize: "16px",
          fontFamily: "Montserrat",
          lineHeight: "24px",
        },
        ImageCard: {
          width: "312px",
          height: "190px",
          backgroundColor: "rgba(255,255,255,0.48)",
          borderRadius: "24px",
          boxShadow: "0px 2px 10px rgba(3,3,3,0.1)",
          backgroundImage: `url(/airline.jpeg)`,
    
          backgroundSize: "cover", // Cover the entire container with th
        },
      };
    
    return(
        <>
            <div className='w-full flex gap-2 h-screen'>
                <div className="md:w-[25%] hidden md:flex bg-gray-100">
                    <RentalCardFilter />
                </div>
                <div className="md:w-[75%] w-full flex flex-col gap-4 p-4">
                    <button onClick={toggleFilter} className="md:hidden bg-blue-500 text-white px-4 py-2 rounded mb-4">Show Filters</button>
                    {
                           (rentalCarData?.recommendations || []).map((car:any, index: Key | null | undefined) => (

                            <>
                              <Flex className="flex-col md:flex-row bg-white shadow-lg rounded-lg w-full " key={index}>
                     
                                  <div className="w-full md:w-1/3 flex flex-col items-center p-4 bg-gray-100">
                            <PiHeartLight size={24} className="text-red-500 mb-2" />
                            <div className='w-full h-40 bg-cover bg-center rounded-lg' style={{ backgroundImage: `url(${car?.rentalCarType})` }}></div>
                        </div>
                        <div className="w-full md:w-2/3 p-4 flex flex-col justify-between">
                            <div className="flex justify-between items-start">
                                <div>
                                    <Text className="text-lg font-bold">{car?.rentalCompany}</Text>
                                </div>
                                <BsThreeDotsVertical className="text-gray-500" />
                            </div>
                            <div className="flex flex-col md:flex-row md:justify-between mt-4">
                                <div className="flex flex-col space-y-2 md:w-2/3">
                                    <div className="flex items-center space-x-2">
                                        
                                        <Text className="text-gray-500">{`Seating Capacity: ${car?.seatingCapacity? car?.seatingCapacity : "NA"}`}</Text>
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        <Text className="font-semibold">BRL Airport</Text>
                                        <Text className="text-gray-500">10.3 mi from city center</Text>
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        <Text className="font-semibold">Type</Text>
                                        <Text className="text-gray-500">{car?.type ? car.type : 'NA'}</Text>
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        <Text className="font-semibold">{car?.mileage? car.mileage : 'NA'}</Text>
                                    </div>
                                </div>
                                <div className="flex flex-col items-end md:w-1/3 space-y-2">
                                    <Text className="text-xl font-bold text-indigo-600">{car?.rentalRate? car?.rentalRate: "NA"}</Text>
                                    <Buttons Text="Reserve" onClick={() => handleReserve(index)}  />
                                </div>
                            </div>
                            <div className="flex justify-between mt-4 md:mt-0 md:flex-col md:items-end">
                                <div className="hidden md:flex flex-col space-y-1 text-gray-600">
                                    <Text>{`Cancellation: ${car?.cancellation? car.cancellation : "NA"}`}</Text>
                                    <Text>Online Check-in</Text>
                                    <Text>Pay at Pickup</Text>
                                    <Text>Reserve with a Card and Save</Text>
                                </div>
                            </div>
                        </div>
                        </Flex>
                            </>
                           ))
                        }
                  
                </div>
            </div>


      {isFilterVisible && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
          <div className="bg-white p-4 rounded-lg w-full h-full md:w-3/4 md:h-3/4 overflow-auto relative">
            <button
              onClick={toggleFilter}
              className="absolute top-4 right-4 bg-red-500 text-white px-4 py-2 rounded"
            >
              Close
            </button>
            <RentalCardFilter />
          </div>
        </div>
      )}
    </>
  );
};
