// import React, { useState } from 'react';
// import { PackageResult } from '../context/PackagesContext';

// const PackageModal = ({ closeModal, heading }:any) => {
//   const {passengerPackageData, setPassengerPackageData} =PackageResult()
  
//   const incrementAdult = () => setPassengerPackageData({ ...passengerPackageData, adultCount: passengerPackageData.adultCount + 1});
//   const decrementAdult = () => passengerPackageData.adultCount > 0 && setPassengerPackageData({ ...passengerPackageData, adultCount: passengerPackageData.adultCount - 1});

//   const incrementYouth = () => setPassengerPackageData({ ...passengerPackageData, youthCount: passengerPackageData.youthCount + 1});
//   const decrementYouth = () => passengerPackageData.youthCount > 0 && setPassengerPackageData({ ...passengerPackageData, youthCount: passengerPackageData.youthCount - 1});

//   const incrementInfrants = () => setPassengerPackageData({ ...passengerPackageData, infrantsCount: passengerPackageData.infrantsCount + 1});
//   const decrementInfrants = () => passengerPackageData.infrantsCount > 0 && setPassengerPackageData({ ...passengerPackageData, infrantsCount: passengerPackageData.infrantsCount - 1});

 
//   return (
//     <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//       <div className="bg-white p-6 rounded-lg shadow-lg w-96">
//         <h2 className="text-xl font-bold mb-4">{heading}</h2>
//         <div className="flex justify-between items-center mb-4">
//           <div>
//             <p className="text-lg font-semibold">Adults</p>
//             <p className="text-gray-500">18+ years</p>
//           </div>
//           <div className="flex items-center space-x-2">
//             <button className="px-3 py-1 bg-gray-200 rounded" onClick={decrementAdult}>-</button>
//             <span className="text-lg">{passengerPackageData.adultCount}</span>
//             <button className="px-3 py-1 bg-gray-200 rounded" onClick={incrementAdult}>+</button>
//           </div>
//         </div>
//         <div className="flex justify-between items-center mb-4">
//           <div>
//             <p className="text-lg font-semibold">Youth</p>
//             <p className="text-gray-500">Ages 2 to 17 years</p>
//           </div>
//           <div className="flex items-center space-x-2">
//             <button className="px-3 py-1 bg-gray-200 rounded" onClick={decrementYouth}>-</button>
//             <span className="text-lg">{passengerPackageData.youthCount}</span>
//             <button className="px-3 py-1 bg-gray-200 rounded" onClick={incrementYouth}>+</button>
//           </div>
//         </div>
//         <div className="flex justify-between items-center mb-4">
//           <div>
//             <p className="text-lg font-semibold">Infrants</p>
//             <p className="text-gray-500">Younger than 2</p>
//           </div>
//           <div className="flex items-center space-x-2">
//             <button className="px-3 py-1 bg-gray-200 rounded" onClick={decrementInfrants}>-</button>
//             <span className="text-lg">{passengerPackageData.infrantsCount}</span>
//             <button className="px-3 py-1 bg-gray-200 rounded" onClick={incrementInfrants}>+</button>
//           </div>
//         </div>
//         <div className="flex justify-end">
//           <button className="px-4 py-2 bg-blue-500 text-white rounded">
//             Add another room 
//           </button>
//         </div>
//         <div className="flex justify-end">
//           <button className="px-4 py-2 bg-blue-500 text-white rounded" onClick={closeModal}>
//            Done
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PackageModal;

import React, { useState } from 'react';
import { PackageResult } from '../context/PackagesContext';

interface PackageModalProps {
  closeModal: () => void;
  heading: string;
}

interface PassengerData {
  adultCount: number;
  youthCount: number;
  infantsCount: number;
}

const PackageModal: React.FC<PackageModalProps> = ({ closeModal, heading }) => {
  const { passengerPackageData, setPassengerPackageData } = PackageResult();
  const [rooms, setRooms] = useState<PassengerData[]>([{ ...passengerPackageData }]);

  const increment = (index: number, type: keyof PassengerData) => {
    const newRooms = [...rooms];
    newRooms[index][type]++;
    setRooms(newRooms);
  };

  const decrement = (index: number, type: keyof PassengerData) => {
    const newRooms = [...rooms];
    if (newRooms[index][type] > 0) {
      newRooms[index][type]--;
      setRooms(newRooms);
      updateParentState(newRooms);
    }
  };

  const addRoom = () => {
    setRooms([...rooms, { ...passengerPackageData }]);
   
  };

  const removeRoom = (index: number) => {
    const newRooms = rooms.filter((_, i) => i !== index);
    setRooms(newRooms);
    updateParentState(newRooms);
  };
  const updateParentState = (updatedRooms: PassengerData[]) => {
    // Calculate total passengers and update parent component state
    let totalAdults = 0;
    let totalYouths = 0;
    let totalInfants = 0;

    updatedRooms.forEach((room) => {
      totalAdults += room.adultCount;
      totalYouths += room.youthCount;
      totalInfants += room.infantsCount;
    });

    setPassengerPackageData({
      adultCount: totalAdults,
      youthCount: totalYouths,
      infantsCount: totalInfants
    });
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        {/* <h2 className="text-xl font-bold mb-4">{heading}</h2> */}
        {rooms.map((room, index) => (
          <div key={index} className="mb-6 border p-4 rounded">
            <h3 className="text-lg font-semibold">Room {index + 1}</h3>
            <div className="flex justify-between items-center mb-4">
              <div>
                <p className="text-lg font-semibold">Adults</p>
                <p className="text-gray-500">18+ years</p>
              </div>
              <div className="flex items-center space-x-2">
                <button className="px-3 py-1 bg-gray-200 rounded" onClick={() => decrement(index, 'adultCount')}>-</button>
                <span className="text-lg">{room.adultCount}</span>
                <button className="px-3 py-1 bg-gray-200 rounded" onClick={() => increment(index, 'adultCount')}>+</button>
              </div>
            </div>
            <div className="flex justify-between items-center mb-4">
              <div>
                <p className="text-lg font-semibold">Youth</p>
                <p className="text-gray-500">Ages 2 to 17 years</p>
              </div>
              <div className="flex items-center space-x-2">
                <button className="px-3 py-1 bg-gray-200 rounded" onClick={() => decrement(index, 'youthCount')}>-</button>
                <span className="text-lg">{room.youthCount}</span>
                <button className="px-3 py-1 bg-gray-200 rounded" onClick={() => increment(index, 'youthCount')}>+</button>
              </div>
            </div>
            <div className="flex justify-between items-center mb-4">
              <div>
                <p className="text-lg font-semibold">Infants</p>
                <p className="text-gray-500">Younger than 2</p>
              </div>
              <div className="flex items-center space-x-2">
                <button className="px-3 py-1 bg-gray-200 rounded" onClick={() => decrement(index, 'infantsCount')}>-</button>
                <span className="text-lg">{room.infantsCount }</span>
                <button className="px-3 py-1 bg-gray-200 rounded" onClick={() => increment(index, 'infantsCount')}>+</button>
              </div>
            </div>
            {index > 0 && (
              <div className="flex justify-end">
                <button className="px-4 py-2 bg-red-500 text-white rounded" onClick={() => removeRoom(index)}>Remove this room</button>
              </div>
            )}
          </div>
        ))}
        <div className="flex justify-between">
          <button className="px-4 py-2 bg-blue-500 text-white rounded" onClick={addRoom}>Add another room</button>
          <button className="px-4 py-2 bg-blue-500 text-white rounded" onClick={closeModal}>Done</button>
        </div>
      </div>
    </div>
  );
};

export default PackageModal;
