import { SetStateAction, useEffect, useState } from "react";
import { SearchDetails, useSearch } from "../context/searchFlight";
import { usePopperContext } from "../context/popperContext";
import { useNavigate } from "react-router-dom";
import { useSearchCountry } from "../context/searchCountry";
import { useFlightTypeContext } from "../context/flightTypeContext";
import { useFlightSearchResult } from "../context/flightSearchResults";
import { useTripType } from "../context/tripType";
import { formatDate } from "../Constant/dateFormat";
import { FlightSearchRequest } from "../types/searchrequest";
import {
  Autocomplete,
  CheckboxField,
  Flex,
  Radio,
  RadioGroupField,
  Theme,
  ThemeProvider,
} from "@aws-amplify/ui-react";
import { airports } from "../Constant/airportData";
import { MdOutlineSwapHoriz } from "react-icons/md";
import { FiCalendar } from "react-icons/fi";
import Buttons from "./Buttons";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { findtravelerValue } from "./roundTrip.helper";
import { useFlexibleFlightResult } from "../context/flexibleFlightContext";
import axios from "axios";
import { useLoading } from "../context/loadingContext";
import { fetchData } from "../api/api";
import {  toast } from "react-toastify";

export const OneWayTrip = () => {
  const navigate = useNavigate();
  const { setLoading, setShowFlightLoading } = useLoading();
  const { flightType } = useFlightTypeContext();
  const { setSearchDetails } = useSearch();
  const { flexibleFlightData, setFlexibleFlightData, setFlexibleDays } =
    useFlexibleFlightResult();
  const { setFlightResultData } = useFlightSearchResult();
  const { to, from, setFrom, setTo } = useSearchCountry();
  const [departureDate, setDepartureDate] = useState<Date | null>(null);
  const [returnDate, setReturnDate] = useState<Date | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { popperValue } = usePopperContext();
  const { tripType } = useTripType();
  const AdultNumbers = popperValue.match(/\d+(?=A)/g);
  let adults = 0;
  if (AdultNumbers && AdultNumbers.length > 0) {
    adults = parseInt(AdultNumbers[0], 10);
  }
  const [childrenNumber, setchildernNumber] = useState(0);
  const [infrants, setInfrants] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    const childrenValue = findtravelerValue(popperValue, "C");
    const infrantsValue = findtravelerValue(popperValue, "I");
    setchildernNumber(childrenValue);
    setInfrants(infrantsValue);
  }, [popperValue]);
  const onChangeFrom = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setFrom(event?.target.value);
  };
  const onChangeTo = (event: { target: { value: SetStateAction<string> } }) => {
    if (from == event.target.value) {
      return;
    }
    setTo(event?.target.value);
  };
  const onClearFrom = () => {
    setFrom(" ");
  };
  const onClearTo = () => {
    setTo("");
  };
  const onSelectFrom = (option: { label: string; id: string }) => {
    const { label, id } = option;
    setFrom(id);
  };
  const onSelectTo = (option: { label: string; id: string }) => {
    const { label, id } = option;
    setTo(id);
  };
  const swapValues = () => {
    const temp = from;
    setFrom(to);
    setTo(temp);
  };
  const handleDepartureDateChange = (date: Date | null) => {
    setDepartureDate(date);
    if (returnDate && date && date > returnDate) {
      setReturnDate(null);
    }
  };

  const handleSearch = async () => {
    if (!departureDate) {
      return;
    }
    setShowFlightLoading(true);
    const searchRequest: FlightSearchRequest = {
      fromAirport: from,
      toAirport: to,
      departureDate: formatDate(departureDate),
      numberOfAdults: adults,
      numberOfChildren: childrenNumber,
      numberofInfrants: infrants,
      //  typeOfTrip:TripType[tripType as keyof typeof TripType] ,
      //  bookingClass:   BookingClass[flightType as keyof typeof BookingClass],
      bookingClass: flightType,
      typeOfTrip: tripType,
      sliceandDice: false,
    };

    const searchDetail: SearchDetails = {
      fromAirport: searchRequest.fromAirport,
      toAirport: searchRequest.toAirport,
      typeOfTrip: searchRequest?.typeOfTrip,
      departureDate: searchRequest.departureDate,
      sliceandDice: searchRequest.sliceandDice,
      numberOfAdults: searchRequest.numberOfAdults,
      numberOfChildren: childrenNumber,
      numberofInfrants: infrants,
      bookingClass: searchRequest.bookingClass,
      lowestPrice: "112",
      numberOfFlexibleDays: "",
    };

    setSearchDetails(searchDetail);
    if (flexibleFlightData) {
      setShowFlightLoading(false);
      navigate("/calendar");
      return;
    }

    const url = "https://api.vimanayaatra.com/SearchLite";

    try {
      const flightData = await fetchData(url, searchDetail);
      setFlightResultData(flightData);
      setShowFlightLoading(false);
      navigate("/flightlist");
    } catch (error: any) {
      setShowFlightLoading(false);
      toast("404 not found");
    }
  };

  useEffect(() => {
    const checkButtonDisabled = () => {
      if (!from || !to || !adults || !departureDate) {
        setIsButtonDisabled(true);
      } else {
        setIsButtonDisabled(false);
      }
    };

    checkButtonDisabled();
  }, [from, to, adults, departureDate]);

  const theme: Theme = {
    name: "autocomplete-theme",
    tokens: {
      components: {
        autocomplete: {
          menu: {
            option: {
              _active: {
                backgroundColor: {
                  value: "#007aff",
                },
              },
            },
          },
        },
      },
    },
  };
  return (
    <>
      <Flex
        paddingInline={"2rem"}
        width={"100%"}
        className="flex sm:flex-row flex-col"
      >
        <Flex className="flex sm:flex-row flex-col">
          <Flex className="flex sm:flex-row flex-col">
            <ThemeProvider theme={theme} colorMode="light">
              <Autocomplete
                label="Autocomplete"
                borderRadius={"16px"}
                size="large"
                width={"12rem"}
                value={from}
                onChange={onChangeFrom}
                onClear={onClearFrom}
                onSelect={onSelectFrom}
                options={airports.map((airport) => ({
                  id: airport.id,
                  label: airport.label,
                }))}
                placeholder="from."
              />
            </ThemeProvider>
            <MdOutlineSwapHoriz
              size={24}
              style={{
                position: "absolute",
                left: "233px",
                top: "82px",
                color: "#007aff",
                width: "35px",
                border: "50% 50%",
                backgroundColor: "#f5f5f5",
                borderRadius: "50%",
                height: "35px",
              }}
              onClick={swapValues}
            />
            <ThemeProvider theme={theme} colorMode="light">
              <Autocomplete
                label="Autocomplete"
                borderRadius={"16px"}
                size="large"
                width={"12rem"}
                value={to}
                onChange={onChangeTo}
                onClear={onClearTo}
                onSelect={onSelectTo}
                options={airports.map((airport) => ({
                  id: airport.id,
                  label: airport.label,
                }))}
                placeholder="to?"
              />
            </ThemeProvider>
          </Flex>
          <CheckboxField
            label="flexible flight"
            marginBlockStart={"1rem"}
            checked={flexibleFlightData}
            onChange={() => {
              setFlexibleFlightData(!flexibleFlightData);
            }}
            name="flexibleFlight"
          />
          {flexibleFlightData && (
            <div style={{ display: "flex" }}>
              <div style={{ border: "30px", padding: "5px" }}>
                <p>± 1 day</p>
              </div>
              <div style={{ border: "30px", padding: "5px" }}>
                <p>± 2 day</p>
              </div>
              <div style={{ border: "30px", padding: "5px" }}>
                <p>± 3 day</p>
              </div>
            </div>
          )}
          <Flex marginBlock={"4px"}>
            <DatePicker
              showIcon
              selected={departureDate}
              onChange={handleDepartureDateChange}
              dateFormat="dd/MM/yy"
              className="custom-datepicker"
              placeholderText="Departing"
              minDate={new Date()}
              icon={<FiCalendar size={24} color="#007aff" />}
            />
          </Flex>
        </Flex>
        <Flex style={{ alignItems: "center", justifyContent: "center" }}>
          <div style={{ zIndex: 1 }}>
            <Buttons
              Text={"Find Flight"}
              onClick={handleSearch}
              width={"10rem"}
              disabled={isButtonDisabled}
            />
          </div>
        </Flex>
      </Flex>
    </>
  );
};
