export function dateDifference(date1: string, date2: string): number {
    // Extract day, month, and year from the date strings
    const day1 = parseInt(date1.substr(0, 2));
    const month1 = parseInt(date1.substr(2, 2));
    const year1 = parseInt(date1.substr(4, 2)) + 2000;

    const day2 = parseInt(date2.substr(0, 2));
    const month2 = parseInt(date2.substr(2, 2));
    const year2 = parseInt(date2.substr(4, 2)) + 2000;

    // Create Date objects for each date
    const dateObj1 = new Date(year1, month1 - 1, day1);
    const dateObj2 = new Date(year2, month2 - 1, day2);

    // Calculate the difference in milliseconds
    const diffMs = Math.abs(dateObj2.getTime() - dateObj1.getTime());

    // Convert milliseconds to days
    const diffDays = Math.ceil(diffMs / (1000 * 60 * 60 * 24));

    return diffDays;
}

export function formatTime(inputTime: string): string {
    // Check if inputTime is not null or undefined using optional chaining
    if (!inputTime?.match(/^\d{4}$/)) {
        // If input is invalid or null/undefined, return an empty string or handle error accordingly
        return '';
    }

    // Extract hours and minutes from the inputTime string
    const hours = inputTime.slice(0, 2);
    const minutes = inputTime.slice(2);

    // Concatenate hours and minutes with ":" in between
    const formattedTime = `${hours}:${minutes}`;

    return formattedTime;
}


export function createReturnStopFilter(totalResponse:any){
    const objForKeyCheck = {
        'non-stop':false,
        'one-stop':false,
        'two-stop':false,
        'nonStopLow':234399,
        'oneWayLow':234399,
        "twoWayLow":234399
    };
    const stopJson: { name: string; price: string; key: string; }[] = []

      totalResponse.map((data:any)=>{
        if(Object.keys(data?.returnSegment?.layOverList).length==0 && objForKeyCheck['non-stop']===false){
            stopJson.push({name:"non Stop",price:"234399", key:"non-return"});
            objForKeyCheck['non-stop'] = true;
        }else if(Object.keys(data?.returnSegment?.layOverList).length==1 && objForKeyCheck['one-stop']===false){
            stopJson.push({name:"1 stop",price:"1223888", key:"one-return"});
            objForKeyCheck['one-stop'] = true;
        }else if(Object.keys(data?.returnSegment?.layOverList).length==2 && objForKeyCheck['two-stop']===false){
            stopJson.push({name:"2 stop",price:"5357777",  key:"two-return"})
            objForKeyCheck['two-stop'] = true;
        }
      })
      totalResponse.map((data:any)=>{
        const lowPrice = +(data?.totalFare);
        if(Object.keys(data?.returnSegment?.layOverList).length==0 && objForKeyCheck.nonStopLow>lowPrice){
            objForKeyCheck.nonStopLow = lowPrice;
        }else if(Object.keys(data?.returnSegment?.layOverList).length==1 && objForKeyCheck.oneWayLow>lowPrice){
            objForKeyCheck.oneWayLow = lowPrice;
        }else if(Object.keys(data?.returnSegment?.layOverList).length==2 && objForKeyCheck.twoWayLow>lowPrice){
            objForKeyCheck.twoWayLow = lowPrice;
        }
      })
      const sendResponse: { name: string; price: string; key: string; }[] = []
      stopJson.map((data)=>{
        if(data.key==="non-return"){
            sendResponse.push({...data, price:""+objForKeyCheck.nonStopLow});   
        } else if(data.key==="one-return"){
            sendResponse.push({...data, price:""+objForKeyCheck.oneWayLow});   
        } else if(data.key==="two-return"){
            sendResponse.push({...data, price:""+objForKeyCheck.twoWayLow});   
        }
      })
      return sendResponse;
}


export function createDepatureStopFilter(totalResponse:any){
    const objForKeyCheck = {
        'non-stop':false,
        'one-stop':false,
        'two-stop':false,
        'nonStopLow':234399,
        'oneWayLow':234399,
        "twoWayLow":234399
    };
    const stopJson: { name: string; price: string; key: string; }[] = []

      totalResponse.map((data:any)=>{
        if(Object.keys(data?.onwardSegment?.layOverList).length==0 && objForKeyCheck['non-stop']===false){
            stopJson.push({name:"non Stop",price:"234399", key:"non-departure"});
            objForKeyCheck['non-stop'] = true;
        }else if(Object.keys(data?.onwardSegment?.layOverList).length==1 && objForKeyCheck['one-stop']===false){
            stopJson.push({name:"1 stop",price:"1223888", key:"one-departure"});
            objForKeyCheck['one-stop'] = true;
        }else if(Object.keys(data?.onwardSegment?.layOverList).length==2 && objForKeyCheck['two-stop']===false){
            stopJson.push({name:"2 stop",price:"5357777",  key:"two-departure"})
            objForKeyCheck['two-stop'] = true;
        }
      })
      totalResponse.map((data:any)=>{
        const lowPrice = +(data?.totalFare);
        if(Object.keys(data?.onwardSegment?.layOverList).length==0 && objForKeyCheck.nonStopLow>lowPrice){
            objForKeyCheck.nonStopLow = lowPrice;
        }else if(Object.keys(data?.onwardSegment?.layOverList).length==1 && objForKeyCheck.oneWayLow>lowPrice){
            objForKeyCheck.oneWayLow = lowPrice;
        }else if(Object.keys(data?.onwardSegment?.layOverList).length==2 && objForKeyCheck.twoWayLow>lowPrice){
            objForKeyCheck.twoWayLow = lowPrice;
        }
      })
      const sendResponse: { name: string; price: string; key: string; }[] = []
      stopJson.map((data)=>{
        if(data.key==="non-departure"){
            sendResponse.push({...data, price:""+objForKeyCheck.nonStopLow});   
        } else if(data.key==="one-departure"){
            sendResponse.push({...data, price:""+objForKeyCheck.oneWayLow});   
        } else if(data.key==="two-departure"){
            sendResponse.push({...data, price:""+objForKeyCheck.twoWayLow});   
        }
      })
      return sendResponse;
}


export function findLowestPrice(data:any){
    let lowest = 534363654;

    data.map((dataValue:any)=>{
        const fareValue = +(dataValue?.totalFare);
        if(fareValue<lowest){
            lowest = fareValue;
        }
    })
    return lowest;
}
export function maxTimeForFlight(flights:any){
    if (!flights.length) {
        return 0;
      }
      let maxTime = 0;
      flights.forEach((flight:any)=>{
        const time = parseInt(flight?.onwardSegment?.flyingTime, 10);
        if (time > maxTime) {
          maxTime = time;
        }
      })
      const hours = Math.floor(maxTime / 100);
      const minutes = maxTime % 100;
      return minutes >= 30 ? hours + 1 : hours;

}