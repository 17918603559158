
import React, { createContext, useState, useContext, ReactNode } from 'react';
interface LoadingContextType {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  showFlightLoading: boolean;
  setShowFlightLoading: (loading: boolean) => void;
}
const LoadingContext = createContext<LoadingContextType | undefined>(undefined);
export const useLoading = () => {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error('useLoading must be used within a LoadingProvider');
  }
  return context;
};
 
export const LoadingProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [showFlightLoading,setShowFlightLoading] = useState(false);
  return (
    <LoadingContext.Provider value={{ loading, setLoading,setShowFlightLoading,showFlightLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};
