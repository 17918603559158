import { useEffect, useState } from "react";
import { Flex } from "@aws-amplify/ui-react";
import Buttons from "./Buttons";
import { MultiCitySubTab } from "./MultiCitySubTab";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useFlightSearchResult } from "../context/flightSearchResults";

export const MultiCity = () => {
    const [numberOfFlightSearch, setNumberOfFlightSearch] = useState(1);
    const [ip, setIP] = useState("");
    const navigate = useNavigate();
    const { setFlightResultData}=useFlightSearchResult();
    const [fromData, setFromData] = useState(null);
    const [tabs, setTabs] = useState([{ id: 1, data: {} }]); // Array to store tab identifiers and data

    const GetNearestAirport = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        setIP(res?.data?.ip);
    };

    useEffect(() => {
        GetNearestAirport();
    }, []);

    useEffect(() => {
        const fetchNearestAirport = async () => {
            try {
                const response = await axios.get(`https://www.travelpayouts.com/whereami?locale=en&ip=${ip}`);
                const data = response.data;
                const { iata } = data;
                setFromData(iata);
            } catch (error) {
                console.error('Error fetching nearest airport:', error);
            }
        }
        if (ip) {
            fetchNearestAirport();
        }
    }, [ip]);

    const addFlight = () => {
        if (numberOfFlightSearch < 5) {
            const newId = Math.max(...tabs.map(tab => tab.id), 0) + 1; // Generate a new unique ID
            setTabs(prevTabs => [...prevTabs, { id: newId, data: {} }]);
            setNumberOfFlightSearch(prev => prev + 1);
        }
    };

    const removeFlight = (idToRemove: any) => {
        if (numberOfFlightSearch > 1) {
            setTabs(prevTabs => prevTabs.filter(tab => tab.id !== idToRemove));
            setNumberOfFlightSearch(prev => prev - 1);
        }
    };

    const handleSearch = async () => {
        const dataOfFlight = tabs.map(tab => tab.data);
        // Need to create an array of object for the API call using the dataOfFlight for now calling the API simple body
        const body = {
          fromAirport: "MIA",
          toAirport: "HNL",
          departureDate: "190424",
          numberOfAdults: 1,
          typeOfTrip: "round-trip",
          bookingClass: "ECONOMY",
          sliceandDice: true

        };
        const proxyUrl="http://localhost:8080/";
         const url = "http://api.tidesandtravels.us/FareMasterPricerTravelBoardSearch";
         const endPoint= proxyUrl + url;
         const response = await fetch('/dummyData.json'); 
         const data = await response.json();
         setFlightResultData(data)
         setTimeout(() => {  
           navigate("/flightdetails"); 
         }, 2000);

    };

    const updateTabData = (id: number, newData: any) => {
      setTabs(prevTabs =>
          prevTabs.map(tab =>
              tab.id === id ? { ...tab, data: { ...tab.data, ...newData } } : tab
          )
      );
  };

    return (
        <>
            <Flex>
                <div>
                    {tabs.map(tab => (
                        <MultiCitySubTab
                            key={tab.id}
                            id={tab.id}
                            onRemove={() => removeFlight(tab.id)}
                            isRemovable={tabs.length > 1}
                            onUpdate={(flightInfo:any) => updateTabData(tab.id, flightInfo)}
                        />
                    ))}
                    {numberOfFlightSearch < 5 && (
                        <p onClick={addFlight} style={{ color: 'blue',cursor:"pointer",textDecoration:"underline",alignItems:"left" }}>Add 1 more flight</p>
                    )}
                    {numberOfFlightSearch >= 5 && (
                        <p style={{ color: 'red' }}>Maximum number of flights reached</p>
                    )}
                </div>
                <div style={{zIndex:"10"}}>
                <Buttons Text={'Find Flight'}  onClick={handleSearch} width={'10rem'} />
                </div>
            </Flex>
        </>
    )
}
