
import React, { createContext, useState, useContext, ReactNode } from 'react';

// Step 1: Create a new context
interface TripTypeContextType {
  tripType: string;
  setTripType: React.Dispatch<React.SetStateAction<string>>;}

const TripTypeContext = createContext<TripTypeContextType | undefined>(undefined);

export const TripTypeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [tripType, setTripType] = useState('round-trip'); 

 
  return (
    // Step 4: Wrap your application with the provider component
    <TripTypeContext.Provider value={{tripType, setTripType}}>
      {children}
    </TripTypeContext.Provider>
  );
};
export const useTripType = (): TripTypeContextType => {
    const context = useContext(TripTypeContext);
    if (!context) {
      throw new Error('useTripType must be used within a TripTypeProvider');
    }
    return context;
  };