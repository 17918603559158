// DataContext.tsx
import React, { createContext, useState, useContext, ReactNode } from 'react';
import {thinksToDoListData, museumsListData} from '../Components/helperFuntions/thinksTravel.helper'

interface ThingsSearchResultType {
  thingsResultData: any;
  setThingsResultData: React.Dispatch<any>;
  passengerThingsData:any;
  setPassengerThingsResultData: React.Dispatch<any>;
  thingsSearchData:any;
  setThingsSearchData: React.Dispatch<any>;
  museumListData:any;
  setMuseumListData: React.Dispatch<any>;
   thingsToDoListData: any;
  setThingsToDoListData: React.Dispatch<any>;
}
const ThingsSearchResultContext = createContext<ThingsSearchResultType | undefined>(undefined);
export const useThingsSearchResult = () => {
    const context = useContext(ThingsSearchResultContext);
    if (!context) {
      throw new Error('useFlightSearchResult must be used within a DataProvider');
    }
    return context;
  };
export const ThingsSearchResultProvider:React.FC<{ children: ReactNode }> = ({ children }) =>{
  const [thingsResultData, setThingsResultData] = useState<any>(null);
  const [thingsSearchData, setThingsSearchData] = useState<any>({
    city: '',
    fromTime: '',
    toDate: ''
  });
  const [passengerThingsData, setPassengerThingsResultData] = useState<any>({ adultCount: 1, youthCount: 0});

  const [thingsToDoListData, setThingsToDoListData] = useState(thinksToDoListData);
  const [museumListData, setMuseumListData] = useState(museumsListData);
  return (
    <ThingsSearchResultContext.Provider value={{museumListData, setMuseumListData,thingsToDoListData, setThingsToDoListData, thingsResultData, setThingsResultData,passengerThingsData, setPassengerThingsResultData,thingsSearchData, setThingsSearchData }}>
      {children}
    </ThingsSearchResultContext.Provider>
  );
};


