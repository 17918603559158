import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define types for filter state
interface Filters {
    [key: string]: boolean;
}

// Define types for context
interface FilterContextType {
    filters: Filters;
    setFilters: React.Dispatch<React.SetStateAction<Filters>>;
    // filtersWithKey: (value: boolean, key: string) => void;
}

// Create the context
const FilterContext = createContext<FilterContextType | undefined>(undefined);

// Custom hook to access the filter state
export const useFilterContext = (): FilterContextType => {
    const context = useContext(FilterContext);
    if (!context) {
        throw new Error('useFilterContext must be used within a FilterProvider');
    }
    return context;
};

// Provider component to wrap the app and provide the filter state
interface FilterProviderProps {
    children: ReactNode;
}
export const FilterProvider = ({ children }: FilterProviderProps): JSX.Element => {
    const [filters, setFilters] = useState<Filters>({});

    const filtersWithKey = (value: boolean, key: string): void => {
        setFilters({ ...filters, [key]: value });
    };

    const value: FilterContextType = {
        filters,
        setFilters,
       
    };

    return (
        <FilterContext.Provider value={value}>
            {children}
        </FilterContext.Provider>
    );
};
