import { useEffect, useState } from 'react';
import { Flex, Text, Button   } from '@aws-amplify/ui-react';
import { BiSolidPlaneAlt } from "react-icons/bi";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import {
  Card,
  View,
  Heading,
  useTheme,
} from '@aws-amplify/ui-react';
import { FormatMonth } from '../Constant/monthFormat';

export const RecentSearch = () => {
  const [recentSearch, setRecentSearch] = useState(JSON.parse(localStorage.getItem('searchHistory') || '[]'));
  const { tokens } = useTheme();
  const [itemsPerPage, setItemsPerPage] = useState(window.innerWidth >= 1024 ? 4 : 1);
  
  const [currentPage, setCurrentPage] = useState(1);
  const styless = {
    ParaText: {
      color: '#030303',
      fontSize: '30px',
      fontFamily: 'Montserrat',
      lineHeight: '30px',
      fontWeight: 'bold',
      alignItems:'left'
      
    }, 
    Card: {
    
      backgroundColor: 'rgba(255,255,255,0.48)',
      borderRadius: '24px',
      boxShadow: '0px 2px 10px rgba(3,3,3,0.1)',
    },
};
const handleResize = () => {
  setItemsPerPage(window.innerWidth >= 1024 ? 4 : 1);
};

useEffect(() => {
  window.addEventListener('resize', handleResize);
  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);

  const totalPages = Math.ceil(recentSearch.length / itemsPerPage);

  const handleClickNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleClickPrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, recentSearch.length);

  return (
    <Flex direction={'column'} gap={'0.8rem'} textAlign={'start'}>
      {recentSearch?.length > 0 && <Text style={styless.ParaText}>Your Recent Searches</Text>}

      <Flex direction={'row'} justifyContent="center" alignItems="center" gap={'0.8rem'} textAlign={'start'} style={{ overflowX: 'auto' }}>
      <Button disabled={currentPage === 1} onClick={handleClickPrev}><IoIosArrowBack size={36}/></Button>
        {recentSearch.slice(startIndex, endIndex).map((searchData:any, index:number) => (
          
            <Card style={styless.Card} key={index} className='md:w-[25%] w-full border-black shadow-md border b-4'>
              <Flex alignItems="center" justifyContent="center">
              <BiSolidPlaneAlt size={28}/>
              <Flex direction="row" alignItems="flex-start">
                <Flex direction="column" alignItems="flex-start" gap={tokens.space.xs}>
                  <Text fontSize={20} fontWeight={600}>Flight from {`${searchData?.fromAirport} to ${searchData?.toAirport}`}</Text>
                  <Text as="span">{`${FormatMonth(searchData?.departureDate)} - ${FormatMonth(searchData?.returnDate)}`}</Text>
                  <Text as="span">{searchData?.typeOfTrip || ''} . {`${(searchData?.numberOfAdults || 0) + (searchData?.numberOfChildren || 0) + (searchData?.numberofInfrants || 0)} travelers`}</Text>
                  <Text>Price ${searchData?.lowestPrice}</Text>
                </Flex>
              </Flex>
              </Flex>
            </Card>
          
        ))}
        <Button  disabled={currentPage === totalPages} onClick={handleClickNext}><IoIosArrowForward size={36}/></Button>
      </Flex>
      {/* <Flex justifyContent="space-between">
        <Button   disabled={currentPage === 1} onClick={handleClickPrev} ></Button>
        <Button    disabled={currentPage === totalPages} onClick={handleClickNext} >right</Button>
      </Flex> */}
    </Flex>
  );
};
