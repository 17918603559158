// DataContext.tsx
import React, { createContext, useState, useContext, ReactNode } from 'react';
// export interface RentalCarSearchDetails {
//   pickUp: string;
//   dropOff: string;
//   pickupDate: string;
//   dropOffDate?: string;
//   pickupTime: string;
//   dropOffTime?: string;

// }

export interface RentalCarSearchDetails {
  airportCode: string;
  pickupDateTime: string; 
  dropOffDateTime: string;
}

interface RentalCarSearchDetailsType {
    rentalCarDetails: RentalCarSearchDetails;
    setRentalCarDetails: React.Dispatch<any>;
    rentalCarData:any;
    setRentalCarData: React.Dispatch<any>;
}

const RentalCarSearchDetailsContext = createContext<RentalCarSearchDetailsType | undefined>(undefined);
export const useRentalCarSearchResult = () => {
    const context = useContext(RentalCarSearchDetailsContext);
    if (!context) {
      throw new Error('useFlightSearchResult must be used within a DataProvider');
    }
    return context;
  };
export const RentalCarSearchDetailsProvider:React.FC<{ children: ReactNode }> = ({ children }) =>{
  const [rentalCarDetails, setRentalCarDetails] = useState<any>(null);
  const [rentalCarData, setRentalCarData] = useState<any>(null);


  return (
    <RentalCarSearchDetailsContext.Provider value={{rentalCarData, setRentalCarData,rentalCarDetails, setRentalCarDetails }}>
      {children}
    </RentalCarSearchDetailsContext.Provider>
  );
};


