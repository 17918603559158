// Card.jsx
import React from 'react';
import { FaClock } from 'react-icons/fa';

const CruiseItinery = () => {
  return(
    <div className="w-full mt-2 items-start bg-gray-200 shadow-lg rounded-lg overflow-hidden p-6">
      
      <h1 className="text-2xl text-start font-bold mb-4">Cruise Itinerary</h1>
      <div className='flex flex-col md:flex-row justify-between w-full'>
      <div className="w-full md:w-7/12">
          <div className='flex flex-col items-start'>
            <h2>Day 1</h2>
            <p className="text-xl font-semibold text-start">Miami</p>
            <p>Florida, United States</p>
            <p className="flex items-center"><FaClock className="mr-2"/>7:00am — 8:00pm</p>
          </div>
          <hr className="border-gray-400 mt-2 mb-4" />
          <div className='flex flex-col items-start'>
            <h2>Day 2</h2>
            <p className="text-xl font-semibold text-start">Key West</p>
            <p>Florida, United States</p>
            <p className="flex items-center"><FaClock className="mr-2"/>7:00am — 8:00pm</p>
          </div>
          <hr className="border-gray-400 mt-2 mb-4" />
          <div className='flex flex-col items-start'>
            <h2>Day 3</h2>
            <p className="text-xl font-semibold text-start">Ocean Cay MSC Marine Reserve (Private Island)</p>
            <p>Bahamas</p>
            <p className="flex items-center"><FaClock className="mr-2"/>7:00am — 8:00pm</p>
          </div>
          <hr className="border-gray-400 mt-2 mb-4" />
          <div className='flex flex-col items-start'>
            <h2>Day 4</h2>
            <p className="text-xl font-semibold text-start">Miami</p>
            <p>Florida, United States</p>
            <p className="flex items-center"><FaClock className="mr-2"/>7:00am — 8:00pm</p>
          </div>
        </div>
        <div className="w-full md:w-5/12 mb-4 md:mb-0">
          <img
            src="https://mediaim.expedia.com/cruise/pc-rp-2024-02-01/653d4729-7850-4c81-b9ad-90fc8b00da9e.jpg?impolicy=resizecrop&ra=fit&rw=500"
            alt="Cruise Image"
            className="w-full h-64 md:h-full object-cover rounded-md"
          />
        </div>
        
       
        
      </div>
    </div>
  );
//    (
//     <div className="w-full mt-2 items-start  bg-gray-200 shadow-lg rounded-lg overflow-hidden p-6">
      
//         <h1 className="text-2xl text-start font-bold mb-4">Cruise Itinerary</h1>
//        <div className='flex justify-between w-full'>

//             <div className=" w-5/12">
//             <div className='flex flex-col items-start'>
//                 <h2 >Day 1</h2>
//                 <p  className="text-xl font-semibold text-start ">Miami</p>
//                 <p>Florida, United States</p>
//                 <p className="flex items-center"><FaClock className="mr-2"/>7:00am — 8:00pm</p>
//             </div>
//             <hr className="border-gray-400  mt-2 mb-4" />
//             <div className='flex flex-col items-start'>
//                 <h2>Day 2</h2>
//                 <p  className="text-xl font-semibold text-start">Key West</p>
//                 <p>Florida, United States</p>
               
//                 <p className="flex items-center"><FaClock className="mr-2"/>7:00am — 8:00pm</p>
//             </div>
//             <hr className="border-gray-400 mt-2 mb-4" />
//             <div className='flex flex-col items-start'>
//                 <h2 >Day 3</h2>
//                 <p className="text-xl font-semibold text-start" >Ocean Cay MSC Marine Reserve (Private Island)</p>
//                 <p >Bahamas</p>
               
//                 <p className="flex items-center"><FaClock className="mr-2"/>7:00am — 8:00pm</p>
//             </div>
//             <hr className="border-gray-400  mt-2 mb-4" />
//             <div className='flex flex-col items-start'>
//                 <h2 >Day 4</h2>
//                 <p className="text-xl font-semibold text-start ">Miami</p>
//                 <p>Florida, United States</p>
//                 <p className="flex items-center"><FaClock className="mr-2"/>7:00am — 8:00pm</p>
//             </div>
         
//             </div>
//             <div className="w-7/12 ">
//                 <img
//                 src="https://mediaim.expedia.com/cruise/pc-rp-2024-02-01/653d4729-7850-4c81-b9ad-90fc8b00da9e.jpg?impolicy=resizecrop&ra=fit&rw=500"
//                 alt="Cruise Image"
//                 className="h-5/12 w-full object-cover"
//                 />
//             </div>
//        </div>
      
//     </div>
//   );
};

export default CruiseItinery;
