import { Button, Card, Flex, Text } from '@aws-amplify/ui-react';
import { FlightData } from '../Constant/flights';
import { useEffect, useState } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

export const TrustedAirlines = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(window.innerWidth >= 1024 ? 4 : 1);

  const totalPages = Math.ceil(FlightData.length / itemsPerPage);

  const handleClickNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleClickPrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleResize = () => {
    setItemsPerPage(window.innerWidth >= 1024 ? 4 : 1);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, FlightData.length);

  return (
    <>
      {/* <Flex direction="column" gap="4" className="mt-8">
        <Text className="text-black text-xl font-montserrat leading-none" data-testid="airline-trust-text">
          Airlines trusted by travelers
        </Text>
        <Flex direction="row" gap="4" wrap={'wrap'}>
          {FlightData.map((data, index) => (
            <Card key={index} className="w-full sm:w-64 bg-white rounded-2xl shadow-md">
              <Flex direction="column" gap="4" padding="4">
                <div
                  className="h-40 md:h-48 lg:h-64 rounded-2xl bg-cover bg-center"
                  style={{ ...data.style, backgroundImage: `url(${data.imageUrl})` }}
                />
                <Text className="text-black text-base font-montserrat">{data.airlineText}</Text>
                <Text className="text-gray-600 text-sm font-montserrat">{data.travelCity}</Text>
                <Flex direction="row" justifyContent="space-between" alignItems="center">
                  <Text className="text-black text-base font-montserrat">{data.price}</Text>
                  <svg className="w-4 h-4 text-black fill-current" viewBox="0 0 320 512">
                    <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z" />
                  </svg>
                </Flex>
              </Flex>
            </Card>
          ))}
        </Flex>
      </Flex> */}

<div className="flex flex-col gap-3 text-start">
      <Text className="text-black text-xl font-montserrat leading-none" data-testid="airline-trust-text">
        Airlines trusted by travelers
      </Text>
      <div className="flex justify-between items-center">
        <Button disabled={currentPage === 1} onClick={handleClickPrev}>
          <IoIosArrowBack size={36} />
        </Button>
        <div className="flex-1 grid grid-cols-1 lg:grid-cols-4 gap-4">
          {FlightData.slice(startIndex, endIndex).map((data, index) => (
            <Card key={index} className="w-full bg-white rounded-2xl shadow-md">
              <Flex direction="column" gap="4" padding="4">
                <div
                  className="h-40 md:h-48 lg:h-64 rounded-2xl bg-cover bg-center"
                  style={{ backgroundImage: `url(${data.imageUrl})` }}
                />
                <Text className="text-black text-base font-montserrat">{data.airlineText}</Text>
                <Text className="text-gray-600 text-sm font-montserrat">{data.travelCity}</Text>
                <Flex direction="row" justifyContent="space-between" alignItems="center">
                  <Text className="text-black text-base font-montserrat">{data.price}</Text>
                  <svg className="w-4 h-4 text-black fill-current" viewBox="0 0 320 512">
                    <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z" />
                  </svg>
                </Flex>
              </Flex>
            </Card>
          ))}
        </div>
        <Button disabled={currentPage === totalPages} onClick={handleClickNext}>
          <IoIosArrowForward size={36} />
        </Button>
      </div>
    </div>
    </>
  );
};
