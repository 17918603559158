// DataContext.tsx
import React, { createContext, useState, useContext, ReactNode } from 'react';

interface FlexibleFlightType {
    flexibleFlightData: any;
  setFlexibleFlightData: React.Dispatch<any>;
  flexibleDays:any;
  setFlexibleDays:any;
}

const FlexibleFlightContext = createContext<FlexibleFlightType | undefined>(undefined);
export const useFlexibleFlightResult = () => {
    const context = useContext(FlexibleFlightContext);
    if (!context) {
      throw new Error('useFlexibleFlightResult must be used within a DataProvider');
    }
    return context;
  };
export const FlexibleFlightResultProvider:React.FC<{ children: ReactNode }> = ({ children }) =>{
  const [flexibleFlightData, setFlexibleFlightData] = useState<any>(null);
  const [flexibleDays, setFlexibleDays] = useState<any>(null);

  return (
    <FlexibleFlightContext.Provider value={{ flexibleFlightData, setFlexibleFlightData,flexibleDays,setFlexibleDays }}>
      {children}
    </FlexibleFlightContext.Provider>
  );
};


