
import React, { createContext,useEffect, useState, useContext, ReactNode } from 'react';

import axios from "axios";
import { airports } from '../Constant/airportData';
// Step 1: Create a new context
interface SearchCountryContextType {
  to: string;
  from: string;
  setTo: React.Dispatch<React.SetStateAction<string>>;
  setFrom: React.Dispatch<React.SetStateAction<string>>;}

const SearchCountryContext = createContext<SearchCountryContextType | undefined>(undefined);

export const SearchCountryProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [ip, setIP] = useState("");
  const [to, setTo] = useState(''); 
  const [from, setFrom] = useState(''); 
  const GetNearestAirport = async () => {
      const res = await axios.get("https://api.ipify.org/?format=json");
      setIP(res?.data?.ip);
    };
    useEffect(() => {
      GetNearestAirport();
    }, []);

    useEffect(() => {
      const fetchNearestAirport = async () => {
        try {
          const response = await axios.get(`https://www.travelpayouts.com/whereami?locale=en&ip=${ip}`);
          const data = response.data;
          const { iata } = data;
          // setFrom(iata);
          setFrom('DFW')
          setTo('BLR')
          }  
        catch (error) {
          console.error('Error fetching nearest airport:', error);
        }
      }
      if (ip) {
        fetchNearestAirport();
      }
     }, [ip]);
  return (
    <SearchCountryContext.Provider value={{to, setTo,from,setFrom}}>
      {children}
    </SearchCountryContext.Provider>
  );
};
export const useSearchCountry = (): SearchCountryContextType => {
    const context = useContext(SearchCountryContext);
    if (!context) {
      throw new Error('useSearchCountry must be used within a SearchCountryProvider');
    }
    return context;
  };