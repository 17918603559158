import React from 'react'
export const FormatMonth=(dateString: any)=> {
   
    const day: string = dateString?.substring(0, 2);
    const month: string = dateString?.substring(2, 4);
    let year: string = dateString?.substring(4);
    if(year && year.length=== 4){
      year = year.substring(2);
    }
    const formattedDate: string = `${parseInt(day)} ${getMonthName(parseInt(month))} ${parseInt(year)}`;
  
    return formattedDate;
  }
  
  function getMonthName(monthNumber: number): string {
    const months: string[] = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    return months[monthNumber - 1];
  }
  
