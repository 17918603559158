// Breadcrumb.tsx

import React from 'react';

interface BreadcrumbItem {
  label: string;
  url: string;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
  return (

<div className="text-sm breadcrumbs mx-4">
<ul>
      
        {items.map((item, index) => (
          
            <li  key={index} className='text-lg font-medium'><a>{item.label}</a></li>   
        
        ))}
        </ul>
        </div>
  );
};

export default Breadcrumb;
