import {
    Button,
    Card,
    CheckboxField,
    Flex,
    Grid,
    Radio,
    RadioGroupField,
    SliderField,
    Text,
  } from "@aws-amplify/ui-react";
  
  const travelerRatings = [
    { id: "any", name: "Any", value: "any" },
    { id: "wonderful", name: "Wonderful 4.5+", value: "wonderful" },
    { id: "very-good", name: "Very good 4+", value: "very-good" },
    { id: "good", name: "Good 3.5+", value: "good" },
  ];
  
  const budgetOptions = [
    { id: "less-than-25", name: "Less than $25", value: "less-than-25" },
    { id: "25-to-50", name: "$25 to $50", value: "25-to-50" },
    { id: "50-to-75", name: "$50 to $75", value: "50-to-75" },
    { id: "75-to-100", name: "$75 to $100", value: "75-to-100" },
    { id: "greater-than-100", name: "Greater than $100", value: "greater-than-100" },
  ];
  
  const popularLocations = [
    { id: "times-square", name: "Times Square", value: "times-square" },
    { id: "central-park", name: "Central Park", value: "central-park" },
    { id: "statue-of-liberty", name: "Statue of Liberty", value: "statue-of-liberty" },
    { id: "broadway", name: "Broadway", value: "broadway" },
    { id: "grand-central-terminal", name: "Grand Central Terminal", value: "grand-central-terminal" },
  ];
  
  export const TravelFilter = () => {
    return (
      <>
        <Flex
          direction="column"
          className="w-full p-6 bg-gray-200 rounded-lg shadow-lg"
        >
          <Text className="text-2xl font-semibold mb-4">Filter by</Text>
          
          {/* <div className="mb-6">
            <Text className="text-lg font-medium mb-2">Location Type</Text>
            <Flex direction="column" className="gap-2">
              <Flex direction="row" justifyContent="space-between">
                <CheckboxField label="Airport" name="airport" value="Airport" />
                <Text className="text-gray-600">$74</Text>
              </Flex>
              <Flex direction="row" justifyContent="space-between">
                <CheckboxField label="Non-Airport" name="non-airport" value="Non-Airport" />
                <Text className="text-gray-600">$54</Text>
              </Flex>
            </Flex>
          </div> */}
          
          <div className="mb-6">
            <Text className="text-lg font-medium mb-2">Capacity</Text>
            <Flex direction="column" className="gap-2">
              {popularLocations.map((item, index) => (
                <Flex key={index} direction="row" justifyContent="space-between">
                  <CheckboxField label={''} name={`capacity_${index}`} value={item.value} />
                  <Text className="text-gray-600">{item.name}</Text>
                </Flex>
              ))}
            </Flex>
          </div>
  
          <div className="mb-6">
            <Text className="text-lg font-medium mb-2">Car Type</Text>
            <Flex direction="column" className="gap-2">
              {travelerRatings.map((item, index) => (
                <Flex key={index} direction="row" justifyContent="space-between">
                    
                    <RadioGroupField legend='' name={`travelerRatings_${index}`}>
    <Radio value={item.value}>{item.name}</Radio>
    
  </RadioGroupField>
                
                </Flex>
              ))}
            </Flex>
          </div>
  
          <div className="mb-6">
            <Text className="text-lg font-medium mb-2">Specifications</Text>
            <Flex direction="column" className="gap-2">
              {budgetOptions.map((item, index) => (
                <Flex key={index} direction="row" justifyContent="space-between">
                  <CheckboxField label={''} name={`specifications_${index}`} value={item.value} />
                  <Text className="text-gray-600">{item.name}</Text>
                </Flex>
              ))}
            </Flex>
          </div>
  
          {/* <Button className="bg-blue-500 text-white px-4 py-2 rounded">Apply Filters</Button> */}
        </Flex>
      </>
    );
  };
  