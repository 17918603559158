import React from 'react'
import { FaArrowLeft, FaHeadphones, FaUser } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import CruiseItinery from '../Components/cruiseItinery';
import CruiseWhatsIncluded from '../Components/cruiseWhatsIncluded';
import CruiseRoomExp from '../Components/cruiseRoomExp';
import CruiseImages from '../Components/cruiseImages';
import CruiseOverview from '../Components/cruiseOverview';
import CruiseBreadBrum from '../Components/cruiseBreadBrum';
import CruiseDetailCard from '../Components/cruiseDetailCard';
import CruisePolicy from '../Components/cruisePolicy';
import { useCruisesResult } from '../context/cruisesContext';

const CruisesDetails = () => {
    const navigate = useNavigate();
    const goBack = () => {
      navigate(-1);
    };
    const {cruises, setCruises} = useCruisesResult();
    return (
      <div className=' w-[100%]  bg-white h-screen '>
        <div className='w-9/12 h-full  mx-auto'>
          <div className="shadow-lg rounded-xl gap-4 bg-gray-200  flex flex-col p-4">
            <div className="flex items-center mb-2">
              <FaArrowLeft className="text-blue-500 cursor-pointer" onClick={goBack} />
              <h2 className="ml-2 text-blue-500 ">See all sailings</h2>
            </div>
            <CruiseDetailCard />
            <CruiseImages />
            <CruiseBreadBrum />
          <CruiseOverview />
          </div>
          <div className="breadcrumbs text-lg">
            <ul>
              <li><a>Choose room type</a></li>
              <li><a>Choose room</a></li>
              <li>Review your trip</li>
            </ul>
          </div>
          <CruiseRoomExp />
          <CruiseWhatsIncluded />
          <CruiseItinery />

          <CruisePolicy />
        </div>
      </div>
      );
}

export default CruisesDetails
