import React from 'react';
import { Table } from '@aws-amplify/ui-react';
import { useSearch } from '../context/searchFlight';
import { formatDateToCustomString } from '../pages/helperFunctions/calender.helper';
import { useFlightSearchResult } from '../context/flightSearchResults';
import { useNavigate } from 'react-router-dom';
import { fetchData } from '../api/api';
import { useLoading } from '../context/loadingContext';
import { useFlightTypeContext } from '../context/flightTypeContext';

type DateArray = Date[];
type PriceArray = number[];

const getFlexibleDates = (date: Date, numberOfFlexibleDays: number): Date[] => {
  const startDate = new Date(date);
  const endDate = new Date(date);
  startDate.setDate(startDate.getDate() - numberOfFlexibleDays);
  endDate.setDate(endDate.getDate() + numberOfFlexibleDays);
  const dates: Date[] = [];
  for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
    dates.push(new Date(d));
  }
  return dates;
};

const generatePriceArray = (
  departureDate: Date,
  numberOfFlexibleDays: number
): PriceArray => {
  const departureDates = getFlexibleDates(departureDate, numberOfFlexibleDays);
  const prices: PriceArray = [];
  for (let i = 0; i < departureDates.length; i++) {
    const price = Math.floor(Math.random() * 401) + 100;
    prices.push(price);
  }
  return prices;
};

const createDepatureDateData = (flexibleData:any) =>{
  if( flexibleData.length<=0){
    return [];
  }
  const newDatesArray = [];
  for(let i=0; i<flexibleData.length-1; i++){
    if(flexibleData[i][0]){
      const newDate = new Date(flexibleData[i][0]);
    newDatesArray.push(newDate);
    }else{
      newDatesArray.push(null);
    }
  }
  return newDatesArray;
}

const createPricesArray = (flexibleData:any) =>{
  if( flexibleData.length<=0){
    return [];
  }
  const newDatesArray = [];
  for(let i=0; i<flexibleData.length-1; i++){
    if(flexibleData[i][1]){
      const newDate =flexibleData[i][1];
    newDatesArray.push(newDate);
    }else{
      newDatesArray.push(null);
    }
  }
  return newDatesArray;
}
function findLowestPrice(prices:any) {
  const validPrices = prices.filter((price: any) => price !== null).map((price:any) => parseFloat(price));
  const lowestPrice = Math.min(...validPrices);
  return lowestPrice + "0";
} 


export const FlightCalendarOneWay: React.FC<{
  departureDate: Date;
  numberOfFlexibleDays: number;
  flexibleData:any
  flexibleSegmentData:any,
}> = ({ departureDate,flexibleSegmentData, numberOfFlexibleDays,flexibleData }) => {
  const { setLoading ,setShowFlightLoading} = useLoading();
  const {selectedFlight,setSelectedFlight}= useFlightTypeContext()
  const lowestPrice = findLowestPrice(createPricesArray(flexibleData));
  console.log(lowestPrice, ":lowestPrice")
  // const departureDates = getFlexibleDates(departureDate, numberOfFlexibleDays);
  const departureDates = createDepatureDateData(flexibleData);
  const { setFlightResultData } = useFlightSearchResult();
  const navigate = useNavigate();
console.log(flexibleSegmentData, ":flexibleSegmentData oneway")
  const { searchDetails } = useSearch();
  // const prices = generatePriceArray(departureDate,numberOfFlexibleDays);
  const prices = createPricesArray(flexibleData);

  const styles = {
    Container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "100vh",
    },
    Card: {
      top: "102px",
      left: "64px",
      width: "912px",
      backgroundColor: "rgba(255,255,255,0.48)",
      borderRadius: "24px",
      boxShadow: "0px 2px 10px rgba(3,3,3,0.1)",
    },
    InnerCard: {
      width: "912px",
      height: "60px",
      backgroundColor: "#f0f0f0",
      borderRadius: "24px",
      margin: "-16px",
    },
    ImageContainer: {
      top: "230px",
      left: "104px",
      width: "70px",
      height: "70px",
      borderRadius: "8px",
      backgroundImage: "url(./image.png)",
      backgroundPosition: "center center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
    FlightInfo: {
      color: "#030303",
      fontSize: "16px",
      fontFamily: "Montserrat",
      lineHeight: "32px",
      fontWeight: 500,
    },
    FlightName: {
      color: "#030303",
      fontSize: "20px",
      fontFamily: "Montserrat",
      lineHeight: "12px",
      fontWeight: 600,
    },
    FlightDate: {
      color: "#030303",
      fontSize: "24px",
      fontFamily: "Montserrat",
      fontWeight: 700,
      lineHeight: "31px",
    },
    Boarding: {
      color: "#030303",
      fontSize: "20px",
      fontFamily: "Montserrat",
      lineHeight: "26px",
    },
    Text: {
      color: "#030303",
      fontSize: "16px",
      fontFamily: "Montserrat",
      fontWeight: 500,
      lineHeight: "40px",
    },
    ParaText: {
      color: "#030303",
      fontSize: "12px",
      fontFamily: "Montserrat",
      lineHeight: "26px",
      fontWeight: 400,
    },
    TH: {
      backgroundColor: "#007aff",
      color: "white",
      width: "100px",
      height: "80px",
    },
  };
  function findSegemtRowColumResponse(flexibleSegmentData:any,row:any){ 
    return flexibleSegmentData[row];
  }
  const clickedCell = async (depatureDateCell: any, row:any) => {
     const flexibleSegmentResponse= findSegemtRowColumResponse(flexibleSegmentData,row)


    setShowFlightLoading(true);
    const payloadObject = {
      numberOfAdults: searchDetails?.numberOfAdults || 0,
      segments: [
        {
          marketingCarrier: flexibleSegmentResponse?.onwardSegment?.marketingCarrier,
          legs: flexibleSegmentResponse?.onwardSegment?.legs || [],
        },
      ],
    };

     let proxyUrl;
      let endPoint;
      const url = "https://api.vimanayaatra.com/FareInformativeBestPricing";
      if (process.env.NODE_ENV === "development") {
        proxyUrl = "http://localhost:8080/";
        endPoint = proxyUrl + url;
      } else {
        endPoint = `https://${url}`;
      }
    try {
      const flightData = await fetchData(endPoint, payloadObject);
      setFlightResultData(flightData)
      setShowFlightLoading(false);
      setSelectedFlight(flexibleSegmentResponse)
      navigate("/checkout");
    } catch (error: any) {
      setShowFlightLoading(false);
      console.error(error.message);
    }
  };

  return (
    <Table width={"80%"} style={{ borderCollapse: "collapse", margin: "auto" }}>
      <thead>
        <tr>
          <th style={styles.TH}></th>
          {departureDates.map((date, index) => (
            <th
              key={index}
              style={{  
                backgroundColor:
                  date?.getTime() === departureDate.getTime()
                    ? "skyBlue"
                    : "#007aff",
                color: "white",
                width: "50px",
                height: "50px",
              }}
            >
              {date?.toDateString() || "NA"}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td
            style={{
              border: "1px solid black",
              backgroundColor: "skyBlue",
              color: "white",
              width: "50px",
              height: "50px",
            }}
          >
            {departureDate.toDateString()}
          </td>
          {prices.map((price, index) => (
            <td
              onClick={() => {
                
                  clickedCell(departureDates[index],index+1);
              }}
             
              
              key={index}
              style={{   ...( price  === lowestPrice ? { backgroundColor: 'green' } : {}),
                border: "1px solid black",
                width: "100px",
                height: "80px",
               
              }}
            >
              {price ? `$ ${price}`:"NA"}
            </td>
          ))}
        </tr>
      </tbody>
    </Table>
  );
};
