import { SetStateAction, useEffect, useRef, useState } from "react";
import { SearchDetails, useSearch } from "../context/searchFlight";
import { usePopperContext } from "../context/popperContext";
import { useNavigate } from "react-router-dom";
import { useSearchCountry } from "../context/searchCountry";
import { useFlightTypeContext } from "../context/flightTypeContext";
import { useFlightSearchResult } from "../context/flightSearchResults";
import { useTripType } from "../context/tripType";
import { formatDate } from "../Constant/dateFormat";
import { FlightSearchRequest } from "../types/searchrequest";

import {
  Autocomplete,
  CheckboxField,
  Flex,
  Radio,
  RadioGroupField,
  Theme,
  ThemeProvider,
} from "@aws-amplify/ui-react";
import { airports } from "../Constant/airportData";
import { MdOutlineSwapHoriz } from "react-icons/md";
import "./../../src/App.css";
import { FiCalendar } from "react-icons/fi";
import Buttons from "./Buttons";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import "react-datepicker/dist/react-datepicker.css";
import { findtravelerValue } from "./roundTrip.helper";
import { useFlexibleFlightResult } from "../context/flexibleFlightContext";
import { addDays, format } from "date-fns";
import { DateRange } from "react-date-range";
import { colorCodedDates } from "./roundTrip.helper";
import axios from "axios";
import { useLoading } from "../context/loadingContext";
import { Flightdetails } from "../pages/flightDetails";
import { fetchData } from "../api/api";
import { toast } from "react-toastify";

export const RoundedTrip = () => {
  const navigate = useNavigate();
  const { setLoading, setShowFlightLoading } = useLoading();
  const { flightType } = useFlightTypeContext();
  const { setSearchDetails } = useSearch();
  const {
    flexibleFlightData,
    setFlexibleFlightData,
    flexibleDays,
    setFlexibleDays,
  } = useFlexibleFlightResult();
  const { setFlightResultData } = useFlightSearchResult();
  const { to, from, setFrom, setTo } = useSearchCountry();
  const { popperValue } = usePopperContext();
  const { tripType } = useTripType();
  const AdultNumbers = popperValue.match(/\d+(?=A)/g);
  const [childrenNumber, setchildernNumber] = useState(0);
  const [infrants, setInfrants] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [showLoading, setShowLoading] = useState(false);

  const [months, setMonths] = useState(2);

  const updateMonths = () => {
    setMonths(window.innerWidth <= 768 ? 1 : 2);
  };

  useEffect(() => {
    updateMonths(); // Initial check
    window.addEventListener("resize", updateMonths);
    return () => {
      window.removeEventListener("resize", updateMonths);
    };
  }, []);

  useEffect(() => {
    const childrenValue = findtravelerValue(popperValue, "C");
    const infrantsValue = findtravelerValue(popperValue, "I");
    setchildernNumber(childrenValue);
    setInfrants(infrantsValue);
  }, [popperValue]);
  let adults = 0;
  if (AdultNumbers && AdultNumbers.length > 0) {
    adults = parseInt(AdultNumbers[0], 10);
  }
  const onChangeFrom = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setFrom(event?.target.value);
  };
  const onChangeTo = (event: { target: { value: SetStateAction<string> } }) => {
    if (from == event.target.value) {
      return;
    }
    setTo(event?.target.value);
  };
  const onClearFrom = () => {
    setFrom(" ");
  };
  const onClearTo = () => {
    setTo("");
  };
  const onSelectFrom = (option: { label: string; id: string }) => {
    const { label, id } = option;
    setFrom(id);
  };
  const onSelectTo = (option: { label: string; id: string }) => {
    const { label, id } = option;
    setTo(id);
  };
  const swapValues = () => {
    const temp = from;
    setFrom(to);
    setTo(temp);
  };
  const handleSearch = async () => {
    if (!dateRange.selection1.startDate || !dateRange.selection1.endDate) {
      console.log("Please fill in all fields");
      return;
    }
    setShowFlightLoading(true);
    const searchRequest: FlightSearchRequest = {
      fromAirport: from,
      toAirport: to,
      departureDate: formatDate(dateRange.selection1.startDate),
      returnDate: formatDate(dateRange.selection1.endDate),
      numberOfAdults: adults,
      numberOfChildren: childrenNumber,
      numberofInfrants: infrants,
      bookingClass: flightType,
      typeOfTrip: tripType,
      sliceandDice: false,
    };

    const searchDetail: SearchDetails = {
      fromAirport: searchRequest.fromAirport,
      toAirport: searchRequest.toAirport,
      typeOfTrip: searchRequest?.typeOfTrip,
      departureDate: searchRequest.departureDate,
      returnDate: searchRequest.returnDate,
      sliceandDice: searchRequest.sliceandDice,
      numberOfAdults: searchRequest.numberOfAdults,
      numberOfChildren: childrenNumber,
      numberofInfrants: infrants,
      bookingClass: searchRequest.bookingClass,
      lowestPrice: "112",
      numberOfFlexibleDays: "",
    };

    setSearchDetails(searchDetail);
    if (flexibleFlightData) {
      setShowFlightLoading(false);
      navigate("/calendar");
      return;
    }
    console.log(searchDetail, "searchDetail133");

    const url = "https://api.vimanayaatra.com/SearchLite";

    try {
      const flightData = await fetchData(url, searchDetail);
      setFlightResultData(flightData);
      setShowFlightLoading(false);
      navigate("/flightlist");
    } catch (error: any) {
      setShowFlightLoading(false);
      toast("404 not found");
    }
  };

  const theme: Theme = {
    name: "autocomplete-theme",
    tokens: {
      components: {
        autocomplete: {
          menu: {
            option: {
              _active: {
                backgroundColor: {
                  value: "#007aff",
                },
              },
            },
          },
        },
      },
    },
  };
  const [dateRange, setDateRange] = useState({
    selection1: {
      startDate: new Date("2024-07-08"),
      endDate: new Date("2024-09-09"),
      key: "selection1",
    },
  });
  const [openDate, setOpenDate] = useState(false);
  const handleDateChange = (ranges: any) => {
    setDateRange({
      ...dateRange,
      selection1: {
        ...dateRange.selection1,
        ...ranges.selection1,
      },
    });
  };
  const dayContentRenderer = (date: Date) => {
    const day = date.getDate();
    const todayDate = new Date();
    let style: React.CSSProperties = {};

    if (
      date >= todayDate &&
      colorCodedDates[day] &&
      (date < dateRange.selection1.startDate ||
        date > dateRange.selection1.endDate)
    ) {
      style = {
        backgroundColor: colorCodedDates[day],
        color: "white",
        width: "90%",
        height: "33px",
        borderRadius: "20%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      };
    } else {
      style = {
        color: "black",
        width: "90%",
        height: "33px",
        borderRadius: "20%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      };
    }

    return <div style={style}>{day}</div>;
  };

  const wrapperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setOpenDate(false);
      }
    }

    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [wrapperRef]);
  useEffect(() => {
    const checkButtonDisabled = () => {
      if (!from || !to || !adults) {
        setIsButtonDisabled(true);
      } else {
        setIsButtonDisabled(false);
      }
    };

    checkButtonDisabled();
  }, [from, to, adults]);

  return (
    <>
      <Flex
        paddingInline={"2rem"}
        width={"100%"}
        className="flex sm:flex-row flex-col"
      >
        <Flex className="flex sm:flex-row flex-col">
          <Flex className="flex sm:flex-row flex-col">
            <ThemeProvider theme={theme} colorMode="light">
              <Autocomplete
                label="Autocomplete"
                className="flex-value"
                borderRadius={"16px"}
                size="large"
                width={"12rem"}
                value={from}
                data-testid="from-roundtrip"
                onChange={onChangeFrom}
                onClear={onClearFrom}
                onSelect={onSelectFrom}
                options={airports.map((airport) => ({
                  id: airport.id,
                  label: airport.label,
                }))}
                placeholder="from."
              />
            </ThemeProvider>
            <MdOutlineSwapHoriz
              size={24}
              style={{
                position: "absolute",
                left: "233px",
                top: "82px",
                color: "#007aff",
                width: "35px",
                border: "50% 50%",
                backgroundColor: "#f5f5f5",
                borderRadius: "50%",
                height: "35px",
              }}
              className="hidden sm:flex"
              onClick={swapValues}
            />
            <ThemeProvider theme={theme} colorMode="light">
              <Autocomplete
                label="Autocomplete"
                borderRadius={"16px"}
                size="large"
                width={"12rem"}
                value={to}
                onChange={onChangeTo}
                onClear={onClearTo}
                onSelect={onSelectTo}
                options={airports.map((airport) => ({
                  id: airport.id,
                  label: airport.label,
                }))}
                placeholder="to?"
              />
            </ThemeProvider>
          </Flex>
          <Flex marginBlock={"4px"}>
            <div
              style={{
                width: "21rem",
                paddingBlockStart: "0.3rem",
                zIndex: "10",
              }}
            >
              <div
                className="px-3 py-[10px] gap-2 flex items-center "
                style={{
                  width: "21rem",
                  border: "1px solid gray",
                  borderRadius: "4rem",
                  // paddingInline: "3rem",
                  // paddingBlock: "0.7rem",
                }}
                onClick={() => setOpenDate(!openDate)}
              >
                <FiCalendar size={14} color="#007aff" />
                {`${format(
                  dateRange.selection1.startDate,
                  "MM/dd/yyyy"
                )} ~ ${format(dateRange.selection1.endDate, "MM/dd/yyyy")}`}
              </div>
              {/* </span> */}
              {openDate && (
                <div
                  style={{
                    zIndex: "1",
                    position: "absolute",
                    backgroundColor: "white",
                    padding: "10px",
                  }}
                >
                  <CheckboxField
                    label="flexible flight"
                    marginBlockStart={"1rem"}
                    checked={flexibleFlightData}
                    onChange={() => {
                      setFlexibleFlightData(!flexibleFlightData);
                    }}
                    name="flexibleFlight"
                  />
                  {flexibleFlightData && (
                    <div
                      style={{
                        display: "flex",
                        gap: "24px",
                        height: "35px",
                        marginBlockStart: "12px",
                      }}
                    >
                      <div
                        style={{
                          border: "1px solid black",
                          display: "flex",
                          alignItems: "center",
                          paddingInline: "14px",
                          borderRadius: "15px",
                          borderColor: flexibleDays === 1 ? "blue" : "black",
                          backgroundColor:
                            flexibleDays === 1 ? "skyblue" : "transparent",
                        }}
                        onClick={() => {
                          setFlexibleDays(1);
                        }}
                      >
                        <p>± 1 day</p>
                      </div>
                      <div
                        style={{
                          border: "1px solid black",
                          paddingInline: "14px",
                          display: "flex",
                          alignItems: "center",
                          borderRadius: "10px",
                          borderColor: flexibleDays === 2 ? "blue" : "black",
                          backgroundColor:
                            flexibleDays === 2 ? "skyblue" : "transparent",
                        }}
                        onClick={() => {
                          setFlexibleDays(2);
                        }}
                      >
                        <p>± 2 day</p>
                      </div>
                      <div
                        style={{
                          border: "1px solid black",
                          borderRadius: "10px",
                          display: "flex",
                          alignItems: "center",
                          paddingInline: "14px",
                          borderColor: flexibleDays === 3 ? "blue" : "black",
                          backgroundColor:
                            flexibleDays === 3 ? "skyblue" : "transparent",
                        }}
                        onClick={() => {
                          setFlexibleDays(3);
                        }}
                      >
                        <p>± 3 day</p>
                      </div>
                    </div>
                  )}
                  <div style={{ zIndex: "10" }}>
                    <DateRange
                      editableDateInputs={true}
                      onChange={handleDateChange}
                      moveRangeOnFirstSelection={false}
                      className="daterange"
                      ranges={[dateRange.selection1]}
                      months={months}
                      direction="horizontal"
                      minDate={new Date()}
                      showPreview={true}
                      dayContentRenderer={dayContentRenderer}
                      maxDate={addDays(new Date(), 9 * 30)}
                    />
                  </div>
                </div>
              )}
            </div>
          </Flex>
        </Flex>
        <div style={{ zIndex: 1 }}>
          <Buttons
            Text={"Find Flight"}
            onClick={handleSearch}
            disabled={isButtonDisabled}
            width={"10rem"}
          />
        </div>
      </Flex>
      {/* } */}
    </>
  );
};
