import React, { useState } from 'react';
import ReviewBox from './reviewBox';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
const reviews = [
    {
      rating: 5,
      review: "Easy to do. All was set up ahead and able to go and enjoy ourselves without worry.",
      author: "Eduardo R",
      date: "Jun 4, 2024"
    },
    {
      rating: 4,
      review: "Great experience overall. Some minor issues but nothing major.",
      author: "Maria S",
      date: "May 10, 2024"
    },
    {
      rating: 5,
      review: "Fantastic! Everything was perfect and went smoothly.",
      author: "John D",
      date: "Apr 22, 2024"
    },
    {
      rating: 4,
      review: "Good value for the money. Would recommend to friends.",
      author: "Jane P",
      date: "Mar 15, 2024"
    },
    {
      rating: 3,
      review: "Average experience. Could have been better.",
      author: "Tom H",
      date: "Feb 8, 2024"
    },
    {
      rating: 5,
      review: "Exceeded expectations! Highly recommend.",
      author: "Anna K",
      date: "Jan 20, 2024"
    },
    {
      rating: 4,
      review: "Very enjoyable. Will use this service again.",
      author: "Paul B",
      date: "Dec 30, 2023"
    }
  ];
  
  
const ClientReview = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const goToNext = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
    };
  
    const goToPrevious = () => {
      setCurrentIndex((prevIndex) => (prevIndex - 1 + reviews.length) % reviews.length);
    };
  return (
    <>
     {/* <div className="p-6 flex items-center justify-center">
      <button
        className="p-2 bg-gray-300 hover:bg-gray-400 rounded-l"
        onClick={goToPrevious}
      >
        &#8592;
      </button>
      <ReviewBox
        rating={reviews[currentIndex].rating}
        review={reviews[currentIndex].review}
        author={reviews[currentIndex].author}
        date={reviews[currentIndex].date}
      />
      <button
        className="p-2 bg-gray-300 hover:bg-gray-400 rounded-r"
        onClick={goToNext}
      >
        &#8594;
      </button>
    </div> */}
  <div className="p-6 flex items-center justify-center space-x-4">
      <button
        className="p-2 bg-gray-300 hover:bg-gray-400 rounded-full"
        onClick={goToPrevious}
      >
        <FaArrowLeft />
      </button>
      <ReviewBox
        rating={reviews[currentIndex].rating}
        review={reviews[currentIndex].review}
        author={reviews[currentIndex].author}
        date={reviews[currentIndex].date}
      />
      <button
        className="p-2 bg-gray-300 hover:bg-gray-400 rounded-full"
        onClick={goToNext}
      >
        <FaArrowRight />
      </button>
    </div>
</>
  )
}

export default ClientReview
