import React from 'react'
import Cruises from '../Components/cruises'
// import Cruises from '../cruises'

const CruisesPage = () => {
  return (
    <div>
      <Cruises />
    </div>
  )
}

export default CruisesPage
