// DataContext.tsx
import React, { createContext, useState, useContext, ReactNode } from 'react';

interface FlightSearchResultType {
    flightResultData: any;
  setFlightResultData: React.Dispatch<any>;
}

const FlightSearchResultContext = createContext<FlightSearchResultType | undefined>(undefined);
export const useFlightSearchResult = () => {
    const context = useContext(FlightSearchResultContext);
    if (!context) {
      throw new Error('useFlightSearchResult must be used within a DataProvider');
    }
    return context;
  };
export const FlightSearchResultProvider:React.FC<{ children: ReactNode }> = ({ children }) =>{
  const [flightResultData, setFlightResultData] = useState<any>(null);

  return (
    <FlightSearchResultContext.Provider value={{ flightResultData, setFlightResultData }}>
      {children}
    </FlightSearchResultContext.Provider>
  );
};


