
import React, { useState } from 'react';
import { useCruisesResult } from '../../context/cruisesContext';
interface CruiseModalProps {
  closeModal: () => void;
  heading: string;
}

interface CruisesData {
  adultCount: number;
  youthCount: number;

}

const CruiseModal: React.FC<CruiseModalProps> = ({ closeModal, heading }) => {
  const {crusiesPassengerData, setCrusiesPassengerData} = useCruisesResult()

  const increment = (type: keyof CruisesData) => {
    setCrusiesPassengerData((prevData: { [x: string]: number; }) => ({
      ...prevData,
      [type]: prevData[type] + 1,
    }));
  };

  const decrement = (type: keyof CruisesData) => {
    setCrusiesPassengerData((prevData: { [x: string]: number; }) => ({
      ...prevData,
      [type]: prevData[type] > 0 ? prevData[type] - 1 : 0,
    }));
  };


  


  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        {/* <h2 className="text-xl font-bold mb-4">{heading}</h2> */}
       
          <div  className="mb-6 border p-4 rounded">
           
            <div className="flex justify-between items-center mb-4">
              <div>
                <p className="text-lg font-semibold">Adults</p>
                <p className="text-gray-500">18+ years</p>
              </div>
              <div className="flex items-center space-x-2">
                <button className="px-3 py-1 bg-gray-200 rounded" onClick={() => decrement('adultCount')}>-</button>
                <span className="text-lg">{crusiesPassengerData.adultCount}</span>
                <button className="px-3 py-1 bg-gray-200 rounded" onClick={() => increment('adultCount')}>+</button>
              </div>
            </div>
            <div className="flex justify-between items-center mb-4">
              <div>
                <p className="text-lg font-semibold text-start">Youth</p>
                <p className="text-gray-500">Ages 2 to 17 years</p>
              </div>
              <div className="flex items-center space-x-2">
                <button className="px-3 py-1 bg-gray-200 rounded" onClick={() => decrement('youthCount')}>-</button>
                <span className="text-lg">{crusiesPassengerData.youthCount}</span>
                <button className="px-3 py-1 bg-gray-200 rounded" onClick={() => increment('youthCount')}>+</button>
              </div>
            </div>
           
            {/* {index > 0 && (
              <div className="flex justify-end">
                <button className="px-4 py-2 bg-red-500 text-white rounded" onClick={() => removeRoom(index)}>Remove this room</button>
              </div>
            )} */}
          </div>
    
        <div className="flex justify-between">
          {/* <button className="px-4 py-2 bg-blue-500 text-white rounded" onClick={addRoom}>Add another room</button> */}
          <button className="px-4 py-2 bg-blue-500 text-white rounded" onClick={closeModal}>Done</button>
        </div>
      </div>
    </div>
  );
};

export default CruiseModal;
