

  import React, { useState } from 'react';
import { IoIosSearch } from 'react-icons/io';
import { Tab } from './FlightTabs';
import { NumberOfTravellers } from './NumberOfTravellers';
import { Flex } from '@aws-amplify/ui-react';
import { RoundedTrip } from './RoundedTrip';
import StayCar from '../pages/stayCar';
import { FlightStayCar } from '../pages/flightstaycar';
import { PackageResult } from '../context/PackagesContext';

// export const PackagesOptions: React.FC = () => {
//   const [packageType, setSelectedOptions] = useState<string[]>([]);

//   const handleButtonClick = (option: string) => {
//     setSelectedOptions((prevOptions) =>
//       prevOptions.includes(option)
//         ? prevOptions.filter((item) => item !== option)
//         : [...prevOptions, option]
//     );
//   };

//   return (
//     <div className="flex flex-col border b-4 border-gray-400 py-4 w-8/12 mx-auto rounded-lg items-center gap-4 mt-8">
//       <div className="flex flex-row gap-4">
//         <button
//           className={`py-2 px-4 rounded-xl ${
//             selectedOptions.includes('Stay') ? 'bg-blue-500 text-white' : 'bg-gray-200'
//           }`}
//           onClick={() => handleButtonClick('Stay')}
//         >
//           {selectedOptions.includes('Stay') ? 'Stay Added' : 'Stay'}
//         </button>
//         <button
//           className={`py-2 px-4 rounded-xl ${
//             selectedOptions.includes('Flight') ? 'bg-blue-500 text-white' : 'bg-gray-200'
//           }`}
//           onClick={() => handleButtonClick('Flight')}
//         >
//           {selectedOptions.includes('Flight') ? 'Flight Added' : 'Flight'}
//         </button>
//         <button
//           className={`py-2 px-4 rounded-xl ${
//             selectedOptions.includes('Car') ? 'bg-blue-500 text-white' : 'bg-gray-200'
//           }`}
//           onClick={() => handleButtonClick('Car')}
//         >
//           {selectedOptions.includes('Car') ? 'Car Added' : 'Car'}
//         </button>
//       </div>

//       {selectedOptions.length < 2 ?  (
//         <div className="flex flex-col items-center mt-4 p-4 border rounded-xl border-red-500 bg-red-100 text-red-700 text-center">
//           <IoIosSearch size={24} className="mb-2" />
//           <p>Please select two or more items</p>
//           <p>To start building your trip, choose two or more items</p>
//         </div>
//       ): 
//       <>
//        <Flex direction={"row"} marginInline={'auto'} justifyContent={'center'}>
//           <Flex  className=" w-8/12 mx-auto">
//             <Tab />
           
//           </Flex>

//           <Flex className="hidden lg:flex lg:w-4/12">
//             <NumberOfTravellers />
         
//           </Flex>
//         </Flex>
      
//       </>
//       }
//     </div>
//   );
// };



export const PackagesOptions: React.FC = () => {
//   const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const { packageType, setPackageType} = PackageResult();
  const handleButtonClick = (option: string) => {
    setPackageType((prevOptions: string[]) =>
      prevOptions.includes(option)
        ? prevOptions.filter((item) => item !== option)
        : [...prevOptions, option]
    );
  };

  const renderButtonText = (option: string) => {
    if (packageType.includes(option)) {
      return `${option} Added`;
    }
    return option;
  };

  const renderContent = () => {
    if (packageType.length < 2) {
      return (
        <div className="flex flex-col items-center mt-4 p-4 border rounded-xl border-red-500 bg-red-100 text-red-700 text-center">
          <IoIosSearch size={24} className="mb-2" />
          <p>Please select two or more items</p>
          <p>To start building your trip, choose two or more items</p>
        </div>
      );
    } else if (packageType.includes('Flight') && packageType.includes('Car')) {
      return   <FlightStayCar />
    } else if (packageType.includes('Stay') && packageType.includes('Car') && !packageType.includes('Flight') ) {
      return <StayCar />;
    } else {
      return (
        <Flex direction={"row"} className="w-full">
         
           <FlightStayCar />
        </Flex>
      );
    }
  };

  return (
    <div className="flex flex-col border b-4 border-gray-400 py-4 w-8/12 mx-auto rounded-lg items-center gap-4 mt-8">
      <div className="flex flex-row gap-4">
        <button
          className={`py-2 px-4 rounded-xl ${
            packageType.includes('Stay') ? 'bg-blue-500 text-white' : 'bg-gray-200'
          }`}
          onClick={() => handleButtonClick('Stay')}
        >
          {renderButtonText('Stay')}
        </button>
        <button
          className={`py-2 px-4 rounded-xl ${
            packageType.includes('Flight') ? 'bg-blue-500 text-white' : 'bg-gray-200'
          }`}
          onClick={() => handleButtonClick('Flight')}
        >
          {renderButtonText('Flight')}
        </button>
        <button
          className={`py-2 px-4 rounded-xl ${
            packageType.includes('Car') ? 'bg-blue-500 text-white' : 'bg-gray-200'
          }`}
          onClick={() => handleButtonClick('Car')}
        >
          {renderButtonText('Car')}
        </button>
      </div>
      {renderContent()}
    </div>
  );
};
