import "@aws-amplify/ui-react/styles.css";
import { Alert, Card, ThemeProvider, createTheme } from "@aws-amplify/ui-react";
import { LeadFormForSampleTestCreateForm } from "../ui-components";
import { useState } from "react";
// import LeadForm from "../ui-components/LeadForm";
// import { LeadFormCreateForm } from "../ui-components";
// import LeadFormCreateForm from "../ui-components/LeadFormCreateForm";

// import { MyForm } from "../ui-components";
// import { MyForm } from "../ui-components";

export const LeadFormComponent = () => {
  const [showForm, setShowForm] = useState(true);

  const styles = {
    Card: {
      width: "40%",
      margin: "auto",
      backgroundColor: "rgba(255,255,255,0.48)",
      borderRadius: "16px",
      boxShadow: "0px 2px 10px rgba(3,3,3,0.1)",
    },
  };

  return (
    <>
      <ThemeProvider>
        {showForm ? (
          <Card style={styles.Card} textAlign={"left"}>
            <LeadFormForSampleTestCreateForm
              onSubmit={(field) => {
                console.log("fields------->", field);
                return field;
              }}
              onError={(fields, errorMessage) => {
                console.log("fields------->", fields);
                console.log("errorMessage------->", errorMessage);
              }}
              onSuccess={(fields) => {
                console.log("fields------->", fields);
                setShowForm(false);
              }}
            />
          </Card>
        ) : (
          <Alert isDismissible={false} hasIcon={true} heading="Lead Form Status" variation="success">
            Form submitted successfully
          </Alert>
        )}
      </ThemeProvider>
    </>
  );
};


