import React, { useState } from "react";

const TravelerModal = ({ handleModalClose }: any) => {
  const [rooms, setRooms] = useState([{ adults: 1, children: 0 }]);
  const [modalHeight, setModalHeight] = useState("auto");
  console.log("rooms----rooms----->", rooms);

  const addRoom = () => {
    if (rooms.length < 5) {
      setRooms([...rooms, { adults: 1, children: 0 }]);
    }
  };

  const removeRoom = (index: number) => {
    const updatedRooms = [...rooms];
    updatedRooms.splice(index, 1);
    setRooms(updatedRooms);
  };

  const updateCount = (index: number, type: string, value: number) => {
    const updatedRooms = [...rooms];
    if (type === "adults") {
      updatedRooms[index].adults += value;
    } else {
      updatedRooms[index].children += value;
    }
    setRooms(updatedRooms);
  };

  const disableButton = (type: string, count: number) => {
    return count === 0;
  };

  const handleModalHeight = () => {
    if (rooms.length > 1) {
      setModalHeight("50vh");
    } else {
      setModalHeight("auto");
    }
  };

  return (
    <div className="modal-overlay">
      <div
        className="modal"
        style={{
          maxHeight: "60vh",
          overflowY: "scroll",
          scrollbarWidth: "none" /* Firefox */,
          msOverflowStyle: "none" /* IE and Edge */,
          WebkitOverflowScrolling: "touch" /* iOS Safari */,
        }}
        onLoad={handleModalHeight}
      >
        <div style={{ display: "flex" }}>
          <span className="close" onClick={()=>handleModalClose()}>
            &times;
          </span>
          <p
            style={{
              marginLeft: "20px",
              fontSize: "1rem",
              fontWeight: "500",
              lineHeight: "1.25rem",
            }}
          >
            Travelers
          </p>
        </div>
        {rooms.map((room, index) => (
          <div className="room" key={index}>
            <p>Room {index + 1}</p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>Adults</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  width: "7vw",
                }}
              >
                <button
                  className={`traveler-button ${disableButton("adults", room.adults) ? "disableButton":""}`}
                  onClick={() => updateCount(index, "adults", -1)}
                  disabled={disableButton("adults", room.adults)}
                >
                  -
                </button>
                <p>{room.adults}</p>
                <button
                  className="traveler-button"
                  onClick={() => updateCount(index, "adults", 1)}
                >
                  +
                </button>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>Children</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  width: "7vw",
                }}
              >
                <button
                  className={`traveler-button ${disableButton("children", room.children) ? "disableButton":""}`}
                  onClick={() => updateCount(index, "children", -1)}
                  disabled={disableButton("children", room.children)}
                >
                  -
                </button>
                <p>{room.children}</p>
                <button
                  className="traveler-button"
                  onClick={() => updateCount(index, "children", 1)}
                >
                  +
                </button>
              </div>
            </div>
            {rooms.length > 1 && index !== 0 && (
              <h2
                style={{ color: "blue", textAlign: "end", cursor: "pointer" }}
                className="remove-room-button"
                onClick={() => removeRoom(index)}
              >
                Remove room
              </h2>
            )}
            <hr />
          </div>
        ))}
        <h2
          style={{ color: "blue", textAlign: "end", cursor: "pointer" }}
          onClick={addRoom}
        >
          Add another room
        </h2>
        <button
          style={{
            color: "white",
            backgroundColor: "blue",
            fontSize: "20px",
            borderRadius: "20px",
            width: "28vw",
            cursor: "pointer",
          }}
        >
          Done
        </button>
      </div>
    </div>
  );
};

export default TravelerModal;
