import React from 'react'
import { FaUtensils, FaTheaterMasks, FaChild, FaConciergeBell, FaCoffee, FaSpa, FaDumbbell } from 'react-icons/fa';

const CruiseWhatsIncluded = () => {
  return (
//     <div className="w-full items-start bg-gray-200 shadow-lg rounded-lg overflow-hidden">
//     <div className="px-6 py-4 flex flex-col items-start">
//       <h1 className="text-xl font-bold mb-2">What's included</h1>
//       <h2 className="text-gray-700 text-base mb-4">These are already included in the cost of your cruise</h2>
//       <div className="flex flex-wrap">

//         <div className='flex gap-2 w-1/2 p-2 items-center'>
//           <FaUtensils className="mr-2 text-lg text-gray-700 text-center"/>
//           <div className=" flex flex-col items-start">
//             <h3 className="text-md font-bold">Dining</h3>
//             <p className="text-gray-700 text-sm">Main dining, buffet meals, and snacks</p>
//           </div>
//         </div>

//         <div className='flex gap-2 w-1/2 p-2 items-center'>
//           <FaTheaterMasks className="mr-2 text-lg text-gray-700 text-center"/>
//           <div className=" flex flex-col items-start">
//             <h3 className="text-md font-bold">Onboard entertainment</h3>
//             <p className="text-gray-700 text-sm">Broadway-style shows and live music</p>
//           </div>
//         </div>

//         <div className='flex gap-2 w-1/2 p-2 items-center'>
//           <FaChild className="mr-2 text-lg text-gray-700 text-center"/>
//           <div className=" flex flex-col items-start">
//             <h3 className="text-md font-bold">Youth programs</h3>
//             <p className="text-gray-700 text-sm">Ages 3-17</p>
//           </div>
//         </div>

//         <div className='flex gap-2 w-1/2 p-2 items-center'>
//           <FaConciergeBell className="mr-2 text-lg text-gray-700 text-center"/>
//           <div className=" flex flex-col items-start">
//             <h3 className="text-md font-bold">Room service</h3>
//             <p className="text-gray-700 text-sm">24-hour in-room dining option</p>
//           </div>
//         </div>

//         <div className='flex gap-2 w-1/2 p-2 items-center'>
//           <FaCoffee className="mr-2 text-lg text-gray-700 text-center"/>
//           <div className=" flex flex-col items-start">
//             <h3 className="text-md font-bold">Drinks</h3>
//             <p className="text-gray-700 text-sm">Water and non-specialty coffee and tea</p>
//           </div>
//         </div>

//         <div className='flex gap-2 w-1/2 p-2 items-center'>
//           <FaSpa className="mr-2 text-lg text-gray-700 text-center"/>
//           <div className=" flex flex-col items-start">
//             <h3 className="text-md font-bold">Spa facilities</h3>
//             <p className="text-gray-700 text-sm">Relaxing wellness spaces</p>
//           </div>
//         </div>
//         <div className='flex gap-2 w-1/2 p-2 items-center'>
//           <FaDumbbell className="mr-2 text-lg text-gray-700 text-center"/>
//           <div className=" flex flex-col items-start">
//             <h3 className="text-md font-bold">Fitness facilities</h3>
//             <p className="text-gray-700 text-sm">Cardio and exercise equipment</p>
//           </div>
//         </div> 
//       </div>
//     </div>
//   </div>


<div className="w-full items-start bg-gray-200 shadow-lg rounded-lg overflow-hidden">
<div className="px-6 py-4 w-full flex flex-col items-start">
  <h1 className="text-xl font-bold mb-2">What's included</h1>
  <h2 className="text-gray-700 text-base mb-4">These are already included in the cost of your cruise</h2>
  <div className="flex flex-wrap">

    <div className='flex gap-2 w-full sm:w-1/2 p-2 items-center'>
      <FaUtensils className="mr-2 text-lg text-gray-700 text-center"/>
      <div className=" flex flex-col items-start">
        <h3 className="text-md text-start font-bold">Dining</h3>
        <p className="text-gray-700 text-start  text-sm">Main dining, buffet meals, and snacks</p>
      </div>
    </div>

    <div className='flex gap-2 w-full sm:w-1/2 p-2 items-center'>
      <FaTheaterMasks className="mr-2 text-lg text-gray-700 text-center"/>
      <div className=" flex flex-col items-start">
        <h3 className="text-md text-start font-bold">Onboard entertainment</h3>
        <p className="text-gray-700 text-start  text-sm">Broadway-style shows and live music</p>
      </div>
    </div>

    <div className='flex gap-2 w-full sm:w-1/2 p-2 items-center'>
      <FaChild className="mr-2 text-lg text-gray-700 text-center"/>
      <div className=" flex flex-col items-start">
        <h3 className="text-md text-start font-bold">Youth programs</h3>
        <p className="text-gray-700 text-start text-sm">Ages 3-17</p>
      </div>
    </div>

    <div className='flex gap-2 w-full sm:w-1/2 p-2 items-center'>
      <FaConciergeBell className="mr-2 text-lg text-gray-700 text-center"/>
      <div className=" flex flex-col items-start">
        <h3 className="text-md text-start  font-bold">Room service</h3>
        <p className="text-gray-700 text-start  text-sm">24-hour in-room dining option</p>
      </div>
    </div>

    <div className='flex gap-2 w-full sm:w-1/2 p-2 items-center'>
      <FaCoffee className="mr-2 text-lg text-gray-700 text-center"/>
      <div className=" flex flex-col items-start">
        <h3 className="text-md text-start  font-bold">Drinks</h3>
        <p className="text-gray-700 text-start  text-sm">Water and non-specialty coffee and tea</p>
      </div>
    </div>

    <div className='flex gap-2 w-full sm:w-1/2 p-2 items-center'>
      <FaSpa className="mr-2 text-lg text-gray-700 text-center"/>
      <div className=" flex flex-col items-start">
        <h3 className="text-md text-start  font-bold">Spa facilities</h3>
        <p className="text-gray-700 text-start  text-sm">Relaxing wellness spaces</p>
      </div>
    </div>
    <div className='flex gap-2 w-full sm:w-1/2 p-2 items-center'>
      <FaDumbbell className="mr-2 text-lg text-gray-700 text-center"/>
      <div className=" flex flex-col items-start">
        <h3 className="text-md text-start font-bold">Fitness facilities</h3>
        <p className="text-gray-700 text-start text-sm">Cardio and exercise equipment</p>
      </div>
    </div> 
  </div>
</div>
</div>

  )
}

export default CruiseWhatsIncluded
