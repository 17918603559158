import { Flex } from "@aws-amplify/ui-react"
import { Search } from "../Components/Search"
import { PopularDestination } from "../Components/PopularDestination"
import { RecentSearch } from "../Components/recentSearch"

export const Res=()=>{
    return (
        <>
          <Flex
          direction={"column"}
          gap={"0px"}
          justifyContent={"center"}
          alignItems={"center"}
        >
  <PopularDestination />
  <RecentSearch />
    </Flex>
        </>
    )
}