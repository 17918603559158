import React from 'react'
import { useCruisesResult } from '../context/cruisesContext';

const CruiseOverview = () => {
    const {selectedCruise,setSelectedCruise, setCruises} = useCruisesResult();
    console.log(selectedCruise, ':6')
  return (
    <>
       <div className=" bg-gray-200 rounded-lg flex flex-col md:flex-row">
      {/* Left side with text */}
      <div className="md:flex-1 text-start w-7/12 items-start">
        <h2 className="text-xl text-start font-bold text-gray-900">{selectedCruise?.title}a</h2>
        <p className="text-sm text-start text-gray-600">{selectedCruise?.ship}</p>
        <p className="text-sm text-start text-gray-600">{selectedCruise?.dates}</p>
        <p className="text-sm text-start text-gray-600"> {selectedCruise?.stops}</p>
      </div>

      {/* Right side with image */}
      <div className="mt-4 md:mt-0  md:w-5/12 flex-shrink-0">
        <img
          className=" w-full object-cover h-56 rounded-md"
          src={selectedCruise?.image}
        //   src="https://mediaim.expedia.com/cruise/pc-rp-2024-02-01/ce34b678-7429-4dda-958f-40048004a285.jpg?impolicy=resizecrop&ra=fit&rw=1000"
          alt="Cruise"
        />
      </div>
    </div>
    </>
  )
}

export default CruiseOverview
