import {
    Button,
    Card,
    CheckboxField,
    Flex,
    Grid,
    SliderField ,
    Text,
  } from "@aws-amplify/ui-react";
  import React, { useEffect, useState } from "react";
  import { RiSunFill } from "react-icons/ri";
  import { BsFillMoonStarsFill } from "react-icons/bs";
  import { TbSunset2 } from "react-icons/tb";
  import { PiSunHorizonFill } from "react-icons/pi";
  import { MdOutlineWbSunny } from "react-icons/md";
  import { useFilterContext } from "../context/filterFlightContext";
  import { useFlightSearchResult } from "../context/flightSearchResults";
  import { createDepatureStopFilter, createReturnStopFilter, maxTimeForFlight } from "../pages/helperFunctions/flightList.helper";
  import { useTripType } from "../context/tripType";
  const airlineJson = [
    { name: "Eithed airline", price: "1223" },
    { name: "British Airline", price: "55435" },
    { name: "delta", price: "3455" },
    { name: "KLM", price: "1223" },
    { name: "Air france", price: "54435" },
    { name: "united", price: "347655" },
    { name: "Eithed airline", price: "1223" },
    { name: "British Airline", price: "55435" },
    { name: "delta", price: "3455" },
    { name: "KLM", price: "1223" },
    { name: "Air france", price: "54435" },
    { name: "united", price: "347655" },
    { name: "Eithed airline", price: "1223" },
    { name: "British Airline", price: "55435" },
    { name: "delta", price: "3455" },
    { name: "KLM", price: "1223" },
    { name: "Air france", price: "54435" },
    { name: "united", price: "347655" },
  ];
  const Popularfilters = [
   
    { name: "Breakfast included", price: "2343", key: "non-return" },
    { name: "Manhattan", price: "1223", key: "one-return" },
    { name: "Hotel", price: "535", key: "two-return" },
    { name: "Airport shuttle included", price: "535", key: "two-return" },

  ];
  const guestRating = [
   




    { name: "Any", price: "2343", key: "non-departure" },
    { name: " Wonderful 9+", price: "1223", key: "one-departure" },
    { name: "Very good 8+", price: "535", key: "two-departure" },
    { name: "Good 7+", price: "535", key: "two-departure" },

  ];
  const departureTiming = [
    {
      name: "Morning",
      time: "{5:00am-11:59am}",
      key: "morning departure",
      icon: <MdOutlineWbSunny color="orange" size={32} />,
    },
    {
      name: "Afternoon",
      time: "{12:00pm-5:59pm}",
      key: "afternoon departure",
      icon: <TbSunset2 size={32} />,
    },
    {
      name: "Night",
      time: "{6:00pm-11:59pm}",
      key: "night departure",
      icon: <BsFillMoonStarsFill size={32} />,
    },
  ];
  const arrivalTiming = [
    {
      name: "Early Morning",
      time: "{12:00am-4:59am}",
      key: "early morning arrival",
      icon: <PiSunHorizonFill color="orange" size={32} />,
    },
    {
      name: "Morning",
      time: "{5:00am-11:59am}",
      key: "morning arrival",
      icon: <MdOutlineWbSunny color="orange" size={32} />,
    },
    {
      name: "Afternoon",
      time: "{12:00pm-5:59pm}",
      key: "afternoon arrival",
      icon: <TbSunset2 size={32} />,
    },
    {
      name: "Night",
      time: "{6:00pm-11:59pm}",
      key: "night arrival",
      icon: <BsFillMoonStarsFill size={32} />,
    },
  ];
  const travelandBagageJson = [
    { name: "seat choice included", price: "1223" },
    { name: "carry on bag included", price: "55435" },
    { name: "No cancel fee", price: "3455" },
    { name: "No change fee", price: "1223" },
  ];
  const layoverAirportJson = [
    { name: "LHR", price: "1223" },
    { name: "ATL", price: "55435" },
    { name: "ATR", price: "3455" },
    { name: "FRA", price: "1223" },
    { name: "DEL", price: "1223" },
    { name: "BGH", price: "55435" },
    { name: "ATR", price: "3455" },
    { name: "FRA", price: "1223" },
  ];
  export const StayCarFilter = () => {
    const { flightResultData } = useFlightSearchResult();
    const [displayCount, setDisplayCount] = useState(7);
    const intailMaxTime = maxTimeForFlight(flightResultData?.recommendations  || [])
    const [value, setValue] = React.useState(intailMaxTime);
    console.log(value, ":value")
    const handleLoadMore = () => {
      setDisplayCount((prevCount) => prevCount + 7);
    };
    const { tripType } = useTripType();
  
    const styles = {
      Card: {
        width: "9.5rem",
        backgroundColor: "rgba(255,255,255,0.48)",
        borderRadius: "24px",
        boxShadow: "0px 2px 10px rgba(3,3,3,0.1)",
      },
      FlightInfo: {
        color: "#030303",
        fontSize: "16px",
        fontFamily: "Montserrat",
        lineHeight: "32px",
        fontWeight: 500,
      },
  
      Text: {
        color: "#030303",
        fontSize: "16px",
        fontFamily: "Montserrat",
        fontWeight: 500,
        lineHeight: "40px",
        // textAlign: 'center',
      },
      ParaText: {
        color: "#030303",
        fontSize: "16px",
        fontFamily: "Montserrat",
        lineHeight: "26px",
        fontWeight: 400,
        // textAlign: 'center',
      },
      CardText: {
        color: "#030303",
        fontSize: "16px",
        fontFamily: "Montserrat",
        lineHeight: "26px",
        fontWeight: 700,
        // textAlign: 'center',
      },
      CardParaText: {
        color: "#030303",
        fontSize: "12px",
        fontFamily: "Montserrat",
        lineHeight: "26px",
        fontWeight: 500,
        // textAlign: 'center',
      },
    };
    // const [filters, setFilters] = useState<{ [key: string]: boolean }>({});
    const { filters, setFilters } = useFilterContext();
    const filtersWithKey = (value: any, key: any) => {
      setFilters({ ...filters, [key]: value });
    };
  
    useEffect(()=>{
      filtersWithKey(intailMaxTime, 'timeRange');
    },[])
  
    return (
      <>
  
        <Flex
          direction={"column"}
          textAlign={"left"}
          width={"100%"}
          // marginBlockStart={"1.6rem"}
          marginInline={"1rem"}
          backgroundColor={"whiteSmoke"}
          paddingInline={"2rem"}
          marginInlineEnd={"0.5rem"}
        >
          <Text style={styles.Text}>Filter by</Text>
          <Flex direction={"column"}>
           
            <Flex direction={"column"} paddingInline={"0.2rem"}>
              {Popularfilters.map((item, index) => (
                <Flex
                  key={index}
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  <CheckboxField
                    label={item?.name}
                    name={`stop_${index}`}
                    value={item?.price}
                    checked={filters[item?.key] || false}
                    onChange={(e) => {
                      filtersWithKey(e.target.checked, item?.key);
                    }}
                  />
                  {/* <Text style={styles.ParaText}>${item?.price}</Text> */}
                </Flex>
              ))}
            </Flex>
            
  
           
  
            <Text style={styles.FlightInfo}>Price</Text>
            <SliderField
              label="Slider"
              step={1}
              value={value}
          onChange={(e)=>{
            filtersWithKey(e, 'timeRange');
            setValue(e);
          }}
              max={intailMaxTime}
            />
            <Text style={styles.FlightInfo}>Guest Rating</Text>
              <Grid templateColumns="repeat(2, 2fr)" gap="20px">
              {guestRating.map((item, index) => (
                <Flex
                key={index}
                direction={"row"}
                justifyContent={"space-between"}
              >
                <CheckboxField
                  label={item?.name}
                  name={`stop_${index}`}
                  value={item?.price}
                  checked={filters[item?.key] || false}
                  onChange={(e) => {
                    filtersWithKey(e.target.checked, item?.key);
                  }}
                />
                {/* <Text style={styles.ParaText}>${item?.price}</Text> */}
              </Flex>
              ))}
            </Grid>
  
            <Text style={styles.FlightInfo}>Stay options</Text>
  
            <Grid templateColumns="repeat(2, 2fr)" gap="20px">
              {arrivalTiming.map((item, index) => (
                <Card
                  style={styles.Card}
                  key={index}
                  backgroundColor={filters[item.key] ? "yellow" : ""}
                  onClick={() => {
                    if (filters[item.key]) {
                      filtersWithKey(false, item.key);
                    } else {
                      filtersWithKey(true, item.key);
                    }
                  }}
                >
                  <Flex
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    gap={"0.2rem"}
                  >
                    <Text>{item.icon}</Text>
                    <Text style={styles.CardText} textAlign={"center"}>
                      {item.name}
                    </Text>
                    <Text style={styles.CardParaText}>{item.time}</Text>
                  </Flex>
                </Card>
              ))}
            </Grid>
            <Flex direction={"row"} justifyContent={"space-between"}>
              <Text style={styles.FlightInfo}>departure Airports</Text>
              <Text style={styles.FlightInfo}>From</Text>
            </Flex>
            <Flex direction={"column"} paddingInline={"0.2rem"}>
              {(createDepatureStopFilter(flightResultData?.recommendations || [])).map((item, index) => (
                <Flex
                  key={index}
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  <CheckboxField
                    label={item?.name}
                    name={`airline_${index}`}
                    style={styles.ParaText}
                    checked={filters[item.key] || false}
                    onChange={(e) => {
                      filtersWithKey(e.target.checked, item.key);
                    }}
                    value={item.price}
                  />
                  <Text style={styles.ParaText}>${item.price}</Text>
                </Flex>
              ))}
            </Flex>
          
          </Flex>
        </Flex>
    
      </>
    );
  };
  