// This Component Having Details For Passenger while Booking the Flight  

import { Card, Flex, View,PhoneNumberField, Text, Input, Label, SelectField, Button, RadioGroupField, Radio } from '@aws-amplify/ui-react'
import {  useState } from 'react';
import Autocomplete from "react-google-autocomplete";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FiCalendar } from 'react-icons/fi'; 
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import {  useNavigate } from 'react-router-dom';
import { useCustomerTravel } from '../context/customerTravelDetail';
import { useSearch } from '../context/searchFlight';
import { usePopperContext } from '../context/popperContext';
import axios from 'axios';
import { useFlightSearchResult } from '../context/flightSearchResults';
import { PackageResult } from '../context/PackagesContext';
import { useRentalCarSearchResult } from '../context/rentalCarSearchDetails';
export const TravelDetails = () => {
  // const apiKey = process.env.REACT_APP_API_KEY;
  const apiKey= 'AIzaSyCeoqm_vAidvDdJXPklB89NWyHrMy-r4Qo'
  const { flightResultData } = useFlightSearchResult();
  
  const { passengerPackageData,packageType, stayFlightCarPackage,setStayFlightCarPackage } = PackageResult();
    const navigate=useNavigate();
    const {searchDetails}=useSearch();
  const [dob, setDOB] = useState<Date | null>(null);
  const [payloadDob, setPayloadDob] = useState<string | null>(null);

  const handleDOB = (date: Date | null) => {
    if (!date) return '';
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear().toString().slice(-2);
    const FormatDate= `${day}${month}${year}`;
    console.log(FormatDate, "FormatDate")
    setPayloadDob(FormatDate);
    setDOB(date);
  };
  const { popperValue} = usePopperContext();
  let PassengersValues = popperValue.split(',').map(function(element) {
    // Extract the number part and parse it as an integer
    return parseInt(element);
});

// Filter out NaN values (non-numeric entries)
PassengersValues = PassengersValues.filter(function(value) {
    return !isNaN(value);
});

// Calculate the sum
let TotalPassenger = PassengersValues.reduce(function(acc, curr) {
    return acc + curr;
}, 0);


const TotalPassengerComponents = (type: string, count: number) => {
  const components = [];
  for (let i = 0; i < count; i++) {
    components.push( 
      <>
       <Text textAlign={'start'} fontSize={'18px'} color={'blue'} paddingInline={'8px'} >{type}  {i + 1}</Text>
            <Flex paddingInline={'8px'} >
                <Flex direction="column" gap="small" textAlign={'start'}>
                    <Label htmlFor="last_name">Last Name *</Label>
                    <Input id="last_name" name="last_name" isRequired={true}  placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                    <Text color="red">{lastNameError}</Text>
                </Flex>
                <Flex direction="column" gap="small" textAlign={'start'}>
                    <Label htmlFor="first_name">First Name *</Label>
                    <Input id="first_name" placeholder="First Name" isRequired={true} name="first_name" value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
                     <Text color="red">{firstNameError}</Text>
                </Flex>   
                <Flex direction="column" gap="small" textAlign={'start'}>
                    <Label htmlFor="first_name">Middle Name:</Label>
                    <Input id="middle_name" placeholder="Middle Name" name="middle_name" value={middleName} onChange={(e) => setMiddleName(e.target.value)}/>
                </Flex>  
                <Flex direction="column" gap="small" textAlign={'start'}>
                    <Label htmlFor="first_name">Location *</Label>
                   <Autocomplete
                  apiKey= {apiKey}
                  onPlaceSelected={(place) => {
                    console.log(place);
                  }}
                />
                </Flex>  
                <Flex direction="column" gap="small" textAlign={'start'}>
                  <SelectField
                      label="Salutation *"
                      isRequired={true}
                      value={salutation}
                      onChange={(e) => setSalutation(e.target.value)}
                      >
                      <option value="mr">Mr</option>
                      <option value="mrs">Mrs</option>
                      <option value="miss">Miss</option>
                      <option value="ms">Ms</option> 
                  </SelectField>
                  <Text color="red">{salutationError}</Text>
                </Flex>
                <Flex direction="column" gap="small" textAlign={'start'}>
                  <SelectField
                      label="Gender *"
                      isRequired={true}
                     
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                      >
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                  </SelectField>
                  <Text color="red">{genderError}</Text>
                </Flex>
                <Flex direction="column" gap="small" textAlign={'start'}>
                    <Label htmlFor="Date of Birth">Date of Birth *</Label>
                    <DatePicker 
                        selected={dob}
                        onChange={handleDOB}
                        dateFormat="dd/MM/yyyy"
                        className="custom-datepicker"
                        icon={<FiCalendar size={24} color='red' />}
                        showIcon 
                    />
                    <Text color="red">{dobError}</Text>
                </Flex>  
                <Flex >
                <Button  gap="0.1rem" size="small" colorTheme="info" height={'36px'} alignSelf={'end'} onClick={handleFFP} > FFP <FaAngleDown /></Button>
                <Button  gap="0.1rem" size="small" colorTheme="info" height={'36px'} alignSelf={'end'} onClick={handleTSA}> TSA <FaAngleDown /></Button>
                <Button  gap="0.1rem" size="small" colorTheme="info" height={'36px'} alignSelf={'end'} onClick={handleAPI}> API <FaAngleDown /></Button>
                </Flex>
            </Flex>
           
      </>
    );
  }
  return components;
};

  const [isExpanded, setIsExpanded] = useState(false);
  const [isFFP, setIsFFP] = useState(false);
  const [isTSA, setIsTSA] = useState(false);
  const [isAPI, setIsAPI] = useState(false);
  const [selectedSection, setSelectedSection] = useState('FFP');
  const [redress, setRedress] = useState(false);
  const [passenger, setPassenger] = useState(false);
  const handlePassenger=()=>{
    setPassenger(!passenger);
}
  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };
  const handleRedress=()=>{
        setRedress(!redress);
  }
  const textContent = `Please enter the traveller's name and date of birth exactly as shown on the passport (for international flights) or valid
   government-issued photo ID (for domestic flights) to be used on this trip. Name changes are not permitted after booking.
    Visa documentation, passport validity, Covid-19 and health formalities along with any reconfirmation of flights is your responsibility.
     We accept no liability if you are refused entry due to lack of proper travel documentation, in such cases no refund. Airline Guidelines & Country Guidelines may have different visa & entry regulation criteria. Please check airline-specific guidelines on their website & country regulatory website. Airlines reserve the right to change flight schedule without prior notice and these changes are communicated on the same contact details. COVID-19 guideline - Important notice: Please check with your airline if you require a PCR test or are required to fill out COVID documentation. Please ensure that you have complied with COVID 19 entry requirements for your transit or final destination.`;

  const truncatedText = isExpanded ? textContent : textContent.slice(0, 200) + '...';
  const handleFFP=()=>{
    // setIsFFP(!isFFP)
    setSelectedSection('FFP')
  }
  const handleTSA=()=>{
    // setIsTSA(!isTSA)
    setSelectedSection('TSA')
    
  }
  const handleAPI=()=>{
    // setIsAPI(!isAPI)
    setSelectedSection('API')
  }
  const {customerTravelData, setCustomerTravelData}=useCustomerTravel();
  const [lastName,setLastName]=useState('')
  const [firstName,setFirstName]=useState('')
  const [middleName,setMiddleName]=useState('')
  const [dateOfBirth,setDateOfBirth]=useState('')
  const [gender,setGender]=useState('')
  const [passportNumber,setPassportNumber]=useState('')
  const [passportExpiry,setPassportExpiry]=useState('')
  const [passportCountry,setPassportCountry]=useState('')
  const [nationality,setNationality]=useState('')
  const [passportIssue,setPassportIssue]=useState('')
  const [passportIssueDate,setPassportIssueDate]=useState('')
  const [passportIssuePlace,setPassportIssuePlace]=useState('')
  const [passportIssueState,setPassportIssueState]=useState('')
  const [passportIssueCountry,setPassportIssueCountry]=useState('')
  const [passportLastName,setPassportLastName]=useState('')
  const [passportFirstName,setPassportFirstName]=useState('')
  const [additionalName,setAdditionalName]=useState('')
  const [redressDetails, setRedressDetails]=useState('')
  const [travelNumber,setTravelNumber]=useState('')
  const [salutation,setSalutation]=useState('')
  const [email,setEmail]=useState('')
  const [phoneNumber,setPhoneNumber]=useState('')
  const [country,setCountry]=useState('')
  const [address,setAddress]=useState('')
  const [city,setCity]=useState('')
  const [state,setState]=useState('')
  const [zip,setZip]=useState('')
  const [countryCode,setCountryCode]=useState('')
  const [lastNameError, setLastNameError] = useState<string>('');
  const [firstNameError, setFirstNameError] = useState<string>('');
const [phoneNumberError, setPhoneNumberError] = useState<string>('');
  const [genderError, setGenderError] = useState<string>('');
  const [salutationError, setSalutationError] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [dobError, setDOBError] = useState<string>('');
  const [dialCode, setDialCode] = useState('+1');
  const {rentalCarDetails, setRentalCarDetails,setRentalCarData}=useRentalCarSearchResult()

  const handleDialCodeChange = (e:any) => {
    setDialCode(e.target.value);
    
  };

  const handleSubmit= async(e: any)=>{

    e.preventDefault()
    if(packageType.includes('Flight') && packageType.includes('Car')){
      const response = await fetch('/rentalCarData.json'); 
      const data = await response.json();
      setRentalCarData(data)
  navigate('/rentalcar')
  }
    let isValid = true;
    if (!lastName) {
      setLastNameError('Please enter your last name');
      isValid = false;
      return;
    } else {
      setLastNameError('');
    }

    if (!firstName) {
      setFirstNameError('Please enter your first name');
      isValid = false;
      return
    } else {
      setFirstNameError('');
    }
    if (!phoneNumber) {
        setPhoneNumberError('Please enter your Phone Number');
        isValid = false;
        return
      } else {
        setPhoneNumberError('');
      }
      if (!gender) {
        setGenderError('Please enter your Gender');
        isValid = false;
        return
      } else {
        setGenderError('');
      }
      if (!salutation) {
        setSalutationError('Please enter your Salutation');
          isValid = false;
          return
        } else {
          setSalutationError('');
        }
        if (!gender) {
            setSalutationError('Please enter your Gender');
            isValid = false;
            return
          } else {
            setGenderError('');
          }
          if (!dob) {
            setDOBError('Please enter your Date of Birth');
              isValid = false;
              return
            } else {
              setDOBError('');
            }
    if (isValid) {
      const passengerDetails = {
        lastName,
        firstName,
        email,
        phoneNumber,
        salutation,
        gender,
        dob
      };
      setCustomerTravelData(passengerDetails);

    
      const payloadObject = {
        numberOfAdults: searchDetails?.numberOfAdults,
        email: email,
        phone: `${phoneNumber} ${dialCode}`,
        passengers: [
            {
                firstName: firstName,
                lastName: lastName,
                dateOfBirth: payloadDob,
                type: "ADT"
            }
        ],
        validatingCarrier : "AA",
        sliceandDice: true,
        itinerary: [
          {
            marketingCarrier: flightResultData?.recommendations?.onwardSegment?.marketingCarrier,
            legs: flightResultData?.recommendations?.onwardSegment?.legs || [],
          },
        ],
      };
      let proxyUrl;
      let endPoint;
      const url = "https://api.vimanayaatra.com//SellRequest";
      if (process.env.NODE_ENV === "development") {
        proxyUrl = "http://localhost:8080/";
        endPoint = proxyUrl + url;
      } else {
        endPoint = `${url}`;
      }
      console.log(payloadObject, 307)
      try{
const response = await axios.post(endPoint,payloadObject, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      console.log(response, 302)
      navigate("/pay")
      }
      catch(err){
        console.log(err)
      }
    
    }
  }
  return (
    <>
    <Card padding={'0px'} as="form" borderRadius={'8px'} border={'1px solid white'}>
        <Flex direction={'column'} gap={'12px'} paddingBottom={'8px'}>
            <Text backgroundColor='teal[90]' textAlign={'start'} fontSize={'24px'} color={'white'} paddingInline={'8px'} paddingBlock={'6px'} >Traveller Details</Text>
            <Text textAlign={'start'} paddingInline={'8px'} >  {isExpanded ? textContent : truncatedText}
            <Text onClick={toggleText} as="span" color={'blue'} paddingInlineStart={'6px'}>{isExpanded ? 'Read Less...' : 'Read More...'}</Text>
            </Text>
            {PassengersValues.map((value, index) => (
              <div key={index}>
                {index === 0 && TotalPassengerComponents("Adult", Math.max(1, value))}
                {index === 1 && TotalPassengerComponents("Children", Math.max(1, value + 1))}
                {index === 2 && TotalPassengerComponents("Infant", value)}
              </div>
      ))}
           
        </Flex>
        
      {  selectedSection === 'FFP' && <Flex borderRadius={'8px'} border={'1px solid'} direction="column" gap="small"  padding={'12px'}  margin={'16px'}  textAlign={'start'}>
            <Text color={'blue'}>Frequent Flyer Program</Text>
            <Flex direction="row" gap="44px" paddingInline={'16px'}>
                    
                <SelectField
                    label="Frequent Flyer Program"
                    // labelHidden
                    placeholder="Frequent"
                    >
                    <option value="mr">Mr</option>
                    <option value="mrs">Mrs</option>
                    <option value="miss">Miss</option>
                    <option value="ms">Ms</option>
                    
                </SelectField>
                <Flex direction="column" gap="12px" textAlign={'start'}>
                    <Label htmlFor="email">FFP Number:</Label>
                    <Input id="ffp" type='text' placeholder="FFP" name="ffp" />
            </Flex>   
            </Flex>    
        </Flex>
      } 
       {  selectedSection === 'TSA' && <Flex borderRadius={'8px'} border={'1px solid'} direction="column" gap="small" padding={'12px'}  margin={'16px'}  textAlign={'start'}>
            <Text color={'blue'}>Transport Security Administration</Text>
            <Flex paddingInline={'16px'} >
                <Flex direction="column" gap="small" textAlign={'start'}>
                    <Label htmlFor="first_name">Last name as per passport:</Label>
                    <Input id="last_name_passport" name="last_name_passport" placeholder="Last name as per passport" value={passportLastName} onChange={(e) => setPassportLastName(e.target.value)} />
                </Flex>
                <Flex direction="column" gap="small" textAlign={'start'}>
                    <Label htmlFor="first_name">First name as per passport:</Label>
                    <Input id="first_name_passport" placeholder="First name as per passport" name="first_name_passport" value={passportFirstName} onChange={(e) => setPassportFirstName(e.target.value)}/>
                </Flex>   
                <Flex direction="column" gap="small" textAlign={'start'}>
                    <Label htmlFor="2nd and additional given names">Additional given names</Label>
                    <Input id="additional_name" placeholder="Additional given names" name="additional_name" value={additionalName} onChange={(e) => setAdditionalName(e.target.value)} />
                </Flex>    
                
                <Flex direction="column" gap="small" textAlign={'start'}>
                    <Label htmlFor="Date of Birth">Date of Birth</Label>
                    <DatePicker 
                        selected={dob}
                        onChange={handleDOB}
                        dateFormat="dd/MM/yyyy"
                        className="custom-datepicker"
                        icon={<FiCalendar size={24} color='red' />}
                        showIcon 
                    />
                </Flex>  
               

            </Flex>    
            <Text color={'red'} onClick={handleRedress}>Redress number and known traveller number {redress? <FaAngleDown /> : <FaAngleUp />} </Text>
            {redress && <>
                <Flex paddingInline={'16px'} >
                    <Flex direction="column" gap="small" textAlign={'start'}>
                        <Label htmlFor="redress_number">Redress Number</Label>
                        <Input id="redress_number" name="redress_number" placeholder="Redress Number" value={redressDetails} onChange={(e) => setRedressDetails(e.target.value)}/>
                    </Flex>
                    <Flex direction="column" gap="small" textAlign={'start'}>
                        <Label htmlFor="known_traveler_number">Known Traveler Number</Label>
                        <Input id="known_traveler_number" placeholder="Known Traveler Number" value={travelNumber} onChange={(e) => setTravelNumber(e.target.value)} name="known_traveler_number" />
                    </Flex>  
                    <SelectField
                        label="Issuing Country"
                        >
                        <option value="mr">Mr</option>
                        <option value="mrs">Mrs</option>
                        <option value="miss">Miss</option>
                        <option value="ms">Ms</option>
                        
                    </SelectField> 
                </Flex>
                </>
            }
        </Flex>
      } 
      {  selectedSection === 'API' && <Flex borderRadius={'8px'} border={'1px solid'} direction="column" gap="small" padding={'12px'}  margin={'16px'}  textAlign={'start'}>
            <Text color={'blue'}>Advance Passenger Information System</Text>
            <Text>Primary Data of the Travel Document</Text>

            <Flex paddingInline={'16px'} direction={'column'} gap={'24px'}>
                <Flex>
                    <Flex direction="column" gap="small" textAlign={'start'}>
                        <Label htmlFor="first_name">Last name as per passport:</Label>
                        <Input id="last_name_passport" name="last_name_passport" placeholder="Last name as per passport" value={passportLastName} onChange={(e) => setPassportLastName(e.target.value)} />
                    </Flex>
                    <Flex direction="column" gap="small" textAlign={'start'}>
                        <Label htmlFor="first_name">First name as per passport:</Label>
                        <Input id="first_name_passport" placeholder="First name as per passport" name="first_name_passport" value={passportFirstName} onChange={(e) => setPassportFirstName(e.target.value)} />
                    </Flex>   
                    <Flex direction="column" gap="small" textAlign={'start'}>
                        <Label htmlFor="2nd and additional given names">Additional given names</Label>
                        <Input id="additional_name" placeholder="Additional given names" value={additionalName} onChange={(e) => setAdditionalName(e.target.value)} name="additional_name" />
                    </Flex>    
                    <Flex direction="column" gap="small" textAlign={'start'}>
                        <Label htmlFor="Date of Birth">Date of Birth</Label>
                        <DatePicker 
                            selected={dob}
                            onChange={handleDOB}
                            dateFormat="dd/MM/yyyy"
                            className="custom-datepicker"
                            icon={<FiCalendar size={24} color='red' />}
                            showIcon 
                        />
                    </Flex>  
                </Flex>
                <Flex paddingInline={'16px'} direction={'row'} gap={'44px'}>
                    <Flex direction="column" gap="small" textAlign={'start'}>
                            <Label htmlFor="Date of Birth">Issue Date</Label>
                            <DatePicker 
                                selected={dob}
                                onChange={handleDOB}
                                dateFormat="dd/MM/yyyy"
                                className="custom-datepicker"
                                icon={<FiCalendar size={24} color='red' />}
                                showIcon 
                            />
                    </Flex> 
                    <Flex direction="column" gap="small" textAlign={'start'}>
                            <Label htmlFor="Date of Birth">Expiration Date</Label>
                            <DatePicker 
                                selected={dob}
                                onChange={handleDOB}
                                dateFormat="dd/MM/yyyy"
                                className="custom-datepicker"
                                icon={<FiCalendar size={24} color='red' />}
                                showIcon 
                            />
                    </Flex> 
                </Flex>
            </Flex>  
            <Flex direction={'column'} gap={'12px'}>
                <Text onClick={handlePassenger}>Passenger contact address (DOCA) {passenger? <FaAngleDown /> : <FaAngleUp />} </Text>
                { passenger && <>
                    <Text fontSize={'14px'}>Type of address </Text>
                    <RadioGroupField legend="Row" name="row" legendHidden direction="row">
                        <Radio value="Destination">Destination</Radio>
                        <Radio value="Residence">Residence</Radio>
                    </RadioGroupField>
                    <Flex>
                        <Flex direction="column" gap="small" textAlign={'start'}>
                            <Label htmlFor="first_name">Address Details</Label>
                            <Input id="address" name="address" placeholder="Address Details" value={address} onChange={(e) => setAddress(e.target.value)} />
                        </Flex>
                        <Flex direction="column" gap="small" textAlign={'start'}>
                            <Label htmlFor="zip">Zip</Label>
                            <Input id="zip" placeholder="Zip"isRequired={true} name="zip" value={zip} onChange={(e) => setZip(e.target.value)} />
                        </Flex>   
                        <SelectField
                        label="Country"
                        >
                        <option value="mr">Mr</option>
                        <option value="mrs">Mrs</option>
                        <option value="miss">Miss</option>
                        <option value="ms">Ms</option>
                        
                    </SelectField>
                    <SelectField
                        label="State"
                        isRequired={true}
                        >
                        <option value="mr">Mr</option>
                        <option value="mrs">Mrs</option>
                        <option value="miss">Miss</option>
                        <option value="ms">Ms</option>
                        
                    </SelectField>
                        <Flex direction="column" gap="small" textAlign={'start'}>
                            <Label htmlFor="city">City</Label>
                            <Input id="city" isRequired={true} placeholder="City" name="city" value={city} onChange={(e) => setCity(e.target.value)} />
                        </Flex>    
                        
                    </Flex>
                </>
                }
            </Flex>  
        </Flex>
      } 
        <Text textAlign={'start'} fontSize={'24px'} backgroundColor={'gray'} padding={'8px'} marginBlock={'12px'}>Passenger Contact Data *</Text>
        <Flex direction="row" gap="12px" paddingBottom={'12px'}>
            <Flex></Flex>
            <Flex direction="column" gap="small" textAlign={'start'}>
                    <Label htmlFor="email">Email *</Label>
                    <Input id="email" type='email' placeholder="email" name="email" isRequired={true} value={email} onChange={(e) => setEmail(e.target.value)}/>
                    <Text color={'red'}>{emailError}</Text>
            </Flex>   
            <PhoneNumberField
                defaultDialCode="+1"
                label="Phone number *"
                placeholder="234-567-8910"
                isRequired={true}
                onDialCodeChange={handleDialCodeChange}
                value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value )}
            />
            <Text color={'red'}>{dobError}</Text>
        </Flex>
        <Button  onClick={handleSubmit} alignSelf="start">
          Continue
        </Button>
        </Card>
    </>
  )
}
 