import React, { useEffect, useRef, useState } from "react";
import { HotelImagesComponent } from "./hotelImagesComponent";
import "./hotelDetails.css";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { FiCalendar } from "react-icons/fi";
import { addDays, format } from "date-fns";
import { hotelPricePerDay } from "./hotelDetails.helper";
import { Flex, Text } from "@aws-amplify/ui-react";
import TravellerInfo from "./hotelTravelers";

export const HotelRoomSelection = () => {
  const [dateRange, setDateRange] = useState({
    selection1: {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection1",
    },
  });
  const handleDateChange = (ranges: any) => {
    setDateRange({
      ...dateRange,
      selection1: {
        ...dateRange.selection1,
        ...ranges.selection1,
      },
    });
  };
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [openDate, setOpenDate] = useState(false);
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setOpenDate(false);
      }
    }

    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [wrapperRef]);

  const dayContentRenderer = (date: Date) => {
    const day = date.getDate();
    const month = date.getMonth();
    const startDateValue = dateRange.selection1.startDate.getDate();
    const endDateValue = dateRange.selection1.endDate.getDate();
    let style: React.CSSProperties = {};

    if (hotelPricePerDay[day]) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "7px",
            color: "black",
          }}
        >
          <p style={{ margin: 0 }}>{day}</p>
          <p>{hotelPricePerDay[day]}</p>
        </div>
      );
    }
    return <div style={style}>{day}</div>;
  };

  return (
    <div style={{ width: "78vw", margin: "auto", textAlign: "left" }}>
      <h2
        style={{
          fontSize: "1.75rem",
          fontWeight: "500",
          lineHeight: "2rem",
        }}
      >
        Choose your room
      </h2>
      <div style={{ display: "flex", justifyContent: 'space-between',width:"40vw" }}>
        <div>
        <div
          style={{
            width: "21rem",
            paddingBlockStart: "0.3rem",
            zIndex: "10",
            position: "absolute",
          }}
          ref={wrapperRef}
        >
          <span
            onClick={() => setOpenDate(!openDate)}
            style={{
              width: "21rem",
              border: "1px solid gray",
              borderRadius: "4rem",
              paddingInline: "3rem",
              paddingBlock: "0.7rem",
            }}
          >
            <FiCalendar size={14} color="#007aff" />
            {`${format(
              dateRange.selection1.startDate,
              "MM/dd/yyyy"
            )} ~ ${format(dateRange.selection1.endDate, "MM/dd/yyyy")}`}
          </span>
          {openDate && (
            <div style={{ zIndex: "1" }}>
              <div style={{ zIndex: "10" }}>
                <DateRange
                  editableDateInputs={true}
                  onChange={handleDateChange}
                  moveRangeOnFirstSelection={false}
                  className="daterange"
                  ranges={[dateRange.selection1]}
                  months={2}
                  direction="horizontal"
                  minDate={new Date()}
                  showPreview={true}
                  dayContentRenderer={dayContentRenderer}
                  //    maxDate={addDays(new Date(), 9 * 30)}
                />
              </div>
            </div>
          )}
        </div>
        </div>
        <TravellerInfo />
      </div>
    </div>
  );
};
