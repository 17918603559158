import React from 'react'
import { FaHeadphones } from 'react-icons/fa'

const CruiseDetailCard = () => {
  return (
    <>
       <div className="flex flex-col md:flex-row border b-2 rounded-lg  mx-2 rounded-xl border-gray-400 p-6 items-center">
            <div className="flex-shrink-0 mb-4 md:mb-0 md:mr-4">
              <FaHeadphones className="text-4xl text-blue-600" />
            </div>
            <div className="text-center md:text-left">
              <h3 className="text-2xl font-bold mb-1">Get expert advice</h3>
              <p className="text-gray-700 mb-2">
                If you have a question, or would like personalized assistance booking your cruise, contact an Expedia Cruises™ travel agent today.
              </p>
              <div className='flex gap-4'>

              <p className="text-blue-500">1-866-403-9848</p>
              <div className="text-blue-500">
                Request a consultation
              </div>
              </div>
            </div>
          </div> 
    </>
  )
}

export default CruiseDetailCard
