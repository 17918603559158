import React from 'react'

import SearchTravelResults from './travelSearchResults'
import { TravelFilter } from '../Components/filterTravels'

const TravelList = () => {
  return (
    <div className="flex flex-col md:flex-row w-full mt-8 space-y-4 md:space-y-0 md:space-x-4">
    <div className='md:w-["20%"] md:flex hidden'>
     <TravelFilter />
    </div>
     <div className='w-full md:w-["80%"]'>
         <SearchTravelResults />
     </div>
   </div>
  )
}

export default TravelList
