import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select'
// import {airports} from '../Constant/airportData'
import {airports} from '../../Constant/airportData'
import { DateRange } from 'react-date-range'
import { CheckboxField, Flex } from '@aws-amplify/ui-react'
import { FiCalendar } from 'react-icons/fi'
import { addDays, format } from "date-fns";
import { colorCodedDates } from  '../roundTrip.helper'
// import '../Components/roundTrip.helper'
import { FaClock, FaUser } from 'react-icons/fa'
import CruiseModal from './cruisesModal'
import { useNavigate } from 'react-router-dom'
import { useCruisesResult } from '../../context/cruisesContext'
const Cruises = () => {
    const navigate=useNavigate();
    const {crusiesPassengerData, setCrusiesPassengerData,setCrusiesSearchData,crusiesSearchData} = useCruisesResult()

    const cruiseSearch=()=>{
      const searchDetails = {
        selectedAirports,
        dateRange: dateRange.selection1,
        minNights,
        maxNights,
        passengers: crusiesPassengerData
      };
  
      setCrusiesSearchData(searchDetails);
      
        navigate('/cruiseList')
    }
    console.log(crusiesSearchData)
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
      ]
      const [isModalOpen, setIsModalOpen] = useState(false);
      const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
      const [isOpenDropdown, setIsOpenDropdown] = useState(false);

      const toggleDropdown = () => {
        setIsOpenDropdown(!isOpenDropdown);
      };
      const [dateRange, setDateRange] = useState({
        selection1: {
          startDate: new Date("2024-07-08"),
          endDate: new Date("2024-09-09"),
          key: "selection1",
        },
      });
      const [selectedAirports, setSelectedAirports] = useState([]);
      const [months, setMonths] = useState(2);
      const [openDate, setOpenDate] = useState(false);
      const [minNights, setMinNights] = useState<number>(1);
  const [maxNights, setMaxNights] = useState<number>(9);
  const handleMinNightsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMinNights(Number(e.target.value));
  };

  const handleMaxNightsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMaxNights(Number(e.target.value));
  };
      const handleDateChange = (ranges: any) => {
        setDateRange({
          ...dateRange,
          selection1: {
            ...dateRange.selection1,
            ...ranges.selection1,
          },
        });
      };
      const dayContentRenderer = (date: Date) => {
        const day = date.getDate();
        const todayDate = new Date();
        let style: React.CSSProperties = {};
    
        if (
          date >= todayDate &&
          colorCodedDates[day] &&
          (date < dateRange.selection1.startDate ||
            date > dateRange.selection1.endDate)
        ) {
          style = {
            backgroundColor: colorCodedDates[day],
            color: "white",
            width: "90%",
            height: "33px",
            borderRadius: "20%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          };
        } else {
          style = {
            color: "black",
            width: "90%",
            height: "33px",
            borderRadius: "20%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          };
        }
    
        return <div style={style}>{day}</div>;
      };
    
      const wrapperRef = useRef<HTMLDivElement>(null);
      useEffect(() => {
        function handleClickOutside(event: any) {
          if (
            wrapperRef.current &&
            !wrapperRef.current.contains(event.target as Node)
          ) {
            setOpenDate(false);
          }
        }
    
        window.addEventListener("click", handleClickOutside);
        return () => {
          window.removeEventListener("click", handleClickOutside);
        };
      }, [wrapperRef]);
      console.log(airports, ":air")
//   return (
//     <div className='flex gap-2 items-center justify-evenly shadow-xl bg-gray-100 p-4 w-10/12 mx-auto'>
//     `   <Select
//       isMulti
//       name="destinations"
//       options={airports}
//       defaultValue={[airports[2], airports[3]]}
//       className=" w-2/3 py-4"
//       classNamePrefix="select"
//     />`
//        <Flex marginBlock={"4px"} >
//             <div
//               style={{
//                 width: "21rem",
//                 paddingBlockStart: "0.3rem",
//                 zIndex: "10",
//               }}
             
//             >
//               <div
//                 className="px-3 py-[10px] gap-2 py-[16px] border border-gray-300 rounded-md shadow-sm flex items-center "
//                 style={{
//                   width: "21rem",
                 
//                 }}
//                 onClick={() => setOpenDate(!openDate)}
//               >
//                 <FiCalendar size={14} color="#007aff" />
//                 {`${format(
//                   dateRange.selection1.startDate,
//                   "MM/dd/yyyy"
//                 )} ~ ${format(dateRange.selection1.endDate, "MM/dd/yyyy")}`}
//               </div>
//               {/* </span> */}
//               {openDate && (
//                 <div
//                   style={{
//                     zIndex: "1",
//                     position: "absolute",
//                     backgroundColor: "white",
//                     padding: "10px",
//                   }}
//                 >
                 
//                   <div style={{ zIndex: "10" }}>
//                     <DateRange
//                       editableDateInputs={true}
//                       onChange={handleDateChange}
//                       moveRangeOnFirstSelection={false}
//                       className="daterange"
//                       ranges={[dateRange.selection1]}
//                       months={months}
//                       direction="horizontal"
//                       minDate={new Date()}
//                       showPreview={true}
//                       dayContentRenderer={dayContentRenderer}
//                       maxDate={addDays(new Date(), 9 * 30)}
//                     />
//                   </div>
//                 </div>
//               )}
//             </div>
//           </Flex>
//           {/* <div className="flex items-center border border-gray-300 rounded-md shadow-sm px-4 py-2">
//             <FaClock className="text-gray-600 mr-2" size={24} />
//             <div className="ml-2">
//                 <h1 className="text-lg font-bold">{'duration'}</h1>
//                 <p className="text-sm text-gray-500">{'subheading'}</p>
//             </div>
//         </div> */}

// <div className="relative ">
//             <div className="flex items-center text-start gap-2 py-1 px-6 border border-gray-300 rounded-md shadow-sm cursor-pointer" onClick={toggleDropdown}>
//                 <FaClock className="" size={24} />
//                 <div className="ml-2">
//                     <h1 className="text-xl font-semibold">{'duration'}</h1>
//                     <p className="text-sm text-gray-500">{`${minNights}- ${maxNights} night`}</p>
//                 </div>
//             </div>

//             {isOpenDropdown && (
//                 <div className="absolute mt-2 pt-1 pb-6 px-[44px] gap-4 border border-gray-300 rounded-md shadow-lg bg-white">
//                     <h2 className="text-lg text-start font-semibold">Duration</h2>
//                     <div className="flex justify-between gap-2 w-full mt-2">
                       
//                        <label className="input input-bordered w-44 flex flex-col items-start">
//                             Min nights
//                             <input type="number" className="grow" value={minNights} onChange={handleMinNightsChange}/>
//                         </label>
//                         <label className="input input-bordered w-44 flex flex-col items-start">
//                             Max nights
//                             <input type="number" className="grow" value={maxNights} onChange={handleMaxNightsChange} />
//                         </label>
//                     </div>
//                     <div className='flex justify-end mt-2'>

//                     <button className="btn btn-info" onClick={()=>setIsOpenDropdown(false)}>Button</button>
//                     </div>
//                 </div>
//             )}
//         </div>
//         <div className="lg:w-1/3 flex items-start  justify-center">
//         <div className="flex items-center w-44 py-1 px-3 rounded-lg cursor-pointer border-gray-300 rounded-md shadow-sm border b-2"   onClick={openModal}>
//       <FaUser className="text-xl text-gray-600 mr-4" />
//       <div className="text-left">
//         <p className="text-md text-gray-800">Travellers</p>
//         <p className="text-sm text-gray-900">{crusiesPassengerData.adultCount + crusiesPassengerData.youthCount} travelers</p>
//       </div>
//     </div>
//         </div>
//         {isModalOpen && <CruiseModal closeModal={closeModal} heading='Room 1' />}
//         <button className="btn btn-info" onClick={cruiseSearch}>Search</button>
//     </div>
//   )
const handleAirportChange = (selectedOptions: any) => {
  setSelectedAirports(selectedOptions);
};
return  (
  <div className='flex flex-col md:flex-row gap-2 items-center justify-evenly shadow-xl bg-gray-100 p-4 w-10/12 mx-auto'>
    <Select
      isMulti
      name="destinations"
      options={airports}
      defaultValue={[airports[2], airports[3]]}
      className="w-full md:w-2/3 py-4"
      classNamePrefix="select"
      // onChange={setSelectedAirports}
      onChange={handleAirportChange}
    />
    <Flex marginBlock={"4px"}>
      <div
        style={{
          width: "21rem",
          paddingBlockStart: "0.3rem",
          zIndex: "10",
        }}
      >
        <div
          className="px-3 py-[10px] gap-2 py-[16px] border border-gray-300 rounded-md shadow-sm flex items-center"
          style={{
            width: "21rem",
          }}
          onClick={() => setOpenDate(!openDate)}
        >
          <FiCalendar size={14} color="#007aff" />
          {`${format(
            dateRange.selection1.startDate,
            "MM/dd/yyyy"
          )} ~ ${format(dateRange.selection1.endDate, "MM/dd/yyyy")}`}
        </div>
        {openDate && (
          <div
            style={{
              zIndex: "1",
              position: "absolute",
              backgroundColor: "white",
              padding: "10px",
            }}
          >
            <div style={{ zIndex: "10" }}>
              <DateRange
                editableDateInputs={true}
                onChange={handleDateChange}
                moveRangeOnFirstSelection={false}
                className="daterange"
                ranges={[dateRange.selection1]}
                months={months}
                direction="horizontal"
                minDate={new Date()}
                showPreview={true}
                dayContentRenderer={dayContentRenderer}
                maxDate={addDays(new Date(), 9 * 30)}
              />
            </div>
          </div>
        )}
      </div>
    </Flex>
    <div className="relative">
      <div
        className="flex items-center text-start gap-2 py-1 px-6 border border-gray-300 rounded-md shadow-sm cursor-pointer"
        onClick={toggleDropdown}
      >
        <FaClock className="" size={24} />
        <div className="ml-2">
          <h1 className="text-xl font-semibold">{'duration'}</h1>
          <p className="text-sm text-gray-500">{`${minNights}- ${maxNights} night`}</p>
        </div>
      </div>
      {isOpenDropdown && (
        <div className="absolute mt-2 pt-1 pb-6 px-[44px] gap-4 border border-gray-300 rounded-md shadow-lg bg-white">
          <h2 className="text-lg text-start font-semibold">Duration</h2>
          <div className="flex justify-between gap-2 w-full mt-2">
            <label className="input input-bordered w-44 flex flex-col items-start">
              Min nights
              <input type="number" className="grow" value={minNights} onChange={handleMinNightsChange} />
            </label>
            <label className="input input-bordered w-44 flex flex-col items-start">
              Max nights
              <input type="number" className="grow" value={maxNights} onChange={handleMaxNightsChange} />
            </label>
          </div>
          <div className='flex justify-end mt-2'>
            <button className="btn btn-info" onClick={() => setIsOpenDropdown(false)}>Button</button>
          </div>
        </div>
      )}
    </div>
    <div className="md:w-1/3 flex md:items-start md:justify-center">
      <div
        className="flex items-center w-full md:w-44 py-1 px-3 rounded-lg cursor-pointer border-gray-300 rounded-md shadow-sm border b-2"
        onClick={openModal}
      >
        <FaUser className="text-xl text-gray-600 mr-4" />
        <div className="text-left">
          <p className="text-md text-gray-800">Travellers</p>
          <p className="text-sm text-gray-900">{crusiesPassengerData.adultCount + crusiesPassengerData.youthCount} travelers</p>
        </div>
      </div>
    </div>
    {isModalOpen && <CruiseModal closeModal={closeModal} heading='Room 1' />}
    <button className="btn btn-info" onClick={cruiseSearch}>Search</button>
  </div>
);
}

export default Cruises
