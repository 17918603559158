import { Card, Text, Flex } from "@aws-amplify/ui-react";
import React, { useState } from "react";
import { IoIosArrowBack, IoIosArrowForward, IoIosTime } from "react-icons/io";
import { TravelFilter } from "../Components/filterTravels";
import { useNavigate } from "react-router-dom";
import { useThingsSearchResult } from "../context/thingToDo";

const SearchTravelResults = () => {
  const itemsPerPage = 4;
  const {thingsToDoListData, museumListData, setThingsResultData} =useThingsSearchResult()
  const [currentPageThingsToDo, setCurrentPageThingsToDo] = useState(1);
  const [currentPageMuseumTickets, setCurrentPageMuseumTickets] = useState(1);

  const totalPagesThingsToDo = Math.ceil(thingsToDoListData.length / itemsPerPage);
  const totalPagesMuseumTickets = Math.ceil(museumListData.length / itemsPerPage);

  const handleClickNextThingsToDo = () => {
    if (currentPageThingsToDo < totalPagesThingsToDo) {
      setCurrentPageThingsToDo(currentPageThingsToDo + 1);
    }
  };

  const handleClickPrevThingsToDo = () => {
    if (currentPageThingsToDo > 1) {
      setCurrentPageThingsToDo(currentPageThingsToDo - 1);
    }
  };

  const handleClickNextMuseumTickets = () => {
    if (currentPageMuseumTickets < totalPagesMuseumTickets) {
      setCurrentPageMuseumTickets(currentPageMuseumTickets + 1);
    }
  };

  const handleClickPrevMuseumTickets = () => {
    if (currentPageMuseumTickets > 1) {
      setCurrentPageMuseumTickets(currentPageMuseumTickets - 1);
    }
  };

  const startIndexThingsToDo = (currentPageThingsToDo - 1) * itemsPerPage;
  const endIndexThingsToDo = Math.min(
    startIndexThingsToDo + itemsPerPage,
    thingsToDoListData.length
  );

  const startIndexMuseumTickets = (currentPageMuseumTickets - 1) * itemsPerPage;
  const endIndexMuseumTickets = Math.min(
    startIndexMuseumTickets + itemsPerPage,
    museumListData.length
  );
  const [isFilterVisible, setFilterVisible] = useState(false);

  const toggleFilter = () => {
    setFilterVisible(!isFilterVisible);
  };
  const navigate = useNavigate();
  const handleReserve = (data:any) => {
    console.log(data, ":index")
    setThingsResultData(data)
    navigate(`/showtravel`);
  };
  return (
    <>

<button onClick={toggleFilter} className="md:hidden bg-blue-500 text-white px-4 py-2 rounded mb-4">Show Filters</button>
      <Flex direction="column" gap="4" className="mt-8">
        <Text
          className="text-black text-xl font-bold font-montserrat leading-none text-left"
          data-testid="airline-trust-text"
        >
          Top 10 things to do
        </Text>
        <Flex direction="row" gap="4" wrap={"wrap"} className="relative">
          <div
            onClick={handleClickPrevThingsToDo}
            className="flex items-center absolute left-0 top-1/2 transform -translate-y-1/2 cursor-pointer"
          >
            <IoIosArrowBack size={36} />
          </div>
          {thingsToDoListData.slice(startIndexThingsToDo, endIndexThingsToDo).map((data:any, index:any) => (
            <Card
              key={index}
              className="w-full sm:w-64 bg-white rounded-2xl shadow-md p-4" onClick={()=>handleReserve(data)}
            >
              <img
                src={data.image}
                alt={data.name}
                className="w-full h-40 object-cover rounded-2xl mb-4"
              />
              <div className="text-lg font-bold text-gray-800 mb-2 text-left">
                {data.name}
              </div>
              <div className="text-sm text-gray-600 mb-1 text-left flex items-center">
                <IoIosTime className="inline-block mr-1" size={20} />
                {data.day}
              </div>
              <div className="text-sm text-gray-600 mb-2 text-left">
                <span className="font-bold">{data.rating}</span> ({data.review})
              </div>
              <div className="text-xl font-bold text-gray-800 text-left">
                {data.price}
              </div>
              <div className="text-sm text-gray-600 text-left">per adult</div>
            </Card>
          ))}
          <div
            className="flex items-center absolute right-0 top-1/2 transform -translate-y-1/2 cursor-pointer"
            onClick={handleClickNextThingsToDo}
          >
            <IoIosArrowForward size={36} />
          </div>
        </Flex>
      </Flex>
      <Flex direction="column" gap="4" className="mt-8">
        <Text
          className="text-black text-xl font-bold font-montserrat leading-none text-left"
          data-testid="airline-trust-text"
        >
          Museum tickets
        </Text>
        <Flex direction="row" gap="4" wrap={"wrap"} className="relative">
          <div
            onClick={handleClickPrevMuseumTickets}
            className="flex items-center absolute left-0 top-1/2 transform -translate-y-1/2 cursor-pointer"
          >
            <IoIosArrowBack size={36} />
          </div>
          {museumListData.slice(startIndexMuseumTickets, endIndexMuseumTickets).map((data:any, index:any) => (
            <Card
              key={index}
              className="w-full sm:w-64 bg-white rounded-2xl shadow-md p-4" onClick={()=>handleReserve(data)}
            >
              <img
                src={data.image}
                alt={data.name}
                className="w-full h-40 object-cover rounded-2xl mb-4"
              />
              <div className="text-lg font-bold text-gray-800 mb-2 text-left">
                {data.name}
              </div>
              <div className="text-sm text-gray-600 mb-1 text-left flex items-center">
                <IoIosTime className="inline-block mr-1" size={20} />
                {data.day}
              </div>
              <div className="text-sm text-gray-600 mb-2 text-left">
                <span className="font-bold">{data.rating}</span> ({data.review})
              </div>
              <div className="text-xl font-bold text-gray-800 text-left">
                {data.price}
              </div>
              <div className="text-sm text-gray-600 text-left">per adult</div>
            </Card>
          ))}
          <div
            className="flex items-center absolute right-0 top-1/2 transform -translate-y-1/2 cursor-pointer"
            onClick={handleClickNextMuseumTickets}
          >
            <IoIosArrowForward size={36} />
          </div>
        </Flex>
      </Flex>
      {isFilterVisible && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
          <div className="bg-white p-4 rounded-lg w-full h-full md:w-3/4 md:h-3/4 overflow-auto relative">
            <button
              onClick={toggleFilter}
              className="absolute top-4 right-4 bg-red-500 text-white px-4 py-2 rounded"
            >
              Close
            </button>
            <TravelFilter />
          </div>
        </div>
      )}
    </>
  );
};

export default SearchTravelResults;
