import React from "react";
import { FaArrowRight, FaClock, FaUser } from "react-icons/fa";
import { TbArrowsShuffle } from "react-icons/tb"; // Importing TbArrowsShuffle icon
import { trainData } from "./helperFunctions/train.helper";
import TrainDetails from "./trainDetails";

const TrainResultList = () => {

  return (
    <div className="p-4">
      <div className="font-bold mb-4 text-left">Saturday, 27/07/2024</div>
      <div className="flex justify-center lg:w-[70vw] w-[90vw]" style={{margin:"auto",justifyContent:"center"}}>
        <div className="hidden lg:block lg:w-2/5 pr-4">
          {trainData.map((train, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-md p-3 mb-4 border border-gray-200"
            >
              <div className="flex items-center mb-2">
                <div className="flex items-center">
                  <div className="mr-2">{train.departure}</div>
                  <FaArrowRight className="text-gray-500" />
                  <div className="ml-2">{train.arrival}</div>
                </div>
              </div>
              <div className="flex items-center mb-2">
                <div className="w-1/2 flex items-center">
                  <FaClock className="mr-2" /> {train.duration}
                  <TbArrowsShuffle className="ml-2 text-gray-500" /> {/* TbArrowsShuffle icon */}
                  <span className="ml-1">5</span> {/* Text "5" */}
                </div>
                <div className="w-1/2 flex justify-end items-center">
                  from USD {train.price} <FaUser className="ml-2" /> {train.passengers}
                </div>
              </div>
              <div className="text-sm text-blue-500">SEAT RESERVATION REQUIRED</div>
            </div>
          ))}
        </div>
        <div className="w-full lg:w-3/5 pl-4 border border-gray-200 rounded-lg shadow-md">
         <TrainDetails/>
        </div>
      </div>
    </div>
  );
};

export default TrainResultList;
