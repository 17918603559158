// FlightTypeContext.tsx
import React, { createContext, useContext, useState,ReactNode } from 'react';

interface FlightTypeContextType {
  flightType: string;
  setFlightType: React.Dispatch<React.SetStateAction<string>>;
  selectedFlight: any, 
  setSelectedFlight: any
}

const FlightTypeContext = createContext<FlightTypeContextType | undefined>(undefined);

export const FlightTypeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [flightType, setFlightType] = useState("ECONOMY");
  const [selectedFlight, setSelectedFlight] = useState({});
  return (
    <FlightTypeContext.Provider value={{ flightType, setFlightType , selectedFlight, setSelectedFlight}}>
      {children}
    </FlightTypeContext.Provider>
  );
};

export const useFlightTypeContext = (): FlightTypeContextType => {
  const context = useContext(FlightTypeContext);
  if (!context) {
    throw new Error('useFlightTypeContext must be used within a FlightTypeProvider');
  }
  return context;
};
