import React from 'react'
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useThingsSearchResult } from '../context/thingToDo';
const loadGoogleMapsScript = (callback: () => void) => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA96SrSSmQhUIevuhYcP0uuv5xjLjPxt2A&libraries=places&v=weekly`;
    script.defer = true;
    script.async = true;
    script.onload = callback;
    document.head.appendChild(script);
  };
const ShowTravel = () => {
    const [pickupPoint, setPickupPoint] = useState('');
    const [typedText, setTypedText] = useState<string>("");
    const {thingsSearchData,setThingsSearchData} =useThingsSearchResult()
 
    useEffect(() => {
        loadGoogleMapsScript(() => {
          const pickupAutocomplete = new window.google.maps.places.Autocomplete(
            document.getElementById("pickup-address") as HTMLInputElement,
            {
              componentRestrictions: { country: ["us", "ca", "in"] },
              fields: ["address_components", "formatted_address"],
              types: ["address"],
            }
          );
    
          pickupAutocomplete.addListener("place_changed", () => {
            const place = pickupAutocomplete.getPlace();
            setPickupPoint(place?.formatted_address || "");
             setThingsSearchData({...thingsSearchData, city: place?.formatted_address || ""})
          });
    
          
    
        });
      }, []);
      const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTypedText(event.target.value);
      
      };
      const handleStartDateChange = (event:any) => {
        setThingsSearchData({
          ...thingsSearchData,
          fromDate: event.target.value,
        });
      };
    
      const handleReturnDateChange = (event:any) => {
        setThingsSearchData({
          ...thingsSearchData,
          toDate: event.target.value,
        });
      };
      const handleInputBlur = () => {
        if (!thingsSearchData.city) {
          setTypedText("");
        }
      };
      const navigate=useNavigate()
      const handleSubmit = (event:any) => {
        event.preventDefault(); 

       navigate('/travellist');
      };
  return (
    <div className="p-8 bg-white rounded-lg w-full shadow-lg">
    <form onSubmit={handleSubmit} className="flex flex-col w-full md:justify-around md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4">
      
      {/* City Selector */}
      <div className="flex flex-col w-['50%]">
        <label htmlFor="city" className="text-gray-700">City</label>
        <input
          id="pickup-address"
          name="pickup-address"
          required
          autoComplete="off"
          value={thingsSearchData.city ? thingsSearchData.city : typedText}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>

      {/* Start Date Picker */}
      <div className="flex flex-col">
        <label htmlFor="start-date" className="text-gray-700">Start Date</label>
        <input type="date" id="start-date" name="start-date" className="mt-1 p-2 border rounded-md" 
         value={thingsSearchData.fromDate}
         onChange={handleStartDateChange}
        />
      </div>

      {/* Return Date Picker */}
      <div className="flex flex-col">
        <label htmlFor="return-date" className="text-gray-700">Return Date</label>
        <input type="date" id="return-date" name="return-date" className="mt-1 p-2 border rounded-md" 
          value={thingsSearchData.toDate}
          onChange={handleReturnDateChange}
        />
      </div>

      {/* Search Button */}
      <button type="submit" className="mt-4 md:mt-0 p-3 bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700">
        Search
      </button>
    </form>
  </div>

  )
}

export default ShowTravel
