interface FlightData {
    imageUrl: string;
    airlineText: string;
    travelCity: string;
    price: string;
    style: any;
  }
 const styles= {
  AirlinesImageContainer: {
    top: '972px',
    left: '73px',
    width: '233px',
    height: '184px',
    borderRadius: '24px',
    backgroundImage: 'url(./image.jpeg)',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
}
  export const FlightData: FlightData[] = [
    {
      imageUrl: 'https://images.unsplash.com/photo-1629630331106-18c4c2aff510?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDUzMDJ8MHwxfHNlYXJjaHw2fHxBaXJjcmFmdCUyQyUyMFJ1bndheSUyQyUyMFRlcm1pbmFsfGVufDF8fHx8MTcwOTE3MjYwMnww&ixlib=rb-4.0.3&q=80&w=1080',
      airlineText: 'Skyline Airways',
      travelCity: 'New York',
      price: 'from $300/flight',
      style: styles.AirlinesImageContainer
    },
    {
        imageUrl: 'https://images.unsplash.com/photo-1629630331106-18c4c2aff510?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDUzMDJ8MHwxfHNlYXJjaHw2fHxBaXJjcmFmdCUyQyUyMFJ1bndheSUyQyUyMFRlcm1pbmFsfGVufDF8fHx8MTcwOTE3MjYwMnww&ixlib=rb-4.0.3&q=80&w=1080',
        airlineText: 'Skyline Airways',
        travelCity: 'New York',
        price: 'from $600/flight',
        style: styles.AirlinesImageContainer
      },
      {
        imageUrl: 'https://images.unsplash.com/photo-1629630331106-18c4c2aff510?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDUzMDJ8MHwxfHNlYXJjaHw2fHxBaXJjcmFmdCUyQyUyMFJ1bndheSUyQyUyMFRlcm1pbmFsfGVufDF8fHx8MTcwOTE3MjYwMnww&ixlib=rb-4.0.3&q=80&w=1080',
        airlineText: 'Skyline Airways',
        travelCity: 'New York',
        price: 'from $200/flight',
        style: styles.AirlinesImageContainer
      },
      {
        imageUrl: 'https://images.unsplash.com/photo-1629630331106-18c4c2aff510?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDUzMDJ8MHwxfHNlYXJjaHw2fHxBaXJjcmFmdCUyQyUyMFJ1bndheSUyQyUyMFRlcm1pbmFsfGVufDF8fHx8MTcwOTE3MjYwMnww&ixlib=rb-4.0.3&q=80&w=1080',
        airlineText: 'Airline',
        travelCity: 'New York',
        price: 'from $500/flight',
        style: styles.AirlinesImageContainer
      },
      {
        imageUrl: 'https://images.unsplash.com/photo-1629630331106-18c4c2aff510?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDUzMDJ8MHwxfHNlYXJjaHw2fHxBaXJjcmFmdCUyQyUyMFJ1bndheSUyQyUyMFRlcm1pbmFsfGVufDF8fHx8MTcwOTE3MjYwMnww&ixlib=rb-4.0.3&q=80&w=1080',
        airlineText: 'Vistara',
        travelCity: 'New York',
        price: 'from $400/flight',
        style: styles.AirlinesImageContainer
      },
    // Add more card data objects as needed
  ];