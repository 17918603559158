// App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { LeadFormComponent } from "./pages/leadForm";
import AddressForm from "./pages/autocompleteForm";
import { Home } from "./pages/home";
import { Flightdetails } from "./pages/flightDetails";
import { Flightlist } from "./pages/flightList";
import { PopperProvider } from "./context/popperContext";
import { FlightTypeProvider } from "./context/flightTypeContext";
import { SearchProvider } from "./context/searchFlight";
import { TripTypeProvider } from "./context/tripType";
import { FlightSearchResultProvider } from "./context/flightSearchResults";
import Pay from "./pages/pay";
import { PaymentProvider } from "./context/paymentData";
import { CustomerTravelProvider } from "./context/customerTravelDetail";
import { SearchCountryProvider } from "./context/searchCountry";
import CheckoutPage from "./pages/checkoutPage";
import { TermsAndConditions } from "./pages/termsAndCondition";
import { Calendar } from "./pages/calendar";
import { FlexibleFlightResultProvider } from "./context/flexibleFlightContext";
import { Hotels } from "./pages/hotels";
import Loader from "./Components/Loader";
import Login from "./pages/login";
import { Authenticator } from "@aws-amplify/ui-react";
import Header from "./Components/common/Header";
import { HotelDetailsHome } from "./pages/hotelDetails/hotelDetails";
import { BucketStorage } from "./pages/bucketStorage";
import { ReadText } from "./pages/readText";
import { FilterProvider } from "./context/filterFlightContext";
import { LoadingProvider, useLoading } from "./context/loadingContext";
import "./App.css";
import { RentalCar } from "./pages/rentalCar";
import AddressInput from "./pages/autocompleteForm";
import { RentalCarSearchDetailsProvider } from "./context/rentalCarSearchDetails";
import { ThingsSearchResultProvider } from "./context/thingToDo";

import { RentalCarList } from "./pages/rentalCarList";
import CarDetail from "./pages/carDetails";
import BookingPage from "./pages/carBookingPage";
import { TrustedAirlines } from "./Components/TrustedAirlines";
import { Res } from "./pages/responsive";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import TravelPage from "./pages/travelPage";
import TravelList from "./pages/travelList";
import ShowTravelDetail from "./pages/showTravelDetail";
import Packages from "./pages/packages";
import StayCar from "./pages/stayCar";
import { StayCarList } from "./pages/stayCarList";
import CruisesPage from "./pages/CruisesPage";
import CruisesList from "./Components/CruisesList";
import { PackageResultProvider } from "./context/PackagesContext";
import { CruisesProvider } from "./context/cruisesContext";
import CruisesDetails from "./pages/cruisesDetails";
import TrainSearch from "./pages/hotelDetails/trainSearch";
import TrainHome from "./pages/trainHome";
import { TrainContextProvider } from "./context/trainContext";
import TrainSearchResult from "./pages/trainSearchResult";

function App() {
  return (
    <Authenticator.Provider>
      <RentalCarSearchDetailsProvider>
        <ThingsSearchResultProvider>
          <PackageResultProvider>
            <CruisesProvider>
              <FilterProvider>
                <PaymentProvider>
                  <CustomerTravelProvider>
                    <SearchCountryProvider>
                      <FlightSearchResultProvider>
                        <FlightTypeProvider>
                          <SearchProvider>
                            <PopperProvider>
                              <TripTypeProvider>
                                <FlexibleFlightResultProvider>
                                  <LoadingProvider>
                                    <TrainContextProvider>
                                      <AppContent />
                                    </TrainContextProvider>
                                  </LoadingProvider>
                                </FlexibleFlightResultProvider>
                              </TripTypeProvider>
                            </PopperProvider>
                          </SearchProvider>
                        </FlightTypeProvider>
                      </FlightSearchResultProvider>
                    </SearchCountryProvider>
                  </CustomerTravelProvider>
                </PaymentProvider>
              </FilterProvider>
            </CruisesProvider>
          </PackageResultProvider>
        </ThingsSearchResultProvider>
      </RentalCarSearchDetailsProvider>
    </Authenticator.Provider>
  );
}

function AppContent() {
  const { loading, showFlightLoading } = useLoading();

  return (
    <>
      {loading && <Loader />}
      {showFlightLoading && <Flightdetails />}
      <Router>
        <div
          style={{
            position: "fixed",
            width: "100%",
            zIndex: "21",
          }}
        >
          <Header />
        </div>
        <div style={{ paddingBlockStart: "7rem" }}>
          <Routes>
            <Route path="/readtext" element={<ReadText />} />
            <Route path="/rentalcar" element={<RentalCarList />} />
            <Route path="/" element={<Home />} />
            <Route path="/bucket" element={<BucketStorage />} />
            <Route path="/hotel" element={<Hotels />} />
            <Route path="/login" element={<Login />} />
            <Route path="/checkout" element={<CheckoutPage />} />
            <Route path="/calendar" element={<Calendar />} />
            <Route path="/flightlist" element={<Flightlist />} />
            <Route path="/flightdetails" element={<Flightdetails />} />
            <Route path="/pay" element={<Pay />} />
            <Route path="/termAndCondition" element={<TermsAndConditions />} />
            <Route path="/leadform" element={<LeadFormComponent />} />
            <Route path="/hotelDetails" element={<HotelDetailsHome />} />
            <Route path="/car" element={<RentalCar />} />
            <Route path="/auto" element={<AddressInput />} />
            <Route path="/car/:id" element={<CarDetail />} />
            <Route path="/booking/:id" element={<BookingPage />} />
            <Route path="/search" element={<TrustedAirlines />} />
            <Route path="/res" element={<Res />} />
            <Route path="/travelpage" element={<TravelPage />} />
            <Route path="/travellist" element={<TravelList />} />
            <Route path="/showtravel" element={<ShowTravelDetail />} />
            <Route path="/thingsTicketBooking" element={<BookingPage />} />
            <Route path="/packages" element={<Packages />} />
            <Route path="/staycar" element={<StayCar />} />
            <Route path="/staycarlist" element={<StayCarList />} />
            <Route path="/cruises" element={<CruisesPage />} />
            <Route path="/cruiselist" element={<CruisesList />} />
            <Route path="/cruisedetails" element={<CruisesDetails />} />
            <Route path="/train" element={<TrainHome />} />
            <Route path="/trainSearchResult" element={<TrainSearchResult />} />
          </Routes>
        </div>
      </Router>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
      />
    </>
  );
}

export default App;
