export const carData =  [
    {
      image: 'https://cdn.pixabay.com/photo/2017/03/05/15/29/aston-martin-2118857__340.jpg',
      name: 'Toyota Camry',
      seatingCapacity: 5,
      doors: 4,
      airCondition: true,
      type: 'Automatic',
      mileage: 'Unlimited',
      cancellation: 'Free',
      rating: 88,
      price: 85,
    },
    {
      image: 'https://cdn.pixabay.com/photo/2015/01/19/13/51/car-604019__340.jpg',
      name: 'Honda Accord',
      seatingCapacity: 5,
      doors: 4,
      airCondition: true,
      type: 'Automatic',
      mileage: 'Unlimited',
      cancellation: 'Free',
      rating: 90,
      price: 80,
    },
    {
      image: 'https://imgd.aeplcdn.com/370x208/n/cw/ec/130591/fronx-exterior-right-front-three-quarter-109.jpeg?isig=0&q=80',
      name: 'Audi A4',
      seatingCapacity: 5,
      doors: 4,
      airCondition: true,
      type: 'Automatic',
      mileage: 'Unlimited',
      cancellation: 'Free',
      rating: 92,
      price: 95,
    },
    {
      image: 'https://static.toiimg.com/photo/msid-97028015/97028015.jpg',
      name: 'BMW 3 Series',
      seatingCapacity: 5,
      doors: 4,
      airCondition: true,
      type: 'Automatic',
      mileage: 'Unlimited',
      cancellation: 'Free',
      rating: 93,
      price: 100,
    },
    {
      image: 'https://i.pinimg.com/736x/d2/6d/2a/d26d2aab2ff63cb48b3a91b0e8e0aa55.jpg',
      name: 'Volkswagen Jetta',
      seatingCapacity: 5,
      doors: 4,
      airCondition: true,
      type: 'Automatic',
      mileage: 'Unlimited',
      cancellation: 'Free',
      rating: 85,
      price: 75,
    },
    {
      image: 'https://www.autocar.co.uk/sites/autocar.co.uk/files/styles/gallery_slide/public/images/car-reviews/first-drives/legacy/rolls_royce_phantom_top_10.jpg?itok=XjL9f1tx',
      name: 'Lamborghini Aventador',
      seatingCapacity: 2,
      doors: 2,
      airCondition: true,
      type: 'Automatic',
      mileage: 'Unlimited',
      cancellation: 'Free',
      rating: 98,
      price: 250,
    },
    {
      image: 'https://www.autocar.co.uk/sites/autocar.co.uk/files/styles/gallery_slide/public/images/car-reviews/first-drives/legacy/rolls_royce_phantom_top_10.jpg?itok=XjL9f1tx',
      name: 'Mercedes-Benz C-Class',
      seatingCapacity: 5,
      doors: 4,
      airCondition: true,
      type: 'Automatic',
      mileage: 'Unlimited',
      cancellation: 'Free',
      rating: 94,
      price: 110,
    },
    {
      image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCtNwanU5KBFHTItU1KxHb-0KYm3Z1d5FV_Q&s',
      name: 'Ford Mustang',
      seatingCapacity: 4,
      doors: 2,
      airCondition: true,
      type: 'Automatic',
      mileage: 'Unlimited',
      cancellation: 'Free',
      rating: 89,
      price: 120,
    },
    {
      image: 'https://imgd.aeplcdn.com/600x337/n/cw/ec/153319/range-rover-velar-exterior-right-front-three-quarter-4.jpeg?isig=0&q=80',
      name: 'Cadillac CTS',
      seatingCapacity: 5,
      doors: 4,
      airCondition: true,
      type: 'Automatic',
      mileage: 'Unlimited',
      cancellation: 'Free',
      rating: 91,
      price: 105,
    },
    {
      image: 'https://www.honestjohn.co.uk/media/zbrl0w0y/range_rover_2022.jpg?width=620',
      name: 'Chevrolet Camaro',
      seatingCapacity: 4,
      doors: 2,
      airCondition: true,
      type: 'Automatic',
      mileage: 'Unlimited',
      cancellation: 'Free',
      rating: 87,
      price: 115,
    },
  ];
  