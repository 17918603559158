// PopperContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define the type for the context value
interface PopperContextType {
  popperValue: string;
  setPopperValue: React.Dispatch<React.SetStateAction<string>>;
}

// Create a context object
const PopperContext = createContext<PopperContextType | undefined>(undefined);

// Create a provider component
export const PopperProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [popperValue, setPopperValue] = useState('1Adults');

  return (
    <PopperContext.Provider value={{ popperValue, setPopperValue }}>
      {children}
    </PopperContext.Provider>
  );
};

// Create a custom hook to use the context
export const usePopperContext = (): PopperContextType => {
  const context = useContext(PopperContext);
  if (!context) {
    throw new Error('usePopperContext must be used within a PopperProvider');
  }
  return context;
};
