import React, { useEffect, useState } from "react";
import { Marker, Popup } from "react-map-gl";
import { MapView } from "@aws-amplify/ui-react-geo";
import { Heading, Text } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import "@aws-amplify/ui-react-geo/styles.css";
import { sampleJsonMapData } from "./helperFunctions/maps.helper";
import awsExports from "../aws-exports";
import axios from "axios";

interface Hotel {
  locationName: string;
  latitude: string;
  longitude: string;
  price: number;
}

// Define your custom marker component
const CustomMarker: React.FC<{
  latitude: number;
  longitude: number;
  price: number;
  onClick: () => void;
}> = ({ latitude, longitude, price, onClick }) => {
  return (
    <Marker latitude={latitude} longitude={longitude} onClick={onClick}>
      <div
        className="custom-marker"
        style={{
          backgroundColor: "white",
          borderRadius: "10%",
          width: "100px",
          textAlign: "center",
          color: "black",
        }}
      >
        ${price}
      </div>
    </Marker>
  );
};

interface MapWithMarketProps {
  className?: any;
}

const MapWithMarket: React.FC<MapWithMarketProps> = ({ className }) => {
  const [selectedHotel, setSelectedHotel] = useState<Hotel | null>(null);
  console.log("selectedHotelselectedHotel- ----->", selectedHotel);

  const handleMarkerClick = (hotel: Hotel) => {
    setSelectedHotel(hotel);
  };

  const handlePopupClose = () => {
    // Do nothing on close, keep selectedHotel as is
    // setSelectedHotel(null);
  };

  const [
    currentLocationLongituteLatitute,
    setCurrentLocationLongituteLatitute,
  ] = useState({ latitude: 37.8, longitude: -122.4 });

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await fetch(`https://ipapi.co/json/`);
        const data = await response.json();
        setCurrentLocationLongituteLatitute({
          latitude: data?.latitude || 37.8,
          longitude: data?.longitude || -122.4,
        });
      } catch (error) {
        console.error("Error fetching location:", error);
      }
    };
    fetchLocation();
  }, []);

  return (
    <div className={`${className}`}>
      <MapView
        initialViewState={{
          latitude: currentLocationLongituteLatitute.latitude,
          longitude: currentLocationLongituteLatitute.longitude,
          zoom: 12,
        }}
      >
        {sampleJsonMapData.map((hotel: Hotel, index: number) => (
          <CustomMarker
            key={index}
            latitude={parseFloat(hotel.latitude)}
            longitude={parseFloat(hotel.longitude)}
            price={hotel.price}
            onClick={() => handleMarkerClick(hotel)}
          />
        ))}

        {selectedHotel?.price && (
          <div className="custompop-is-here">
            <Popup
              style={{
                backgroundColor: "wheat",
                padding: "10px",
                borderRadius: "15px",
              }}
              key={
                parseFloat(selectedHotel.latitude) +
                parseFloat(selectedHotel.longitude)
              }
              closeOnClick={false}
              latitude={parseFloat(selectedHotel.latitude)}
              longitude={parseFloat(selectedHotel.longitude)}
              onClose={handlePopupClose}
            >
              <div className="popup-content">
                <Heading>{selectedHotel.locationName}</Heading>
                <Text>Price: ${selectedHotel.price}</Text>
                <Text>3 Star</Text>
                <img
                  src="https://images.pexels.com/photos/164595/pexels-photo-164595.jpeg"
                  alt="hotel"
                  width="80px"
                />
              </div>
            </Popup>
          </div>
        )}
      </MapView>
    </div>
  );
};

export default MapWithMarket;
