export function calculateTimeDifference(dateOfDeparture: string, dateOfArrival: string, timeOfDeparture: string, timeOfArrival: string, dateFormat: string = "DDMMYY", timeFormat: string = "HHmm"): string {
    // Parse the input strings into Date objects
    const departureDateTime: Date = new Date(
        2000 + parseInt(dateOfDeparture.substring(4, 6)),  // Year (assuming year is in YY format)
        parseInt(dateOfDeparture.substring(2, 4)) - 1,     // Month (zero-based index)
        parseInt(dateOfDeparture.substring(0, 2)),        // Day
        parseInt(timeOfDeparture.substring(0, 2)),        // Hours
        parseInt(timeOfDeparture.substring(2, 4))         // Minutes
    );

    const arrivalDateTime: Date = new Date(
        2000 + parseInt(dateOfArrival.substring(4, 6)),
        parseInt(dateOfArrival.substring(2, 4)) - 1,
        parseInt(dateOfArrival.substring(0, 2)),
        parseInt(timeOfArrival.substring(0, 2)),
        parseInt(timeOfArrival.substring(2, 4))
    );

    // Calculate the time difference in milliseconds
    const timeDifference: number = arrivalDateTime.getTime() - departureDateTime.getTime();

    // Convert milliseconds to hours and minutes
    const hours: number = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutes: number = Math.round((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

    return `${hours} hours ${minutes} minutes`;
}