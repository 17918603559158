//  

import React from 'react';
import { Button, ButtonProps } from '@aws-amplify/ui-react';
interface ButtonsProps {
  variation?: ButtonProps['variation'];
  colorTheme?: ButtonProps['colorTheme'];
  TextAlign?: string;
  height?: ButtonProps['height'];
  Text?: string;
  disabled?:boolean;
  color?: string,
  borderRadius?: string;
  borderStyle?: string;
  borderWidth?: string;
  backgroundColor?: string;
  size?: string;
  disabledColor?: string;
  onClick?: () => void;
  width?: string
}

const Buttons: React.FC<ButtonsProps> = ({
  variation,
  size,
  TextAlign,
  backgroundColor,
  color,
  height,
  disabled,
  Text,
  borderRadius,
  borderStyle,
  borderWidth,
width,
disabledColor,
  onClick
}) => {
  const defaultVariation = 'primary';
  const defaultBackgroundColor = '#007aff';
  const defaultHeight = '40px';
  const defaultBorderRadius = '16px';
  const defaultBorderStyle = 'solid';
  const defaultBorderWidth = '4px';
  const defaultColor = 'white';
  const defaultWidth='8rem'
  const defaultTextAlign= 'center'
  const defaultDisabled=false
  const defaultDisabledColor = 'red';  
  return (
    <>
      <Button
      color={color || defaultColor}
      disabled={disabled || defaultDisabled}
        variation={variation || defaultVariation}
        textAlign={TextAlign || defaultTextAlign}
        backgroundColor={disabled ? (disabledColor || defaultDisabledColor) : (backgroundColor || defaultBackgroundColor) }
     
       
        height={height || defaultHeight}
        borderRadius={borderRadius || defaultBorderRadius}
        borderStyle={borderStyle || defaultBorderStyle}
        borderWidth={borderWidth || defaultBorderWidth}
        onClick={onClick}
        width={width || defaultWidth}
      >
        {Text}
      </Button>
    </>
  );
};

export default Buttons;
