import React from 'react'
import { FaUser } from 'react-icons/fa'

const CruiseRoomExp = () => {
  return (
    
    <div className='flex md:flex-row gap-2 items-start'>

    <div className="max-w-sm  my-4 p-6 bg-gray-200 rounded-lg shadow-md">

  <img
    className="w-full h-48 object-cover rounded-t-md"
    src="https://mediaim.expedia.com/cruise/pc-rp-2024-02-01/653d4729-7850-4c81-b9ad-90fc8b00da9e.jpg?impolicy=resizecrop&ra=fit&rw=500"
    alt="Room"
  />
  
  <div className="mt-4 text-start gap-2 flex flex-col">
    <h3 className="text-lg font-semibold text-gray-900">Inside</h3>
    <p className="text-sm text-gray-600">
      Compact interior windowless rooms, recommended if you plan to spend most time outside the room.
    </p>
    <div className='flex gap-4'>

      <p className="text-sm flex gap-2 items-center text-gray-600 mt-2">
      
        <FaUser className='text-lg' />
        2-4 
      </p>
      <p className="text-sm text-gray-600 mt-2">
        <span className="text-gray-500">175 sqft</span>
      </p>
    </div>
    <div className='flex justify-between'>
      <div className='flex flex-col gap-1'>
        <p className="text-sm text-gray-600">per traveler*</p>
        <p className="text-lg font-bold text-gray-900 mt-2">$884</p>
        <p className="text-sm text-gray-600">per traveler*</p>
        <p className="text-sm text-gray-600">including taxes & fees</p>
      </div>
      <div className='flex items-end '>

        <button className="w-32 bg-blue-600 text-white py-2 rounded-full shadow-md">
          Continue
        </button>
      </div>
    </div>
    
   
  </div>
    </div>
    <div className="max-w-sm my-4 p-6 bg-gray-200 rounded-lg shadow-md">

<img
 className="w-full h-48 object-cover rounded-t-md"
 src="https://mediaim.expedia.com/cruise/pc-rp-2024-02-01/653d4729-7850-4c81-b9ad-90fc8b00da9e.jpg?impolicy=resizecrop&ra=fit&rw=500"
 alt="Room"
/>

<div className="mt-4 text-start gap-2 flex flex-col">
 <h3 className="text-lg font-semibold text-gray-900">Inside</h3>
 <p className="text-sm text-gray-600">
   Compact interior windowless rooms, recommended if you plan to spend most time outside the room.
 </p>
 <div className='flex gap-4'>

   <p className="text-sm flex gap-2 items-center text-gray-600 mt-2">
   
     <FaUser className='text-lg' />
     2-4 
   </p>
   <p className="text-sm text-gray-600 mt-2">
     <span className="text-gray-500">175 sqft</span>
   </p>
 </div>
 <div className='flex justify-between'>
   <div className='flex flex-col gap-1'>
     <p className="text-sm text-gray-600">per traveler*</p>
     <p className="text-lg font-bold text-gray-900 mt-2">$884</p>
     <p className="text-sm text-gray-600">per traveler*</p>
     <p className="text-sm text-gray-600">including taxes & fees</p>
   </div>
   <div className='flex items-end '>

     <button className="w-32 bg-blue-600 text-white py-2 rounded-full shadow-md">
       Continue
     </button>
   </div>
 </div>
 

</div>
 </div>

    </div>
  )




}

export default CruiseRoomExp
