import { Table, TableBody, TableCell, TableHead, TableRow, Text } from '@aws-amplify/ui-react'
export const FareDetails = ({recommendation}:any) => {
  return (
    <>
      <Text>*All Calculations and Charges in USD</Text>
      <Table
        caption=""
        highlightOnHover={false}>
        <TableHead>
          <TableRow>
            <TableCell as="th">Pax Type</TableCell>
            <TableCell as="th">Base Fare</TableCell>
            <TableCell as="th">	Discount</TableCell>
            <TableCell as="th">Calculated Base Fare</TableCell>
            <TableCell as="th">Tax</TableCell>
            <TableCell as="th">Total Per Pax</TableCell>
            <TableCell as="th">Pax</TableCell>
            <TableCell as="th">Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
          <TableCell >Pax Type  </TableCell>
            <TableCell >Base Fare {recommendation?.paxFareDetails?.ADT?.paxFareAmount}</TableCell>
            <TableCell >	Discount</TableCell>
            <TableCell >Calculated Base Fare</TableCell>
            <TableCell > {recommendation?.paxFareDetails?.ADT?.paxTaxAmount}</TableCell>
            <TableCell >Total Per Pax</TableCell>
            <TableCell >{recommendation?.recommendationIndex}</TableCell>
            <TableCell >Total</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Total</TableCell>
            <TableCell>821</TableCell>
            
          </TableRow>
        </TableBody>
      </Table>
    </>
  )
}
