import { SetStateAction, useEffect, useState } from "react";
import { Autocomplete, Flex, Theme, ThemeProvider } from "@aws-amplify/ui-react";
import { airports } from "../Constant/airportData";
import { MdOutlineSwapHoriz } from "react-icons/md";
import { FiCalendar } from "react-icons/fi";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export const MultiCitySubTab = ({fromData,onRemove, isRemovable,onUpdate }:any) => {
    const [departureDate, setDepartureDate] = useState<Date | null>(null);
    const [returnDate, setReturnDate] = useState<Date | null>(null);
    const [to, setTo] = useState('');
    const [from, setFrom] = useState(fromData);
    

    const theme: Theme = {
        name: 'autocomplete-theme',
        tokens: {
            components: {
                autocomplete: {
                    menu: {
                        option: {

                            _active: {
                                backgroundColor: {
                                    value: '#007aff'

                                },

                            },

                        },
                    },
                },
            },
        },
    };

    const onChangeFrom = (event: { target: { value: SetStateAction<string>; }; }) => {

        setFrom(event?.target.value)
        onUpdate({ from: event?.target.value });
    }
    const onClearFrom = () => {
        setFrom(' ')
    }
    const onSelectFrom = (option: { label: string, id: string }) => {
        const { label, id }  = option;
        setFrom(id);
    };
    const onChangeTo=(event: { target: { value: SetStateAction<string>; }; })=>{
        if(from== event.target.value){
          
            return;
        }
          setTo(event?.target.value)
          onUpdate({ to: event?.target.value });
      }
      const onClearTo=()=>{
        setTo("")
    }
    const onSelectTo = (option: { label: string, id: string }) => {
        const { label, id } = option;
        setTo(id);
    };

    const handleDepartureDateChange = (date: Date | null) => {
        setDepartureDate(date);
        onUpdate({ depatureDate: date });
        if (returnDate && date && date > returnDate) {
          setReturnDate(null);
          onUpdate({ returnDate: null });
        }
      };

      const swapValues = () => { 
        const temp = from;
        setFrom(to);
        setTo(temp);
    };

    const handleRemove = () => {
        onRemove(); // Call the remove function passed from MultiCity
    };

    return (
        <>
        <Flex paddingInline={'2rem'}>
            <Flex>
                <ThemeProvider theme={theme} colorMode="light">
                    <Autocomplete
                        label="Autocomplete"
                        borderRadius={'16px'}
                        size='large'
                        width={'12rem'}
                        value={from}
                        onChange={onChangeFrom}
                        onClear={onClearFrom}
                        onSelect={onSelectFrom}
                        options={airports.map(airport => ({ id: airport.id, label: airport.label }))}
                        placeholder="from."
                    />
                </ThemeProvider>
                <MdOutlineSwapHoriz size={24} style={{ position: 'absolute', left: '233px', color: '#007aff', width: '35px', border: '50% 50%', backgroundColor: '#f5f5f5', borderRadius: '50%', height: '35px' }} onClick={swapValues} />
                <ThemeProvider theme={theme} colorMode="light">
                    <Autocomplete
                        label="Autocomplete"
                        borderRadius={'16px'}
                        size='large'
                        width={'12rem'}
                        value={to}
                        onChange={onChangeTo}
                        onClear={onClearTo}
                        onSelect={onSelectTo}
                        options={airports.map(airport => ({ id: airport.id, label: airport.label }))}
                        placeholder="to?"
                    />
                </ThemeProvider>
            </Flex>
            <Flex marginBlock={'4px'}>
                <DatePicker
                    showIcon
                    selected={departureDate}
                    onChange={handleDepartureDateChange}
                    dateFormat="dd/MM/yy"
                    className="custom-datepicker"
                    placeholderText="Departing"
                    minDate={new Date()}
                    icon={<FiCalendar size={24} color='#007aff' />}
                />
                {isRemovable && (
                        <button onClick={handleRemove} style={{ zIndex:10,marginLeft: '5px', backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}>X</button>
                    )}
            </Flex>
            {/* Render the close button if isRemovable is true */}
            
            </Flex>
        </>
    )
}
