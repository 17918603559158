// DataContext.tsx
import React, { createContext, useState, useContext, ReactNode } from 'react';

interface ThingsSearchResultType {
  crusiesPassengerData:any;
  setCrusiesPassengerData: React.Dispatch<any>;
  crusiesSearchData:any,
  setCrusiesSearchData: React.Dispatch<any>;
  cruises:any;
  setCruises: React.Dispatch<any>;
  selectedCruise:any;
  setSelectedCruise: React.Dispatch<any>;
}
export interface Cruise {
    id: number;
    image: string;
    title: string;
    ship: string;
    dates: string;
    stops: string;
    discount: string;
    oldPrice: string;
    newPrice: string;
    description: string;
  }
const CruisesContext = createContext<ThingsSearchResultType | undefined>(undefined);
export const useCruisesResult = () => {
    const context = useContext(CruisesContext);
    if (!context) {
      throw new Error('useFlightSearchResult must be used within a DataProvider');
    }
    return context;
  };
export const CruisesProvider:React.FC<{ children: ReactNode }> = ({ children }) =>{
  
  const [crusiesPassengerData, setCrusiesPassengerData] = useState<any>({ adultCount: 1, youthCount: 0 });
  const [crusiesSearchData, setCrusiesSearchData] = useState<any>([]);
  const [cruises, setCruises] = useState<Cruise[]>([]);
  const [selectedCruise, setSelectedCruise] = useState<any>([]);

  return (
    <CruisesContext.Provider value={{crusiesPassengerData,cruises,selectedCruise, setSelectedCruise, setCruises, setCrusiesPassengerData,crusiesSearchData,setCrusiesSearchData }}>
      {children}
    </CruisesContext.Provider>
  );
};


