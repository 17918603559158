import {
  Button,
  Card,
  CheckboxField,
  Flex,
  Grid,
  SliderField,
  Text,
} from "@aws-amplify/ui-react";

const carType = [
  { name: "Economy", price: "1223" },
  { name: "Mid Size", price: "55435" },
  { name: "Large", price: "3455" },
  { name: "Standard", price: "1223" },
  { name: "Premium", price: "54435" },
  { name: "Full Size", price: "347655" },
];
const capacity = [
  { name: "2-5 passenger", price: "1223", key: "one-return" },
  { name: "6 passenger", price: "535", key: "two-return" },
];
const specifications = [
  { name: "Automatic", price: "2343", key: "non-departure" },
  { name: "All Wheel", price: "1223", key: "one-departure" },
];

export const RentalCardFilter = () => {
  return (
    <>
      <Flex
        direction="column"
        className="w-full p-6 bg-gray-200 rounded-lg shadow-lg"
      >
        <Text className="text-2xl font-semibold mb-4">Filter by</Text>
        
        <div className="mb-6">
          <Text className="text-lg font-medium mb-2">Location Type</Text>
          <Flex direction="column" className="gap-2">
            <Flex direction="row" justifyContent="space-between">
              <CheckboxField label="Airport" name="airport" value="Airport" />
              <Text className="text-gray-600">$74</Text>
            </Flex>
            <Flex direction="row" justifyContent="space-between">
              <CheckboxField label="Non-Airport" name="non-airport" value="Non-Airport" />
              <Text className="text-gray-600">$54</Text>
            </Flex>
          </Flex>
        </div>
        
        <div className="mb-6">
          <Text className="text-lg font-medium mb-2">Capacity</Text>
          <Flex direction="column" className="gap-2">
            {capacity.map((item, index) => (
              <Flex key={index} direction="row" justifyContent="space-between">
                <CheckboxField label={item.name} name={`capacity_${index}`} value={item.price} />
                <Text className="text-gray-600">${item.price}</Text>
              </Flex>
            ))}
          </Flex>
        </div>

        <div className="mb-6">
          <Text className="text-lg font-medium mb-2">Car Type</Text>
          <Flex direction="column" className="gap-2">
            {carType.map((item, index) => (
              <Flex key={index} direction="row" justifyContent="space-between">
                <CheckboxField label={item.name} name={`carType_${index}`} value={item.price} />
                <Text className="text-gray-600">${item.price}</Text>
              </Flex>
            ))}
          </Flex>
        </div>

        <div className="mb-6">
          <Text className="text-lg font-medium mb-2">Specifications</Text>
          <Flex direction="column" className="gap-2">
            {specifications.map((item, index) => (
              <Flex key={index} direction="row" justifyContent="space-between">
                <CheckboxField label={item.name} name={`specifications_${index}`} value={item.price} />
                <Text className="text-gray-600">${item.price}</Text>
              </Flex>
            ))}
          </Flex>
        </div>

        {/* <Button className="bg-blue-500 text-white px-4 py-2 rounded">Apply Filters</Button> */}
      </Flex>
    </>
  );
};
