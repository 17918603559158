// DataContext.tsx
import React, { createContext, useState, useContext, ReactNode } from 'react';
import {thinksToDoListData, museumsListData} from '../Components/helperFuntions/thinksTravel.helper'

interface TrainContextType {
}
const TrainContext = createContext<TrainContextType | undefined>(undefined);
export const useThingsSearchResult = () => {
    const context = useContext(TrainContext);
    if (!context) {
      throw new Error('useFlightSearchResult must be used within a DataProvider');
    }
    return context;
  };
export const TrainContextProvider:React.FC<{ children: ReactNode }> = ({ children }) =>{

  return (
    <TrainContext.Provider value={{ }}>
      {children}
    </TrainContext.Provider>
  );
};


