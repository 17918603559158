import React, { useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import TravelerModal from "./travelerModal";

const TravellerInfo = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  console.log("isModalOpen----->", isModalOpen);

  const handleModalOpen = () => {
    console.log("called in the true------------");
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    console.log("called here-----in-----");
    setIsModalOpen(false);
  };

  return (
    <>
      <div
        className="traveller-info"
        style={{
          border: "1px solid black",
          borderRadius: "15px",
          padding: "10px",
          cursor: "pointer",
          width:"20vw"
        }}
        onClick={handleModalOpen}
      >
        <p style={{ margin: 0 }}>Travellers</p>
        <div style={{ display: "flex", alignItems: "center" }}>
          <FaUserCircle size={24} style={{ marginRight: "5px" }} />
          <p style={{ margin: "0 0 0 5px" }}>2 room, 4 travelers</p>
        </div>
      </div>
      {isModalOpen && <TravelerModal handleModalClose={handleModalClose} />}
    </>
  );
};

export default TravellerInfo;
