import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { carData } from '../Constant/cirData';


const CarDetail = () => {
  const { id } = useParams();
  const car = carData.find((car, index) => index.toString() === id);
  const navigate = useNavigate();
  if (!car) {
    return <div>Car not found</div>;
  }
  const handleReserve = () => {
    navigate(`/booking/${id}`);
  };
  return (
    <div className="flex flex-col gap-4 md:flex-row items-center justify-center min-h-screen p-4 bg-gray-100">
    <div className="bg-white shadow-lg rounded-lg w-full md:w-1/2 p-4 mb-4 md:mb-0">
      <div className="flex flex-col items-center">
        <div
          className="w-full h-64 bg-cover bg-center rounded-lg mb-4"
          style={{ backgroundImage: `url(${car.image})` }}
        ></div>
        <h2 className="text-2xl font-bold mb-2">{car.name}</h2>
        <p className="text-gray-500">{`Seating Capacity: ${car.seatingCapacity}`}</p>
        <p className="text-gray-500">{`Type: ${car.type}`}</p>
        <p className="text-gray-500">{`Mileage: ${car.mileage}`}</p>
        <p className="text-gray-500">{`Price: ${car.price}`}</p>
      </div>
    </div>
    <div className="bg-white shadow-lg rounded-lg w-full md:w-1/4 p-4 flex flex-col items-center">
      <h3 className="text-xl font-bold mb-2">Extra Information</h3>
      <p className="text-gray-500 mb-2">Cancellation: Free cancellation</p>
      <p className="text-gray-500 mb-2">Online Check-in available</p>
      <p className="text-gray-500 mb-2">Pay at Pickup</p>
      <p className="text-gray-500 mb-2">Reserve with a card and save</p>
      <button className="bg-blue-500 text-white px-4 py-2 rounded mt-4" onClick={handleReserve}>Reserve</button>
    </div>
  </div>
  );
};

export default CarDetail;
