import { useEffect, useState } from "react";
import { airports } from "../Constant/airportData";
import { RentalCarSearchDetails, useRentalCarSearchResult } from "../context/rentalCarSearchDetails";
import { useNavigate } from "react-router-dom";
import { Autocomplete } from "@aws-amplify/ui-react";
const loadGoogleMapsScript = (callback: () => void) => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA96SrSSmQhUIevuhYcP0uuv5xjLjPxt2A&libraries=places&v=weekly`;
    script.defer = true;
    script.async = true;
    script.onload = callback;
    document.head.appendChild(script);
  };
  
  
export const RentalCar=()=>{
    const [typedText, setTypedText] = useState<string>("");
    const [dropoffText, setDropoffText] = useState<string>("");
    const [pickupPoint, setPickupPoint] = useState('');
    const [droppingPoint, setDroppingPoint] = useState('');
    const [pickupDate, setPickupDate] = useState('');
    const [dropoffDate, setDropoffDate] = useState('');
    const [pickupTime, setPickupTime] = useState('');
    const [dropoffTime, setDropoffTime] = useState('');
    const {rentalCarDetails, setRentalCarDetails,setRentalCarData}=useRentalCarSearchResult()
  const [selectedAddress, setSelectedAddress] = useState<string>("");
  const [addressError, setAddressError] = useState<boolean>(false);

  
  const navigate = useNavigate();
  //  const rentalCarDetailsSearch:RentalCarSearchDetails={
  //   pickUp: pickupPoint,
  //   dropOff: droppingPoint,
  //   pickupDate: pickupDate,
  //   dropOffDate: dropoffDate,
  //   pickupTime: pickupTime,
  //   dropOffTime: dropoffTime
  //  }
   
   const RentalCarPayload: RentalCarSearchDetails = {
    airportCode: pickupPoint,
    pickupDateTime: `${pickupDate}T${pickupTime}`,
    dropOffDateTime: `${dropoffDate}T${dropoffTime}`
  };
   const handleSubmit = async(e:any) => {
    e.preventDefault();
    // setRentalCarDetails(rentalCarDetailsSearch)
    setRentalCarDetails(RentalCarPayload)
    console.log(rentalCarDetails, ':rental');
    const response = await fetch('/rentalCarData.json'); 
         const data = await response.json();
         setRentalCarData(data)
     navigate('/rentalcar')
  };
  
   console.log(rentalCarDetails,":rentalCarDetails")
    const timeSlots = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minutes = 0; minutes < 60; minutes += 30) {
        const time = `${hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
        timeSlots.push(time);
      }
    }
    useEffect(() => {
        loadGoogleMapsScript(() => {
          const pickupAutocomplete = new window.google.maps.places.Autocomplete(
            document.getElementById("pickup-address") as HTMLInputElement,
            {
              componentRestrictions: { country: ["us", "ca", "in"] },
              fields: ["address_components", "formatted_address"],
              types: ["address"],
            }
          );
    
          pickupAutocomplete.addListener("place_changed", () => {
            const place = pickupAutocomplete.getPlace();
            setPickupPoint(place?.formatted_address || "");
          });
    
          const dropoffAutocomplete = new window.google.maps.places.Autocomplete(
            document.getElementById("dropoff-address") as HTMLInputElement,
            {
              componentRestrictions: { country: ["us", "ca", "in"] },
              fields: ["address_components", "formatted_address"],
              types: ["address"],
            }
          );
    
          dropoffAutocomplete.addListener("place_changed", () => {
            const place = dropoffAutocomplete.getPlace();
            setDroppingPoint(place?.formatted_address || "");
          });
    
        });
      }, []);
      const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTypedText(event.target.value);
        setAddressError(false);
      };
    
      const handleInputBlur = () => {
        if (!pickupPoint) {
          setTypedText("");
        }
      };
      const handleDroppingPointChange=(event: React.ChangeEvent<HTMLInputElement>)=>{
        setDropoffText(event.target.value);
      }
      const handleDroppingPointBlur=()=>{
        if (!droppingPoint) {
          setDropoffText("");
          }
      }



      const onChangeFrom = (event: { target: { value: any } }) => {
        if (pickupPoint == event.target.value) {
          return;
        }
        setPickupPoint(event?.target.value);
      };
      const onClearFrom = () => {
        setPickupPoint("");
      };
      const onSelectFrom = (option: { label: string; id: string }) => {
        const { label, id } = option;
        setPickupPoint(id);
      };

      const onChangeTo = (event: { target: { value: any } }) => {
        if (pickupPoint == event.target.value) {
          return;
        }
        setDroppingPoint(event?.target.value);
      };
      const onClearTo = () => {
        setDroppingPoint("");
      };
      const onSelectTo = (option: { label: string; id: string }) => {
        const { label, id } = option;
        setDroppingPoint(id);
      };
    return (
        <>
         {/* <div className="bg-gray-100 w-screen items-center"> */}
        
        <div className="bg-white py-8 px-12 rounded shadow-md md:w-11/12 justify-center w-lg mx-auto my-8">
      <form className="space-y-4 md:flex items-end justify-between gap-4" onSubmit={handleSubmit} >
          {/* <div>
          <label className="block text-sm font-medium text-gray-700">Pickup Point</label>
          <input
          id="pickup-address"
          name="pickup-address"
          required
          autoComplete="off"
          value={pickupPoint ? pickupPoint : typedText}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
           </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Dropping Point</label>
            <input
            id="dropoff-address"
            name="dropoff-address"
            required
            autoComplete="off"
            value={droppingPoint ? droppingPoint : dropoffText}
            onChange={handleDroppingPointChange}
            onBlur={handleDroppingPointBlur}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          </div> */}

              <Autocomplete
                label="Autocomplete"
                className="flex-value rounded-lg"
              
                size="large"
                width={"12rem"}
                value={pickupPoint}
                data-testid="from-roundtrip"
                onChange={onChangeFrom}
                onClear={onClearFrom}
                onSelect={onSelectFrom}
                options={airports.map((airport) => ({
                  id: airport.id,
                  label: airport.label,
                }))}
                placeholder="from."
              />
       
      

      <Autocomplete
                label="Autocomplete"
                className="flex-value rounded-lg"
              
                size="large"
                width={"12rem"}
                value={droppingPoint}
                data-testid="from-roundtrip"
                onChange={onChangeTo}
                onClear={onClearTo}
                onSelect={onSelectTo}
                options={airports.map((airport) => ({
                  id: airport.id,
                  label: airport.label,
                }))}
                placeholder="from."
              />

          <div>
            <label className="block text-sm font-medium text-gray-700">Pickup Date</label>
            <input type="date" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            value={pickupDate}
            onChange={(e) => setPickupDate(e.target.value)}
            />
          </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Pickup Time</label>
          <select className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
           value={pickupTime}
           onChange={(e) => setPickupTime(e.target.value)}
          >
            {timeSlots.map((time, index) => (
              <option key={index} value={time}>{time}</option>
            ))}
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Dropoff Date</label>
          <input type="date" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" 
           value={dropoffDate}
           onChange={(e) => setDropoffDate(e.target.value)}
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Dropoff Time</label>
          <select className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          value={dropoffTime}
          onChange={(e) => setDropoffTime(e.target.value)}
          >
            {timeSlots.map((time, index) => (
              <option key={index} value={time}>{time}</option>
            ))}
          </select>
        </div>
        <div>
          <button type="submit" className="w-full bg-indigo-600 text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" >
            Search
          </button>
        </div>
      </form>
    </div>
    {/* </div> */}
        </>
    )
}