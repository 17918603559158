import React, { createContext, useState, useContext, ReactNode } from 'react';
import { PaymentData,PaymentContextType } from '../types/paymentdata';

const PaymentContext = createContext<PaymentContextType | undefined>(undefined);

// Create the provider component
export const PaymentProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [paymentData, setPaymentData] = useState<PaymentData>({
    cardName: '',
    cardNumber: '',
    cvv: '',
    cardExpiry: '',
    fullName: '',
    addressOne: '',
    addressTwo: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    email: '',
    phoneNumber: '',
    optionalEmail: '',
    optionalPhoneNumber: '',
  });

  return (
    <PaymentContext.Provider value={{ paymentData, setPaymentData }}>
      {children}
    </PaymentContext.Provider>
  );
};

// Custom hook to use the booking context
export const usePayment = () => {
  const context = useContext(PaymentContext);
  if (!context) {
    throw new Error('usePayment must be used within a PaymentProvider');
  }
  return context;
};
