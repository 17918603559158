export const trainData = [
    {
      departure: "08:19",
      arrival: "09:20",
      duration: "14h 31m",
      price: "$50",
      passengers: 3,
      seatReservation: true,
    },
    {
      departure: "10:00",
      arrival: "12:30",
      duration: "2h 30m",
      price: "$30",
      passengers: 2,
      seatReservation: true,
    },
    {
      departure: "13:45",
      arrival: "15:15",
      duration: "1h 30m",
      price: "$25",
      passengers: 1,
      seatReservation: false,
    },
    {
      departure: "16:00",
      arrival: "18:30",
      duration: "2h 30m",
      price: "$35",
      passengers: 4,
      seatReservation: true,
    },
    {
      departure: "19:00",
      arrival: "21:30",
      duration: "2h 30m",
      price: "$40",
      passengers: 2,
      seatReservation: true,
    },
    {
      departure: "22:00",
      arrival: "23:30",
      duration: "1h 30m",
      price: "$20",
      passengers: 3,
      seatReservation: false,
    },
  ];

  export const trainDetails = [
    {
      time: "08:19",
      station: "BARCELONA-SANTS (Spain)",
      details: [
        { type: "text", content: "AVI 9737" },
        { type: "text", content: "AVE International" },
        { type: "text", content: "Direction", bold: true },
        { type: "text", content: "LYON PART DIEU (France)" },
        { type: "info", content: "Seat reservations required", background: "bg-blue-200" },
        { type: "info", content: "You need to buy a seat reservation to board.", background: "bg-blue-200" },
        { type: "class", classType: "1st class seat", price: "From USD $42" },
        { type: "class", classType: "2nd class seat", price: "From USD $32.40" },
      ],
    },
    {
      time: "22:50",
      station: "LONDON ST PANCRAS (UK)",
    },
    {
      time: "08:19",
      station: "BARCELONA-SANTS (Spain)",
      details: [
        { type: "text", content: "AVI 9737" },
        { type: "text", content: "AVE International" },
        { type: "text", content: "Direction", bold: true },
        { type: "text", content: "LYON PART DIEU (France)" },
        { type: "info", content: "Seat reservations required", background: "bg-blue-200" },
        { type: "info", content: "You need to buy a seat reservation to board.", background: "bg-blue-200" },
        { type: "class", classType: "1st class seat", price: "From USD $42" },
        { type: "class", classType: "2nd class seat", price: "From USD $32.40" },
      ],
    },
    {
      time: "22:50",
      station: "LONDON ST PANCRAS (UK)",
    },{
      time: "08:19",
      station: "BARCELONA-SANTS (Spain)",
      details: [
        { type: "text", content: "AVI 9737" },
        { type: "text", content: "AVE International" },
        { type: "text", content: "Direction", bold: true },
        { type: "text", content: "LYON PART DIEU (France)" },
        { type: "info", content: "Seat reservations required", background: "bg-blue-200" },
        { type: "info", content: "You need to buy a seat reservation to board.", background: "bg-blue-200" },
        { type: "class", classType: "1st class seat", price: "From USD $42" },
        { type: "class", classType: "2nd class seat", price: "From USD $32.40" },
      ],
    },
    {
      time: "22:50",
      station: "LONDON ST PANCRAS (UK)",
    },
    {
      time: "13:20",
      station: "LYON PART DIEU (France)",
      details: [
        { type: "text", content: "TGV 5312" },
        { type: "text", content: "TGV" },
        { type: "text", content: "Direction", bold: true },
        { type: "text", content: "PARIS GARE DE LYON (France)" },
        { type: "info", content: "Seat reservations required", background: "bg-blue-200" },
        { type: "info", content: "You need to buy a seat reservation to board.", background: "bg-blue-200" },
        { type: "class", classType: "1st class seat", price: "From USD $35" },
        { type: "class", classType: "2nd class seat", price: "From USD $25" },
      ],
    },
    {
      time: "22:50",
      station: "LONDON ST PANCRAS (UK)",
    },
    {
      time: "16:45",
      station: "PARIS GARE DE LYON (France)",
      details: [
        { type: "text", content: "EUROSTAR 9024" },
        { type: "text", content: "EUROSTAR" },
        { type: "text", content: "Direction", bold: true },
        { type: "text", content: "LONDON ST PANCRAS (UK)" },
        { type: "info", content: "Seat reservations required", background: "bg-blue-200" },
        { type: "info", content: "You need to buy a seat reservation to board.", background: "bg-blue-200" },
        { type: "class", classType: "1st class seat", price: "From USD $55" },
        { type: "class", classType: "2nd class seat", price: "From USD $45" },
      ],
    },
    {
      time: "22:50",
      station: "LONDON ST PANCRAS (UK)",
    },
  ];


  export const hourArray = [0,1,2,3,4,5,6,7,8,9,10,11];

  export const minutesArray = [1,2,3,4,5,6,7,8,9,10,11,12];