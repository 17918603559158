import React from 'react';

interface ReviewBoxProps {
    rating: number;
    review: string;
    author: string;
    date: string;
  }
const ReviewBox: React.FC<ReviewBoxProps> = ({ rating, review, author, date }) =>{
  return (
    // <div className="border-gray-200 border-4 p-4 max-w-md mx-auto my-4">
    //   <div className="flex items-center mb-2">
    //     <span className="text-xl font-bold mr-2">{rating}/5</span>
    //     <span className="text-gray-600">{review}</span>
    //   </div>
    //   <a href="#" className="text-blue-500 hover:underline mb-2 inline-block">Read more</a>
    //   <div className="text-gray-500">
    //     <p>{author}</p>
    //     <p>{date}</p>
    //   </div>
    // </div>

    <div className="border-gray-200 border-4 p-4 max-w-md mx-auto my-4 rounded-lg shadow-lg">
      <div className="flex items-center mb-2">
        <span className="text-xl font-bold mr-2">{rating}/5</span>
        <span className="text-gray-600">{review}</span>
      </div>
      <a href="#" className="text-blue-500 hover:underline mb-2 inline-block">Read more</a>
      <div className="text-gray-500">
        <p>{author}</p>
        <p>{date}</p>
      </div>
    </div>
  );
};

export default ReviewBox;
