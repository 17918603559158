import React, { useState } from "react";
import { Flex } from "@aws-amplify/ui-react";
import { HotelImagesComponent } from "./hotelImagesComponent";
import "./hotelDetails.css";
import { HotelRating } from "./hotelRating";
import {
  FaSpa,
  FaParking,
  FaHome,
  FaUtensils,
  FaGlassMartini,
  FaSmoking,
} from "react-icons/fa";

export const HotelDiscription = () => {
  return (
    <div style={{ width: "78vw", margin: "auto", textAlign: "left" }}>
      <h1 style={{ margin: "0",fontSize:"2.25rem",fontWeight:"500",lineHeight:"2.5rem" }}>Waldorf Astoria Cancun</h1>
      <HotelRating rating={4.5} />
      <div style={{ display: "flex" }}>
        <span
          style={{
            color: "white",
            fontWeight: "bold",
            backgroundColor: "green",
            borderRadius: "5px",
            padding: "5px",
          }}
        >
          9.0
        </span>
        <p style={{ marginTop: "0", marginBottom: "0", marginLeft: "10px" }}>
          Wonderful
        </p>
      </div>
      <p>Luxury resort on the beach, with 2 outdoor swimming pools</p>

      <h2 style={{ marginBottom: "0",fontSize:"1.75rem",fontWeight:"500",lineHeight:"2rem" }}>Popular amenities</h2>
      <div className="amenities-container">
        <div className="row">
          <div className="amenity">
            <FaSpa size={30} />
            <p>Spa</p>
          </div>
          <div className="amenity">
            <FaParking size={30} />
            <p>Parking</p>
          </div>
          <div className="amenity">
            <FaHome size={30} />
            <p>Housing</p>
          </div>
        </div>
        <div className="row">
          <div className="amenity">
            <FaUtensils size={30} />
            <p>Room Service</p>
          </div>
          <div className="amenity">
            <FaGlassMartini size={30} />
            <p>Bar</p>
          </div>
          <div className="amenity">
            <FaSmoking size={30} />
            <p>Smoking Room</p>
          </div>
        </div>
      </div>
    </div>
  );
};
