// This Page Shows the Detail of the Search Flight.

import { Card,Divider,Flex,Text, Image } from '@aws-amplify/ui-react'
import { usePopperContext } from '../context/popperContext';
import { useFlightTypeContext } from '../context/flightTypeContext';
import { useSearch } from '../context/searchFlight';
import { FormatMonth } from '../Constant/monthFormat';
import { useTripType } from '../context/tripType';
export const Flightdetails = () => {
  const { searchDetails } = useSearch();
  const from =  searchDetails?.fromAirport; 
  const to = searchDetails?.toAirport;
  // const tripType = searchDetails?.typeOfTrip;
  const departureDate = searchDetails?.departureDate;
  const returnDate = searchDetails?.returnDate;
  const { tripType } = useTripType();

const { popperValue } = usePopperContext();
const {flightType} =useFlightTypeContext()

  const styless={
    Card: {
      width: '800px',
      backgroundColor: 'rgba(255,255,255,0.48)',
      borderRadius: '24px',
      boxShadow: '0px 2px 10px rgba(3,3,3,0.1)',
      fontSize: '1.5rem'
    },

  }
  return (
   <>
   <div className='flight-details'>

   <Flex direction={'column'} justifyContent={'center'} alignItems={'center'}>

      <Card  style={styless.Card} borderRadius={'16px'} borderStyle={'solid'} borderWidth={'4px'} textAlign={'center'} fontSize={'1.5rem'}>
        <Text>Please wait while we are checking availability and comparing the best fares across our global suppliers.</Text>
      
        <Divider  />
        <Flex direction={'row'} justifyContent={'space-evenly'}>
          <Flex direction={'column'}>
            <Text color={'blue'} fontSize={'2.5rem'}> 
              {from}  
            </Text>
            <Text fontSize={'1.5rem'}> {FormatMonth(departureDate)} </Text>
            <Text>
          
              {from}    
            </Text>
          </Flex>
          <Image
          src="/flight_loading.gif"
          width={'350px'}
          alt="flight loading"
        />
          <Flex direction={'column'}>
            <Text color={'blue'} fontSize={'2.5rem'}>
            {to}   
            </Text>
            <Text fontSize={'1.5rem'}>
              {tripType==="one-way" ? FormatMonth(departureDate):FormatMonth(returnDate)}
              </Text>
            <Text>
              {to}   
            </Text>
          </Flex>
        </Flex>
        <Divider  />
        <Text fontSize={'1.5rem'}>
          {`${tripType}, ${flightType}, ${ popperValue}`}
          </Text>
      </Card>
   </Flex>
   </div>
   
   </>
  )
}
