import { Flex, Text, Badge, Image, Divider, ScrollView } from '@aws-amplify/ui-react'
import { FaRegClock } from 'react-icons/fa'
import { FaShoppingBag } from 'react-icons/fa'
import { FormatMonth } from '../Constant/monthFormat'
import { convertTo24Hour } from '../Constant/timeUtils'
import { formatTime } from '../pages/helperFunctions/flightList.helper'
import { calculateTimeDifference } from './helperFuntions/flightInfo.helper'
const Info = (props: any) => {

  const styles = {
    Container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
    },
    Card: {
      top: '102px',
      left: '64px',
      width: '912px',
      // height: '230px',
      backgroundColor: 'rgba(255,255,255,0.48)',
      borderRadius: '24px',
      boxShadow: '0px 2px 10px rgba(3,3,3,0.1)',
    },
    InnerCard: {
      width: '912px',
      height: '60px',
      backgroundColor: '#f0f0f0',
      borderRadius: '24px',
      margin: '-16px'
    },
    ImageContainer: {
      top: '230px',
      left: '104px',
      width: '70px',
      height: '70px',
      borderRadius: '8px',
      backgroundImage: 'url(./image.png)',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
    FlightInfo: {
      color: '#030303',
      fontSize: '16px',
      fontFamily: 'Montserrat',
      lineHeight: '32px',
      fontWeight: 500,
    },
    FlightName: {
      color: '#030303',
      fontSize: '20px',
      fontFamily: 'Montserrat',
      lineHeight: '12px',
      fontWeight: 600,
    },
    FlightDate: {
      color: '#030303',
      fontSize: '24px',
      fontFamily: 'Montserrat',
      fontWeight: 700,
      lineHeight: '31px',
    },
    Boarding: {
      color: '#030303',
      fontSize: '20px',
      fontFamily: 'Montserrat',
      lineHeight: '26px',
    },
    Text: {
      color: '#030303',
      fontSize: '16px',
      fontFamily: 'Montserrat',
      fontWeight: 500,
      lineHeight: '40px',
      // textAlign: 'center',
    },
    ParaText: {
      color: '#030303',
      fontSize: '12px',
      fontFamily: 'Montserrat',
      lineHeight: '26px',
      fontWeight: 400,
      // textAlign: 'center',
    },
  }
  return (
    <>
      {
        props.brand.map((flight: any, index: string) => (
          <>

            <Flex direction={'row'} justifyContent={'space-between'} marginInline={'1.5rem'}>
              <Flex direction={'column'} gap={'8px'} width={'10%'}>
               
                 <Image src={`https://pics.avs.io/240/80/${flight?.operatingCarrier}.png `}
                      alt="flight picture"
                     
                      objectFit="initial"
                    />
              
                <Text style={styles.Text}> {flight?.operatingCarrier} </Text>
                <Text style={styles.Text}>{flight?.flightNumber}</Text>
              </Flex>
              <Flex>
                <Divider orientation={'vertical'} />
                <Flex direction={'column'} justifyContent={'space-between'}>
                  <Flex direction={'column'} gap={'6px'}>
                    <Text style={styles.FlightName} > {formatTime(flight?.timeOfDeparture)}</Text>
                    <Text style={styles.FlightInfo}> {FormatMonth(flight?.dateOfDeparture)} </Text>
                  </Flex>
                  <Text style={styles.Text}><span style={{ paddingBlock: '8px' }}> <FaRegClock width={24} height={24} /></span>  {calculateTimeDifference(flight.dateOfDeparture,flight.dateOfArrival,flight.timeOfDeparture,flight.timeOfArrival)} </Text>
                  <Flex direction={'column'} gap={'6px'}>
                    <Text style={styles.FlightName} > {formatTime(flight?.timeOfArrival)}</Text>
                    <Text style={styles.FlightInfo}> {FormatMonth(flight?.dateOfArrival)} </Text>
                  </Flex>
                </Flex>
              </Flex>

              <Flex direction={'column'} justifyContent={'space-between'} >
                <Flex direction={'column'} gap={'6px'}>
                  <Text style={styles.FlightName}> {flight?.fromAirport}</Text>
                  <Text style={styles.FlightInfo}> {flight?.fromAirport} </Text>
                  <Text style={styles.Text} marginBlock={'6px'}>Terminal {flight?.toAirportTerminal || flight?.toAirport}</Text>
                </Flex>
                <Flex direction={'column'} gap={'6px'}>
                  <Text style={styles.FlightName} >{flight?.toAirport}</Text>
                  <Text style={styles.FlightInfo}> {flight?.toAirport} </Text>
                </Flex>

              </Flex>
              <Flex direction={'column'} gap={'8px'}>
                <Text style={styles.ParaText} > <FaShoppingBag width={32} color='black' height={32} /> Cabin Baggage 7kg</Text>
                <Text style={styles.ParaText}>Baggage 30kg </Text>
                <Text style={styles.ParaText} > Refundable</Text>
                <Text style={styles.ParaText}> Reschedule </Text>
              </Flex>
            </Flex>
            <Flex direction={'flex'} justifyContent={'end'} width={'80%'}>
              <Text marginBlock={'1.5rem'} alignSelf={'end'} textAlign={'start'} paddingInline={'1rem'} paddingBlock={'0.7rem'} color={'white'}></Text>
            </Flex>
          </>
        ))}
    </>
  )
}

export const FlightInfo = ({ recommendation }: any) => {

  return (
    <>
      <ScrollView width="100%" height="300px" maxWidth="99%">
        <ul>
          {
            <>
              <Info brand={recommendation?.onwardSegment?.legs} />
              <h1>return</h1>
              <Info brand={recommendation?.returnSegment?.legs} />
            </>
          }
        </ul>
      </ScrollView>

    </>
  )
}