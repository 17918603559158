
import { Amplify} from 'aws-amplify';
import { useState } from 'react';
// import {Predictions } from '@aws-amplify/predictions';
import { downloadData, uploadData } from 'aws-amplify/storage';
import { getProperties } from 'aws-amplify/storage';
import amplifyconfig from '../amplifyconfiguration.json';
import { list , getUrl} from 'aws-amplify/storage';
import { useAuthenticator } from '@aws-amplify/ui-react';


Amplify.configure(amplifyconfig);




export const BucketStorage = () => {
    const [file, setFile] = useState<File | null>(null);
  const { user} = useAuthenticator((context) => [context.user]);

    const handleFileChange = (e:any) => {
        setFile(e.target.files[0]);
    };
    const Res= async()=>{
        if(!file) return;
        const { body, eTag } = await downloadData({
            path:  `public/${file.name}`,
            // Alternatively, path: ({identityId}) => `protected/${identityId}/album/2024/1.jpg`
            options: {
              onProgress: (event) => {
                console.log(event.transferredBytes);
              },
              bytesRange: {
                start: 1024,
                end: 2048
              } 
            }
          }).result;
          console.log(body, eTag);
    }
  
   
    const Download=async()=>{
        if(!file) return;
        try {
    const getUrlResult = await getUrl({
        key: file.name,
      });
      console.log(getUrlResult, 'get');
     }
      catch (error) {
        console.log(error);
      }
    
}
 const List=async()=>{
    try {
        const result = await list({
          prefix: 'public/'
        });
        console.log(result);
      } catch (error) {
        console.log(error);
      }
 }

    const updateFile= async()=>{
        if(!file) return;
        // try {
        //     const result = await getProperties({
        //     //   path: file.name,
        //       path: '/public/*'
        //     });
        //     console.log('File Properties ', result);
        //   } catch (error) {
        //     console.log('Error ', error);
        //   }

        
    }
    const protectedUploadDataInBrowser =  async(event:any) => {
        
      if (file) { 
          console.log(file); 
          try {
              uploadData({
                  path: ({identityId}) => `protected/${identityId}/${file.name}`,
                  data: file
              });
              console.log('File uploaded successfully');
          } catch (error) {
              console.error('Error uploading file:', error);
          }
      } else {
          console.log('No file selected');
      }

     
  };

    const privateUploadDataInBrowser =  async(event:any) => {
        
        if (file) { 
            console.log(file); 
            try {
                uploadData({
                   
                    path: ({identityId}) => `private/${identityId}/${file.name}`,
                    data: file
                });
                console.log('File uploaded successfully');
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        } else {
            console.log('No file selected');
        }

       
    };

    const publicUploadDataInBrowser =  async(event:any) => {
        
      if (file) { 
          console.log(file); 
          try {
              uploadData({
                   path: `public/${file.name}`,
                 
                  data: file
              });
              console.log('File uploaded successfully');
          } catch (error) {
              console.error('Error uploading file:', error);
          }
      } else {
          console.log('No file selected');
      }

      // try {
      //   console.log(user)
      //     const result = await getProperties({
      //        path: ({identityId}) => `private/${identityId}/${file.name}`,
             
      //     });
      //     console.log('File Properties ', result);
      //   } catch (error) {
      //     console.log('Error ', error);
      //   }
      //  }
  };

    return (
    <>
    <div>
      <div>
      
<input type="file" onChange={handleFileChange} />

<button onClick={publicUploadDataInBrowser}>UploadPublic</button>
<button onClick={privateUploadDataInBrowser}>UploadPrivate</button>
<button onClick={protectedUploadDataInBrowser}>UploadProtected</button>

<button onClick={List}>List</button>
<button onClick={Download}>Download</button>

<button onClick={Res}>Download</button>
      </div>
    </div>
    </>
  )
}
