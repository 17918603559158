export const SavedHotelsData = [
    {
      id: 1,
      hotelName: "Luxury Resort & Spa",
      reviewScore: 4.5,
      pricePerNight: "$200",
      location: "Beachfront Paradise",
      fromDate: "10 May",
      toDate: "15 May",
      comments:[]
    },
    {
      id: 2,
      hotelName: "City Lights Hotel",
      reviewScore: 4.2,
      pricePerNight: "$150",
      location: "Downtown City Center",
      fromDate: "12 May",
      toDate: "17 May",
      comments:[]
    },
    {
      id: 3,
      hotelName: "Mountain Lodge Retreat",
      reviewScore: 4.8,
      pricePerNight: "$180",
      location: "Scenic Mountain Views",
      fromDate: "08 May",
      toDate: "13 May",
      comments:[]
    },
    {
      id: 4,
      hotelName: "Riverside Inn",
      reviewScore: 4.3,
      pricePerNight: "$120",
      location: "Tranquil Riverside",
      fromDate: "14 May",
      toDate: "19 May",
      comments:[]
    },
    {
      id: 5,
      hotelName: "Cozy Cottage Retreat",
      reviewScore: 4.6,
      pricePerNight: "$100",
      location: "Countryside Serenity",
      fromDate: "11 May",
      toDate: "16 May",
      comments:[]
    },
    // Add more data as needed
  ];
  
   