import {
  Button,
  View,
  Card,
  Flex,
  Text,
  Image,
  Input,
} from "@aws-amplify/ui-react";
import {  useNavigate } from 'react-router-dom';
import React, { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdInsertComment } from "react-icons/md";
import { GrDislike, GrLike } from "react-icons/gr";
import { PiHeartLight, PiHeartStraightFill } from "react-icons/pi";
import { SavedHotelsData } from "../Constant/savedHotels";
import Buttons from "./Buttons";
const HotelList = ({
  keyValue: key,
  hotel,
  setHoltesState,
  hotelsState,
  toggleSidebar,
  showSidebar,
}: any) => {
  const navigate=useNavigate();
  console.log("key----->,", key);
  const style = {
    Card: {
      top: "102px",
      left: "64px",
      width: "912px",
      // height: '200px',
      backgroundColor: "rgba(255,255,255,0.48)",
      borderRadius: "44px",
      boxShadow: "10px 2px 10px rgba(3,3,3,0.1)",
      cursor:"pointer"
    },
    Sidebar: {
      width: "300px",
      padding: "1rem",
      backgroundColor: "#F7F7F7",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    },
    InnerCard: {
      width: "912px",
      // height: '60px',
      backgroundColor: "#f0f0f0",
      borderRadius: "24px",
      margin: "-16px",
    },
    ImageContainer: {
      top: "230px",
      left: "104px",
      // width: '309px',
      // height: '200px',
      width: "70px",
      height: "70px",
      borderRadius: "8px",
      backgroundImage: "url(./image.png)",
      backgroundPosition: "center center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
    FlightName: {
      color: "#030303",
      fontSize: "24px",
      fontFamily: "Montserrat",
      lineHeight: "32px",
    },
    Text: {
      color: "#030303",
      fontSize: "16px",
      fontFamily: "Montserrat",
      lineHeight: "24px",
    },
    ImageCard: {
      width: "312px",
      height: "190px",
      backgroundColor: "rgba(255,255,255,0.48)",
      borderRadius: "24px",
      boxShadow: "0px 2px 10px rgba(3,3,3,0.1)",
      backgroundImage: `url(/airline.jpeg)`,

      backgroundSize: "cover", // Cover the entire container with th
    },
  };

  const [comment, setComment] = useState("");
  const handleChangeComment = (e: any) => {
    setComment(e.target.value);
  };

  const handleSubmitComment = () => {
    const newCommentData = hotelsState.map((data: any) => {
      if (data.id === key) {
        return { ...data, comments: [...data.comments, comment] };
      }
      return data;
    });
    setHoltesState(newCommentData);
    setComment("");
  };
  const [savedPlaced, setSavedPlaced] = useState(true);
  const [likes, setLikes] = useState(0);
  const [likePlaced, setLikePlaced] = useState(false);
  const handleLiked = () => {
    setLikePlaced(!likePlaced);
    setLikes(likes - 1);
  };
  const handleDisLiked = () => {
    setLikePlaced(!likePlaced);
    setLikes(likes + 1);
  };
  const handleSaved = () => {
    setSavedPlaced(!savedPlaced);
  };

  return (
    <Flex>
      <Card style={style.Card} width={"100%"} onClick={()=>{
        console.log("called in the hotel-----------")
        navigate("/hotelDetails")
      }}>
        <Flex width={"100%"}>
          <Flex width={"30%"} style={style.ImageCard}></Flex>
          <Flex direction={"column"} width={"70%"} gap={"0.3rem"}>
            <Flex direction={"row"} justifyContent={"space-between"}>
              <Flex>
                {savedPlaced ? (
                  <PiHeartStraightFill
                    color="pink"
                    size={24}
                    onClick={handleSaved}
                  />
                ) : (
                  <PiHeartLight size={24} onClick={handleSaved} />
                )}
                <Text>Saved</Text>
              </Flex>
              <BsThreeDotsVertical />
            </Flex>
            <Text
              style={style.FlightName}
              textAlign={"left"}
            >{`${hotel.hotelName} ${hotel.location}`}</Text>
            <Text style={style.Text} textAlign={"left"}>
              {hotel.reviewScore} (641 review)
            </Text>
            <Text
              style={style.Text}
              textAlign={"left"}
            >{`${hotel.fromDate} ${hotel.toDate}`}</Text>
            <Flex direction={"row"} justifyContent={"space-between"}>
              <Flex direction={"row"} gap={"2.3rem"} alignItems={"center"}>
                <Flex gap={"0.3rem"}>
                  {likePlaced ? (
                    <GrLike color="skyblue" size={24} onClick={handleLiked} />
                  ) : (
                    <GrLike size={24} onClick={handleDisLiked} />
                  )}
                  {likes}
                </Flex>
                <MdInsertComment
                  size={24}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    toggleSidebar(hotel.id);
                  }}
                />
              </Flex>
              <Flex direction={"column"} gap={"0.1rem"}>
                <Text style={style.Text}>{hotel.pricePerNight}</Text>
                <Text style={style.Text}>Per Night</Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Card>
      {showSidebar === hotel.id && (
        <View
          style={{
            ...style.Sidebar,
            position: "absolute",
            top: 0,
            right: 0,
            zIndex: "5",
          }}
          width={"25%"}
        >
          <Flex
            direction="column"
            height={"660px"}
            style={{ overflowY: "auto" }}
          >
            {hotel.comments.map((comment: any, index: number) => (
              <Text textAlign={"start"} key={index}>
                {comment}
              </Text>
            ))}
            <p
              onClick={() => toggleSidebar("")}
              style={{
                position: "absolute",
                left: "95%",
                cursor: "pointer",
                border: "1px solid",
                padding: "3px",
              }}
            >
              X
            </p>
          </Flex>

          <Flex style={{ position: "fixed", width: "25%", bottom: "1rem" }}>
            <Input
              value={comment}
              onChange={handleChangeComment}
              placeholder="Add your comment"
            />
            <Buttons onClick={handleSubmitComment} Text="+" />
          </Flex>
        </View>
      )}
    </Flex>
  );
};
export const SavedHotels = () => {
  const style = {
    Sidebar: {
      width: "300px",
      padding: "1rem",
      backgroundColor: "#F7F7F7",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    },
  };
  const [hotelsState, setHoltesState] = useState([...SavedHotelsData]);
  const [searchHotelInput, setSearchHotelInput] = useState("");

  const [showSidebar, setShowSidebar] = useState("");
  const toggleSidebar = (index: any) => {
    setShowSidebar(index);
  };

  const handleSearch =() =>{
    alert(searchHotelInput);
    console.log("searchHotelInputsearchHotelInput------>",searchHotelInput);
    setSearchHotelInput("");
  }

  return (
    <>
      <Flex
        direction={"column"}
        justifyContent={"space-between"}
        width={"100%"}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            width: "50%",
          }}
        >
          <input
            type="text"
            onChange={(e) => setSearchHotelInput(e.target.value)}
            value={searchHotelInput}
            placeholder="type Hotel name..."
            style={{
              flex: 1,
              padding: "10px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              marginRight: "10px",
              marginLeft:"10px",
              fontSize: "16px",
            }}
          />
          <button
            style={{
              padding: "10px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              fontSize: "16px",
              cursor:"pointer"
            }}
            onClick={handleSearch}
          >
            Search
          </button>
        </div>

        <Flex
          direction={"column"}
          gap={"1.7rem"}
          // width={"60%"}
          style={{ height: "100vh", overflowY: "auto", scrollbarWidth: "none" }}
          className= 'md:w-7/12 w-full'
        >
          {hotelsState.map((hotel) => {
            console.log("hotel------->,", hotel);
            return (
              <HotelList
                keyValue={hotel.id}
                showSidebar={showSidebar}
                setHoltesState={setHoltesState}
                toggleSidebar={toggleSidebar}
                hotelsState={hotelsState}
                hotel={hotel}
              />
            );
          })}
        </Flex>
      </Flex>
    </>
  );
};
