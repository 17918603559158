const styles = {
    ImageContainer: {
      top: '534px',
      left: '64px',
      width: '316px',
      height: '352px',
      borderRadius: '24px',
      backgroundImage: 'url(./image.jpeg)',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
  };
export const FlightDestination = [
    {
      imageUrl: 'https://images.unsplash.com/photo-1629630331106-18c4c2aff510?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDUzMDJ8MHwxfHNlYXJjaHw2fHxBaXJjcmFmdCUyQyUyMFJ1bndheSUyQyUyMFRlcm1pbmFsfGVufDF8fHx8MTcwOTE3MjYwMnww&ixlib=rb-4.0.3&q=80&w=1080',
      style: styles.ImageContainer
    },
    {
      imageUrl: 'https://images.unsplash.com/photo-1483247416020-58799b6de4c1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDUzMDJ8MHwxfHNlYXJjaHwxfHxQYXNzcG9ydCUyQyUyMEdsb2JlJTJDJTIwSm91cm5leXxlbnwxfHx8fDE3MDkxNzI2MDJ8MA&ixlib=rb-4.0.3&q=80&w=1080',
      style: styles.ImageContainer
    },
    {
      imageUrl: 'https://images.unsplash.com/photo-1517760307355-e48f68215de6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDUzMDJ8MHwxfHNlYXJjaHwyfHxCZWFjaCUyQyUyMElzbGFuZCUyQyUyMFJlbGF4YXRpb258ZW58MXx8fHwxNzA5MTcyNjAyfDA&ixlib=rb-4.0.3&q=80&w=1080',
      style: styles.ImageContainer
    },
   
    {
      imageUrl: 'https://images.unsplash.com/photo-1629630331106-18c4c2aff510?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDUzMDJ8MHwxfHNlYXJjaHw2fHxBaXJjcmFmdCUyQyUyMFJ1bndheSUyQyUyMFRlcm1pbmFsfGVufDF8fHx8MTcwOTE3MjYwMnww&ixlib=rb-4.0.3&q=80&w=1080',
      style: styles.ImageContainer
    },
   
  ];