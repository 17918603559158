import React from "react";
import { Card, Flex, Text } from "@aws-amplify/ui-react"; // Adjust this import based on your UI library or components

const PopularTrainJourney = () => {
  // Array of journey objects
  const journeys = [
    {
      id: 1,
      image: "https://www.eurail.com/content/eurail/en/plan-your-trip/eurail-timetable/jcr:content/root/responsivegrid/section_1753353961/section_par/blockcolumns_copy/field1/block/field1/image.adaptive.420.1708348226053.jpg",
      title: "Rome to Milan",
      duration: "3h",
      price: "$16.50",
    },
    {
      id: 2,
      image: "https://www.eurail.com/content/eurail/en/plan-your-trip/eurail-timetable/jcr:content/root/responsivegrid/section_1753353961/section_par/blockcolumns_copy/field2/block/field1/image_copy.adaptive.420.1708348229776.jpg",
      title: "Paris to Berlin",
      duration: "4h",
      price: "$20.00",
    },
    {
      id: 3,
      image: "https://www.eurail.com/content/eurail/en/plan-your-trip/eurail-timetable/jcr:content/root/responsivegrid/section_1753353961/section_par/blockcolumns_copy/field3/block/field1/image_copy.adaptive.420.1708348232693.jpg",
      title: "Madrid to Barcelona",
      duration: "2.5h",
      price: "$14.75",
    },
    {
      id: 4,
      image: "https://www.eurail.com/content/eurail/en/plan-your-trip/eurail-timetable/jcr:content/root/responsivegrid/section_1753353961/section_par/blockcolumns_1508240_963127688/field1/block/field1/image.adaptive.420.1708348236631.jpg",
      title: "London to Edinburgh",
      duration: "5h",
      price: "$25.00",
    },
    {
      id: 5,
      image: "https://www.eurail.com/content/eurail/en/plan-your-trip/eurail-timetable/jcr:content/root/responsivegrid/section_1753353961/section_par/blockcolumns_1508240_963127688/field2/block/field1/image.adaptive.420.1708348241281.jpg",
      title: "Vienna to Prague",
      duration: "3.5h",
      price: "$18.25",
    },
    {
      id: 6,
      image: "https://www.eurail.com/content/eurail/en/plan-your-trip/eurail-timetable/jcr:content/root/responsivegrid/section_1753353961/section_par/blockcolumns_1508240_963127688/field3/block/field1/image.adaptive.420.1708348243938.jpg",
      title: "Amsterdam to Brussels",
      duration: "2h",
      price: "$12.50",
    },
  ];

  return (
    <div style={{ marginBottom: "90px" }} className="mt-[33rem] lg:mt-1">
      <Text as="div" style={{ fontWeight: "bold", fontSize: "24px", marginBottom: "20px" }}>
        Popular train journeys
      </Text>
      <Flex gap="20px" justifyContent="center" wrap={"wrap"}>
        {journeys.map((journey) => (
          <Card key={journey.id} style={{
            width: "300px",
            padding: "0",
            // borderRadius: "8px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            marginBottom: "20px",
          }}>
            <img src={journey.image} alt={journey.title} style={{ width: "100%", height: "auto", borderRadius: "8px", marginBottom: "10px" }} />
            <Text as="div" style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "10px" }}>{journey.title}</Text>
            <Text as="div" style={{ marginBottom: "10px" }}>{journey.duration} | from {journey.price}</Text>
          </Card>
        ))}
      </Flex>
    </div>
  );
};

export default PopularTrainJourney;
