import axios from "axios";

export const fetchData= async(url: string, payload: any)=>{
  let proxyUrl;
  let endPoint;

  if (process.env.NODE_ENV === "development") {
    proxyUrl = "http://localhost:8080/";
    endPoint = proxyUrl + url;
  } else {
    endPoint = url;
  }
  try {
    const response = await axios.post(endPoint, payload, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
}






