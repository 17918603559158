import React from 'react';
import { Table } from '@aws-amplify/ui-react';
import { useSearch } from '../context/searchFlight';
import { formatDateToCustomString } from '../pages/helperFunctions/calender.helper';
import { useNavigate } from 'react-router-dom';
import { useFlightSearchResult } from '../context/flightSearchResults';
import { fetchData } from '../api/api';
import { useLoading } from '../context/loadingContext';
import axios from 'axios';
import { useFlightTypeContext } from '../context/flightTypeContext';

type DateArray = Date[];
type PriceMatrix = number[][];

const getFlexibleDates = (date: Date,numberOfFlexibleDays:any): DateArray => {
  const startDate = new Date(date);
  const endDate = new Date(date);
  startDate.setDate(startDate.getDate() - numberOfFlexibleDays);
  endDate.setDate(endDate.getDate() + numberOfFlexibleDays);
  const dates: Date[] = [];
  for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
    dates.push(new Date(d));
  }
  return dates;
};

const generatePriceMatrix = (departureDate: Date, returnDate: Date,numberOfFlexibleDays:any): PriceMatrix => {
  const departureDates = getFlexibleDates(departureDate,numberOfFlexibleDays);
  const returnDates = getFlexibleDates(returnDate,numberOfFlexibleDays);
  const matrix: PriceMatrix = [];
  for (let i = 0; i < departureDates.length; i++) {
    const row: number[] = [];
    for (let j = 0; j < returnDates.length; j++) {
      const price = Math.floor(Math.random() * 401) + 100;
      row.push(price);
    }
    matrix.push(row);
  }
  return matrix;
};

const createDepatureDate = (flexibleData:any)=>{
    if( flexibleData.length<=0){
      return [];
    }
    const newDatesArray = [];

    for(let i=1; i<flexibleData[0].length; i++){
      if(flexibleData[0][i]){
        const newDate = new Date(flexibleData[0][i]);
      newDatesArray.push(newDate);
      }else{
        newDatesArray.push(null);
      }
      
    }
    return newDatesArray;
}

const createReturenDates = (flexibleData:any)=>{
  if( flexibleData.length<=0){
    return [];
  }
  const newDatesArray = [];
  for(let i=1; i<flexibleData.length; i++){
    if(flexibleData[i][0]){
      const newDate = new Date(flexibleData[i][0]);
    newDatesArray.push(newDate);
    }else{
      newDatesArray.push(null);
    }
  }
  return newDatesArray;
}

const createPriceMatrix=(flexibleData:any)=>{
  if( flexibleData.length<=0){
    return [];
  }
  const newDatesArray = [];
  for(let i=1; i<flexibleData.length; i++){
    const newArray = [];
    for(let j=1; j<flexibleData[i].length; j++){
      newArray.push(flexibleData[i][j]);
    }
    newDatesArray.push(newArray);
  }
  return newDatesArray;
}
function findLowestPrice(prices:any) {
  const flatPrices = prices.flat();
  const validPrices = flatPrices.filter((price: any) => price !== null).map((price: string) => parseFloat(price));
  const lowestPrice = Math.min(...validPrices);
  return lowestPrice+"";
}
export const FlightCalendarRoundTrip: React.FC<{ departureDate: Date; flexibleSegmentData:any, returnDate: Date ; numberOfFlexibleDays: Number,flexibleData:any}> = ({ flexibleSegmentData,departureDate, returnDate,numberOfFlexibleDays,flexibleData }) => {
  // const departureDates = getFlexibleDates(departureDate,numberOfFlexibleDays);
  // const returnDates = getFlexibleDates(returnDate,numberOfFlexibleDays);
  console.log(flexibleData,":flexibleData")
  const {selectedFlight,setSelectedFlight}= useFlightTypeContext()
  const departureDates = createDepatureDate(flexibleData);
  const returnDates = createReturenDates(flexibleData);
  const priceMatrix = createPriceMatrix(flexibleData);
  const {searchDetails} = useSearch();
  const { setFlightResultData}=useFlightSearchResult()
  const navigate = useNavigate();
  const { setLoading ,setShowFlightLoading} = useLoading();
  const lowestPrice = findLowestPrice(createPriceMatrix(flexibleData));
  console.log("flexibleData-------->",flexibleData)
console.log(flexibleSegmentData, ":flexibleSegmentData twoway")

  // console.log("priceMatrix2----------->",priceMatrix2)
  const styles = {
    Container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
    },
    Card: {
      top: '102px',
      left: '64px',
      width: '912px',
      // height: '230px',
      backgroundColor: 'rgba(255,255,255,0.48)',
      borderRadius: '24px',
      boxShadow: '0px 2px 10px rgba(3,3,3,0.1)',
    },
    InnerCard: {
      width: '912px',
      height: '60px',
      backgroundColor: '#f0f0f0',
      borderRadius: '24px',
      margin: '-16px'
    },
    ImageContainer: {
      top: '230px',
      left: '104px',
      width: '70px',
      height: '70px',
      borderRadius: '8px',
      backgroundImage: 'url(./image.png)',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
    FlightInfo: {
      color: '#030303',
      fontSize: '16px',
      fontFamily: 'Montserrat',
      lineHeight: '32px',
      fontWeight: 500,
    },
    FlightName: {
      color: '#030303',
      fontSize: '20px',
      fontFamily: 'Montserrat',
      lineHeight: '12px',
      fontWeight: 600,
    },
    FlightDate: {
      color: '#030303',
      fontSize: '24px',
      fontFamily: 'Montserrat',
      fontWeight: 700,
      lineHeight: '31px',
    },
    Boarding: {
      color: '#030303',
      fontSize: '20px',
      fontFamily: 'Montserrat',
      lineHeight: '26px',
    },
    Text: {
      color: '#030303',
      fontSize: '16px',
      fontFamily: 'Montserrat',
      fontWeight: 500,
      lineHeight: '40px',
      // textAlign: 'center',
    },
    ParaText: {
      color: '#030303',
      fontSize: '12px',
      fontFamily: 'Montserrat',
      lineHeight: '26px',
      fontWeight: 400,
      // textAlign: 'center',
    },
    TH:{
      backgroundColor: '#007aff',
      color: 'white',
      width: '100px',
      height: '80px',

    }
  }

  function findSegemtRowColumResponse(segementPairList:any,row:any,colum:any){
    for(let i=0; i<segementPairList.length; i++){
      const splitData = segementPairList[i].segmentPairIndex.split("");
      const rowIndex = +(splitData[0]);
      const columIndex = +(splitData[1]);
      if(rowIndex===row && columIndex===colum){
        return segementPairList[i];
      }
    }

  }
  const clickedCell = async(depatureDateCell : Date,returnDateCell : Date, row:any, col:any) =>{
   const flexibleSegmentResponse= findSegemtRowColumResponse(flexibleSegmentData,row,col)
   
      const payloadObject = {
        numberOfAdults: searchDetails?.numberOfAdults || 0,
        segments: [
          {
            marketingCarrier: flexibleSegmentResponse?.onwardSegment?.marketingCarrier,
            legs: flexibleSegmentResponse?.onwardSegment?.legs || [],
          },
        ],
      };
       setShowFlightLoading(true);
      let proxyUrl;
      let endPoint;
      const url = "https://api.vimanayaatra.com/FareInformativeBestPricing";
      if (process.env.NODE_ENV === "development") {
        proxyUrl = "http://localhost:8080/";
        endPoint = proxyUrl + url;
      } else {
        endPoint = `https://${url}`;
      }
  
      try {
        const response = await axios.post(endPoint, payloadObject, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        if (response.data?.status === "OK") {
          setShowFlightLoading(false);
          setSelectedFlight(flexibleSegmentResponse)
          navigate("/checkout");
        } else {
          setShowFlightLoading(false);
          console.log("price not match-------------------");
        }
      } catch (error: any) {
        setShowFlightLoading(false);
        console.log("price not match-------------------");
      }
    
    // navigate("/flightlist");
    // newBody.departureDate = formatDateToCustomString(returnDateCell);
    // newBody.returnDate = formatDateToCustomString(depatureDateCell);
    // const url = "https://api.vimanayaatra.com/SearchLite";
    // try {
    //   const flightData = await fetchData(url, newBody);
    //   setFlightResultData(flightData)
    //   setShowFlightLoading(false);
    //   navigate("/flightlist");
    // } catch (error: any) {
    
    //   console.error(error.message);
    // }

  }

  return (
    <Table width={"80%"} style={{ borderCollapse: 'collapse', margin: 'auto' }}>
      <thead>
        <tr>
          <th style={styles.TH}></th>
          {departureDates.map((date, index) => (
            <th key={index} style={{  backgroundColor: date?.getTime() === departureDate.getTime() ? 'skyBlue' : '#007aff', color: 'white', width: '50px', height: '50px' }}>
              {date?.toDateString() || "NA"}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {returnDates.map((date, index) => (
          <tr key={index}>
            <td style={{ border: '1px solid black', backgroundColor: returnDate.getTime() === date?.getTime() ? 'skyBlue' : '#007aff', color: 'white', width: '50px', height: '50px' }}>
              {date?.toDateString() || "NA"}
            </td>
            {departureDates.map((departureDate, colIndex) => (
              <td onClick={()=>{
                if(!departureDate || !date){
                  return
                }
                clickedCell(departureDate,date,index+1, colIndex+1);
              }} key={`${index}-${colIndex}`} style={{ border: '1px solid black', width: '100px', height: '80px',cursor:"pointer" ,  ...(priceMatrix[index][colIndex] === lowestPrice ? { backgroundColor: 'green' } : {})}}>
                {priceMatrix[index][colIndex] ? `$ ${priceMatrix[index][colIndex]}`:"NA"}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
