//  This File Having a Popper on Click on that we can select Number Of Travellers for Booking Flight and (Max. Traveller 9, Min. 1 adult)

import React, { useRef, useState, useEffect } from 'react'
import { usePopperContext } from '../context/popperContext';
import { usePopper } from 'react-popper';
import { Flex, SelectField, Text, Button, Card, Divider } from '@aws-amplify/ui-react';
import { CiCircleRemove } from 'react-icons/ci';
import { useFlightTypeContext } from '../context/flightTypeContext';
export const NumberOfTravellers = () => {
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
          if (popperElementRef.current && !popperElementRef.current.contains(event.target as Node)) {
            setIsOpen(false);
          }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);
    
    const styless = {
        Container: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
         
        },
        Card: {
          top: '102px',
          left: '64px',
          width: '1312px',
          height: '290px',
          backgroundColor: 'rgba(255,255,255,0.48)',
          borderRadius: '24px',
          boxShadow: '0px 2px 10px rgba(3,3,3,0.1)',
        },
        Text: {
          color: '#030303',
          fontSize: '40px',
          fontFamily: 'Montserrat',
          fontWeight: 600,
          lineHeight: '60px',
          // textAlign: 'center',
        },
        ParaText: {
          color: '#030303',
          fontSize: '20px',
          fontFamily: 'Montserrat',
          lineHeight: '30px',
          // textAlign: 'center',
        },
        SearchCard: {
          top: '280px',
          // left: '20%',
          width: '1044px',
          height: '180px',
          backgroundColor: '#ffffff',
          borderRadius: '100px',
          boxShadow: '0px 2px 10px rgba(3,3,3,0.1)',
        },
       
        ImageContainer: {
          top: '534px',
          left: '64px',
          width: '316px',
          height: '352px',
          borderRadius: '24px',
          backgroundImage: 'url(./image.jpeg)',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        },
        
       
        AirlinesCard: {
          top: '964px',
          left: '65px',
          width: '249px',
          height: '294px',
          backgroundColor: '#ffffff',
          borderRadius: '24px',
          boxShadow: '0px 2px 10px rgba(3,3,3,0.1)',
        },
       
        AirlinesText: {
          color: '#030303',
          fontSize: '16px',
          fontFamily: 'Montserrat',
          lineHeight: '24px',
        },    
                
        TravelCity: {
          color: '#858585',
          fontSize: '14px',
          fontFamily: 'Montserrat',
          fontWeight: 300,
          lineHeight: '22px',
        },
       
        ArrowIcon: {
          color: '#030303',
          fill: '#030303',
          fontSize: '14px',
          top: '1227px',
          left: '288px',
          width: '14px',
          height: '14px',
        },
       
          SearchSelection: {
            borderRadius: '24px',
            marginTop: '0.6rem',
            borderColor: 'green',
          },
          PopupCard: {
            left: '500px',
            // position: 'absolute',
            top: '50px',
            width: '450px',
            height: '290px',
            //  backgroundColor: 'rgba(255,255,255,0.48)',
            borderRadius: '12px',
            boxShadow: '0px 2px 10px rgba(3,3,3,0.1)',
            
          },
          PopperButton: {
            color: 'white',
            height: '2rem',
            width: '10rem',
            marginTop: '0.8rem',
            paddingTop: '0.2rem',
            borderRadius: '36px',
            backgroundColor: 'rgb(0, 122, 255)',
          }
      };
      const { flightType, setFlightType } = useFlightTypeContext()
    const { popperValue, setPopperValue} = usePopperContext();
    const referenceElement = useRef<HTMLDivElement>(null);
    const popperElementRef = useRef<HTMLDivElement>(null); 
    const [isOpen, setIsOpen] = useState(false);
    const { attributes } = usePopper(referenceElement.current, popperElementRef.current, {
        placement: 'bottom',
        modifiers: [
        {
            name: 'offset',
            options: {
            offset: [0, 10], 
            },
        },
        ],
    });

    
    const [adults, setAdults] = useState(1);
    const [children, setChildren] = useState(0);
    const [infants, setInfants] = useState(0);
    useEffect(() => {
      if (infants > adults) {
        setInfants(infants - 1);
      }
      }, [adults]);
    const incrementAdults = () => {
      if (adults + children + infants < 9) {
      setAdults(adults + 1);

    }
   
  };

  const decrementAdults = () => {
    
    if (adults > 1) {
      setAdults(adults - 1);
    }
  };

  const incrementChildren = () => {
    if (adults + children + infants < 9) {
      setChildren(children + 1);
    }
  };

  const decrementChildren = () => {
    if (children > 0) {
      setChildren(children - 1);
    }
  };

  const incrementInfants = () => {
    if (infants < adults && adults + children + infants < 9) {
      setInfants(infants + 1);
    }
  };

  const decrementInfants = () => {
    if (infants > 0) {
      setInfants(infants - 1);
    }
  };

  const done=()=>{
    if(children===0 && infants===0){
      setPopperValue(`${adults}A,${children}C,${infants}I`)
    }
    else if(infants===0 && children!==0){
      setPopperValue(`${adults}A,${children}C,${infants}I`)
    }
    else if(children===0 && infants!==0){
      setPopperValue(`${adults}A,${children}C,${infants}I `)
    }
    else{ 
      setPopperValue(`${adults}A,${children}C,${infants}I `)
    }
    setIsOpen(false)
 
    

  }
  return (
  <>
  
    
   <div ref={referenceElement} data-testid="toggle-div"  style={styless.PopperButton} onClick={() => setIsOpen(!isOpen)}>
   Travellers:
    {` ${adults}A,${children}C,${infants}I `}
    </div>
 
    {isOpen && (
        <Card ref={popperElementRef} data-testid="modal"   style={{...styless.PopupCard, position: 'absolute',zIndex:"20"}} {...attributes.popper}>
          <div onClick={(e) => e.stopPropagation()}>
            <Flex className="modal-header" display={'flex'} justifyContent={'space-between'} alignSelf={'center'}>
              <Text fontSize={24} textAlign={'center'} >Travels</Text>
              <CiCircleRemove data-testid="close-modal"  onClick={() => setIsOpen(false)} />
            </Flex>
            <Divider></Divider>
            <Flex className="modal-body" marginBlockStart={'1rem'}  direction={'row'} justifyContent={'space-evenly'}>
              <Flex direction={'column'} gap={'0.5rem'}>
                <Text data-testid='adults-id' fontSize={24}>Adults</Text>
                <Text>(Age 12+)</Text>
                <Flex backgroundColor={'#f5f5f5'}>
                   
                  <Button  backgroundColor={'white'} color={'black'} width={8} height={28}  onClick={decrementAdults}>-</Button> 
                  <Text fontWeight={700}>{adults}</Text>
                  <Button backgroundColor={'white'} color={'black'} width={8} height={28}  onClick={incrementAdults}>+</Button> 
                </Flex>
              </Flex>
                <Flex direction={'column'} gap={'0.5rem'}>
                <Text fontSize={24} fontStyle={''}>Children</Text>
                <Text>(Age 2-12)</Text>
                <Flex>
                  <Button backgroundColor={'white'} color={'black'} width={8} height={28}  onClick={decrementChildren}>-</Button> 
                  <Text fontWeight={700}>{children}</Text>
                  <Button backgroundColor={'white'} color={'black'} width={8} height={28} onClick={incrementChildren}>+</Button> 
                </Flex>
              </Flex>
              <Flex direction={'column'} gap={'0.5rem'}>
              <Text fontSize={24}>Infrants</Text>
                <Text >(Age &lt; 2)</Text>
                <Flex direction={'row'} paddingInline={'2px'}>
                  <Button backgroundColor={'white'} color={'black'} width={8} height={28} onClick={decrementInfants}>-</Button> 
                  <Text fontWeight={700}>{infants}</Text>
                  <Button backgroundColor={'white'} color={'black'} width={8} height={28} onClick={incrementInfants}>+</Button> 
                </Flex>
               
              </ Flex>
            </Flex>
      
              <Flex direction={'row'} marginBlock={'2rem'} justifyContent={'space-between'}>
                <SelectField
                    label="type"
                    labelHidden={true}
                    borderRadius={'16px'} 
                    paddingInlineEnd={'1rem'}
                    style={styless.SearchSelection}
                    value={flightType}
                    onChange={(e) => setFlightType(e.target.value)}
                >
                    <option value="ECONOMY">Economy</option>
                    <option value="PREMIUM-ECONOMY">Premium Economy</option>
                    <option value="BUSINESS">Business</option>
                    <option value="FIRST-CLASS">First Class</option>
                </SelectField>
                <Button  backgroundColor= '#007aff' color={'white'} onClick={done}>Done</Button> 
              </Flex>
          </div>
        </Card>
      )}
  </>
  )
}
