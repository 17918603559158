import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useCruisesResult } from '../context/cruisesContext';
export interface Cruise {
    id: number;
    image: string;
    title: string;
    ship: string;
    dates: string;
    stops: string;
    discount: string;
    oldPrice: string;
    newPrice: string;
    description: string;
  }
const CruisesList = () => {
    const {cruises,setSelectedCruise, setCruises} = useCruisesResult();

    useEffect(() => {
      fetch('/cruisesdata.json')
        .then(response => response.json())
        .then(data => setCruises(data));
    }, []);
  return (
    <div>
      {cruises?.map((cruise: { id: any; }) => (
        <CruiseCard key={cruise?.id} cruise={cruise} />
      ))}
    </div>
  )
}

export default CruisesList




const CruiseCard = ({ cruise }:any) => {
  const navigate=useNavigate();
  const {cruises,setSelectedCruise, setCruises} = useCruisesResult();
const handleDetails=()=>{
  console.log(cruise, ':cruise');
  setSelectedCruise(cruise)
  navigate('/cruisedetails')
}
  return (
    <div className="max-w-4xl mx-auto my-10 p-6 bg-white rounded-lg shadow-md flex flex-col md:flex-row"  onClick={handleDetails}>
      <div className="flex-shrink-0">
        <img
          className="h-48 w-full md:w-48 object-cover rounded-md"
          src={cruise.image}
          alt="Cruise"
        />
      </div>
      <div className="mt-4 md:mt-0 md:ml-6 flex flex-col w-full justify-between">
        <div className='flex flex-col items-start'>
          <h2 className="text-xl font-bold text-gray-900">{cruise.title}</h2>
          <p className="text-sm text-gray-600">{cruise.ship}</p>
          <p className="text-sm text-gray-600">{cruise.dates}</p>
          <p className="text-sm text-start text-gray-600">{cruise.stops}</p>
        </div>
        <div className="mt-4 flex flex-col items-end">
          {/* <span className="text-red-600 font-bold">{cruise.discount}</span>
          <p className="text-sm text-gray-600 line-through">The price was {cruise.oldPrice}</p> */}
          <p className="text-xl text-end font-bold text-gray-900">{cruise.newPrice}</p>
          <p className="text-sm text-end text-gray-600">{cruise.description}</p>
        </div>
      </div>
    </div>
  );
};


