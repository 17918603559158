  //  This File Shows Popular Destination for Vacation Which are coming from FlightDestination Component

  import { useEffect, useState } from 'react';
  import { FlightDestination } from '../Constant/flightDestination'
  import { Button, Flex, Grid,Text } from '@aws-amplify/ui-react'
  import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
  export const PopularDestination = () => {
    const styless = {
          ParaText: {
            color: '#030303',
            fontSize: '20px',
            fontFamily: 'Montserrat',
            lineHeight: '30px',
            
          }, 
    };
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(window.innerWidth >= 1024 ? 4 : 1);
  
    const totalPages = Math.ceil(FlightDestination.length / itemsPerPage);
  
    const handleClickNext = () => {
      if (currentPage < totalPages) {
        setCurrentPage(currentPage + 1);
      }
    };
  
    const handleClickPrev = () => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    };
  
    const handleResize = () => {
      setItemsPerPage(window.innerWidth >= 1024 ? 4 : 1);
    };
  
    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, FlightDestination.length);
  
    return (
      <>
        {/* <Flex direction={'column'} gap={'0.8rem'} textAlign={'start'}>
          <Text style={styless.ParaText} data-testid='popular-destination-test'>Popular destinations for flights</Text>
          <Grid templateColumns="1fr 1fr 1fr 1fr" gap={'1rem'}>
              {FlightDestination.map((item, index) => (
                <div key={index} style={{ ...item.style, backgroundImage: `url(${item.imageUrl})` }} />
              ))}
          </Grid>
        </Flex>  */}
        
        
        <div className="flex flex-col gap-3 text-start">
      <Text className="text-black text-lg font-montserrat leading-8" data-testid="popular-destination-test">
        Popular destinations for flights
      </Text>
      <div className="flex justify-between gap-4 w-screen">
        <Button disabled={currentPage === 1} border={'none'} onClick={handleClickPrev}>
          <IoIosArrowBack size={36} />
        </Button>
        <div className="flex-1 grid grid-cols-1 lg:grid-cols-4 ">
          {FlightDestination.slice(startIndex, endIndex).map((item, index) => (
            <div
              key={index}
              className="relative w-full h-72 rounded-xl bg-cover bg-center"
              style={{ backgroundImage: `url(${item.imageUrl})` }}
            />
          ))}
        </div>
        <Button disabled={currentPage === totalPages} border={'none'} onClick={handleClickNext}>
          <IoIosArrowForward size={36} />
        </Button>
      </div>
    </div>
      </>
    )
  }
