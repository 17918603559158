import React, { useState } from 'react';
import { RxCross2 } from "react-icons/rx";
const Drawer = ({ isOpen, onClose, children }:any) => {
  return (
    <div className={`fixed inset-0 z-50 transition-transform ${isOpen ? 'translate-x-0' : 'hidden'} bg-white shadow-lg w-64`}>
      <div className="absolute   bg-green-600 text-2xl  top-4 right-4" onClick={onClose}>
        
      <RxCross2 />
      </div>
      {children}
    </div>
  );
};

export default Drawer;