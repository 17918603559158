import { SavedHotels } from "../Components/SavedHotels";
import { Flex } from "@aws-amplify/ui-react";
import MapWithMarket from "./mapsComponent";

export const Hotels = () => {
  return (
    <Flex>
      <SavedHotels />
      <div className="hidden md:flex">

      <MapWithMarket className="map"/>
      </div>
    </Flex>
  );
};
