import React, { useEffect, useState } from "react";
import { FlightCalendarRoundTrip } from "../Components/FlightCalendarRoundTrip";
import { useTripType } from "../context/tripType";
import { FlightCalendarOneWay } from "../Components/FlightCalenderOneWay";
import { useSearch } from "../context/searchFlight";
import { formatDateForMultipleDates, oneWaySampleData } from "./helperFunctions/calender.helper";
import { fetchData } from "../api/api";
import { useLoading } from "../context/loadingContext";
import { useFlexibleFlightResult } from "../context/flexibleFlightContext";
import { toast } from "react-toastify";

export const Calendar = () => {
  const { searchDetails } = useSearch();
  const { setLoading } = useLoading();
  const { tripType } = useTripType();
  const {  flexibleDays } =
    useFlexibleFlightResult();
  const [flexibleData, setFlexibleData] = useState([]);
  const [flexibleSegmentData, setFlexibleSegmentData] = useState([]);

  console.log("oneWaySampleData---------?",oneWaySampleData)
  
  const departureDate = new Date(
    formatDateForMultipleDates(searchDetails?.departureDate || "20052024")
  ); // Example departure date
  const returnDate = new Date(
    formatDateForMultipleDates(searchDetails?.returnDate || "20072024")
  );
  const numberOfFlexibleDays = searchDetails?.numberOfFlexibleDays || 3;
  const url = "https://api.vimanayaatra.com/FlexibleDateSearchNew";


  const result = async () => {
    setLoading(true);
    try {
      const flightData = await fetchData(url, {
        fromAirport: searchDetails?.fromAirport,
        toAirport: searchDetails?.toAirport,
        departureDate: searchDetails?.departureDate,
        returnDate: searchDetails?.returnDate,
        numberOfAdults: searchDetails?.numberOfAdults,
        typeOfTrip: searchDetails?.typeOfTrip,
        interval: +flexibleDays || 2,
        bookingClass: searchDetails?.bookingClass,
        sliceandDice: false,
      });
      const response = await fetch('/flightdata.json');

      //  const flightData = await response.json();
      console.log("flightData------------>",flightData.recommendations.segmentPairs );
      setFlexibleSegmentData(flightData?.recommendations?.segmentPairs || [])
      setFlexibleData(flightData?.recommendations?.datesArray || []);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast("404 not found");
    }
  };
  useEffect(() => {
    result();
  }, []);

  return (
    <>
      {tripType === "round-trip" ? (
        <FlightCalendarRoundTrip
          departureDate={departureDate}
          returnDate={returnDate}
          numberOfFlexibleDays={numberOfFlexibleDays}
          flexibleData={flexibleData}
          flexibleSegmentData={flexibleSegmentData}
        />
      ) : (
        <FlightCalendarOneWay
          departureDate={departureDate}
          numberOfFlexibleDays={numberOfFlexibleDays}
          flexibleData={flexibleData}
          flexibleSegmentData={flexibleSegmentData}
        />
      )}
    </>
  );
};
