// First Screen which are Header Footer Body

import { Flex } from "@aws-amplify/ui-react";
import "react-datepicker/dist/react-datepicker.css";
import { Footer } from "../Components/common/Footer";
import { Subscription } from "../Components/Subscription";
import { TrustedAirlines } from "../Components/TrustedAirlines";
import { PopularDestination } from "../Components/PopularDestination";
import { Search } from "../Components/Search";
import { RecentSearch } from "../Components/recentSearch";


export const Home = () => {
  return (
    <>
      <Flex direction={"column"} gap={"0px"}>
        <Flex
          direction={"column"}
          gap={"0px"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Search />
          <Flex direction={"column"} gap={"1rem"} marginBlockStart={"3rem"}>
            <RecentSearch />
            <PopularDestination />

            <TrustedAirlines />
          </Flex>
          <Subscription />
          <Footer />
        </Flex>
        <Flex></Flex>
      </Flex>
    </>
  );
};
