import React, { useRef } from 'react'
import { useEffect, useState } from "react";
import { RentalCarSearchDetails, useRentalCarSearchResult } from "../context/rentalCarSearchDetails";
import { useNavigate } from "react-router-dom";
import { DateRange } from 'react-date-range';
import { FiCalendar } from 'react-icons/fi';
import { addDays, format } from "date-fns";
import { formatDate } from "../Constant/dateFormat";
import { Flex } from '@aws-amplify/ui-react';
import { FaUser } from 'react-icons/fa';
import { post,get } from 'aws-amplify/api';
import TicketModal from '../Components/TicketModal';
import PackageModal from '../Components/PackageModal';
import { PackageResult } from '../context/PackagesContext';
import axios from 'axios';

const loadGoogleMapsScript = (callback: () => void) => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA96SrSSmQhUIevuhYcP0uuv5xjLjPxt2A&libraries=places&v=weekly`;
    script.defer = true;
    script.async = true;
    script.onload = callback;
    document.head.appendChild(script);
  };
const StayCar = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    const [typedText, setTypedText] = useState<string>("");
    const [dropoffText, setDropoffText] = useState<string>("");
    const [pickupPoint, setPickupPoint] = useState('');
    const [droppingPoint, setDroppingPoint] = useState('');
    const [pickupDate, setPickupDate] = useState('');
    const [dropoffDate, setDropoffDate] = useState('');
    const [pickupTime, setPickupTime] = useState('');
    const [dropoffTime, setDropoffTime] = useState('');
    const {rentalCarDetails, setRentalCarDetails}=useRentalCarSearchResult()
  const { packageType, setPackageType, setStayCarPackage} = PackageResult();

  const [selectedAddress, setSelectedAddress] = useState<string>("");
  const [addressError, setAddressError] = useState<boolean>(false);
  
  const navigate = useNavigate();
//    const rentalCarDetailsSearch:RentalCarSearchDetails={
//     pickUp: pickupPoint,
//     dropOff: droppingPoint,
//     pickupDate: pickupDate,
//     dropOffDate: dropoffDate,
//     pickupTime: pickupTime,
//     dropOffTime: dropoffTime
//    }
const RentalCarPayload: RentalCarSearchDetails = {
    airportCode: pickupPoint,
    pickupDateTime: `${pickupDate}T${pickupTime}`,
    dropOffDateTime: `${dropoffDate}T${dropoffTime}`
  };
  const packageStayCar: any={
    airportCode: pickupPoint,
    pickupDateTime:pickupDate,
    dropOffDateTime: dropoffDate,
  }
   
const handleSubmit = async (e:any) => {
    e.preventDefault();
console.log("call happen in the stay----")
 

    // try {
    //     const response = await axios.post('https://4h5qd7nwr2.execute-api.us-west-2.amazonaws.com/staging/hotels/search', payload);
    //     console.log('API Response:', response.data);
    //     // Handle the response data as needed
    // } catch (error) {
    //     console.error('Error making API call:', error);
    //     // Handle the error as needed
    // }

    try {
      const restOperation = post({
        apiName: 'hotelsSearch',
        path: '/search',
        options: {
          body: {
            "stay": {
              "checkIn": "2024-07-15",
              "checkOut": "2024-07-16"
            },
            "occupancies": [
              {
                "rooms": 1,
                "adults": 2,
                "children": 0
              },
              {
                "rooms": 1,
                "adults": 1,
                "children": 1,
                "paxes": [
                  {
                    "type": "CH",
                    "age": 2
                  }
                ]
              }
            ],
            "hotels": {
              "hotel": [
                1533,
                1803,
                2587,
                3219,
                6930,
                69995,
                586091,
                586234
              ]
            },
            "filter": {
              "maxHotels": 1
            }
          }
        }
      });
      const response = await restOperation.response;
      console.log('PUT call succeeded: ', response);
    } catch (e:any) {
      console.log('PUT call failed:---- ', e.response);
    }


   
};

  
   console.log(rentalCarDetails,":rentalCarDetails")
    const timeSlots = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minutes = 0; minutes < 60; minutes += 30) {
        const time = `${hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
        timeSlots.push(time);
      }
    }
    const wrapperRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        function handleClickOutside(event: any) {
          if (
            wrapperRef.current &&
            !wrapperRef.current.contains(event.target as Node)
          ) {
            setOpenDate(false);
          }
        }
    
        window.addEventListener("click", handleClickOutside);
        return () => {
          window.removeEventListener("click", handleClickOutside);
        };
      }, [wrapperRef]);
    useEffect(() => {
        loadGoogleMapsScript(() => {
          const pickupAutocomplete = new window.google.maps.places.Autocomplete(
            document.getElementById("pickup-address") as HTMLInputElement,
            {
              componentRestrictions: { country: ["us", "ca", "in"] },
              fields: ["address_components", "formatted_address"],
              types: ["address"],
            }
          );
    
          pickupAutocomplete.addListener("place_changed", () => {
            const place = pickupAutocomplete.getPlace();
            setPickupPoint(place?.formatted_address || "");
          });
    
          const dropoffAutocomplete = new window.google.maps.places.Autocomplete(
            document.getElementById("dropoff-address") as HTMLInputElement,
            {
              componentRestrictions: { country: ["us", "ca", "in"] },
              fields: ["address_components", "formatted_address"],
              types: ["address"],
            }
          );
    
          dropoffAutocomplete.addListener("place_changed", () => {
            const place = dropoffAutocomplete.getPlace();
            setDroppingPoint(place?.formatted_address || "");
          });
    
        });
      }, []);
      const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTypedText(event.target.value);
        setAddressError(false);
      };
    
      const handleInputBlur = () => {
        if (!pickupPoint) {
          setTypedText("");
        }
      };
      const handleDroppingPointChange=(event: React.ChangeEvent<HTMLInputElement>)=>{
        setDropoffText(event.target.value);
      }
      const handleDroppingPointBlur=()=>{
        if (!droppingPoint) {
          setDropoffText("");
          }
      }
      const [dateRange, setDateRange] = useState({
        selection1: {
          startDate: new Date("2024-07-08"),
          endDate: new Date("2024-09-09"),
          key: "selection1",
        },
      });
      const [openDate, setOpenDate] = useState(false);
      const handleDateChange = (ranges: any) => {
        setDateRange({
          ...dateRange,
          selection1: {
            ...dateRange.selection1,
            ...ranges.selection1,
          },
        });
      };

      const dayContentRenderer = (date: Date) => {
        const day = date.getDate();
        const todayDate = new Date();
        let style: React.CSSProperties = {};
    
        if (
          date >= todayDate &&
        //   colorCodedDates[day] &&
          (date < dateRange.selection1.startDate ||
            date > dateRange.selection1.endDate)
        ) {
          style = {
            // backgroundColor: colorCodedDates[day],
            color: "white",
            width: "90%",
            height: "33px",
            borderRadius: "20%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          };
        } else {
          style = {
            color: "black",
            width: "90%",
            height: "33px",
            borderRadius: "20%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          };
        }
    
        return <div style={style}>{day}</div>;
      };
    const stayCarSearch=()=>{

    }
  return (
    // <div className="bg-white py-8 px-12 rounded shadow-md md:w-11/12 justify-center w-lg mx-auto my-8">
      <form className="space-y-4 md:flex items-end justify-between gap-4" onSubmit={handleSubmit} >
        <div>
          {/* <label className="block text-sm font-medium text-gray-700">Pickup Point</label> */}
          <input
          id="pickup-address"
          name="pickup-address"
          required
          autoComplete="off"
          value={pickupPoint ? pickupPoint : typedText}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          className="mt-1 block w-96 px-8 py-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
          {/* <input type="text" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" /> */}
        </div>
      
        {/* <div>
          <label className="block text-sm font-medium text-gray-700">Pickup Date</label>
          <input type="date" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
           value={pickupDate}
           onChange={(e) => setPickupDate(e.target.value)}
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Dropoff Date</label>
          <input type="date" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" 
           value={dropoffDate}
           onChange={(e) => setDropoffDate(e.target.value)}
          />
        </div> */}
        
        <Flex marginBlock={"4px"} className='border-gray-300 rounded-md shadow-sm'>
            <div
              style={{
                width: "21rem",
                paddingBlockStart: "0.3rem",
                zIndex: "10",
              }}
            >
              <div
                className="px-3 py-3 gap-2 flex items-center border-gray-300 rounded-md shadow-sm border b-2  "
                style={{
                  width: "21rem",
                //   border: "1px solid gray",
                 
                  // paddingInline: "3rem",
                  // paddingBlock: "0.7rem",
                }}
                onClick={() => setOpenDate(!openDate)}
              >
                <FiCalendar size={14} color="#007aff" />
                {`${format(
                  dateRange.selection1.startDate,
                  "MM/dd/yyyy"
                )} ~ ${format(dateRange.selection1.endDate, "MM/dd/yyyy")}`}
              </div>
              {/* </span> */}
              {openDate && (
                <div
                  style={{
                    zIndex: "1",
                    position: "absolute",
                    backgroundColor: "white",
                    padding: "10px",
                  }}
                >
                 
                  
                  <div style={{ zIndex: "10" }}>
                    <DateRange
                      editableDateInputs={true}
                      onChange={handleDateChange}
                      moveRangeOnFirstSelection={false}
                      className="daterange"
                      ranges={[dateRange.selection1]}
                       months={2}
                      direction="horizontal"
                      minDate={new Date()}
                      showPreview={true}
                      dayContentRenderer={dayContentRenderer}
                       maxDate={addDays(new Date(), 9 * 30)}
                    />
                  </div>
                </div>
              )}
            </div>
          </Flex>
          <div className="lg:w-1/3 flex items-start  justify-center">
        <div className="flex items-center py-1 px-2 rounded-lg cursor-pointer border-gray-300 rounded-md shadow-sm border b-2"   onClick={openModal}>
      <FaUser className="text-xl text-gray-600 mr-4" />
      <div className="text-left">
        <p className="text-md text-gray-800">Travellers</p>
        <p className="text-sm text-gray-900">2travelers, 1room</p>
      </div>
    </div>
        </div>
        <div>
          <button type="submit" className="w-full bg-indigo-600 text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" onClick={stayCarSearch} >
            Search
          </button>
        </div>
        {isModalOpen && <PackageModal closeModal={closeModal} heading='Room 1' />}
      </form>
    // </div>
  )
}

export default StayCar
