// DataContext.tsx
import React, { createContext, useState, useContext, ReactNode } from 'react';

interface PackageResultType {
    passengerPackageData:any;
    setPassengerPackageData: React.Dispatch<any>;
    packageType:any;
    setPackageType: React.Dispatch<any>;
    stayCarPackage:any;
     setStayCarPackage: React.Dispatch<any>;
     flightCarPackage:any;
      setFlightCarPackage: React.Dispatch<any>;
      stayFlightCarPackage:any;
      setStayFlightCarPackage:React.Dispatch<any>;
}
const PackageResultContext = createContext<PackageResultType | undefined>(undefined);
export const PackageResult = () => {
    const context = useContext(PackageResultContext);
    if (!context) {
      throw new Error('useFlightSearchResult must be used within a DataProvider');
    }
    return context;
  };
export const PackageResultProvider:React.FC<{ children: ReactNode }> = ({ children }) =>{
  
  const [passengerPackageData, setPassengerPackageData] = useState<any>({ adultCount: 1, youthCount: 0, infantsCount: 0});
  const [packageType, setPackageType] = useState<string[]>([]);
  const [stayCarPackage, setStayCarPackage] = useState(null);
  const [stayFlightCarPackage, setStayFlightCarPackage] = useState(null);
  const [flightCarPackage, setFlightCarPackage] = useState(null);



  return (
    <PackageResultContext.Provider value={{flightCarPackage, setFlightCarPackage,stayFlightCarPackage, setStayFlightCarPackage,passengerPackageData, setPassengerPackageData,packageType, setPackageType, stayCarPackage, setStayCarPackage}}>
      {children}
    </PackageResultContext.Provider>
  );
};


