// import React from "react";
// import { trainDetails } from "./helperFunctions/train.helper";

// const TrainDetails = () => {
//   return (
//     <div className="p-4">
//       {trainDetails.map((detail, index) => (
//         <div key={index} className="relative flex items-start mb-8">
//           <div className="w-1/4 text-right pr-4 font-bold">{detail.time}</div>
//           <div className="relative flex items-center">
//             {detail.details ? (
//               <div className="w-4 h-4 rounded-full bg-blue-500"></div>
//             ) : (
//               <div className="w-4 h-4 rounded-full border border-blue-500"></div>
//             )}
//             {index < trainDetails.length - 1 && (
//               <div className="absolute left-1/2 top-full transform -translate-x-1/2 w-0.5 h-56 bg-blue-500"></div>
//             )}
//           </div>
//           <div className="w-3/4 pl-4 text-left">
//             <div className="font-bold">{detail.station}</div>
//             {detail.details && (
//               <>
//                 <div className="flex items-center">
//                   {detail.details[0].type === "text" && (
//                     <span className="mr-2">{detail.details[0].content}</span>
//                   )}
//                   {detail.details[1].type === "text" && (
//                     <span className="mr-2">{detail.details[1].content}</span>
//                   )}
//                 </div>
//                 <div className="flex items-center">
//                   {detail.details[2].type === "text" && (
//                     <span className={`mr-2 ${detail.details[2].bold ? "font-bold" : ""}`}>
//                       {detail.details[2].content}
//                     </span>
//                   )}
//                   {detail.details[3].type === "text" && (
//                     <span className={`mr-2 ${detail.details[3].bold ? "font-bold" : ""}`}>
//                       {detail.details[3].content}
//                     </span>
//                   )}
//                 </div>
//                 <div className="p-2 bg-blue-200 mt-2">
//                   <div className="text-sm">{detail.details[4].content}</div>
//                   <div className="text-sm">{detail.details[5].content}</div>
//                 </div>
//                 {detail.details.slice(6).map((d, i) => (
//                   <div key={i} className={`flex items-center ${d.bold ? "font-bold" : ""}`}>
//                     {d.type === "class" && (
//                       <div className="flex justify-between w-full mt-2">
//                         <span>{d.classType}</span>
//                         <span className="font-bold text-lg">{d.price}</span>
//                       </div>
//                     )}
//                   </div>
//                 ))}
//               </>
//             )}
//           </div>
//         </div>
//       ))}
//       <div className="text-right">
//         <button className="mt-4 px-6 py-2 border border-blue-500 rounded-lg bg-blue-500 text-white">
//           Book Train
//         </button>
//       </div>
//     </div>
//   );
// };

// export default TrainDetails;



import React from "react";
import { trainDetails } from "./helperFunctions/train.helper";

const TrainDetails = () => {
  return (
    <div className="p-4 mx-auto max-w-screen-md">
      {trainDetails.map((detail, index) => (
        <div key={index} className="relative flex flex-col md:flex-row items-start mb-8">
          <div className="w-full md:w-1/4 text-right pr-4 font-bold">{detail.time}</div>
          <div className="relative flex items-center md:items-start">
            {detail.details ? (
              <div className="w-4 h-4 rounded-full bg-blue-500"></div>
            ) : (
              <div className="w-4 h-4 rounded-full border border-blue-500"></div>
            )}
            {index < trainDetails.length - 1 && (
              <div className="absolute left-1/2 top-full transform -translate-x-1/2 w-0.5 h-56 md:h-72 bg-blue-500"></div>
            )}
          </div>
          <div className="w-full md:w-3/4 pl-4 text-left">
            <div className="font-bold">{detail.station}</div>
            {detail.details && (
              <>
                <div className="flex flex-wrap items-center">
                  {detail.details.map((d, i) => (
                    <React.Fragment key={i}>
                      {d.type === "text" && (
                        <span className={`mr-2 ${d.bold ? "font-bold" : ""}`}>{d.content}</span>
                      )}
                      {d.type === "class" && (
                        <div className="flex flex-col md:flex-row justify-between w-full mt-2">
                          <span>{d.classType}</span>
                          <span className="font-bold text-lg">{d.price}</span>
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </div>
                {detail.details.length > 0 && (
                  <div className="p-2 bg-blue-200 mt-2">
                    {detail.details[0].type === "text" && (
                      <div className="text-sm">{detail.details[0].content}</div>
                    )}
                    {detail.details[1].type === "text" && (
                      <div className="text-sm">{detail.details[1].content}</div>
                    )}
                    {detail.details[2].type === "text" && (
                      <div className={`text-sm ${detail.details[2].bold ? "font-bold" : ""}`}>
                        {detail.details[2].content}
                      </div>
                    )}
                    {detail.details[3].type === "text" && (
                      <div className={`text-sm ${detail.details[3].bold ? "font-bold" : ""}`}>
                        {detail.details[3].content}
                      </div>
                    )}
                    {detail.details[4]?.type === "text" && (
                      <div className="text-sm">{detail.details[4].content}</div>
                    )}
                    {detail.details[5]?.type === "text" && (
                      <div className="text-sm">{detail.details[5].content}</div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      ))}
      <div className="text-center md:text-right">
        <button className="mt-4 px-6 py-2 border border-blue-500 rounded-lg bg-blue-500 text-white">
          Book Train
        </button>
      </div>
    </div>
  );
};

export default TrainDetails;
