
import { useState } from 'react';
import { Predictions } from '@aws-amplify/predictions';
import { CustomerIdentityDetailsCreateForm, UploadFileCreateForm, UploadFileUpdateForm } from '../ui-components';
import { Card } from '@aws-amplify/ui-react';

export const ReadText = () => {
  const initial = {
    GivenName: "",
    Surname: "",
    Sex: "",
    DateOfBirth: "",
    DateOfExpiry: "",
    PassportNumber: "",
    DateOfIssue: "",
    Nationality: "",
    PlaceOfBirth: "",
  };
  const keyMappings: { [key: string]: string } = {
    "Surname/Nom/Apellidos": "Surname",
    "Given names/Prénoms/Nombres": "GivenName",
    "Sex/Sexe/Sexo": "Sex",
    "Date of birth/Date de naissan Benfacha da vacimanto": "DateOfBirth",
    "Date of issue/Date de délivrance. Fecha de expedicion": "DateOfIssue",
    "Date of expiration/Date d'expiration decha de caducidad": "DateOfExpiry",
    "Nationality/Nationalité/Nacionalided": "Nationality",
    "Place of Birth/Distride naissance/Lugar de nacimiento": "PlaceOfBirth",
    "Passport No./No. du Passeport/No. de Pasaporte": "PassportNumber",
    
};
const modifiedFormValues: any = { ...initial };
    const [file, setFile] = useState<File | null>(null);
    const [formValues, setFormValues] = useState<any>(initial);
    const [customerDetails, setCustomerDetails]= useState<any>(null)
    const styles = {
       
      Card: {
        width: '40%',
       margin: 'auto',
        backgroundColor: 'rgba(255,255,255,0.48)',
        borderRadius: '16px',
        boxShadow: '0px 2px 10px rgba(3,3,3,0.1)',
      },
  }
  const handleFileChange=()=>{

  }
    const Read = async () => {
        if (!file) return;
        console.log('9');
        const response = await Predictions.identify({
            text: {
              source: {
                file
              },
              format: 'ALL'
            }
          });
          console.log( response.text, '17');

setCustomerDetails(response.text)
// if (response.text && response.text.keyValues) {
//   const values: any = { ...initial }; 
//   response.text.keyValues.forEach((keyValue: { key: string, value: any }) => {
//       const key = keyValue.key;
//       const value = keyValue.value.text;

     
//       if (key === "Given names/Prénoms/Nombres") {
//           values["FirstName"] = value; 
//       } else if (key === "Surname/Nom/Apellidos") {
//           values["LastName"] = value; 
//       }
//       else if(key=== "Date of expiration"){
//         values["Expiry"]= value;
//       }
//   });
//   setFormValues(values);
// }

if (response.text && response.text.keyValues) {
  response.text.keyValues.forEach((keyValue: { key: string, value: any }) => {
      const mappedKey = keyMappings[keyValue.key];
      if (mappedKey) {
          modifiedFormValues[mappedKey] = keyValue.value.text;
      }
  });
}

setFormValues(modifiedFormValues);
    }
   
console.log(formValues, '44')
    return (
        <div>
            <input type="file" onChange={(e) => setFile(e.target.files ? e.target.files[0] : null)} />
            <button onClick={Read}>Extract Information</button>
            {/* {
              customerDetails &&
              <>
                <h2>Full Text</h2>
                        <p>{customerDetails.fullText}</p>
                         <table>
                            <thead>
                                <tr>
                                    <th>Key</th>
                                    <th>Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {customerDetails.keyValues.map((keyValue: { key: string, value: any }, index: number) => (
                                    <tr key={index}>
                                        <td>{keyValue.key}</td>
                                        <td>{keyValue.value.text}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
              </>
            } */}
      <Card style={styles.Card} textAlign={'left'}>
      <CustomerIdentityDetailsCreateForm {...formValues} 
         onSubmit={(field)=>{
          console.log(field, 'field')
          return field;
      }}
     
      // onChange={field =>field.GivenName ='field'}
      // InitialState={{GivenName:  'coolemai', Surname: 'sahil' }}
      />
            </Card>
         
        </div>
    );
}
