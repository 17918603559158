import React, { createContext, useState, useContext, ReactNode } from 'react';
const CustomerTravelContext = createContext<any>(undefined);
export const CustomerTravelProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [customerTravelData, setCustomerTravelData] = useState<any>({});
  
    return (
      <CustomerTravelContext.Provider value={{ customerTravelData, setCustomerTravelData }}>
        {children}
      </CustomerTravelContext.Provider>
    );
  };
  export const useCustomerTravel=()=>{
    const Context=useContext(CustomerTravelContext);
    if(!Context){
      throw new Error('useCustomerTravel must be used within a CustomerTravelProvider');
    }
    return Context
  }