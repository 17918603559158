import React from 'react'

const CruiseBreadBrum = () => {
  return (
    <>
     <div className='flex justify-between'>
          <div role="tablist" className="tabs tabs-bordered">
                <input type="radio" name="my_tabs_1" role="tab" className="tab font-semibold" aria-label="Overview" />
               
                <input
                  type="radio"
                  name="my_tabs_1"
                  role="tab"
                  className="tab font-semibold"
                  aria-label="Room Experience"
                  defaultChecked />
               
                <input type="radio" name="my_tabs_1" role="tab" className="tab font-semibold" aria-label="Itinerary" />
                <input type="radio" name="my_tabs_1" role="tab" className="tab font-semibold" aria-label="What's included" />
                <input type="radio" name="my_tabs_1" role="tab" className="tab font-semibold" aria-label="Policies" />
              </div>
              <button className="btn btn-primary rounded-full">Choose a Room</button>
            
          </div> 
    </>
  )
}

export default CruiseBreadBrum
