// This is Subscription Component Present in Home Page

import { Card, Flex, Button, Text } from '@aws-amplify/ui-react';

export const Subscription = () => {
  return (
    <>
      <Card className="top-32 left-4 w-1312 h-92 bg-white rounded-24 shadow-md" marginBlockStart={'2rem'} >
        <Flex direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Flex direction={'row'} gap={'2rem'} >
            <svg className="text-black fill-current w-12 h-12" viewBox="0 0 576 512">
              <path d="M512 32C547.3 32 576 60.65 576 96V128H0V96C0 60.65 28.65 32 64 32H512zM576 416C576 451.3 547.3 480 512 480H64C28.65 480 0 451.3 0 416V224H576V416zM112 352C103.2 352 96 359.2 96 368C96 376.8 103.2 384 112 384H176C184.8 384 192 376.8 192 368C192 359.2 184.8 352 176 352H112zM240 384H368C376.8 384 384 376.8 384 368C384 359.2 376.8 352 368 352H240C231.2 352 224 359.2 224 368C224 376.8 231.2 384 240 384z">
              </path>
            </svg>
            <Flex direction={'column'} gap={'0.2rem'} textAlign={'start'}>
              <Text className="text-black font-semibold text-base">Exclusive deals for loyal travelers! Join our Flyer Club now!</Text>
              <Text className="text-black font-normal text-sm">Want to stay updated with our latest offers and discounts? Subscribe to our newsletter today!</Text>
            </Flex>
          </Flex>
          <Button className="top-33 left-112 w-216 h-38 px-10 py-2 border border-black rounded-full bg-white text-black text-sm font-medium leading-18 outline-none" data-testid='subscribe-button'>Subscribe now</Button>
        </Flex>
      </Card>
    </>
  );
};
