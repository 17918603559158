// This File have FlightTab Component And NumberofTravellers Component

import { Card, Flex, Text } from "@aws-amplify/ui-react";
import React, { useState } from "react";
import { Tab } from "./FlightTabs";
import { NumberOfTravellers } from "./NumberOfTravellers";
import { url } from "inspector";
import { CheckboxField } from "@aws-amplify/ui-react";
import { useFlexibleFlightResult } from "../context/flexibleFlightContext";
import { useLoading } from "../context/loadingContext";

export const Search = () => {
  const { flexibleFlightData, setFlexibleFlightData } =
    useFlexibleFlightResult();
  const { loading, setLoading } = useLoading();
  
  const styless = {
    Card: {
      // top: "102px",
      // left: "64px",
      // width: "1312px",
      // height: "290px",
      backgroundColor: "rgba(255,255,255,0.48)",
      borderRadius: "24px",
      boxShadow: "0px 2px 10px rgba(3,3,3,0.1)",
      backgroundImage: `url(/airline.jpeg)`,
      backgroundSize: "cover",
    },
    Text: {
      color: "#030303",
      fontSize: "40px",
      fontFamily: "Montserrat",
      fontWeight: 600,
      lineHeight: "60px",
      // textAlign: 'center',
    },
    ParaText: {
      color: "#030303",
      fontSize: "20px",
      fontFamily: "Montserrat",
      lineHeight: "30px",
      // textAlign: 'center',
    },
    SearchCard: {
      top: "280px",
      // left: '20%',
      // width: "40%",
      // height: "180px",
      backgroundColor: "#ffffff",
      // backgroundColor:"pink",
      borderRadius: "25px",
      boxShadow: "0px 2px 10px rgba(3,3,3,0.1)",
      // padding: "20px",
    },
  };
  return (
    <>
      <Card style={styless.Card} className="hidden lg:block w-screen h-[290px]">
        <Flex direction={"column"} textAlign={"center"}>
          <Text as="div" style={styless.Text}>
            Book your flights with VimanaYaatra
          </Text>
          <Text style={styless.ParaText}>
            1,480,086 flights available world
          </Text>
        </Flex>
      </Card>
      <Card
        style={{
          ...styless.SearchCard,
          // position: "absolute",
          display: "flex",
          alignItems: "center",
          
        }}
        className="sm:absolute"
      >
        <Flex direction={"row"} gap={"2rem"}>
          <Flex  className="w-full lg:w-8/12">
            <Tab />
           
          </Flex>

          <Flex className="hidden lg:flex lg:w-4/12">
            <NumberOfTravellers />
         
          </Flex>
        </Flex>
        
      </Card>
    </>
  );
};
