import React, { useState } from 'react';
import { useThingsSearchResult } from '../context/thingToDo';

const TicketModal = ({ closeModal }:any) => {
  const {passengerThingsData,setPassengerThingsResultData} =useThingsSearchResult()
  
  const incrementAdult = () => setPassengerThingsResultData({ ...passengerThingsData, adultCount: passengerThingsData.adultCount + 1});
  const decrementAdult = () => passengerThingsData.adultCount > 0 && setPassengerThingsResultData({ ...passengerThingsData, adultCount: passengerThingsData.adultCount - 1});

  const incrementYouth = () => setPassengerThingsResultData({ ...passengerThingsData, youthCount: passengerThingsData.youthCount + 1});
  const decrementYouth = () => passengerThingsData.youthCount > 0 && setPassengerThingsResultData({ ...passengerThingsData, youthCount: passengerThingsData.youthCount - 1});

 
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        <h2 className="text-xl font-bold mb-4">New York CityPASS: Admission to Top 5 Attractions</h2>
        <div className="flex justify-between items-center mb-4">
          <div>
            <p className="text-lg font-semibold">Adult ($146.00)</p>
            <p className="text-gray-500">18+ years</p>
          </div>
          <div className="flex items-center space-x-2">
            <button className="px-3 py-1 bg-gray-200 rounded" onClick={decrementAdult}>-</button>
            <span className="text-lg">{passengerThingsData.adultCount}</span>
            <button className="px-3 py-1 bg-gray-200 rounded" onClick={incrementAdult}>+</button>
          </div>
        </div>
        <div className="flex justify-between items-center mb-4">
          <div>
            <p className="text-lg font-semibold">Youth ($124.00)</p>
            <p className="text-gray-500">6-17 years</p>
          </div>
          <div className="flex items-center space-x-2">
            <button className="px-3 py-1 bg-gray-200 rounded" onClick={decrementYouth}>-</button>
            <span className="text-lg">{passengerThingsData.youthCount}</span>
            <button className="px-3 py-1 bg-gray-200 rounded" onClick={incrementYouth}>+</button>
          </div>
        </div>
        <div className="flex justify-end">
          <button className="px-4 py-2 bg-blue-500 text-white rounded" onClick={closeModal}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default TicketModal;
