import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { carData } from '../Constant/cirData';


interface FormData {
  driverName: string;
 
  phoneNumber: string;
  email: string;
  cardNumber: string;
  expiryDate: string;
  cvv: string;
}

const BookingPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const car = carData.find((_, index) => index.toString() === id);

  const [formData, setFormData] = useState<FormData>({
    driverName: '',
    phoneNumber: '',
    email: '',
    cardNumber: '',
    expiryDate: '',
    cvv: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log('Booking and Payment Details:', formData);
    // Add your payment processing logic here
  };

  // if (!car) {
  //   return <div>Car not found</div>;
  // }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4 bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg w-full md:w-1/2 p-4 mb-4">
        {/* <h2 className="text-2xl font-bold mb-2">Booking for {car.name}</h2>
        <p className="text-gray-500 mb-2">{`Price: ${car.price}`}</p> */}
        <form onSubmit={handleSubmit} className="w-full text-left items-left px-2">
          <div className="mb-4">
            <label className="block text-gray-700">cardHolder Name</label>
            <input
              type="text"
              name="cardNumber"
              value={formData.driverName}
              onChange={handleChange}
              className="mt-1 p-2 w-full border rounded"
              required
            />
          </div>
          {/* <div className="mb-4">
            <label className="block text-gray-700">Driver Age</label>
            <input
              type="number"
              name="driverAge"
              value={formData.driverAge}
              onChange={handleChange}
              className="mt-1 p-2 w-full border rounded"
              required
            />
          </div> */}
          <div className="mb-4">
            <label className="block text-gray-700">Phone Number</label>
            <input
              type="text"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              className="mt-1 p-2 w-full border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="mt-1 p-2 w-full border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Card Number</label>
            <input
              type="text"
              name="cardNumber"
              value={formData.cardNumber}
              onChange={handleChange}
              className="mt-1 p-2 w-full border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Expiry Date</label>
            <input
              type="month"
              name="expiryDate"
              value={formData.expiryDate}
              onChange={handleChange}
              className="mt-1 p-2 w-full border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">CVV</label>
            <input
              type="text"
              name="cvv"
              value={formData.cvv}
              onChange={handleChange}
              className="mt-1 p-2 w-full border rounded"
              required
            />
          </div>
          <button type="submit" className="bg-blue-500 flex items-center justify-center text-white px-4 py-2 rounded">
            <p>

            Pay Now
            </p>
          </button>
        </form>
      </div>
    </div>
  );
};

export default BookingPage;
