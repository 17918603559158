// This page is For Payment While Booking Flight

import { Card, TableBody,Table, TableCell, TableHead, TableRow, Text, Flex, Button, Label, Input, View, Grid, RadioGroupField, Radio, TextAreaField, PhoneNumberField, SelectField, CheckboxField } from '@aws-amplify/ui-react'
import React, { useState } from 'react'
import { usePayment } from '../context/paymentData'
import { PaymentData } from '../types/paymentdata'
import { useCustomerTravel } from '../context/customerTravelDetail'
import { FiCalendar } from 'react-icons/fi'; 
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { Amplify } from 'aws-amplify';

import { withAuthenticator } from '@aws-amplify/ui-react';
// import '@aws-amplify/ui-react/styles.css';
import config from '../amplifyconfiguration.json';
Amplify.configure(config);

 const Pay = () => {
  const [checked, setChecked] = useState(false);
    const [cardName,setCardName]=useState('')
    const [cardNumber,setCardNumber]=useState('')
    const [cvv,setCvv]=useState('')
    const [cardExpiry,setCardExpiry]=useState('')
    const [fullName,setFullName]=useState('')
    const [addressOne,setAddressOne]=useState('')
    const [addressTwo,setAddressTwo]=useState('')
    const [city,setCity]=useState('')
    const [state,setState]=useState('')
    const [zip,setZip]=useState('')
    const [country,setCountry]=useState('')
    const [email,setEmail]=useState('')
    const [phoneNumber,setPhoneNumber]=useState('')
    const [optionalEmail,setOptionalEmail]=useState('')
    const [optionalPhoneNumber,setOptionalPhoneNumber]=useState('')
    const [optionalType,setOptionalType]=useState('')
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const {paymentData, setPaymentData}= usePayment();
    const {customerTravelData, setCustomerTravelData}=useCustomerTravel();
    const handleValidation = () => {
    let isValid = true;
    const newErrors: { [key: string]: string } = {};
    if (!cardNumber) {
      newErrors['cardNumber'] = 'Please enter card number';
      isValid = false;
    } else if (!/^\d+$/.test(cardNumber)) {
      newErrors['cardNumber'] = 'Card number must contain only numeric digits';
      isValid = false;
    } else if (cardNumber.length !== 16) {
      newErrors['cardNumber'] = 'Card number must be exactly 16 digits long';
      isValid = false;
    }
  
    if (!cardName) {
      newErrors['cardName'] = 'Please enter card name';
      isValid = false;
    }
  
    if (!cvv) {
      newErrors['cvv'] = 'Please enter CVV';
      isValid = false;
    } else if (!/^\d+$/.test(cvv)) {
      newErrors['cvv'] = 'CVV must contain only numeric digits';
      isValid = false;
    } else if (cvv.length !== 3) {
      newErrors['cvv'] = 'CVV must be exactly 3 digits long';
      isValid = false;
    }
  
      if (!cardExpiry) {
        newErrors['cardExpiry'] = 'Please enter card expiry';
        isValid = false;
      }
      if (!fullName) {
        newErrors['fullName'] = 'Please enter full name';
        isValid = false;
      }
      if (!addressOne) {
        newErrors['addressOne'] = 'Please enter address line 1';
        isValid = false;
      }
      if (!city) {
        newErrors['city'] = 'Please enter city';
        isValid = false;
      }
      if (!state) {
        newErrors['state'] = 'Please select a state';
        isValid = false;
      }
      if (!zip) {
        newErrors['zip'] = 'Please enter ZIP code';
        isValid = false;
      }
       else if (!/^\d+$/.test(zip)) {
        newErrors['zip'] = 'zip must contain only numeric digits';
        isValid = false;
      } else if (zip.length !== 6) {
        newErrors['zip'] = 'zip must be exactly 6 digits long';
        isValid = false;
      }
      if (!country) {
        newErrors['country'] = 'Please select a country';
        isValid = false;
      }
      if (!email) {
        newErrors['email'] = 'Please enter email';
        isValid = false;
      } else if (!(email)) {
        newErrors['email'] = 'Please enter a valid email';
        isValid = false;
      }
      if (!phoneNumber) {
        newErrors['phoneNumber'] = 'Please enter phone number';
        isValid = false;
      } else if (!(phoneNumber)) {
        newErrors['phoneNumber'] = 'Please enter a valid phone number';
        isValid = false;
      }
    
      setErrors(newErrors);
      return isValid;
    };
  console.log(customerTravelData, '89')

  const handleCardNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newCardNumber = e.target.value;
    setCardNumber(newCardNumber);

   
  };


  const handleCvvChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newCvv = e.target.value;
    setCvv(newCvv);

    if (!/^\d+$/.test(newCvv)) {
      setErrors({ cvv: 'CVV must contain only numeric digits' });
    } else if (newCvv.length !== 3) {
      setErrors({ cvv: 'CVV must be exactly 3 digits long' });
    } else {
      setErrors({}); 
    }
  };

  const [departureDate, setDepartureDate] = useState<Date | null>(null);
  
  const handleDepartureDateChange = (date: Date | null) => {
    setDepartureDate(date);
   
  };




    const handleBooking=()=>{
        const isValid = handleValidation();

        if (isValid) {
            const paymentDataObject: PaymentData = {
                cardName,
                cardNumber,
                cvv,
                cardExpiry,
                fullName,
                addressOne,
                addressTwo,
                city,
                state,
                zip,
                country,
                email,
                phoneNumber,
                optionalEmail,
                optionalPhoneNumber,
              };
          console.log('Form submitted successfully!');
          console.log('Card Name:', cardName);
          console.log('Card Number:', cardNumber);
          console.log('CVV:', cvv);
          console.log('Card Expiry:', cardExpiry);
          console.log('Full Name:', fullName);
          console.log('Address Line 1:', addressOne);
          console.log('Address Line 2:', addressTwo);
          console.log('City:', city);
          console.log('State:', state);
          console.log('ZIP Code:', zip);
          console.log('Country:', country);
          console.log('Email:', email);
          console.log('Phone Number:', phoneNumber);
          console.log('Optional Email:', optionalEmail);
          console.log('Optional Phone Number:', optionalPhoneNumber);
          setPaymentData(paymentDataObject);
        } else {
          console.log('Form validation failed!');
        }
    }
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() + 12); 
    console.log(paymentData, '130')
  return (
    <>
        <Card >
            <Flex direction={'column'} gap={'16px'}>
                <Text backgroundColor={'blue'} textAlign={'start'} fontSize={'24px'} color={'white'} paddingInline={'8px'} paddingBlock={'6px'} >Itinerary Summary</Text>
                <Text textAlign={'end'}>Below amount is in USD</Text>
                <Table
                    caption=""
                    highlightOnHover={false}
                    variation="bordered">
                    <TableHead>
                        <TableRow>
                        <TableCell as="th">Travel Purchases</TableCell>
                        <TableCell as="th">Base Price	</TableCell>
                        <TableCell as="th">Taxes & Fees</TableCell>
                        <TableCell as="th">Sub Total</TableCell>
                        <TableCell as="th">Discount	</TableCell>
                        <TableCell as="th">Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <Flex direction={'row'} justifyContent={'space-between'}>
                                    <Flex direction={'column'}>
                                        <Text  textAlign={'start'} >From - Fort Lauderdale | Apr 18 - Apr 27, 2024</Text>
                                        <Text  textAlign={'start'} >FLL → HNL → HNL → FLL | 1 (ADT) | {customerTravelData.firstName} {customerTravelData.lastName}</Text>
                                        <Text  textAlign={'start'} >Add-ons: :USD 0.00</Text>
                                    </Flex>
                                    <Flex alignItems={'center'}>
                                    <Button>View/Modify</Button>
                                    </Flex>
                                </Flex>
                            </TableCell>
                            <TableCell>Nectarine</TableCell>
                            <TableCell>Raspberry</TableCell>
                            <TableCell>Nectarine</TableCell>
                            <TableCell>Raspberry</TableCell>
                            <TableCell>Nectarine</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell  textAlign={'end'}>Sub-Total</TableCell>
                            <TableCell>Nectarine</TableCell>
                            <TableCell>Raspberry</TableCell>
                            <TableCell>Nectarine</TableCell>
                            <TableCell>Raspberry</TableCell>
                            <TableCell>Nectarine</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Text backgroundColor={'blue'} textAlign={'start'} fontSize={'24px'} color={'white'} paddingInline={'8px'} paddingBlock={'6px'} >Enter Card Details</Text>
                <Flex paddingInline={'8px'} >
                    <Flex direction="column" gap="small" textAlign={'start'}>
                        <Label htmlFor="last_name">Card Number *</Label>
                        <Input id="card_number" name="card_number" isRequired={true}  placeholder="Last Name" value={cardNumber} onChange={handleCardNumberChange} />
                        <Text color="red">{}</Text>
                        <Text color="red">{errors['cardNumber']}</Text>
                    </Flex>
                    <Flex direction="column" gap="small" textAlign={'start'}>
                        <Label htmlFor="card_name">Enter Name on the card *</Label>
                        <Input id="card_name" placeholder="Card Name" isRequired={true} name="card_name" value={cardName}  onChange={(e) => setCardName(e.target.value)} />
                        
                        <Text color="red">{errors['cardName']}</Text>
                    </Flex>   
                    <Flex direction="column" gap="small" textAlign={'start'}>
                        <Label htmlFor="Date of Birth">Expire Date *</Label>
                        <DatePicker
                selected={departureDate}
                onChange={handleDepartureDateChange}
                dateFormat="MM/yyyy"
                minDate={new Date()}
                maxDate={maxDate}
               
                showMonthYearPicker
                className="custom-datepicker"
                
                icon={<FiCalendar size={24} color='red' />}

                showIcon 
              />
                    </Flex>  
                    <Flex direction="column" gap="small" textAlign={'start'}>
                        <Label htmlFor="cvv">CVV</Label>   
                        <Input id="cvv" placeholder="CVV" isRequired={true} name="cvv" value={cvv} onChange={handleCvvChange}/>
                        <Text color="red">{errors['cvv']}</Text>
                    </Flex>  
                </Flex>
                <Flex direction={'row'}>
                    <Text>We accept all major Credit Card :</Text>
                </Flex>
                <Text>Total Fare: USD 745.40 (Merchant Fee 0.00)</Text>
                <Text backgroundColor={'blue'} textAlign={'start'} fontSize={'24px'} color={'white'} paddingInline={'8px'} paddingBlock={'6px'} >Enter Billing Details</Text>
                
                    <Grid
                        templateColumns="1fr 1fr 1fr"
                        templateRows="5rem 5rem"
                        gap={'24px'}
                        paddingInline={'6px'} paddingBlock={'6px'}
                        // border={'1px solid black'}
                        >
                        
                       
                        <Flex direction="column" gap="small" textAlign={'start'}>
                        <Label htmlFor="full_name">Full Name *</Label>
                        <Input id="full_name" name="full_name" isRequired={true}  placeholder="Full Name" value={fullName} onChange={(e) => setFullName(e.target.value)} />
                        <Text color="red">{errors['fullName']}</Text>
                    </Flex>
                     
                    <Flex direction="column" gap="small" textAlign={'start'}>
                    <SelectField
                        label="Country *"
                     onChange={(e) => setCountry(e.target.value)}
                     value={country}
                        >
                          
                        <option value="india">India</option>
                        <option value="usa">Usa</option>
                        <option value="england">england</option>
                        <option value="singapore">singapore</option>
                          
                        
                    </SelectField>
                    <Text color="red">{errors['country']}</Text>
                    </Flex> 
                    <Flex direction="column" gap="small" textAlign={'start'}>
                        <Label htmlFor="address">Address Line 1 *</Label>
                        <Input id="address_one" placeholder="Address 1" isRequired={true} name="address_one" value={addressOne} onChange={(e) => setAddressOne(e.target.value)}/>
                        <Text color="red">{errors['addressOne']}</Text>
                    </Flex>   
                    <Flex direction="column" gap="small" textAlign={'start'}>
                        <Label htmlFor="address">Address Line 2 (Optional)</Label>
                        <Input id="address_two" placeholder="Address 2" isRequired={true} name="address_two" value={addressTwo} onChange={(e) => setAddressTwo(e.target.value)}/>
                    </Flex>
                    <Flex direction="column" gap="small" textAlign={'start'}>
                        <SelectField
                        label="Region/State/Province *"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                        >
                        <option value="rajasthan">Rajasthan</option>
                        <option value="delhi">Delhi</option>
                        <option value="agra">Agra</option>
                        <option value="punjab">Punjab</option>
                        
                    </SelectField>
                    <Text color="red">{errors['state']}</Text>
                    </Flex>  
                    <Flex direction="column" gap="small" textAlign={'start'}>
                        <Label htmlFor="card_name">City *</Label>
                        <Input id="city" placeholder="City" isRequired={true} name="city" value={city} onChange={(e) => setCity(e.target.value)}/>
                        <Text color="red">{errors['city']}</Text>
                    </Flex>
                    <Flex direction="column" gap="small" textAlign={'start'}>
                        <Label htmlFor="first_name">Zip Code *</Label>
                        <Input id="zip_code" placeholder="Zip Code" isRequired={true} name="zip_code" value={zip} onChange={(e) => setZip(e.target.value)}/>
                        <Text color="red">{errors['zip']}</Text>
                    </Flex>   
                    <Flex direction="column" gap="small" textAlign={'start'}>
                    
                        <PhoneNumberField
                defaultDialCode="+1"
                label="Phone number"
                placeholder="234-567-8910"
                value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}
            />
                 <Text color="red">{errors['phoneNumber']}</Text>
                </Flex>    
                    <Flex direction="column" gap="small" textAlign={'start'}>
                        <Label htmlFor="last_name">Email *</Label>
                        <Input id="email" name="email" isRequired={true}  placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        <Text color="red">{errors['email']}</Text>
                        
                    </Flex>
                      
                   
                    <Flex direction="column" gap="small" textAlign={'start'}>
                        <Flex>
                        <Label htmlFor="first_name">Alternate Contact Details</Label>
                        <Flex>

                        <RadioGroupField
                            legend="type"
                            name="type"
                            labelPosition="bottom"
                            legendHidden
                            direction="row"
                            onChange={(e) => setOptionalType(e.target.value)}
                            >
                            <Radio value="OptionalTypePhone">Phone</Radio>
                            <Radio value="OptionalTypeEmail">Email</Radio>

                        </RadioGroupField>

                        </Flex>
                        </Flex>
                        {
                            optionalType=== 'OptionalTypePhone' ? (
                                <PhoneNumberField
                                    defaultDialCode="+1"
                                    label="Phone number"
                                    placeholder="234-567-8910"
                                    value={optionalPhoneNumber} onChange={(e) => setOptionalPhoneNumber(e.target.value)}
                                />
                            ) : 
                            <Input id="optional_email" placeholder="Optional Email" name="optional_email" value={optionalEmail} onChange={(e) => setOptionalEmail(e.target.value)}/>
                   
                        }
                        
                         </Flex>
                       
                    </Grid>
                    
                  <CheckboxField
                  label="By clicking 'Confirm & Book', I agree that I have read and accepted the Terms of use."
                  name="By clicking 'Confirm & Book', I agree that I have read and accepted the Terms of use."
                  value="yes"
                 
                  checked={!checked}
                  onChange={(e) => setChecked(e.target.checked)}
                />
                <Text textAlign={'center'}>By clicking 'Confirm & Book', I agree that I have read and accepted the Terms of use.</Text>
                <Flex alignItems={'center'} justifyContent={'center'}>

                <Button backgroundColor={'red'} alignSelf={'center'} color={'white'} isDisabled={checked}  onClick={handleBooking}>Confirm & Book</Button>
                </Flex>
            </Flex>
        </Card>
    </>
  )
}

// export default Pay;

export default withAuthenticator(Pay);