

export function formatDateForMultipleDates(inputDate) {
    // Extract day, month, and year from the input date string
    const day = inputDate.substring(0, 2);
    const month = inputDate.substring(2, 4);
    const year = inputDate.substring(4);
  
    // Format the date as 'yyyy-mm-dd'
    const formattedDate = `${"20"+year}-${month}-${day}`;
    
    return formattedDate;
  }

  const filterMaping = {

  }

  const filterDepartureTimeMaping = {
      'Morning':'morning departure',
     'Afternoon':"afternoon departure",
     "Night":"night departure",
  }
  const filterReturnTimeMaping = {
    'Morning':'morning return',
   'Afternoon':"afternoon return",
   "Night":"night return",
}

  export function formatDateToCustomString(date) {
    // Extract day, month, and year from the date object
    const day = ('0' + date.getDate()).slice(-2); // Ensure two digits for day
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Ensure two digits for month
    const year = date.getFullYear() % 100;
  
    // Concatenate day, month, and year to form the desired string
    const formattedDate = `${day}${month}${year}`;
  
    return formattedDate;
  }

  function filterFlightsByMaxTime(flights, maxHours) {
    // Convert maxHours to the format used in the data (e.g., 6 hours -> 600)
    const maxTime = maxHours * 100;
  
    // Filter the flights based on the maxTime
    return flights.filter(flight => {
      const time = parseInt(flight.onwardSegment.flyingTime, 10);
      return time <= maxTime;
    });
  }

  

  export function findFilteredData(filter, data) {
    const newFilter = {};
    Object.keys(filter).forEach((key) => {
      if (filter[key] === true) {
        newFilter[key] = true;
      }else if(key==="timeRange"){
        newFilter[key] = filter[key];
      }
    });
    if (Object.keys(newFilter).length === 0) {
      return data;
    }
  
    let newData = [...(data?.recommendations || [])];
  
    // Helper function to filter based on layOverList length
    const filterByLayovers = (data, key, length) => {
      return data.filter((dataValue) => {
        if (key.includes('return')) {
          return (Object.keys(dataValue?.returnSegment?.layOverList || {})).length === length;
        } else if (key.includes('departure')) {
          return (Object.keys(dataValue?.onwardSegment?.layOverList || {})).length === length;
        }
        return false;
      });
    };
  
    // Helper function to filter based on departure times
    const filterByTime = (data, key) => {
      return data.filter((dataValue) => {
        if (key.includes('return')) {
          return filterReturnTimeMaping[dataValue?.returnSegment?.departureTimes] === key;
        } else if (key.includes('departure')) {
          return filterDepartureTimeMaping[dataValue?.onwardSegment?.departureTimes] === key;
        }
        return false;
      });
    };
  
    let returnLayoverFilteredData = newData;
    let departureLayoverFilteredData = newData;
    let returnTimeFilteredData = newData;
    let departureTimeFilteredData = newData;
  
    // Apply return segment filters based on layovers
    if (newFilter['non-return'] || newFilter['one-return'] || newFilter['two-return']) {
      const nonReturnData = newFilter['non-return'] ? filterByLayovers(newData, 'non-return', 0) : [];
      const oneReturnData = newFilter['one-return'] ? filterByLayovers(newData, 'one-return', 1) : [];
      const twoReturnData = newFilter['two-return'] ? filterByLayovers(newData, 'two-return', 2) : [];
      returnLayoverFilteredData = [...nonReturnData, ...oneReturnData, ...twoReturnData];
    }
  
    // Apply departure segment filters based on layovers
    if (newFilter['non-departure'] || newFilter['one-departure'] || newFilter['two-departure']) {
      const nonDepartureData = newFilter['non-departure'] ? filterByLayovers(newData, 'non-departure', 0) : [];
      const oneDepartureData = newFilter['one-departure'] ? filterByLayovers(newData, 'one-departure', 1) : [];
      const twoDepartureData = newFilter['two-departure'] ? filterByLayovers(newData, 'two-departure', 2) : [];
      departureLayoverFilteredData = [...nonDepartureData, ...oneDepartureData, ...twoDepartureData];
    }
  
    // Apply departure segment filters based on time
    if (newFilter['morning departure'] || newFilter['afternoon departure'] || newFilter['night departure']) {
      const morningDepartureData = newFilter['morning departure'] ? filterByTime(newData, 'morning departure') : [];
      const afternoonDepartureData = newFilter['afternoon departure'] ? filterByTime(newData, 'afternoon departure') : [];
      const nightDepartureData = newFilter['night departure'] ? filterByTime(newData, 'night departure') : [];
      departureTimeFilteredData = [...morningDepartureData, ...afternoonDepartureData, ...nightDepartureData];
    }
  
    // Apply return segment filters based on time
    if (newFilter['morning return'] || newFilter['afternoon return'] || newFilter['night return']) {
      const morningReturnData = newFilter['morning return'] ? filterByTime(newData, 'morning return') : [];
      const afternoonReturnData = newFilter['afternoon return'] ? filterByTime(newData, 'afternoon return') : [];
      const nightReturnData = newFilter['night return'] ? filterByTime(newData, 'night return') : [];
      returnTimeFilteredData = [...morningReturnData, ...afternoonReturnData, ...nightReturnData];
    }
  
    // Combine results from all filters
    newData = returnLayoverFilteredData
      .filter((value) => departureLayoverFilteredData.includes(value))
      .filter((value) => returnTimeFilteredData.includes(value))
      .filter((value) => departureTimeFilteredData.includes(value));

      newData = filterFlightsByMaxTime(newData,filter.timeRange);
  
    return { recommendations: newData };
  }

  
  


  export const oneWaySampleData = [
    [
        "2024-07-06",
        "81.99",
        null,
        null,
        null,
        null,
        null,
        null
    ],
    [
        "2024-07-07",
        "82.08",
        null,
        null,
        null,
        null,
        null,
        null
    ],
    [
        "2024-07-08",
        "82.08",
        null,
        null,
        null,
        null,
        null,
        null
    ],
    [
        "2024-07-09",
        "44.09",
        null,
        null,
        null,
        null,
        null,
        null
    ],
    [
        "2024-07-10",
        "43.99",
        null,
        null,
        null,
        null,
        null,
        null
    ],
    [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
    ],
    [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
    ],
    [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
    ]
];

export function responseDataToUiFormatData(input) {
  // Extract parts of the input string
  let day = input.slice(0, 2);
  let month = input.slice(2, 4);
  let year = input.slice(4, 6);

  // Convert year from 2 digits to 4 digits
  year = '20' + year;

  // Convert month number to month name
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  month = monthNames[parseInt(month) - 1];

  // Format the date string
  return `${day} ${month} ${year}`;
}