/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { Button, Flex, Grid, TextField } from "@aws-amplify/ui-react";
import { CustomerIdentityDetails } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function CustomerIdentityDetailsCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    GivenName: "",
    Surname: "",
    Sex: "",
    DateOfBirth: "",
    DateOfExpiry: "",
    PassportNumber: "",
    DateOfIssue: "",
    Nationality: "",
  };
  const [GivenName, setGivenName] = React.useState(initialValues.GivenName);
  const [Surname, setSurname] = React.useState(initialValues.Surname);
  const [Sex, setSex] = React.useState(initialValues.Sex);
  const [DateOfBirth, setDateOfBirth] = React.useState(
    initialValues.DateOfBirth
  );
  const [DateOfExpiry, setDateOfExpiry] = React.useState(
    initialValues.DateOfExpiry
  );
  const [PassportNumber, setPassportNumber] = React.useState(
    initialValues.PassportNumber
  );
  const [DateOfIssue, setDateOfIssue] = React.useState(
    initialValues.DateOfIssue
  );
  const [Nationality, setNationality] = React.useState(
    initialValues.Nationality
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setGivenName(initialValues.GivenName);
    setSurname(initialValues.Surname);
    setSex(initialValues.Sex);
    setDateOfBirth(initialValues.DateOfBirth);
    setDateOfExpiry(initialValues.DateOfExpiry);
    setPassportNumber(initialValues.PassportNumber);
    setDateOfIssue(initialValues.DateOfIssue);
    setNationality(initialValues.Nationality);
    setErrors({});
  };
  const validations = {
    GivenName: [],
    Surname: [],
    Sex: [],
    DateOfBirth: [],
    DateOfExpiry: [],
    PassportNumber: [],
    DateOfIssue: [],
    Nationality: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          GivenName,
          Surname,
          Sex,
          DateOfBirth,
          DateOfExpiry,
          PassportNumber,
          DateOfIssue,
          Nationality,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(new CustomerIdentityDetails(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "CustomerIdentityDetailsCreateForm")}
      {...rest}
    >
      <TextField
        label="Given name"
        isRequired={false}
        isReadOnly={false}
        value={GivenName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              GivenName: value,
              Surname,
              Sex,
              DateOfBirth,
              DateOfExpiry,
              PassportNumber,
              DateOfIssue,
              Nationality,
            };
            const result = onChange(modelFields);
            value = result?.GivenName ?? value;
          }
          if (errors.GivenName?.hasError) {
            runValidationTasks("GivenName", value);
          }
          setGivenName(value);
        }}
        onBlur={() => runValidationTasks("GivenName", GivenName)}
        errorMessage={errors.GivenName?.errorMessage}
        hasError={errors.GivenName?.hasError}
        {...getOverrideProps(overrides, "GivenName")}
      ></TextField>
      <TextField
        label="Surname"
        isRequired={false}
        isReadOnly={false}
        value={Surname}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              GivenName,
              Surname: value,
              Sex,
              DateOfBirth,
              DateOfExpiry,
              PassportNumber,
              DateOfIssue,
              Nationality,
            };
            const result = onChange(modelFields);
            value = result?.Surname ?? value;
          }
          if (errors.Surname?.hasError) {
            runValidationTasks("Surname", value);
          }
          setSurname(value);
        }}
        onBlur={() => runValidationTasks("Surname", Surname)}
        errorMessage={errors.Surname?.errorMessage}
        hasError={errors.Surname?.hasError}
        {...getOverrideProps(overrides, "Surname")}
      ></TextField>
      <TextField
        label="Sex"
        isRequired={false}
        isReadOnly={false}
        value={Sex}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              GivenName,
              Surname,
              Sex: value,
              DateOfBirth,
              DateOfExpiry,
              PassportNumber,
              DateOfIssue,
              Nationality,
            };
            const result = onChange(modelFields);
            value = result?.Sex ?? value;
          }
          if (errors.Sex?.hasError) {
            runValidationTasks("Sex", value);
          }
          setSex(value);
        }}
        onBlur={() => runValidationTasks("Sex", Sex)}
        errorMessage={errors.Sex?.errorMessage}
        hasError={errors.Sex?.hasError}
        {...getOverrideProps(overrides, "Sex")}
      ></TextField>
      <TextField
        label="Date of birth"
        isRequired={false}
        isReadOnly={false}
        value={DateOfBirth}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              GivenName,
              Surname,
              Sex,
              DateOfBirth: value,
              DateOfExpiry,
              PassportNumber,
              DateOfIssue,
              Nationality,
            };
            const result = onChange(modelFields);
            value = result?.DateOfBirth ?? value;
          }
          if (errors.DateOfBirth?.hasError) {
            runValidationTasks("DateOfBirth", value);
          }
          setDateOfBirth(value);
        }}
        onBlur={() => runValidationTasks("DateOfBirth", DateOfBirth)}
        errorMessage={errors.DateOfBirth?.errorMessage}
        hasError={errors.DateOfBirth?.hasError}
        {...getOverrideProps(overrides, "DateOfBirth")}
      ></TextField>
      <TextField
        label="Date of expiry"
        isRequired={false}
        isReadOnly={false}
        value={DateOfExpiry}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              GivenName,
              Surname,
              Sex,
              DateOfBirth,
              DateOfExpiry: value,
              PassportNumber,
              DateOfIssue,
              Nationality,
            };
            const result = onChange(modelFields);
            value = result?.DateOfExpiry ?? value;
          }
          if (errors.DateOfExpiry?.hasError) {
            runValidationTasks("DateOfExpiry", value);
          }
          setDateOfExpiry(value);
        }}
        onBlur={() => runValidationTasks("DateOfExpiry", DateOfExpiry)}
        errorMessage={errors.DateOfExpiry?.errorMessage}
        hasError={errors.DateOfExpiry?.hasError}
        {...getOverrideProps(overrides, "DateOfExpiry")}
      ></TextField>
      <TextField
        label="Passport number"
        isRequired={false}
        isReadOnly={false}
        value={PassportNumber}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              GivenName,
              Surname,
              Sex,
              DateOfBirth,
              DateOfExpiry,
              PassportNumber: value,
              DateOfIssue,
              Nationality,
            };
            const result = onChange(modelFields);
            value = result?.PassportNumber ?? value;
          }
          if (errors.PassportNumber?.hasError) {
            runValidationTasks("PassportNumber", value);
          }
          setPassportNumber(value);
        }}
        onBlur={() => runValidationTasks("PassportNumber", PassportNumber)}
        errorMessage={errors.PassportNumber?.errorMessage}
        hasError={errors.PassportNumber?.hasError}
        {...getOverrideProps(overrides, "PassportNumber")}
      ></TextField>
      <TextField
        label="Date of issue"
        isRequired={false}
        isReadOnly={false}
        value={DateOfIssue}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              GivenName,
              Surname,
              Sex,
              DateOfBirth,
              DateOfExpiry,
              PassportNumber,
              DateOfIssue: value,
              Nationality,
            };
            const result = onChange(modelFields);
            value = result?.DateOfIssue ?? value;
          }
          if (errors.DateOfIssue?.hasError) {
            runValidationTasks("DateOfIssue", value);
          }
          setDateOfIssue(value);
        }}
        onBlur={() => runValidationTasks("DateOfIssue", DateOfIssue)}
        errorMessage={errors.DateOfIssue?.errorMessage}
        hasError={errors.DateOfIssue?.hasError}
        {...getOverrideProps(overrides, "DateOfIssue")}
      ></TextField>
      <TextField
        label="Nationality"
        isRequired={false}
        isReadOnly={false}
        value={Nationality}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              GivenName,
              Surname,
              Sex,
              DateOfBirth,
              DateOfExpiry,
              PassportNumber,
              DateOfIssue,
              Nationality: value,
            };
            const result = onChange(modelFields);
            value = result?.Nationality ?? value;
          }
          if (errors.Nationality?.hasError) {
            runValidationTasks("Nationality", value);
          }
          setNationality(value);
        }}
        onBlur={() => runValidationTasks("Nationality", Nationality)}
        errorMessage={errors.Nationality?.errorMessage}
        hasError={errors.Nationality?.hasError}
        {...getOverrideProps(overrides, "Nationality")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
