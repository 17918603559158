// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const VacationTypes = {
  "CRUISE": "CRUISE",
  "ALLINCLUSIVE": "ALLINCLUSIVE",
  "NATIONALPARKS": "NATIONALPARKS"
};

const { UploadFile, LeadFormForSampleTest, CustomerIdentityDetails } = initSchema(schema);

export {
  UploadFile,
  LeadFormForSampleTest,
  CustomerIdentityDetails,
  VacationTypes
};