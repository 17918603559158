// This page has FOOTER PART WHICH IS PRESENT IN HOMEPAGE
import { useNavigate } from "react-router-dom";
import { Card, Flex, Text } from '@aws-amplify/ui-react';

export const Footer = () => {
  const navigate = useNavigate();

  const styless = {
    FooterImageContainerOne: {
      top: '1562px',
      left: '53px',
      width: '200px',
      height: '57px',
      borderRadius: '8px',
      backgroundImage: 'url(https://assets.api.uizard.io/api/cdn/stream/e672ec1a-52f8-47f3-a698-99c0ec5a9289.jpg)',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
    FooterImageContainerTwo: {
      top: '1542px',
      left: '257px',
      width: '94px',
      height: '81px',
      borderRadius: '8px',
      backgroundImage: 'url(https://assets.api.uizard.io/api/cdn/stream/7c2b2375-149a-4f10-8f19-dd90cf2151d7.jpg)',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    }
  };

  return (
    <Card className="top-1446px left-0 w-full h-176px bg-white shadow-md" marginBlockStart={'4rem'}>
      <Flex direction={'row'} justifyContent={'space-between'} wrap={"wrap"}>
        <Flex direction={'column'} gap={'1rem'}>
          <Text className="text-blue-600 text-2xl font-semibold">VimanaYaatra</Text>
          <Text className="text-blue-600 text-2xl font-semibold"> TidesandTravels Company</Text>
          <Text data-testid="flight-booking-text" className="text-gray-700 text-sm">Your trusted flight booking platform since 2019</Text>
          <Flex>
            <div style={styless.FooterImageContainerOne} />
            <div style={styless.FooterImageContainerTwo} />
          </Flex>
        </Flex>
        <Flex direction={'column'} gap={'0.5rem'}>
          <Text className="text-gray-700 text-sm font-semibold">Help Center</Text>
          <Text className="text-gray-700 text-sm">FAQs</Text>
          <Text className="text-gray-700 text-sm">Customer</Text>
          <Text className="text-gray-700 text-sm cursor-pointer" onClick={() => { navigate("/termAndCondition"); }}>Terms and Conditions</Text>
          <Text className="text-gray-700 text-sm">Customer Support</Text>
        </Flex>
      </Flex>
    </Card>
  )
}
