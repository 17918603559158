import { Button, Flex } from '@aws-amplify/ui-react';
import React, { useRef, useState } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import {boxes} from "./helperFuntions/thinksTravel.helper";

const TravelCarousel = () => {
  // Sample data for boxes
  const itemsPerPage = 4;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(boxes.length / itemsPerPage);

  const handleClickNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleClickPrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, boxes.length);

  return (
    <Flex direction={'row'} justifyContent="center" alignItems="center" gap={'0.8rem'} textAlign={'start'} style={{ overflowX: 'auto' }}>
    <div onClick={handleClickPrev}><IoIosArrowBack size={36}/></div>
     
        {boxes.slice(startIndex, endIndex).map((box, index) => (
          <div key={index} className="bg-gray-200 w-44 p-4 m-2 rounded-lg">
            <h2 className="text-lg font-semibold mb-2">{box.title}</h2>
            <p>{box.date}</p>
            <p>{box.traveller}</p>
          </div>
        ))}
      <div  onClick={handleClickNext}><IoIosArrowForward size={36}/></div>
     
    </Flex>
  );
};

export default TravelCarousel;
