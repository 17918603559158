import React, { useState } from "react";
import { FaUserCircle, FaExchangeAlt, FaPlus, FaMinus, FaClock } from "react-icons/fa"; // Import icons for UI elements
import { Card, Flex, Text } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import { hourArray, minutesArray } from "../helperFunctions/train.helper";

const TrainSearch = () => {
  const navigate = useNavigate();
  const [showViaStationInput, setShowViaStationInput] = useState(false);
  const [selectedDeparting, setSelectedDeparting] = useState(true);
  const [selectedArriving, setSelectedArriving] = useState(false);
  const [departureCity, setDepartureCity] = useState("");
  const [destinationCity, setDestinationCity] = useState("");
  const [viaStation, setViaStation] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [showTravelerModal, setShowTravelerModal] = useState(false);
  const [numTravelers, setNumTravelers] = useState(2); // Default number of travelers
  const [showTimeModal, setShowTimeModal] = useState(false);
  const [timeObject,setTimeObject]  = useState({hour:"06",minutes:"06",period:"PM"});
  console.log("timeObject---->",timeObject);

  const toggleViaStationInput = () => {
    setShowViaStationInput(!showViaStationInput);
  };

  const handleDepartingClick = () => {
    setSelectedDeparting(true);
    setSelectedArriving(false);
  };

  const handleArrivingClick = () => {
    setSelectedArriving(true);
    setSelectedDeparting(false);
  };

  const toggleTravelerModal = () => {
    setShowTravelerModal(!showTravelerModal);
  };

  const decreaseTravelers = () => {
    if (numTravelers > 1) {
      setNumTravelers(numTravelers - 1);
    }
  };

  const increaseTravelers = () => {
    setNumTravelers(numTravelers + 1);
  };

  const handleContinue = () => {
    setShowTravelerModal(false);
    // Additional logic to handle continue button click
  };

  const handleSearch = () => {
    navigate("/trainSearchResult");
  };

  const toggleTimeModal = () => {
    setShowTimeModal(!showTimeModal);
  };

  return (
    <>
      <Flex direction="column" gap="0">
        <Flex direction="column" gap="0" justifyContent="center" alignItems="center">
          <Card className="hidden lg:block w-screen h-[290px] lg:bg-cover lg:bg-no-repeat lg:bg-opacity-50 rounded-3xl shadow-md" style={{ backgroundImage: `url(https://www.eurail.com/content/dam/reservations/reservations-hero.jpg)` }}>
            <Flex direction="column" textAlign="center">
              <Text as="div" className="text-black text-4xl font-bold font-montserrat leading-[60px]">
                Search train times in Europe
              </Text>
              <Text className="text-black text-xl font-montserrat leading-[30px]">
                From big cities to tiny villages, see where your Eurail Pass can take you
              </Text>
            </Flex>
          </Card>
          <Card className="absolute sm:flex flex-col items-center bg-white rounded-3xl shadow-md top-24 bg-gray-100 lg:top-[280px] w-[100vw] lg:w-[60vw] p-4">
            <div className="flex flex-col sm:flex-row items-center w-full mx-auto">
              <div className="flex-1 mb-2 w-full sm:mb-0">
                <Text as="div" className="text-lg font-medium mb-1">
                  From
                </Text>
                <input
                  type="text"
                  className="w-full p-2 rounded border border-gray-300 mt-1"
                  placeholder="Enter departure city"
                  value={departureCity}
                  onChange={(e) => setDepartureCity(e.target.value)}
                />
              </div>
              <FaExchangeAlt size={24} className="mx-2 mt-6 hidden sm:block" />
              <div className="flex-1 mb-2 w-full sm:mb-0">
                <Text as="div" className="text-lg font-medium mb-1">
                  To
                </Text>
                <input
                  type="text"
                  className="w-full p-2 rounded border border-gray-300 mt-1"
                  placeholder="Enter destination city"
                  value={destinationCity}
                  onChange={(e) => setDestinationCity(e.target.value)}
                />
              </div>
              {showViaStationInput ? (
                <div className="flex-1 relative mb-2 sm:mb-0">
                  <Text as="div" className="text-lg font-medium mb-1">
                    Via
                  </Text>
                  <input
                    type="text"
                    className="w-full p-2 rounded border border-gray-300 mt-1"
                    placeholder="Enter via station"
                    value={viaStation}
                    onChange={(e) => setViaStation(e.target.value)}
                  />
                  <FaMinus
                    size={20}
                    className="absolute right-2 mt-4 rounded-full bg-gray-600 top-1/2 transform -translate-y-1/2 text-blue-500 cursor-pointer"
                    onClick={() => setShowViaStationInput(false)}
                  />
                </div>
              ) : (
                <div className="flex items-center mb-2 sm:mb-0 ml-0 sm:ml-2">
                  <FaPlus
                    size={20}
                    className="text-blue-500 cursor-pointer"
                    onClick={() => setShowViaStationInput(true)}
                  />
                  <Text
                    as="span"
                    className="ml-1 underline cursor-pointer"
                    onClick={() => setShowViaStationInput(true)}
                  >
                    via station
                  </Text>
                </div>
              )}
            </div>
            <div className="flex flex-col sm:flex-row items-center w-full mx-auto mt-4">
              <div className="flex items-center mb-2 sm:mb-0">
                <button
                  className={`border-2 rounded px-4 py-2 mr-2 font-bold cursor-pointer ${selectedDeparting ? 'border-blue-500 bg-white' : 'border-gray-300'}`}
                  onClick={handleDepartingClick}
                >
                  Departing
                </button>
                <button
                  className={`border-2 rounded px-4 py-2 mr-2 font-bold cursor-pointer ${selectedArriving ? 'border-blue-500 bg-white' : 'border-gray-300'}`}
                  onClick={handleArrivingClick}
                >
                  Arriving
                </button>
              </div>
              <input
                type="date"
                className="w-full sm:w-[150px] mb-2 sm:mb-0 ml-0 sm:ml-2 p-2 rounded border border-gray-300"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
              />
              <div
                className="flex items-center justify-between w-full sm:w-[100px] mb-2 sm:mb-0 ml-0 sm:ml-2 p-2 rounded border border-gray-300 cursor-pointer"
                onClick={toggleTimeModal}
              >
                {`${timeObject.hour}:${timeObject.minutes} ${timeObject.period}`}
                <FaClock size={16} />
              </div>
              <div
                className="flex items-center border w-full lg:w-48 border-gray-300 px-2 rounded mb-2 sm:mb-0 ml-0 sm:ml-2 cursor-pointer"
                onClick={toggleTravelerModal}
              >
                <FaUserCircle size={24} className="mr-2" />
                <div>
                  <Text as="div" className="font-bold">
                    Traveler
                  </Text>
                  <Text as="div">{numTravelers} Travelers</Text>
                </div>
              </div>
              <button className="border-2 rounded w-full lg:w-24 px-4 py-2 ml-0 sm:ml-2 font-bold cursor-pointer bg-blue-500 text-white" onClick={handleSearch}>
                Search
              </button>
            </div>
          </Card>
        </Flex>
      </Flex>

      {showTravelerModal && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50" onClick={toggleTravelerModal}>
          <div
            className="bg-white rounded-lg p-6 shadow-lg w-80 text-center"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex justify-center items-center mb-4">
              <Text as="div" className="mr-2">
                Traveler
              </Text>
              <button className="text-xl p-1 rounded border border-gray-300 cursor-pointer" onClick={decreaseTravelers}>
                -
              </button>
              <input
                type="number"
                className="w-10 text-lg p-2 rounded border border-gray-300 mx-2 text-center"
                value={numTravelers}
                onChange={(e) => setNumTravelers(parseInt(e.target.value))}
              />
              <button className="text-xl p-1 rounded border border-gray-300 cursor-pointer" onClick={increaseTravelers}>
                +
              </button>
            </div>
            <button className="border-none bg-blue-500 text-white px-4 py-2 rounded cursor-pointer mt-2" onClick={handleContinue}>
              Continue
            </button>
          </div>
        </div>
      )}

      {showTimeModal && (
        <div className="fixed inset-0 flex mt-24 justify-center items-center bg-black bg-opacity-50" onClick={toggleTimeModal}>
          <div
            className="bg-white rounded-lg p-6 shadow-lg w-80 flex justify-around"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex flex-col items-center">
              {hourArray.map(i => {
                const hour = (i + 1).toString().padStart(2, '0');
                return (
                  <div
                    key={hour}
                    className={`cursor-pointer p-2 ${timeObject.hour == hour ? 'bg-blue-500 text-white' : ''}`}
                    onClick={() => setTimeObject({...timeObject,hour:hour})}
                  >
                    {hour}
                  </div>
                );
              })}
            </div>
            <div className="flex flex-col items-center">
              {minutesArray.map(i => {
                const minute = i.toString().padStart(2, '0');
                return (
                  <div
                    key={minute}
                    className={`cursor-pointer p-2 ${timeObject.minutes == minute ? 'bg-blue-500 text-white' : ''}`}
                    onClick={() => setTimeObject({...timeObject,minutes:minute})}
                  >
                    {minute}
                  </div>
                );
              })}
            </div>
            <div className="flex flex-col items-center">
              {["AM", "PM"].map(period => (
                <div
                  key={period}
                  className={`cursor-pointer p-2 ${timeObject.period == period ? 'bg-blue-500 text-white' : ''}`}
                  onClick={() => setTimeObject({...timeObject,period:period})}
                >
                  {period}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TrainSearch;
