import React from 'react';

export const TermsAndConditions: React.FC = () => {

    const styless: { [key: string]: React.CSSProperties } = {
        termsContainer: {
          textAlign: 'left', // Align text to left
          margin: '20px', // Add margin
          color: '#030303',
         fontSize: '20px',
          fontFamily: 'Montserrat',
          lineHeight: '30px',
        }
      };

  return (
    <div style={styless.termsContainer}>
      <h2>TidesandTravels Company DBA VimanaYaatra - Terms and Conditions</h2>
      <h3>AGENCY:</h3>
      <p>
        Products and Services sold by TidesandTravels Company DBA VimanaYaatra as an agent for airline, consolidators and other suppliers providing services, including accommodation and transportation services.
        TidesandTravels Company DBA VimanaYaatra is not a provider of such services and has no responsibility for services provided and not provided by any party.
      </p>
      <p>
        We make no warranty or representation regarding the standard or availability for any service or product.
      </p>
      <p>
        All Travel documents issued are subject to the terms and conditions of sale of the suppliers. TidesandTravels Company DBA VimanaYaatra is merely acting as an agent.
      </p>
      <h3>LIABILITY:</h3>
      <ul>
        <li>TidesandTravels Company DBA VimanaYaatra. has no liability for any act, omission or default, whether negligent or otherwise, of airlines, car rental operators, ferry companies, hoteliers, tour operators or any other supplier or third party.</li>
        <li>We have no liability for any loss or damage occasioned by the negligence, act or omission of any supplier or other third party. We reserve the right to cancel or modify itineraries or bookings where circumstances require.</li>
        <li>In circumstances where liability of TidesandTravels Company DBA VimanaYaatra cannot be excluded, such liability is limited to the value of the purchased travel arrangements. TidesandTravels Company DBA VimanaYaatra reserves the right to decline any booking. </li>
      </ul>
      <h3>ACCEPTED PAYMENT METHODS:</h3>
      <ul>
        <li> Debit cards attached to bank accounts</li>
        <li>Credit cards (if they are not pre-paid) </li>
        <ul>
            <li>Visa, MasterCard™, Discover, American Express® are and vary based on the airline rules. </li>
        </ul>
      </ul>
      <h3>PAYMENT METHODS THAT ARE NOT ACCEPTED:</h3>
      <ul>
        <li>Pre-paid or reloadable credit/debit cards </li>
        <ul>
            <li>Example:cards that can be purchased from a retail store </li>
        </ul>
        <li>PayPal® </li>
        <li>Venmo </li>
        <li>Cash App Card </li>
        <li>ACH bank transfers (Direct Debit)</li>
        <li>Money orders, cash or cash equivalents </li>
        <li>No Flexible-payment methods offered </li>
      </ul>
      {/* <p>
        Pre-paid or reloadable credit/debit cards
        Example: cards that can be purchased from a retail store
        PayPal®, Venmo, Cash App Card
        ACH bank transfers (Direct Debit)
        Money orders, cash or cash equivalents
        No Flexible-payment methods offered
      </p> */}
      <h3>REFUNDS</h3>
      <ul>
        <li>Most of our tickets and fees are NON-REFUNDABLE, but if you have made a refund request to us based on advice from us, there is no guarantee of this refund, nor if refund is granted, there is no guarantee of how long it will take for the refund process. </li>
        <li>For Refundable tickets, airline penalties and TidesandTravels service fee of $25 per person/ticket are applicable. Airline penalties may vary from $375 USD and above per passenger/ticket. </li>
        <li>For any reason, if the airlines are offering a full refund for your ticket, TidesandTravels Company DBA VimanaYaatra will charge a $25.00 service fee per ticket for processing. </li>
        <li>Partially Used Tickets are non-refundable. </li>
      </ul>
      <h3>CREDIT CARD PAYMENTS</h3>
      <ul>
        <li>Ticket cost and other fees can appear as separate charges on your credit card statement. Charges to your credit card may appear in the name of the airline involved, one of their consolidators, or TidesandTravels Company.  </li>
        <li>TidesandTravels Company accepts most credit cards issued in the USA. Credit card acceptances policies may vary by airline and type of fare, or many other factors No third-party credit cards are accepted.  </li>
        <li>Valid documentation for credit authorization needs to be sent toTidesandTravels Company DBA VimanaYaatra before we can process the tickets especially if the traveler is not part of the Ticket reservation. Copy of a valid Driver’s License, or a valid form of identification of the card holder with the current address listed maybe required in some cases.  </li>
        <li>Terms and Conditions must be accepted by the customer beforeTidesandTravels Company DBA VimanaYaatra can issue the tickets. </li>
        <li>Sections of the Phone Calls are recorded where the customer provides consent and authorization to credit card charges. </li>
      </ul>
      <h3>CREDIT CARD DISPUTES / CHARGEBACKS</h3>
      <p>
        The customer agrees not to dispute any charges to their credit card which were authorized by the customer on the website or on a call with TidesandTravels Company. If a customer places a dispute or chargeback on a valid charge purchased by the customer, the customer agrees to pay all fees incurred by TidesandTravels Company DBA VimanaYaatra as a result of the chargeback / credit card dispute including but not limited to chargeback fees, airline chargeback processing fees, and any legal fees.
      </p>
      <h3>FARE CHANGES / RESERVATION CANCELLATION</h3>
      <p>
        In some very rare instances, the fare can change between the time of you ordering the ticket online, and actual ticket issuance. If the fare increases, TidesandTravels Company DBA VimanaYaatra will contact you and see if you want the tickets at the new fare or if you want to cancel your ticket order. If the fare decreases, there is not an option of reverting back the fare difference from the airline. In some very rare instances, a reservation can get cancelled between the time of you ordering the ticket online, and actual ticket issuance. If this does occur, TidesandTravels Company DBA VimanaYaatra will contact you with your options.
      </p>
      <h3>TICKET DELIVERY</h3>
      <p>
        All the airline tickets we sell are E-tickets.
      </p>
      <h3>NAME CHANGES</h3>
      <p>
        No name changes are permitted once the tickets are issued.
      </p>
      <h3>RULES FOR CHANGEABLE OR EXCHANGE TICKETS:</h3>
      <ul>
        <li>Tickets may or may not be changeable. </li>
        <li>If your ticket is changeable, it must be changed and reissued more than 24hours before departure. </li>
        <li>If your ticket is changeable, then the following applies: </li>
        <ul>
            <li>You will be charged any airline penalties plus any applicable fare and tax differences for a date change for both used and unused tickets based on the airlines. Airline penalties may vary from $375 USD and above per passenger/ticket. </li>
            <li>In addition to the airline fees, there will be a service fee of $25.00 per passenger charged by TidesandTravels Company DBA VimanaYaatra. </li>
        </ul>
      </ul>
      <h3>AGE DEFINITIONS:</h3>
      <ul>
        <li>Infant is defined as a passenger less than 2 years of age for the entire length of travel. </li>
        <li>Child is defined as a passenger 2-11 years of age for the entire length of travel. </li>
      </ul>
      <h3>UNACCOMPANIED MINORS:</h3>
      <p>
        Tickets for minors aged under 18 and are traveling alone; these tickets may be purchased but please be advised that most airlines charge fees to for unaccompanied minors and may deny the unaccompanied minor boarding on certain flights. It is your responsibility to contact the airline and make sure that your child will be able to travel the intended itinerary.
      </p>
      <h3>AIRLINE /SUPPLIER INVOLUNTARY CHANGES:</h3>
      <ul>
        <li>Involuntary schedule changes or flight cancellations initiated by the airline are not the responsibility of TidesandTravels Company DBA VimanaYaatra though we will try our best to re-accommodate. </li>
      </ul>
      <h3>NO-SHOW:</h3>
      <ul>
        <li>If passengers do not show up for their flights, tickets hold no value. </li>
      </ul>
      <h3>VISA & PASSPORT REQUIREMENTS:</h3>
      <ul>
        <li>If you are transiting through multiple countries, please check with respective airlines if you need transit visa or visa. </li>
        <li>if you are denied boarding due to insufficient visa, transit visa, passport or other travel document that no refund will be possible. </li>
        <li>Please make sure your Passport is valid for more than 6 months, until the round-trip travel is completed. </li>
      </ul>
      <h3>SEVERABILITY</h3>
      <p>
        These terms and conditions are severable. If any provision is determined to be unenforceable or invalid, such provision shall nonetheless be enforced to the fullest extent permitted by applicable law, and such determination shall not affect the validity and enforceability of any other remaining provisions.
      </p>
      <h3>NO WAIVER</h3>
      <p>
        No failure on the part of TidesandTravels Company DBA VimanaYaatra to enforce any part of these Terms and Conditions shall constitute a waiver of any of TidesandTravels Company DBA VimanaYaatra rights under these Terms and Conditions, for past or future actions on the part of any person. Neither receipt of funds by TidesandTravels Company DBA VimanaYaatra nor the reliance of any person on TidesandTravels Company’s actions shall be deemed to constitute a waiver of any of these Terms and Conditions. Only a written waiver signed by an authorized representative of TidesandTravels Company DBA VimanaYaatra shall have legal effect.
      </p>
    </div>
  );
}
