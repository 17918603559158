// import { Card } from "@aws-amplify/ui-react";
// import React, { useState } from "react";
// import { FaArrowRight, FaCalendarAlt, FaClock, FaPen, FaExchangeAlt, FaUserCircle, FaPlus, FaMinus } from "react-icons/fa"; // Import icons for UI elements
// import { useNavigate } from "react-router-dom";

// const TrainResulttopHeading = ({ onChangeSearch }:any) => {
//   return (
//     <div className="flex items-center p-4 bg-white shadow-md rounded-md">
//       <p className="font-bold text-lg">Barcelona City (Spain)</p>
//       <FaArrowRight className="mx-4" />
//       <p className="font-bold text-lg">Milano City (Italy)</p>
//       <div className="flex items-center ml-6">
//         <FaCalendarAlt className="mr-2" />
//         <p className="text-lg">26 Jul 2024</p>
//       </div>
//       <div className="flex items-center ml-6">
//         <FaClock className="mr-2" />
//         <p className="text-lg">18:06</p>
//       </div>
//       <div className="flex items-center ml-auto">
//         <FaPen className="mr-2" />
//         <p className="text-lg underline cursor-pointer" onClick={onChangeSearch}>
//           Change Search
//         </p>
//       </div>
//     </div>
//   );
// };

// const TrainSearchModel = () => {
//   const navigate = useNavigate();
//   const [showViaStationInput, setShowViaStationInput] = useState(false);
//   const [selectedDeparting, setSelectedDeparting] = useState(true);
//   const [selectedArriving, setSelectedArriving] = useState(false);
//   const [departureCity, setDepartureCity] = useState("");
//   const [destinationCity, setDestinationCity] = useState("");
//   const [viaStation, setViaStation] = useState("");
//   const [selectedDate, setSelectedDate] = useState("");
//   const [selectedTime, setSelectedTime] = useState("06:06 PM");
//   const [showTravelerModal, setShowTravelerModal] = useState(false);
//   const [numTravelers, setNumTravelers] = useState(2); // Default number of travelers
//   const [showSearchModal, setShowSearchModal] = useState(false);

//   const toggleViaStationInput = () => {
//     setShowViaStationInput(!showViaStationInput);
//   };

//   const handleDepartingClick = () => {
//     setSelectedDeparting(true);
//     setSelectedArriving(false);
//   };

//   const handleArrivingClick = () => {
//     setSelectedArriving(true);
//     setSelectedDeparting(false);
//   };

//   const toggleTravelerModal = () => {
//     setShowTravelerModal(!showTravelerModal);
//   };

//   const decreaseTravelers = () => {
//     if (numTravelers > 1) {
//       setNumTravelers(numTravelers - 1);
//     }
//   };

//   const increaseTravelers = () => {
//     setNumTravelers(numTravelers + 1);
//   };

//   const handleContinue = () => {
//     setShowTravelerModal(false);
//     // Additional logic to handle continue button click
//   };

//   const handleSearch = () => {
//     navigate("/trainSearchResult");
//   };

//   const toggleSearchModal = () => {
//     setShowSearchModal(!showSearchModal);
//   };

//   return (
//     <>
//       <TrainResulttopHeading onChangeSearch={toggleSearchModal} />
//       {showSearchModal && (
//         <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50" style={{zIndex:"300"}} onClick={toggleSearchModal}>
//           <div className="rounded-lg p-6 shadow-lg w-[80vw] max-w-lg" onClick={(e) => e.stopPropagation()}>
//             <Card className="absolute sm:flex flex-col items-center bg-white rounded-3xl shadow-md top-[280px] right-[280px]">
//               <div className="flex items-center w-[60vw] mx-auto">
//                 <div className="flex-1">
//                   <p className="text-lg font-medium mb-1">From</p>
//                   <input
//                     type="text"
//                     className="w-full p-2 rounded border border-gray-300 mt-1"
//                     placeholder="Enter departure city"
//                     value={departureCity}
//                     onChange={(e) => setDepartureCity(e.target.value)}
//                   />
//                 </div>
//                 <FaExchangeAlt size={24} className="mx-2" />
//                 <div className="flex-1">
//                   <p className="text-lg font-medium mb-1">To</p>
//                   <input
//                     type="text"
//                     className="w-full p-2 rounded border border-gray-300 mt-1"
//                     placeholder="Enter destination city"
//                     value={destinationCity}
//                     onChange={(e) => setDestinationCity(e.target.value)}
//                   />
//                 </div>
//                 {showViaStationInput ? (
//                   <div className="flex-1 relative">
//                     <p className="text-lg font-medium mb-1">Via</p>
//                     <input
//                       type="text"
//                       className="w-full p-2 rounded border border-gray-300 mt-1"
//                       placeholder="Enter via station"
//                       value={viaStation}
//                       onChange={(e) => setViaStation(e.target.value)}
//                     />
//                     <FaMinus
//                       size={20}
//                       className="absolute right-2 top-1/2 transform -translate-y-1/2 text-blue-500 cursor-pointer"
//                       onClick={() => setShowViaStationInput(false)}
//                     />
//                   </div>
//                 ) : (
//                   <div className="flex items-center ml-2">
//                     <FaPlus
//                       size={20}
//                       className="text-blue-500 cursor-pointer"
//                       onClick={() => setShowViaStationInput(true)}
//                     />
//                     <p className="ml-1 underline cursor-pointer" onClick={() => setShowViaStationInput(true)}>
//                       via station
//                     </p>
//                   </div>
//                 )}
//               </div>
//               <div className="flex items-center w-[60vw] mx-auto mt-4">
//                 <div className="flex items-center">
//                   <button
//                     className={`border-2 rounded px-4 py-2 mr-2 font-bold cursor-pointer ${
//                       selectedDeparting ? "border-blue-500 bg-white" : "border-gray-300"
//                     }`}
//                     onClick={handleDepartingClick}
//                   >
//                     Departing
//                   </button>
//                   <button
//                     className={`border-2 rounded px-4 py-2 mr-2 font-bold cursor-pointer ${
//                       selectedArriving ? "border-blue-500 bg-white" : "border-gray-300"
//                     }`}
//                     onClick={handleArrivingClick}
//                   >
//                     Arriving
//                   </button>
//                 </div>
//                 <input
//                   type="date"
//                   className="w-[150px] ml-2 p-2 rounded border border-gray-300"
//                   value={selectedDate}
//                   onChange={(e) => setSelectedDate(e.target.value)}
//                 />
//                 <input
//                   type="text"
//                   className="w-[100px] ml-2 p-2 rounded border border-gray-300"
//                   value={selectedTime}
//                   onChange={(e) => setSelectedTime(e.target.value)}
//                 />
//                 <div
//                   className="flex items-center border border-gray-300 p-2 rounded ml-2 cursor-pointer"
//                   onClick={toggleTravelerModal}
//                 >
//                   <FaUserCircle size={24} className="mr-2" />
//                   <div>
//                     <p className="font-bold">Traveler</p>
//                     <p>{numTravelers} Travelers</p>
//                   </div>
//                 </div>
//                 <button
//                   className="border-2 rounded px-4 py-2 ml-2 font-bold cursor-pointer bg-blue-500 text-white"
//                   onClick={handleSearch}
//                 >
//                   Search
//                 </button>
//               </div>
//             </Card>
//           </div>
//         </div>
//       )}
//       {showTravelerModal && (
//         <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50" style={{zIndex:"301"}} onClick={toggleTravelerModal}>
//           <div className="bg-white rounded-lg p-6 shadow-lg w-80 text-center" onClick={(e) => e.stopPropagation()}>
//             <div className="flex justify-center items-center mb-4">
//               <p className="mr-2">Traveler</p>
//               <button className="text-xl p-1 rounded border border-gray-300 cursor-pointer" onClick={decreaseTravelers}>
//                 -
//               </button>
//               <input
//                 type="number"
//                 className="w-10 text-lg p-2 rounded border border-gray-300 mx-2 text-center"
//                 value={numTravelers}
//                 onChange={(e) => setNumTravelers(parseInt(e.target.value))}
//               />
//               <button className="text-xl p-1 rounded border border-gray-300 cursor-pointer" onClick={increaseTravelers}>
//                 +
//               </button>
//             </div>
//             <button className="border-none bg-blue-500 text-white px-4 py-2 rounded cursor-pointer mt-2" onClick={handleContinue}>
//               Continue
//             </button>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default TrainSearchModel;


import { Card } from "@aws-amplify/ui-react";
import React, { useState } from "react";
import {
  FaArrowRight,
  FaCalendarAlt,
  FaClock,
  FaPen,
  FaExchangeAlt,
  FaUserCircle,
  FaPlus,
  FaMinus,
} from "react-icons/fa"; // Import icons for UI elements
import { useNavigate } from "react-router-dom";
import { hourArray, minutesArray } from "./helperFunctions/train.helper";

const TrainResulttopHeading = ({ onChangeSearch }: any) => {
  return (
    <div className="flex flex-wrap items-center p-4 bg-white shadow-md rounded-md">
      <p className="text-md font-semibold lg:font-bold lg:text-lg flex-1 md:flex-none">Barcelona City (Spain)</p>
      <FaArrowRight className="mx-4" />
      <p className="text-md font-semibold lg:font-bold lg:text-lg flex-1 md:flex-none">Milano City (Italy)</p>
      <div className="flex items-center ml-4">
        <FaCalendarAlt className="mr-2" />
        <p className="text-lg">26 Jul 2024</p>
      </div>
      <div className="flex items-center ml-4">
        <FaClock className="mr-2" />
        <p className="text-lg">18:06</p>
      </div>
      <div className="flex items-center ml-auto">
        <FaPen className="mr-2" />
        <p className="text-lg underline cursor-pointer" onClick={onChangeSearch}>
          Change Search
        </p>
      </div>
    </div>
  );
};

const TrainSearchModel = () => {
  const navigate = useNavigate();
  const [showViaStationInput, setShowViaStationInput] = useState(false);
  const [selectedDeparting, setSelectedDeparting] = useState(true);
  const [selectedArriving, setSelectedArriving] = useState(false);
  const [departureCity, setDepartureCity] = useState("");
  const [destinationCity, setDestinationCity] = useState("");
  const [viaStation, setViaStation] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("06:06 PM");
  const [showTravelerModal, setShowTravelerModal] = useState(false);
  const [numTravelers, setNumTravelers] = useState(2); // Default number of travelers
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [showTimeModal, setShowTimeModal] = useState(false);
  const [timeObject,setTimeObject]  = useState({hour:"06",minutes:"06",period:"PM"});

  const toggleViaStationInput = () => {
    setShowViaStationInput(!showViaStationInput);
  };

  const handleDepartingClick = () => {
    setSelectedDeparting(true);
    setSelectedArriving(false);
  };

  const handleArrivingClick = () => {
    setSelectedArriving(true);
    setSelectedDeparting(false);
  };

  const toggleTravelerModal = () => {
    setShowTravelerModal(!showTravelerModal);
  };

  const decreaseTravelers = () => {
    if (numTravelers > 1) {
      setNumTravelers(numTravelers - 1);
    }
  };

  const increaseTravelers = () => {
    setNumTravelers(numTravelers + 1);
  };

  const handleContinue = () => {
    setShowTravelerModal(false);
    // Additional logic to handle continue button click
  };

  const handleSearch = () => {
    navigate("/trainSearchResult");
  };

  const toggleSearchModal = () => {
    setShowSearchModal(!showSearchModal);
  };

  const toggleTimeModal = () => {
    setShowTimeModal(!showTimeModal);
  };

  return (
    <>
      <TrainResulttopHeading onChangeSearch={toggleSearchModal} />
      {showSearchModal && (
        
        <div
          className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50"
          onClick={toggleSearchModal}
        >
          <div
            className="rounded-lg p-6 shadow-lg w-full max-w-md"
            onClick={(e) => e.stopPropagation()}
          >
            <Card className="flex flex-col items-center bg-white rounded-3xl shadow-md p-4 w-full  lg:w-[max-content]" >
              <div className="flex flex-col md:flex-row md:items-center w-full space-y-4 md:space-y-0 md:space-x-4">
                <div className="flex-1">
                  <p className="text-lg font-medium mb-1">From</p>
                  <input
                    type="text"
                    className="w-full p-2 rounded border border-gray-300 mt-1"
                    placeholder="Enter departure city"
                    value={departureCity}
                    onChange={(e) => setDepartureCity(e.target.value)}
                  />
                </div>
                <FaExchangeAlt size={24} className="mx-2 hidden lg:block" />
                <div className="flex-1">
                  <p className="text-lg font-medium mb-1">To</p>
                  <input
                    type="text"
                    className="w-full p-2 rounded border border-gray-300 mt-1"
                    placeholder="Enter destination city"
                    value={destinationCity}
                    onChange={(e) => setDestinationCity(e.target.value)}
                  />
                </div>
                {showViaStationInput ? (
                  <div className="flex-1 relative">
                    <p className="text-lg font-medium mb-1">Via</p>
                    <input
                      type="text"
                      className="w-full p-2 rounded border border-gray-300 mt-1"
                      placeholder="Enter via station"
                      value={viaStation}
                      onChange={(e) => setViaStation(e.target.value)}
                    />
                    <FaMinus
                      size={20}
                      className="absolute right-2 mt-4 rounded-full bg-gray-600 top-1/2 transform -translate-y-1/2 text-blue-500 cursor-pointer"
                      onClick={() => setShowViaStationInput(false)}
                    />
                  </div>
                ) : (
                  <div className="flex items-center ml-2">
                    <FaPlus
                      size={20}
                      className="text-blue-500 cursor-pointer"
                      onClick={() => setShowViaStationInput(true)}
                    />
                    <p className="ml-1 underline cursor-pointer" onClick={() => setShowViaStationInput(true)}>
                      via station
                    </p>
                  </div>
                )}
              </div>
              <div className="flex flex-col md:flex-row md:items-center w-full space-y-4 md:space-y-0 md:space-x-4 mt-4">
                <div className="flex flex-col md:flex-row items-center w-full space-y-2 md:space-x-4">
                  <button
                    className={`border-2 rounded px-4 py-2 font-bold cursor-pointer ${
                      selectedDeparting ? "border-blue-500 bg-white" : "border-gray-300"
                    }`}
                    onClick={handleDepartingClick}
                  >
                    Departing
                  </button>
                  <button
                    className={`border-2 rounded px-4 py-2 font-bold cursor-pointer ${
                      selectedArriving ? "border-blue-500 bg-white" : "border-gray-300"
                    }`}
                    onClick={handleArrivingClick}
                  >
                    Arriving
                  </button>
                </div>
                <div className="flex flex-col md:flex-row md:items-center w-full md:space-x-4 space-y-2 md:space-y-0">
                  <input
                    type="date"
                    className="w-full md:w-[150px] p-2 rounded border border-gray-300"
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                  />
                  {/* <input
                    type="text"
                    className="w-full md:w-[100px] p-2 rounded border border-gray-300"
                    value={selectedTime}
                    onChange={(e) => setSelectedTime(e.target.value)}
                  /> */}
                  <div
                className="flex items-center justify-between w-full sm:w-[100px] mb-2 sm:mb-0 ml-0 sm:ml-2 p-2 rounded border border-gray-300 cursor-pointer"
                onClick={toggleTimeModal}
              >
                {`${timeObject.hour}:${timeObject.minutes} ${timeObject.period}`}
                <FaClock size={16} />
              </div>
                  <div
                    className="flex items-center border border-gray-300 p-2 rounded cursor-pointer"
                    onClick={toggleTravelerModal}
                  >
                    <FaUserCircle size={24} className="mr-2" />
                    <div>
                      <p className="font-bold">Traveler</p>
                      <p>{numTravelers} Travelers</p>
                    </div>
                  </div>
                </div>
                <button
                  className="border-2 rounded px-4 py-2 font-bold cursor-pointer bg-blue-500 text-white mt-2"
                  onClick={handleSearch}
                >
                  Search
                </button>
              </div>
            </Card>
          </div>
        </div>
      )}
      {showTravelerModal && (
        <div
          className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50"
          onClick={toggleTravelerModal}
        >
          <div
            className="bg-white rounded-lg p-6 shadow-lg w-full max-w-sm text-center"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex flex-col items-center mb-4">
              <p className="text-lg font-medium mb-2">Traveler</p>
              <div className="flex items-center justify-center mb-2">
                <button
                  className="text-xl p-1 rounded border border-gray-300 cursor-pointer"
                  onClick={decreaseTravelers}
                >
                  -
                </button>
                <input
                  type="number"
                  className="w-10 text-lg p-2 rounded border border-gray-300 mx-2 text-center"
                  value={numTravelers}
                  onChange={(e) => setNumTravelers(parseInt(e.target.value))}
                />
                <button
                  className="text-xl p-1 rounded border border-gray-300 cursor-pointer"
                  onClick={increaseTravelers}
                >
                  +
                </button>
              </div>
              <button
                className="border-none bg-blue-500 text-white px-4 py-2 rounded cursor-pointer mt-2"
                onClick={handleContinue}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      )}

{showTimeModal && (
        <div className="fixed inset-0 flex mt-24 justify-center items-center bg-black bg-opacity-50 z-50" onClick={toggleTimeModal}>
          <div
            className="bg-white rounded-lg p-6 shadow-lg w-80 flex justify-around"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex flex-col items-center">
              {hourArray.map(i => {
                const hour = (i + 1).toString().padStart(2, '0');
                return (
                  <div
                    key={hour}
                    className={`cursor-pointer p-2 ${timeObject.hour == hour ? 'bg-blue-500 text-white' : ''}`}
                    onClick={() => setTimeObject({...timeObject,hour:hour})}
                  >
                    {hour}
                  </div>
                );
              })}
            </div>
            <div className="flex flex-col items-center">
              {minutesArray.map(i => {
                const minute = i.toString().padStart(2, '0');
                return (
                  <div
                    key={minute}
                    className={`cursor-pointer p-2 ${timeObject.minutes == minute ? 'bg-blue-500 text-white' : ''}`}
                    onClick={() => setTimeObject({...timeObject,minutes:minute})}
                  >
                    {minute}
                  </div>
                );
              })}
            </div>
            <div className="flex flex-col items-center">
              {["AM", "PM"].map(period => (
                <div
                  key={period}
                  className={`cursor-pointer p-2 ${timeObject.period == period ? 'bg-blue-500 text-white' : ''}`}
                  onClick={() => setTimeObject({...timeObject,period:period})}
                >
                  {period}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TrainSearchModel;

